const gbbgLeadActions = {
    template: `
    <md-fab-speed-dial
        md-open="vm.isOpen"
        md-direction="up"
        class="md-scale md-fab md-fab-bottom-right"
        ng-mouseenter="vm.isOpen = true"
        ng-mouseleave="vm.isOpen = false"
    >
        <md-fab-trigger>
            <md-button aria-label="menu" class="md-fab md-primary">
                <md-icon md-font-library="material-icons">menu</md-icon>
            </md-button>
        </md-fab-trigger>

        <md-fab-actions>
            <md-button
                aria-label="Save"
                class="md-fab md-raised md-mini md-primary md-hue-1"
                ng-click="vm.save()"
                ng-disabled="vm.processing"
            > <md-icon md-font-library="material-icons">save</md-icon> </md-button>
            <md-button
                aria-label="Delete"
                class="md-fab md-raised md-mini md-warn"
                ng-click="vm.openDialog()"
                ng-disabled="vm.processing"
            > <md-icon md-font-library="material-icons">delete_forever</md-icon> </md-button>
        </md-fab-actions>
    </md-fab-speed-dial>
    `,
    bindings: { lead: '<' },
    controller: leadActionsController,
    controllerAs: 'vm'
};
function leadActionsController($mdDialog, $mdToast, $state, LeadHandler, LeadService, ErrorHandler) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.isOpen = false;
    vm.processing = false;

    vm.openDialog = openDialog;
    vm.save = save;

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you wish to delete this lead?')
            .textContent('This action is permanent and cannot be undone.')
            .ariaLabel('Delete lead')
            .ok('Delete lead')
            .cancel('Cancel')
        ).then(() => confirmDelete(), () => {});
    }

    function confirmDelete() {
        vm.processing = true;
        if (!vm.lead || !vm.lead._id) return;
        LeadService.deleteLead(vm.lead._id).then(
            data => $state.go('root.auth.leads.all'),
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Delete failed!', error);
                console.error("Failed to delete lead:", error);
            }
        );
    }

    function save() {
        vm.processing = true;
        if (!vm.lead || !vm.lead._id) return;
        LeadService.saveLead(vm.lead).then(
            data => {
                showToast('Saved lead successfully!')
                vm.processing = false;
            },
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Failed to save lead', error);
                console.error("Failed to save lead:", error);
            }
        );
    }

    function showToast(message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message || '')
            .position('top right')
            .hideDelay(4000)
        );
    }
}

export { gbbgLeadActions };
