function emailTemplatesListViewController($state, $stateParams, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedTeam = null;

    vm.emails = EmailService.emails;

    vm.getTemplates = getTemplates;

    vm.$onInit = activate;

    function activate() {
        vm.selectedTeam = $stateParams.team || null;
        EmailService.getAllTemplates($stateParams.team).then(
            data => { },
            error => {
                ErrorHandler.showDialog('Failed to get email templates', error);
                console.error('Failed to get email templates:', error);
            }
        );
    }

    function getTemplates(team) {
        $state.go($state.current.name, { team }, { reload: $state.current.name });
    }
}

export { emailTemplatesListViewController }
