import { authorityListViewController } from './authority_list_view_controller';

function authorityRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.authorities', {
        url: '/issuing-authorities?country',
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-new-issuing-authority></gbbg-new-issuing-authority>
                </gbbg-top-nav>
                `
            },
            "main@root.auth": {
                templateUrl: '/partials/views/issuing-authority/list.html',
                controller: authorityListViewController,
                controllerAs: 'vm'
            }
        }
    });
}

export { authorityRoutes };
