const gbbgClientFees = {
    template: `
    <md-card>
        <form name="vm.feeForm">
            <md-card-content>
                    <div layout="row">
                        <label for="from" flex>From</label>
                        <label for="fee" flex>Fee (%)</label>
                    </div>
                    <div layout="row" layout-align="start center" ng-repeat="fee in vm.fees">
                        <md-input-container class="no-margin" flex>
                            <input
                                type="number"
                                name="from"
                                ng-model="fee.from"
                                aria-label="From"
                                required
                                ng-disabled="$index === 0"
                                min="{{vm.getMinFrom($index)}}"
                            />
                        </md-input-container>
                        <md-input-container class="no-margin" flex>
                            <input
                                type="number"
                                name="fee"
                                ng-model="fee.fee"
                                aria-label="Fee as percent"
                                min="0"
                                max="100"
                                required
                            />
                        </md-input-container>

                        <md-button
                            class="md-icon-button"
                            ng-click="vm.remove($index)"
                            ng-disabled="($index === 0)"
                        > <md-icon> remove </md-icon> </md-button>
                    </div>
                </form>
                <p class="md-body-2" ng-if="!vm.fees.length">
                    There are no fees listed. Clients will be allocated £0.00 from a refunded claim with no fee structure.
                </p>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-actions layout="row" layout-align="end">
                <md-button class="md-accent" ng-click="vm.newLine()">New line</md-button>
                <ng-transclude></ng-transclude>
            </md-card-actions>
        </form>
    </md-card>
    `,
    bindings: { fees: '=' },
    transclude: true,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.getMinFrom = getMinFrom;
    vm.newLine = newLine;
    vm.remove = remove;

    function getMinFrom(idx) { return (idx == 0) ? 0 : (vm.fees[idx-1].from || 1); }

    function newLine() {
        if (!vm.fees || vm.fees.length >= 10) return;
        if (vm.fees.length === 0) {
            vm.fees.push({ from: 0, fee: 30 });
        } else {
            const lastFee = vm.fees[vm.fees.length-1];
            vm.fees.push({ from: (lastFee.from+1), fee: 10 });
        }
    }

    function remove(idx) {
        $mdDialog.show(
            $mdDialog
            .confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to remove this fee? Changes will not take effect until you click \'Update\'.')
            .ariaLabel('Remove fee')
            .ok('Remove fee')
            .cancel('Cancel')
        ).then(() => vm.fees.splice(idx, 1), () => {});
    }
}

export { gbbgClientFees };
