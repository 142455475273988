import { default as moment } from 'moment-timezone';

function reportSourceViewController($state, $stateParams, ReportService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.reportTypes = [
        { name: 'Total commission per source', paramString: 'commission' },
        { name: 'Claims by source', paramString: 'claims' },
        // { name: 'Clients by source', paramString: 'clients' }
    ];

    vm.limits = [ '50', '100', '250', '500' ];

    vm.period = {
        from: null,
        to: null,
        today: null
    };

    vm.selectedResults = [];
    vm.selectedSource = null;
    vm.selectedTeam = null;

    vm.reportSummary = ReportService.summary;
    vm.reportData = ReportService.data;

    vm.refresh = refresh;
    vm.toggleSelection = toggleSelection;

    vm.$onInit = activate;

    function activate() {
        if ($stateParams.report && vm.reportTypes.some(({ paramString }) => paramString === $stateParams.report)) {
            vm.selectedReport = $stateParams.report;
        } else {
            vm.selectedReport = vm.reportTypes[0].paramString;
        }

        const today = new Date();
        vm.period.from = new Date($stateParams.from ? Number($stateParams.from) : moment().subtract(3, 'months'));
        vm.period.to = new Date($stateParams.to ? Number($stateParams.to) : today);
        // vm.period.today = today;

        vm.limit = $stateParams.limit || vm.limits[0];
        vm.clientCountry = $stateParams.clientCountry || '';
        vm.selectedTeam = $stateParams.team || 'null';
        vm.selectedSource = $stateParams.source || 'null';

        if (!$stateParams.report) {
            $state.go($state.current.name, {
                report: vm.selectedReport,
                from: vm.period.from.getTime(),
                to: vm.period.to.getTime(),
                limit: vm.limit || $stateParams.limit,
                clientCountry: vm.clientCountry || $stateParams.clientCountry,
                team: vm.selectedTeam || $stateParams.team,
                source: vm.selectedSource || $stateParams.source
            }, { reload: $state.current.name });
        } else {
            getReport();
        }
    }

    function refresh() {
        if (!vm.selectedReport) return;
        $state.go($state.current.name, {
            report: vm.selectedReport,
            from: vm.period.from.getTime() || $stateParams.from,
            to: vm.period.to.getTime() || $stateParams.to,
            limit: vm.limit || $stateParams.limit,
            clientCountry: vm.clientCountry,
            team: vm.selectedTeam || null,
            source: vm.selectedSource || null
        }, { reload: $state.current.name });
    }

    function getReport() {
        vm.selectedResults.splice(0);
        ReportService.getSourceReport({
            report: vm.selectedReport,
            from: vm.period.from.getTime(),
            to: vm.period.to.getTime(),
            limit: vm.limit,
            clientCountry: vm.clientCountry,
            team: vm.selectedTeam,
            source: vm.selectedSource
        }).then(
            data => {
                console.log('RESULT:', data);
                //data.reportData.map(({ _id }) => vm.selectedResults.push(_id))
            },
            error => {
                ErrorHandler.showDialog('Failed to get claim report data', error);
                console.error('Failed to get claim report data:', error);
            },
        );
    }

    function toggleSelection(clientId) {
        if (!clientId) return;
        const idx = vm.selectedResults.indexOf(clientId);
        (idx >= 0) ? vm.selectedResults.splice(idx, 1) : vm.selectedResults.push(clientId);
    }
}

export { reportSourceViewController };
