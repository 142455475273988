function managementRoutes($stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management', {
        url: "/management",
        abstract: true
    });
}

export { managementRoutes };
