const gbbgSignOut = {
    template: `
    <div layout="column">
        <md-button class="md-warn" ng-click="vm.signOut()">Sign out</md-button>
    </div>
    `,
    controller,
    controllerAs: 'vm'
}

function controller($state, $window, AuthService) {
    "ngInject"
    /*validthis:true*/
    const vm = this

    vm.signOut = signOut

    function signOut() {
        AuthService.signOut().then(
            data => destroySession(data),
            error => {
                console.error('Sign out failed. Terminating session anyway.', error)
                destroySession(error)
            }
        )
    }

    function destroySession(data) {
        AuthService.clearSession()
        $state.go('root.signin', { reload: true })
        $window.location.href = process.env.MS_SIGNOUT_REDIRECT || 'https://login.microsoftonline.com/bd6458d5-e66d-475b-a348-456584d3c3e3/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3000'
        // return data
    }
}

export { gbbgSignOut }
