function preClaimListViewController($state, $stateParams, PreClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let requestPage = 1;

    vm.showLoadMore = false;
    vm.isLoading = false;
    vm.preClaims = [];

    vm.getPreClaims = getPreClaims;

    vm.$onInit = getPreClaims;

    function getPreClaims() {
        vm.isLoading = true;
        const { status, from, to, source } = $stateParams;

        const today = new Date();

        if (!status) return $state.go('root.auth.preclaims.all', {
            status: status || localStorage.getItem('gbbgPreClaimFilterStatus') || 'unassigned',
            from: from || localStorage.getItem('gbbgPreClaimFilterFrom') || today.getTime()-7776000000, // 3 months in milliseconds
            to: to || localStorage.getItem('gbbgPreClaimFilterTo') || today.getTime(),
            source: source || localStorage.getItem('gbbgPreClaimFilterSource') || null
        }, { reload: $state.current.name });

        PreClaimService.getAllPreClaims({ status, from, to, source, page: requestPage }).then(
            data => {
                vm.isLoading = false;
                data.preClaims.map(claim => vm.preClaims.push(claim));
                vm.showLoadMore = (data.page < data.pages);
                vm.totalClaims = data.total || 0;
                requestPage = Number(data.page) + 1;
            },
            error => {
                vm.isLoading = false;
                ErrorHandler.showDialog('Failed to get potential claims', error);
                console.error('Failed to get potential claims:', error);
            }
        );
    }
}

export { preClaimListViewController };
