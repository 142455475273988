const alignRight = { halign: 'right' }

const PDF_HEADER = function(data) {
    return [
        [
            { content: 'Claims report', colSpan: 7 },
            { content: data.total, styles: alignRight },
            { content: data.totalGbbgAllocation, styles: alignRight },
            { content: data.totalAgentAllocation, styles: alignRight },
            { content: data.totalClientAllocation, styles: alignRight },
            { content: data.totalCosts, styles: alignRight },
            { content: data.totalCharges, styles: alignRight },
        ],
        [ 'Ref', 'Client', 'Client country', 'Claim country', 'URN', 'Date submitted', 'Date refunded', 'Amt. claimed', 'GBBG fee', 'Agent fee', 'Client fee', 'Restyling', 'Other charges' ]
    ]
}

const PDF_COLUMNS = [
    { header: 'Ref.', dataKey: 'referenceNo' },
    { header: 'Client', dataKey: 'clientName' },
    { header: 'Client country', dataKey: 'clientCountry' },
    { header: 'Claim country', dataKey: 'country' },
    { header: 'URN', dataKey: 'urn' },
    { header: 'Date submitted', dataKey: 'dateSubmitted' },
    { header: 'Date refunded', dataKey: 'dateRefunded' },
    { header: 'Amt. claimed', dataKey: 'amountClaimed' },
    { header: 'GBBG fee', dataKey: 'gbbgFee' },
    { header: 'Agent fee', dataKey: 'agentFee' },
    { header: 'Client fee', dataKey: 'clientFee' },
    { header: 'Restyling', dataKey: 'penaltyTotal' },
    { header: 'Other charges', dataKey: 'costs', styles: { halign: 'right' } }
]

const COLUMN_STYLES = { amountClaimed: alignRight, gbbgFee: alignRight, agentFee: alignRight, clientFee: alignRight, penaltyTotal: alignRight, costs: alignRight }

const STYLES = { fontSize: 8 }

const didDrawPage = function({ doc }) {
    // Example: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
    let str = `Page ${doc.internal.getNumberOfPages()} of {total_pages_count_string}` // https://github.com/simonbengtsson/jsPDF-AutoTable/issues/144#issuecomment-232106480
    doc.setFontSize(8)
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 5, pageHeight - 5)
}

export { PDF_HEADER, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage }
