function reportClaimViewController($state, $stateParams, ReportService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.reportTypes = [
        { name: 'Pending claims (not yet submitted)', paramString: 'pending' },
        { name: 'Submitted claims (not refunded)', paramString: 'submitted' },
        { name: 'Submitted claims (not refunded or resubmitted)', paramString: 'submittedNotResubmitted' },
        { name: 'Resubmitted claims (not refunded)', paramString: 'resubmitted' },
        { name: 'Submitted claims (including refunded)', paramString: 'submittedRefund' },
        { name: 'Refunded from tax authority', paramString: 'taxRefund' },
        { name: 'Refunded from tax authority (refund date expired)', paramString: 'taxRefundDateExpired' }, // If the date refunded to client is set and older than today but the status is still 'refunded'
        { name: 'Refunded from tax authority (invoice created but not sent)', paramString: 'taxRefundInvoiceCreated' }, // The status might still be 'refunded' but no invoice has been issued to the client.
        { name: 'Refunded to client', paramString: 'clientRefund' }
    ];

    vm.selectedClaims = [];
    vm.selectedSource = null;
    vm.selectedTeams = null;

    vm.reportSummary = ReportService.summary;
    vm.reportData = ReportService.data;

    vm.refresh = refresh;

    vm.$onInit = activate;

    function activate() {
        if ($stateParams.report && vm.reportTypes.some(({ paramString }) => paramString === $stateParams.report)) {
            vm.selectedReport = $stateParams.report;
        } else {
            vm.selectedReport = vm.reportTypes[1].paramString;
        }

        vm.claimCountry = $stateParams.claimCountry || '';
        vm.clientCountry = $stateParams.clientCountry || '';
        if (Array.isArray($stateParams.team)) {
            vm.selectedTeams = $stateParams.team || 'null';
        } else {
            if ($stateParams.team) vm.selectedTeams = $stateParams.team.split(',') || 'null';
        }
        if (Array.isArray($stateParams.source)) {
            vm.selectedSource = $stateParams.source || 'null';
        } else {
            if ($stateParams.source) vm.selectedSource = $stateParams.source.split(',') || 'null';
        }

        const d = new Date();
        const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        const threeMonthsAgo = (d.getMonth() >= 3) ? (d.getMonth() - 3) : ((11-3)+d.getMonth()); // set 'vm.dates.from' to 3 months ago by default
        const year = (d.getMonth() >= 3) ? d.getFullYear() : (d.getFullYear()-1); // if 3 months ago was last year, set year to last year
        vm.dates = {
            from: ($stateParams.from && !isNaN(Number($stateParams.from))) ? new Date(Number($stateParams.from)) : new Date(year, threeMonthsAgo, 1),
            to: ($stateParams.to && !isNaN(Number($stateParams.to))) ? new Date(Number($stateParams.to)) : today,
            today: today
        };
        vm.period = Object.create(vm.dates);

        if (!$stateParams.report || !$stateParams.from || !$stateParams.to) {
            $state.go($state.current.name, {
                report : vm.selectedReport,
                from: $stateParams.from || vm.dates.from.getTime(),
                to: $stateParams.to || vm.dates.to.getTime(),
                claimCountry: vm.claimCountry || $stateParams.claimCountry,
                clientCountry: vm.clientCountry || $stateParams.clientCountry,
                team: vm.selectedTeams || $stateParams.team,
                source: vm.selectedSource || $stateParams.source
            }, { reload: $state.current.name });
        } else {
            getReport();
        }
    }

    function refresh() {
        if (!vm.selectedReport) return;
        $state.go($state.current.name, {
            report : vm.selectedReport,
            from: vm.period.from.getTime() || $stateParams.from,
            to: vm.period.to.getTime() || $stateParams.to,
            claimCountry: vm.claimCountry || $stateParams.claimCountry,
            clientCountry: vm.clientCountry || $stateParams.clientCountry,
            team: vm.selectedTeams || null,
            source: vm.selectedSource || null
        }, { reload: $state.current.name });
    }

    function getReport() {
        vm.selectedClaims.splice(0);
        ReportService.getClaimReport({
            report: vm.selectedReport,
            from: vm.period.from.getTime(),
            to: vm.period.to.getTime(),
            claimCountry: vm.claimCountry,
            clientCountry: vm.clientCountry,
            team: Array.isArray(vm.selectedTeams) ? vm.selectedTeams.toString() : vm.selectedTeams,
            source: Array.isArray(vm.selectedSource) ? vm.selectedSource.toString() : vm.selectedSource
        }).then(
            data => data.reportData.map(({ _id }) => vm.selectedClaims.push(_id)),
            error => {
                ErrorHandler.showDialog('Failed to get claim report data', error);
                console.error('Failed to get claim report data:', error);
            },
        )
    }
}

export { reportClaimViewController };
