const gbbgClaimPeriod = {
    template: `
    <h5 class="no-margin">Claim period</h5>
    <div layout="row">
        <md-datepicker
            disable-if-agent
            flex
            ng-model="vm.period.start"
            md-placeholder="Claim start date"
            md-current-view="year"
            md-min-date="vm.dates.lastYear"
            md-max-date="vm.dates.today"
        ></md-datepicker>
        <md-datepicker
            disable-if-agent
            flex
            ng-model="vm.period.end"
            md-placeholder="Claim end date"
            md-current-view="year"
            md-min-date="vm.dates.lastYear"
            md-max-date="vm.dates.nextYear"
        ></md-datepicker>
    </div>
    `,
    bindings: { period: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.dates = {
        today: null,
        nextYear: null,
        lastYear: null
    };

    vm.$onInit = activate;

    function activate() {
        const d = new Date();
        vm.dates.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        vm.dates.nextYear = new Date(d.getFullYear() + 1, 11, 31);
        vm.dates.lastYear = new Date(d.getFullYear() - 1, 0, 1);

        if (vm.period && vm.period.start) vm.period.start = new Date(vm.period.start);
        if (vm.period && vm.period.end) vm.period.end = new Date(vm.period.end);
    }
}

export { gbbgClaimPeriod };
