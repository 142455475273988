const gbbgClientContacts = {
    template: `
    <md-card
        md-theme="docs-dark"
        ng-if="vm.primaryContact && (vm.primaryContact.name || vm.primaryContact.email || vm.primaryContact.phone)"
    >
        <md-card-title layout="column" layout-gt-xs="row" layout-align="end">
            <md-card-title-text flex>
                <span class="md-headline">Primary Contact</span>
            </md-card-title-text>
        </md-card-title>
        <md-divider></md-divider>
        <md-card-content>
            <h4 ng-bind="vm.primaryContact.name || 'n/a'"></h4>
            <p>
                <span ng-if="vm.primaryContact.position" ng-bind="'(' + vm.primaryContact.position + ')'"></span>
                <md-icon md-font-library="material-icons">mail_outline</md-icon>
                <span ng-bind="vm.primaryContact.email || 'n/a'"></span>
                <md-icon md-font-library="material-icons">phone</md-icon>
                <span ng-bind="vm.primaryContact.phone || 'n/a'"></span>
            </p>
        </md-card-content>
    </md-card>

    <md-card>
        <md-card-title layout="row" layout-align="end">
            <md-card-title-text flex>
                <span class="md-headline">Contacts</span>
                <span class="md-subhead" ng-if="!vm.contacts || !vm.contacts.length">You haven't added any additional contacts to this record.</span>
            </md-card-title-text>
            <md-button class="md-icon-button md-raised" ng-click="vm.openNewContactDialog()">
                <md-icon>person_add</md-icon>
                <md-tooltip>New contact</md-tooltip>
            </md-button>
        </md-card-title>
        <md-divider></md-divider>
        <md-list class="md-dense" flex>
            <md-list-item
                class="md-3-line md-long-text"
                ng-repeat="contact in vm.contacts track by $index"
            >
                <div class="md-list-item-text">
                    <h3>
                        <span ng-bind="contact.name"></span>
                        <span ng-if="contact.position" ng-bind="'(' + contact.position + ')'"></span>
                    </h3>
                    <p>
                        <!-- email -->
                        <md-icon md-font-library="material-icons">mail_outline</md-icon>
                        <span ng-bind="contact.email || 'n/a'"></span>
                        <!-- phone -->
                        <md-icon md-font-library="material-icons">phone</md-icon>
                        <span ng-bind="contact.phone || 'n/a'"></span>
                    </p>
                </div>
                <md-button
                    class="md-secondary md-icon-button"
                    aria-label="call"
                    ng-click="vm.openSetPrimaryDialog($index, contact)"
                >
                    <md-icon md-font-library="material-icons">star_border</md-icon>
                    <md-tooltip md-direction="bottom">Set as primary contact</md-tooltip>
                </md-button>
                <gbbg-edit-contact class="md-secondary" client-id="{{vm.clientId}}" contact="contact"></gbbg-edit-contact>
                <md-button
                    class="md-secondary md-icon-button"
                    aria-label="call"
                    ng-click="vm.openDeleteContactDialog($index, contact)"
                >
                    <md-icon md-font-library="material-icons">delete_forever</md-icon>
                    <md-tooltip md-direction="bottom">Delete contact</md-tooltip>
                </md-button>
                <md-divider></md-divider>
            </md-list-item>
        </md-list>
    </md-card>
    `,
    bindings: { clientId: '@', contacts: '=', primaryContact: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $timeout, $state, ErrorHandler, ClientService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.addContact = addContact;
    vm.openDeleteContactDialog = openDeleteContactDialog;
    vm.openSetPrimaryDialog = openSetPrimaryDialog;
    vm.openNewContactDialog = openNewContactDialog;

    function openNewContactDialog() {
        $mdDialog.show({
            template: `
            <md-dialog aria-label="New contact dialog" style="max-width:600px;">
                <form name="newContactDialogForm">
                    <md-toolbar>
                        <div class="md-toolbar-tools">
                            <h2>New contact</h2>
                            <span flex></span>
                            <gbbg-close-dialog></gbbg-close-dialog>
                        </div>
                    </md-toolbar>
                    <md-dialog-content layout="column" layout-padding>

                        <p>Contacts must have a name and either an email address or phone number.</p>

                        <gbbg-client-contact-details contact="vm.newContact"></gbbg-client-contact-details>

                    </md-dialog-content>
                    <md-dialog-actions layout="row">
                        <span flex></span>
                        <md-button class="md-warn" ng-click="vm.cancel()">Cancel</md-button>
                        <md-button
                            class="md-primary md-raised"
                            ng-click="vm.createContact()"
                            ng-disabled="newContactDialogForm.$invalid || vm.invalidContact(vm.newContact)"
                        > Add contact </md-button>
                    </md-dialog-actions>
                </form>
            </md-dialog>
            `,
            controller: newContactDialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then(newContact => addContact(newContact), () => {});
    }

    function addContact(contact) {
        if (!vm.contacts) vm.contacts = [];
        if (!vm.clientId || !contact.name || (!contact.email && !contact.phone)) return;
        ClientService.addContact(vm.clientId, contact).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('New contact added successfully.')
                    .position('top right')
                    .hideDelay(4000)
                );
                vm.contacts.push(angular.copy(contact));
            },
            error => {
                ErrorHandler.showDialog('Failed to add new contact', error);
                console.error("Failed to add contact to record:", error);
            }
        );
    }

    // DELETE CONTACT

    function openDeleteContactDialog(idx, contact) {
        const name = contact.name || 'this contact'
        const text = `You can delete ${name} from this record. This action is permanent and cannot be undone.`;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to delete this contact?')
            .textContent(text)
            .ariaLabel('Delete contact')
            .ok('Delete contact')
            .cancel('Cancel')
        ).then(() => deleteContact(idx, contact._id), () => {});
    }

    function deleteContact(idx, contactId) {
        if (!vm.clientId || !contactId) return;
        ClientService.deleteContact(vm.clientId, contactId).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Contact deleted successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                vm.contacts.splice(idx, 1);
                idx = null;
            },
            error => {
                ErrorHandler.showDialog('Failed to delete contact', error);
                console.error("Failed to delete contact from record:", error);
            }
        );
    }

    // SET PRIMARY CONTACT

    function openSetPrimaryDialog(idx, contact) {
        const name = contact.name || 'this contact'
        const text = `You can set ${name} as the primary contact for this client record. The current primary contact will not be deleted.`;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Update primary contact?')
            .textContent(text)
            .ariaLabel('Set primary contact dialog')
            .ok('Set primary contact')
            .cancel('Cancel')
        ).then(() => setPrimaryContact(idx, contact), () => {});
    }

    function setPrimaryContact(idx, contact) {
        if (!vm.clientId || !contact) return;
        ClientService.setPrimaryContact(vm.clientId, contact).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Primary contact set!')
                    .position('top right')
                    .hideDelay(4000)
                );
                if (!vm.primaryContact) return;
                vm.contacts.splice(idx, 1);
                vm.contacts.push(vm.primaryContact);
                vm.primaryContact = contact;
                idx = null;
                contact = {};
            },
            error => {
                ErrorHandler.showDialog('Failed to set primary contact', error);
                console.error("Failed to set new primary contact for record:", error);
            }
        );
    }
}
function newContactDialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.newContact = {
        name: '',
        phone: '',
        email: '',
        role: '',
        additional: [],
    };

    vm.cancel = $mdDialog.cancel;
    vm.createContact = createContact;
    vm.invalidContact = invalidContact;

    function createContact() {
        if (!vm.newContact.name.length) return;
        $mdDialog.hide(vm.newContact);
    }

    function invalidContact(contact) {
        return (!contact || !contact.name || (!contact.phone && !contact.email));
    }
}

export { gbbgClientContacts };
