const gbbgTypeOfSupply = {
    template: `
    <div layout="column">
        <md-input-container>
            <label>Type of supply</label>
            <md-select ng-model="vm.typesOfSupply" required>
                <md-optgroup label="types of supply">
                    <md-option ng-value="type" ng-repeat="type in vm.types">{{type}}</md-option>
                </md-optgroup>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: { typesOfSupply: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.types = [
        'beverages','business samples', 'car rental', 'chauffeur',
        'conference / exhibition', 'fuel', 'hotel', 'meals', 'misc.', 'parking',
        'stationery', 'taxi', 'telecoms', 'training / course / seminar', 'samples'
    ];
}

export { gbbgTypeOfSupply };
