const gbbgAddAttachment = {
    template: `
    <div layout="column" layout-gt-xs="row">
        <md-button
            class="md-raised"
            type="file"
            ngf-select="vm.upload($files)"
            ngf-multiple="true"
            ngf-pattern="'.pdf,.jpg,.jpeg,.png,.doc,.docx'"
            ngf-accept="'.pdf,.jpg,.jpeg,.png,.doc,.docx'"
        > Upload attachment </md-button>
    </div>
    `,
    bindings: {
        attachment: '=?',
        attachments: '=?',
        attachmentIds: '=?'
    },
    controller,
    controllerAs: 'vm'
};
function controller(Upload, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.upload = upload;

    function upload(files) {
        if (!files || !files.length) return;
        vm.disabled = true;
        files.map(file => {
            Upload.upload({
                url: '/api/email/attachments',
                data: { file }
            }).then((resp) => {
                vm.attachment = resp.data;
                if (!vm.attachments) vm.attachments = [];
                vm.attachments.push(resp.data);
                if (vm.attachmentIds) vm.attachmentIds.push(resp.data.id);
                else vm.attachmentIds = [ resp.data.id ];
            }, (resp) => {
                console.log('Failed to upload email attachment:', resp);
            });
        });
    }
}

export { gbbgAddAttachment };
