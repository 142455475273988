import { gbbgClientUsersAdd } from './client_users_add';
import { gbbgClientUsersList } from './client_users_list';

import { ClientUsersService } from './client_users_service';

angular.module('client-users', [])
.component('gbbgClientUsersAdd', gbbgClientUsersAdd)
.component('gbbgClientUsersList', gbbgClientUsersList)
.factory('ClientUsersService', ClientUsersService)

export default 'client-users';
