const gbbgClientUsersList = {
    template: `
    <md-card>
        <md-card-title layout="row" layout-align="end end">
            <gbbg-client-users-add></gbbg-client-users-add>
        </md-card-title>
        <md-divider></md-divider>
        <md-list>
            <md-list-item ng-repeat="user in vm.authorisedUsers track by user.referenceNo">
                <p ng-class="{'text--warn': !user.active}">
                    <strong>{{user.firstName}} {{user.surname}} ({{user.referenceNo}})</strong>
                    <span> {{user.email}} </span>
                    <span ng-if="user.phone">| {{user.phone}}</span>
                    <span ng-if="user.position">| {{user.position}}</span>
                </p>
                <md-button
                    class="md-secondary md-icon-button"
                    ng-if="user.access === 'authorisedClientUser'"
                    ng-click="vm.resetPassword(user._id)"
                >
                    <md-icon>vpn_key</md-icon>
                    <md-tooltip>Reset password</md-tooltip>
                </md-button>
                <gbbg-client-users-add class="md-secondary" user="user"></gbbg-client-users-add>
                <gbbg-suspend-user-button class="md-secondary" active="user.active" user-id="{{user._id}}"></gbbg-suspend-user-button>
            </md-list-item>
        </md-list>
    </md-card>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $stateParams, ClientUsersService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.authorisedUsers = ClientUsersService.authorisedUsers;
    vm.resetPassword = resetPassword;

    vm.$onInit = activate;

    function activate() {
        ClientUsersService.getAuthorisedUsers($stateParams.id).then(() => {}, (error) => {
            ErrorHandler.showDialog('Failed to get authorised users', error);
            console.error('Failed to get authorised users:', error);
        });
    }

    function resetPassword(userId) {
        console.log('URD:', userId);
        if (!userId) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Reset user password?')
            .textContent(`This action will replace the user's current password with a new randomly generated password.`)
            .ariaLabel('Reset password')
            .ok('Reset password')
            .cancel('Cancel')
        ).then(() => {
            ClientUsersService.resetUserPassword(userId).then(() => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Password reset successfully.')
                    .position('top right')
                    .hideDelay(3000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to reset user password', error)
                console.error('Failed to reset user password:', error);
            })
        }, () => { });
    }
}

export { gbbgClientUsersList };
