const gbbgLeadStatus = {
    template: `
    <div layout="column">
        <md-input-container>
            <label>Select status</label>
            <md-select ng-model="vm.selectedStatus" ng-change="vm.openDialog()">
                <md-option ng-repeat="s in vm.statuses" ng-value="s">{{s}}</md-option>
            </md-select>
            <div class="hint" ng-bind="vm.response"></div>
        </md-input-container>
    </div>
    `,
    bindings: { leadId: '@', status: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, EmailService, LeadService, LeadHandler, ErrorHandler) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.selectedStatus = '';
    vm.statuses = [ 'pending', 'prospect', 'ineligible', 'closed' ];
    vm.response = '';

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() {
        vm.selectedStatus = (vm.status && vm.status.status) ? vm.status.status : '';
    }

    function openDialog() {
        if (!vm.selectedStatus || !vm.leadId) return;
        vm.response = '';
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm',
            locals: {
                status: vm.selectedStatus,
                leadId: vm.leadId
            }
        }).then(({ reason, details }) => {
            LeadService.setStatus(vm.leadId, vm.selectedStatus, reason.name, details).then(
                data => {
                    LeadHandler.addToTimeline(data);
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Status set successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    /*if (vm.selectedStatus !== 'pending' && vm.selectedStatus !== 'prospect') {
                        EmailService.openSendEmailDialog(vm.leadId).then(
                            data => {
                                $mdToast.show(
                                    $mdToast.simple()
                                    .textContent('Email sent successfully!')
                                    .position('top right')
                                    .hideDelay(4000)
                                );
                            },
                            error => {
                                if (!error) return;
                                ErrorHandler.showDialog('Failed to send email', error);
                                console.log('Failed to send email:', error);
                            }
                        );
                    }*/
                },
                error => {
                    ErrorHandler.showDialog('Failed to change lead status', error);
                    console.error('Failed to change lead status:', error);
                }
            );
        }, () => {});
    }
}
function dialogController(status, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.requireReason = false;
    vm.reason = '';
    vm.details = '';

    vm.cancel = $mdDialog.cancel;
    vm.changeStatus = changeStatus;

    activate();

    function activate() {
        vm.requireReason = (status === 'ineligible' || status === 'closed');
    }

    function changeStatus() {
        $mdDialog.hide({
            reason: vm.reason,
            details: vm.details
        });
    }
}

const template = `
<md-dialog aria-label="Lead status change dialog">
    <form name="statusChangeForm" ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Change status</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">

                <p>Are you sure you wish to change the status to <b ng-bind="vm.status"></b>?</p>

                <p ng-if="vm.requireReason">Please provide a reason for changing the status to <b ng-bind="vm.status"></b>.</p>

                <gbbg-lead-status-reason
                    ng-if="vm.requireReason"
                    reason="vm.reason"
                ></gbbg-lead-status-reason>

                <md-input-container ng-if="vm.requireReason" flex>
                    <label>Details</label>
                    <textarea ng-model="vm.details" maxlength="200" md-maxlength="200"></textarea>
                </md-input-container>

            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button ng-click="vm.changeStatus()" ng-disabled="statusChangeForm.$invalid">
                Confirm change
            </md-button>
            <md-button class="md-warn" ng-click="vm.cancel()">
                Cancel
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

export { gbbgLeadStatus };
