const gbbgClaimInvoicesRefund = {
    template: `
    <md-list>
        <md-list-item class="md-3-line" ng-repeat="invoice in vm.invoices">
            <div class="md-list-item-text">
                <h3>
                    <strong>VAT claimed:</strong>
                    <span>{{invoice.vatClaimed | currency:'£'}}</span>
                </h3>
                <h4>
                    <strong>{{invoice.taxDate | date: 'dd MMM, yyyy'}}</strong>
                    <span ng-bind="invoice.supplier.supplier.name"></span>
                    <span ng-if="invoice.supplier.supplier.taxNo">
                        ({{invoice.supplier.supplier.taxNo || 'no tax no.'}}),
                    </span>
                    <span ng-bind="invoice.supplier.supplier.address.branch + ','"></span>
                    <span ng-bind="invoice.supplier.supplier.address.country"></span>
                </h4>
                <p>
                    <strong>Invoice no.:</strong>
                    <span ng-bind="invoice.invoiceNo || 'n/a'"></span>

                    <strong>Type of supply:</strong>
                    <span ng-bind="invoice.supplier.typeOfSupply"></span>

                    <strong>VAT rate:</strong>
                    <span ng-bind="invoice.supplier.vatRate + '%'"></span>
                </p>
                <md-icon class="md-secondary md-warn" ng-if="invoice.penalty.set">
                    priority_high
                    <md-tooltip>{{invoice.penalty.value}}% restyling charge</md-tooltip>
                </md-icon>
                <md-input-container class="md-secondary">
                    <label>Amount refunded (£)</label>
                    <input type="number" ng-model="invoice.vatRefunded" min="0" max="{{invoice.vatClaimed}}"/>
                </md-input-container>
            </div>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { invoices: '<' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgClaimInvoicesRefund };
