const gbbgEmailCcSelect = {
    template: `
    <md-chips
        flex
        ng-model="vm.selectedRecipients"
        md-autocomplete-snap
        md-separator-keys="vm.customKeys"
        md-transform-chip="vm.transformChip($chip)"
    >
        <md-autocomplete
            md-selected-item="vm.selectedItem"
            md-search-text="vm.searchText"
            md-items="item in vm.querySearch(vm.searchText)"
            md-item-text="item.name"
            placeholder="{{vm.placeholder || 'CC recipients (comma separated)'}}"
        >
            <span md-highlight-text="vm.searchText">{{item}}</span>
        </md-autocomplete>
        <md-chip-template>
            <span>
                <strong>{{$chip}}</strong>
            </span>
        </md-chip-template>
    </md-chips>
    `,
    bindings: {
        placeholder: '@',
        selectedRecipients: '=',
        clientId: '@',
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdConstant, ClientService, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.readonly = false;
    vm.selectedItem = null;
    vm.searchText = null;

    vm.emails = [];
    vm.customKeys = [ $mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA ];

    vm.querySearch = querySearch;
    vm.transformChip = transformChip;

    vm.$onInit = activate;

    function activate() {
        if (!vm.emails) vm.emails = [];
        if (!vm.clientId) vm.clientId = (ClaimService.claim && ClaimService.claim.client) ? ClaimService.claim && ClaimService.claim.client._id : '';
        if (vm.emails.length === 0 && (vm.clientId && vm.clientId.length)) {
            ClientService.getContacts(vm.clientId).then(
                (data) => {
                    vm.emails = data
                    .filter((contact) => contact.email && contact.email.length)
                    .map((contact) => contact.email);
                },
                (error) => {
                    ErrorHandler.showDialog('Failed to get contacts for client', error);
                    console.error(`Failed to get contacts for client '${vm.clientId}':`, error);
                }
            );
        }
    }

    function querySearch (query) {
        if (!Array.isArray(vm.selectedRecipients)) vm.selectedRecipients = [];
        if (!query || !query.length) return [];
        const lowercaseQuery = query.toLowerCase();
        return vm.emails.filter((email) => {
            return (email && email.indexOf(lowercaseQuery) >= 0);
        });
    }

    function transformChip(chip) {
        if (!Array.isArray(vm.selectedRecipients)) vm.selectedRecipients = [];
        return chip;
    }
}

export { gbbgEmailCcSelect }
