function leadFlaggedViewController($mdDialog, $state, $stateParams, AuthService, ErrorHandler, LeadService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.selectedLeads = [];
    vm.leads = [];

    vm.concatAddress = concatAddress;
    vm.openDialog = openDialog;
    vm.toggleAll = toggleAll;
    vm.toggleLead = toggleLead;
    vm.toggleFilter = toggleFilter;

    vm.$onInit = activate;

    function activate() {
        vm.isAdmin = !!AuthService.isAdmin();
        vm.lockedFilter = ($stateParams.lockedFilter && $stateParams.lockedFilter == 'true');
        vm.agentFilter = ($stateParams.agentFilter && $stateParams.agentFilter == 'true');
        LeadService.getFlaggedLeads(vm.lockedFilter, vm.agentFilter).then(
            data => {
                vm.leads = data;
                vm.selectedLeads = [...vm.leads];
            },
            error => {
                ErrorHandler.showDialog('Failed to get flagged leads', error, 'retry', true);
                console.error('Failed to get flagged leads:', error);
            }
        );
    }

    function toggleFilter(lockedFilter, agentFilter) {
        $state.go($state.current.name, {
            lockedFilter: (lockedFilter || lockedFilter == false) ? lockedFilter : $stateParams.lockedFilter,
            agentFilter: (agentFilter || agentFilter == false) ? agentFilter : $stateParams.agentFilter
        }, { reload: $state.current.name });
    }

    function openDialog() {
        if (!vm.selectedLeads || vm.selectedLeads.length < 1) return;

        const len = vm.selectedLeads.length;
        const text = len > 1 ? `${len} leads` : 'one lead';

        $mdDialog.show(
            $mdDialog.confirm()
            .title('Close leads?')
            .textContent(`Are you sure you want to close ${text}? You can undo this action at a later date.`)
            .ariaLabel('Close flagged leads')
            .ok('Close flagged leads')
            .cancel('Cancel')
        ).then(() => { setStatuses() }, () => {});
    }

    function setStatuses() {
        vm.processing = true;

        vm.selectedLeads = vm.selectedLeads.map(lead => {
            return {
                _id: lead._id,
                status: 'ineligible',
                reason: lead.status.flag
            };
        }).filter(lead => (lead._id && lead.status && lead.reason));

        LeadService.setMultipleStatuses(vm.selectedLeads).then(
            data => {
                if (data.errors && data.errors.length) {
                    console.error('Errors returned from server while updating flagged leads:', errors);
                }
                $mdDialog.show(
                    $mdDialog.alert()
                    .title(`${data.successes} flagged leads closed`)
                    .textContent(`${data.errors.length} errors returned from server.`)
                    .ariaLabel('Leads closed dialog')
                    .ok('Okay')
                ).then(() => {
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                });
            },
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Failed to set statuses for flagged leads', error);
                console.error('Failed to update flagged lead statuses:', error);
            }
        );
    }

    function toggleLead(lead) {
        const idx = vm.selectedLeads.indexOf(lead);
        (idx >= 0) ? vm.selectedLeads.splice(idx, 1) : vm.selectedLeads.push(lead);
    }

    function toggleAll() {
        const allSelected = (vm.selectedLeads.length === vm.leads.length);
        vm.selectedLeads.splice(0);
        if (!allSelected) vm.selectedLeads = [...vm.leads];
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { leadFlaggedViewController };
