const gbbgAddPreClaim = {
    template: `
    <div layout="column">
        <md-button class="md-accent md-raised" ng-click="vm.openDialog()">
            Notify GBBG about claim
        </md-button>
    </div>
    `,
    bindings: { clientId: '@',  },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, PreClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/pre-claim/add_preclaim_dialog.html',
            fullscreen: true,
            controller: dialogController,
            controllerAs: 'vm'
        }).then(data => {
            data.client = vm.clientId;
            PreClaimService.newPreClaim(data).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Potential claim added to client!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                }, error => {
                    ErrorHandler.showDialog('Failed to create new potential claim', error);
                    console.error('Failed to create new potential claim:', error);
                }
            );
        }, () => {});
    }
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.preClaim = {
        clientFee: 0,
        estimatedValue: 0,
        paperwork: { sent: false },
        country: '',
        notes: ''
    };

    vm.cancel = $mdDialog.cancel;

    vm.addPreClaim = addPreClaim;

    function addPreClaim() { $mdDialog.hide(vm.preClaim); }
}

export { gbbgAddPreClaim };
