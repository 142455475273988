function sourceListViewController(SourceService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.enquirySources = [];

    vm.concatName = concatName;

    activate();

    function activate() {
        SourceService.getEnquirySources().then(
            data => {
                if (data && data.length > 0) return vm.enquirySources = data;
            },
            error => {
                ErrorHandler.showDialog('Failed to get enquiry sources', error);
                console.error('Failed to get enquiry sources:', error);
            }
        );
    }

    function concatName(source) {
        const { address = {} } = source;
        let string = '';
        if (address.city) string += `${address.city}`;
        if (address.city && address.country) string += ', ';
        if (address.country) string += `${address.country}`;
        return string || '';
    }
}

export { sourceListViewController };
