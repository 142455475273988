const gbbgUserTeams = {
    template: `
    <md-list>
        <md-list-item class="md-2-line" ng-repeat="team in vm.teams track by $index">
            <div class="md-list-item-text" layout="column">
                <h3>{{team.name}} {{team.location.area}}, {{team.location.country}}</h3>
                <p>{{team.referenceNo}} | {{team._id}}</p>
            </div>
            <md-button
                class="md-icon-button md-secondary"
                ui-sref="root.auth.management.teams.team({ id: team._id })"
            ><md-icon>open_in_new</md-icon></md-button>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { userId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($timeout, TeamService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.teams = [];

    this.$onInit = activate;

    function activate() {
        if (!vm.userId) return;
        TeamService.getTeamsByUserId(vm.userId).then(
            data => vm.teams = data,
            err => console.error(`Failed to load teams for user ${vm.userId}.`, err)
        );
    }
}

export { gbbgUserTeams };
