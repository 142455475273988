const gbbgLeadForceValid = {
    template: `
    <md-button class="md-icon-button" ng-click="vm.toggleForceValid()">
        <md-icon ng-if="!vm.status.forceValid">lock_open</md-icon>
        <md-icon ng-if="vm.status.forceValid">lock_outline</md-icon>
        <md-tooltip>Force validity</md-tooltip>
    </md-button>
    `,
    bindings: { leadId: '@', status: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdToast, LeadService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.toggleForceValid = toggleForceValid;

    function toggleForceValid() {
        LeadService.toggleForceValid(vm.leadId).then(
            data => {
                vm.status.forceValid = data || false;
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Changed validity successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                ErrorHandler.showDialog('Failed to change validity of lead', error);
                console.error('Failed to change validity of lead:', error);
            }
        )
        vm.status.forceValid = !vm.status.forceValid;
    }
}

export { gbbgLeadForceValid };
