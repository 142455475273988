const gbbgUserChangePassword = {
    template: `
    <md-button ng-click="vm.openDialog()" ng-disabled="!vm.hasWriteAccess">
        Change password
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($scope, $mdDialog, $mdToast, UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$onInit = activate;

    function activate() {
        vm.hasWriteAccess = UserService.hasWriteAccess();
        if (localStorage.gbbgRequirePasswordReset == 'true') openDialog();
    }

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/management/user/password_change_dialog.html',
            controller: dialogController,
            controllerAs: 'vm'
        }).then(passwords => {
            UserService.changePassword(passwords).then(
                () => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Password changed successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    localStorage.setItem('gbbgRequirePasswordReset', 'false');
                },
                error => {
                    ErrorHandler.showDialog('Failed to change password', error);
                    console.error('Failed to change password:', error);
                }
            );
        }, () => {});
    }
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.passwords = {
        old: '',
        new: '',
        confirm: ''
    };

    vm.cancel = $mdDialog.cancel;
    vm.submit = submit;

    function submit() {
        if (vm.passwords.new === vm.passwords.confirm) $mdDialog.hide(vm.passwords);
    }
}

export { gbbgUserChangePassword }
