const gbbgDeleteAuthority = {
    template: `
    <md-button
        class="md-warn md-icon-button"
        ng-disabled="!vm.authorityId"
        ng-click="vm.openDialog()"
    >
        <md-icon>delete</md-icon>
        <md-tooltip md-direction="down">Delete authority</md-tooltip>
    </md-button>
    `,
    bindings: { authorityId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, $mdToast, AuthorityService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Delete issuing authority?')
            .textContent('Deleting this issuing authority is a permanent action and cannot be undone.')
            .ariaLabel('Delete issuing authority')
            .ok('Delete authority')
            .cancel('Cancel')
        ).then(() => deleteAuthority(), () => {});
    }

    function deleteAuthority() {
        if (!vm.authorityId) return;
        AuthorityService.deleteAuthority(vm.authorityId).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Issuing authority deleted successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                $state.go($state.current.name, {}, { reload: $state.current.name });
            },
            error => {
                ErrorHandler.showDialog('Failed to delete issuing authority', error);
                console.error(`Failed to delete issuing authority ${vm.authorityId}:`, error);
            }
        );
    }
}
export { gbbgDeleteAuthority }
