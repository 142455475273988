import { emailSendDialogController } from './email_send_dialog_controller';

function EmailService($http, $mdDialog) {
    'ngInject';
    const service = {
        emails: [],
        // TEMPLATES
        newTemplate(email) {
            return $http.post('/api/email/templates', email).then(success, failure);
        },
        getAllTemplates(teamFilter = '') {
            return $http.get(`/api/email/templates?team=${teamFilter}`).then((response) => {
                if (response.status != 200) throw response;
                service.emails.splice(0);
                response.data.map(email => service.emails.push(email));
                return response.data;
            }, failure);
        },
        getTemplate(templateId) {
            return $http.get(`/api/email/templates/${templateId}`).then(success, failure);
        },
        updateTemplate(email) {
            return $http.put(`/api/email/templates/${email._id}`, email).then(success, failure);
        },
        renderTemplate(templateId = 'blank', client, claim, overwrite) {
            const query = (client && client.length) ? `?client=${client || ''}` : `?claim=${claim || ''}`;
            return $http.get(`/api/email/render/${templateId}${query}`, {params: { client, claim, overwrite }}).then(success, failure);
        },
        renderTemplateForObject(templateId, object) {
            return $http.put(`/api/email/render/${templateId}`, object).then(success, failure);
        },
        deleteTemplate(templateId) {
            return $http.delete(`/api/email/templates/${templateId}`).then(success, failure);
        },
        getEmailErrors(page) {
            return $http.get(`/api/email/error?page=${page}`).then(success, failure);
        },
        // SENT EMAILS
        getEmail(emailId) {
            return $http.get(`/api/email/sent/${emailId}`).then(success, failure);
        },
        sendEmail(email, recipients, clientId, claimId, details, sender) { // details is for the timeline activity
            return $http.post('/api/email/send', {
                email,
                recipients,
                clientId,
                claimId,
                details,
                sender
            }).then(success, failure);
        },
        openSendEmailDialog(clientId = '', claimId = '', attachments = []) {
            return new Promise((resolve, reject) => {
                $mdDialog.show({
                    templateUrl: '/partials/templates/email/send_email_dialog.html',
                    locals: {
                        clientId,
                        claimId,
                        attachments
                    },
                    controller: emailSendDialogController,
                    controllerAs: 'vm'
                }).then(({ email, recipients, sender }) => {
                    if ((!clientId && !claimId)) return;
                    service.sendEmail(email, recipients, clientId, claimId, null, sender).then(
                        (data) => resolve(data),
                        (error) => reject(error)
                    );
                }, () => { reject(); });
            });
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { EmailService };
