const gbbgEmailTemplateContent = {
    template: `
    <div layout="column" layout-gt-xs="row">
        <md-input-container ng-if="!vm.hideTitle" flex>
            <label>Template title</label>
            <input type="text" maxlength="100" md-maxlength="100" ng-model="vm.email.title" required/>
        </md-input-container>

        <md-input-container flex>
            <label>Email subject line</label>
            <input type="text" maxlength="100" md-maxlength="100" ng-model="vm.email.subject" required/>
        </md-input-container>
    </div>
    <div layout="column">
        <ng-quill-editor
            ng-model="vm.email.body"
            placeholder="Email body"
            max-length="5000"
            required="true"
        ></ng-quill-editor>
    </div>
    `,
    bindings: { email: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($state) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.hideTitle = false;

    vm.$onInit = activate;

    function activate() {
        vm.hideTitle = !$state.includes('root.auth.management.emails');
    }
}

export { gbbgEmailTemplateContent };
