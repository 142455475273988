const gbbgPreClaimToClaim = {
    template: `
    <md-button
        class="md-primary md-icon-button"
        ng-disabled="!vm.claimId"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">content_copy</md-icon>
        <md-tooltip md-direction="down">Create new claim</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.claimId) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .clickOutsideToClose(true)
            .title('Create new claim?')
            .textContent('This will create a new claim from this potential claim and attach it to the client record.')
            .ariaLabel('Create claim from potential claim')
            .ok('Create claim')
            .cancel('Cancel')
        ).then(() => {
            ClaimService.newClaimFromPreClaim(vm.claimId).then(
                data => {
                    $mdDialog.show(
                        $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('New claim created')
                        .textContent('Successfully created a new claim.')
                        .ariaLabel('New claim created')
                        .ok('Okay!')
                    );
                    $state.go('root.auth.claims.claim', { id: data });
                },
                error => {
                    ErrorHandler.showDialog('Failed to create new claim from potential claims', error);
                    console.error('Failed to create new claim from potential claims:', error);
                }
            );
        }, () => { });
    }
}

export { gbbgPreClaimToClaim };
