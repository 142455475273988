import { default as moment } from 'moment-timezone';

function gbbgLocalTime($interval) {
    return {
        restrict: 'E',
        template: `<span ng-bind="date"></span>`,
        replace: true,
        scope: { timezone: '@' },
        link(scope) {

            scope.date = '';

            let killInterval;

            activate();

            function activate() {
                killInterval = $interval(() => {
                    if (!scope.timezone) return scope.date = '';
                    const m = moment(new Date().toISOString());
                    scope.date = m.tz(scope.timezone).format('MMM DD, YYYY h:mm:ss a') || '';
                }, 1000);

                scope.$on('$destroy', () => $interval.cancel(killInterval));
            }
        }
    };
}

export { gbbgLocalTime };
