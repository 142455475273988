function notificationScroll($timeout, NotificationService) {
    'ngInject';
    return {
        template: `
        <div>
            <ng-transclude></ng-transclude>
            <div class="md-padding" layout="row" layout-align="center">
                <md-progress-circular
                    class="md-hue-2"
                    ng-disabled="loading != true"
                    md-diameter="20px"
                ></md-progress-circular>
            </div>
        </div>
        `,
        restrict: 'AE',
        scope: true,
        transclude: true,
        link
    };
    function link(scope, elem, attrs) {

        let notifications;
        scope.loading = false;

        $timeout(() => {
            notifications = angular.element(document.querySelector('.notifications'));
            notifications.on('scroll', loadMore);
        }, 50);

        function loadMore() {
            const { offsetHeight, scrollHeight, scrollTop } = notifications[0];
            const diff = scrollHeight-offsetHeight;
            if (scrollTop >= diff && !scope.loading) {
                scope.loading = true;
                scope.$apply();
                NotificationService.loadNotifications().then(
                    data => $timeout(() => scope.loading = false, 1000),
                    error => {
                        scope.loading = false;
                        ErrorHandler.showDialog('Failed to load additional notifications', error);
                        console.error('Failed to load additional notifications', error);
                    }
                );
            }
        }
    }
}

export { notificationScroll };
