const gbbgAssignAccountManagerSelect = {
    template: `
    <div layout="column">
        <md-input-container ng-if="vm.users.length > 0" disable-if-agent>
            <label>Account manager...</label>
            <md-select ng-model="vm.selectedUser" ng-change="vm.openDialog()" disable-if-agent>
                <md-option disable-if-agent
                    ng-repeat="user in vm.users track by user._id"
                    ng-value="user._id"
                    disable-if-agent
                >{{user.firstName + ' ' + user.surname}}</md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: {
        leadId: '@',
        accountManager: '=',
        status: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $state, ErrorHandler, LeadService, LeadHandler, UserService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedUser = '';
    vm.users = [];

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() {
        vm.selectedUser = (vm.accountManager && vm.accountManager._id) ? vm.accountManager._id : '';
        UserService.getAllUsers().then(
            data => vm.users = data.filter((user) => user.active || user._id == vm.selectedUser),
            error => {
                ErrorHandler.showDialog('Failed to load users', error, 'Retry', true);
                console.error("Failed to get users:", error);
            }
        );
    }

    function openDialog() {
        if (!vm.selectedUser || !vm.leadId) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Change assignee')
            .textContent('Are you sure you want to change the account manager?')
            .ariaLabel('Change account manager')
            .ok('Set account manager')
            .cancel('Cancel')
        ).then(() => setAccountManager(), () => {});
    }

    function setAccountManager() {
        if (isInvalid()) return;
        LeadService.setAccountManager([ vm.leadId ], [ vm.selectedUser ]).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Account manager updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                LeadHandler.currentLead.accountManager = vm.users.filter(({ _id }) => _id === vm.selectedUser).shift();
            },
            error => {
                ErrorHandler.showDialog('Failed to set account manager', error);
                console.error('Failed to set account manager:', error);
            }
        );
    }

    function isInvalid() {
        return (
            !vm.selectedUser ||
            (vm.accountManager && vm.selectedUser === vm.accountManager._id)
        );
    }
}

export { gbbgAssignAccountManagerSelect };
