const gbbgActiveUrns = {
    template: `
    <md-card flex>
        <md-list class="md-dense">
            <md-list-item class="secondary-button-padding" ng-repeat="urn in vm.urns">
                <p><b ng-bind="urn.country"></b> {{urn.number}}</p>
                <md-divider></md-divider>
            </md-list-item>
        </md-list>
        <md-card-content ng-if="!vm.urns.length">
            <p>No unique reference numbers have been added to this client.</p>
        </md-card-content>
        <md-card-actions layout="row" layout-align="end center">
            <ng-transclude></ng-transclude>
        </md-card-actions>
    </md-card>
    `,
    transclude: true,
    bindings: {
        clientId: '@',
        urns: '<',
        country: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgActiveUrns };
