function gbbgForceKeepFocus($timeout) {
    return {
        restrict: 'A',
        require: [ '^^mdSelect', '^ngModel' ],
        link
    };
    function link(scope, elem) {

        elem.on('keypress keyup keydown', (e) => {
            const code = e.keyCode;
            if (code == '37' || code == '38' || code == '39' || code == '40') return;
            e.stopPropagation();
            elem.focus();
        });
    }
}

export { gbbgForceKeepFocus };
