const gbbgEligibilityFlagSelect = {
    template: `
    <md-input-container>
        <label>ineligibility flag</label>
        <md-select
            ng-model="vm.flag"
            ng-change="vm.updateOnChange()"
            aria-label="select eligibility flag"
            ng-disabled="vm.disable"
        >
            <md-option ng-repeat="flag in vm.flags" ng-value="flag.name">{{flag.name}}</md-option>
        </md-select>
    </md-input-container>
    `,
    bindings: {
        flag: '=',
        disable: '<',
        onChange: '&'
    },
    controller,
    controllerAs: 'vm'
};
function controller($timeout, ErrorHandler, FlagService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.flags = [];

    vm.updateOnChange = updateOnChange;

    vm.$onInit = activate;

    function activate() {
        FlagService.getAllFlags().then(
            (data) => {
                if (!data || !data.length) {
                    ErrorHandler.showDialog('No eligibility flags were returned from the database');
                } else {
                    vm.flags = data;
                }
            },
            (error) => {
                ErrorHandler.showDialog('Failed to get eligibility flags', error);
                console.error("Failed to get eligibility flags:", error);
            }
        );
    }

    function updateOnChange() {
        if (vm.onChange) $timeout(() => vm.onChange({ flag: vm.flag }));
    }
}

export { gbbgEligibilityFlagSelect };
