function AuthService($mdDialog, $http, $state, $stateParams, $location) {
    const service = {
        getMsUri() {
            return $http.get('/ms-uri').then((response) => response.data, failure)
        },
        msVerifyUser(query = {}) {
            return $http.post('/ms-verify', query).then((response) => {
                if (response.status != 200) throw response;
                const { token, email, id, access, requirePasswordReset } = response.data;
                if (token) {
                    localStorage.setItem('gbbgToken', token);
                    localStorage.setItem('gbbgUserId', id);
                    localStorage.setItem('gbbgEmail', email);
                    localStorage.setItem('gbbgUserAccess', access);
                    localStorage.setItem('gbbgRequirePasswordReset', requirePasswordReset);
                }
                return response.data;
            }, failure)
        },
        signIn(credentials) {
            return $http.post('/signin', credentials).then((response) => {
                if (response.status != 200) throw response;
                const { token, email, id, access, requirePasswordReset } = response.data;
                if (token) {
                    localStorage.setItem('gbbgToken', token);
                    localStorage.setItem('gbbgUserId', id);
                    localStorage.setItem('gbbgEmail', email);
                    localStorage.setItem('gbbgUserAccess', access);
                    localStorage.setItem('gbbgRequirePasswordReset', requirePasswordReset);
                }
                return response.data;
            }, failure);
        },
        signOut() {
            return $http.get(`/signout`).then(
                response => {
                    this.clearSession();
                    if (response.status != 200) throw response;
                    else return response.data;
                },
                error => {
                    this.clearSession();
                    throw error;
                });
        },
        clearSession() {
            if (localStorage) localStorage.clear();
            if (window.localStorage) window.localStorage.clear();
        },
        authenticateTransition() {
            const unrestricted = ['/'];
            const token = localStorage.getItem('gbbgToken') || '';
            const toPath = $location.path() // used to be: $location.url();
            const toRestricted = unrestricted.indexOf(toPath) == -1;
            if (token.length > 0) {
                toRestricted ? $location.path(toPath) : $location.path('/');
            } else {
                this.clearSession();
                toRestricted ? $location.path('/') : $location.path(toPath);
            }
            return true;
        },
        openSignInDialog(message, successCallback, cancelCallback) {
            $stateParams.redirectMessage = message || '';
            $mdDialog.show({
                template: `
                <md-dialog aria-label="Sign in dialog" style="max-width:600px;">
                    <form name="signInForm" ng-submit="vm.signIn()">
                        <md-toolbar>
                            <div class="md-toolbar-tools">
                                <h2>Sign in</h2>
                                <span flex></span>
                                <gbbg-close-dialog></gbbg-close-dialog>
                            </div>
                        </md-toolbar>
                        <md-dialog-content class="md-padding" layout="column">

                            <p ng-bind="vm.redirectMessage"></p>

                            <md-input-container flex>
                                <label>Email</label>
                                <input type="email" ng-model="vm.credentials.email" required>
                            </md-input-container>
                            <md-input-container flex>
                                <label>Password</label>
                                <input type="password" ng-model="vm.credentials.password" required>
                            </md-input-container>

                        </md-dialog-content>
                        <md-dialog-actions layout="row">
                            <span flex></span>
                            <md-button
                                type="submit"
                                class="md-primary md-raised"
                                ng-disabled="signInForm.$invalid || vm.pending"
                            >Sign in</md-button>
                        </md-dialog-actions>
                    </form>
                </md-dialog>
                `,
                controller: 'SignInController',
                controllerAs: 'vm'
            }).then(
                (credentials) => { if (successCallback) successCallback(); },
                (error) => { if (cancelCallback) cancelCallback(); }
            );
        },
        isAdmin() { return (localStorage.gbbgUserAccess >= 701); },
        isAgent() { return (localStorage.gbbgUserAccess <= 301); }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) {
        throw error;
    }
}

export { AuthService };
