const gbbgTeamDetails = {
    templateUrl: '/partials/templates/management/team/details.html',
    bindings: { team: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    "ngInject";
    /*validthis:true*/
    const vm = this;
}

export { gbbgTeamDetails };
