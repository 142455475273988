const gbbgTogglePaperwork = {
    template: `
    <md-checkbox
        class="no-margin"
        ng-model="vm.paperwork.sent"
        ng-change="vm.setPaperworkSent()"
        ng-disabled="::vm.disableSent"
        aria-label="Awaiting paperwork"
    > Paperwork sent to GBBG </md-checkbox>
    <p class="md-caption">
        <span ng-if="vm.paperwork.sent == true">Paperwork sent on: {{vm.paperwork.dateSent | date: 'dd MMM, yyyy'}}</span>
        <span ng-if="vm.paperwork.sent != true">Paperwork has not been sent.</span>
    </p>
    <md-checkbox
        class="no-margin"
        ng-model="vm.paperwork.received"
        ng-change="vm.setPaperworkReceived()"
        ng-disabled="::vm.disableReceived"
        aria-label="Awaiting paperwork"
    > Paperwork received by GBBG </md-checkbox>
    <p class="md-caption">
        <span ng-if="vm.paperwork.received == true">Paperwork received on: {{vm.paperwork.dateReceived | date: 'dd MMM, yyyy'}}</span>
        <span ng-if="vm.paperwork.received != true">Paperwork has not been received.</span>
    </p>
    `,
    bindings: { leadId: '@', paperwork: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdToast, ErrorHandler, LeadService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.setPaperworkSent = setPaperworkSent;
    vm.setPaperworkReceived = setPaperworkReceived;

    vm.$onInit = activate;

    function activate() {
        vm.disableSent = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 101;
        vm.disableReceived = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 301;
    }

    function setPaperworkSent() {
        const value = vm.paperwork.sent || false;
        LeadService.setPaperworkSent(vm.leadId, value).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Updated paperwork sent!')
                    .position('top right')
                    .hideDelay(4000)
                );
                if (vm.paperwork.sent == true) vm.paperwork.dateSent = new Date();
                else vm.paperwork.dateSent = null;
            },
            error => {
                ErrorHandler.showDialog('Failed to update paperwork sent', error);
                console.error('Failed to update paperwork status:', error);
            }
        );
    }

    function setPaperworkReceived() {
        const value = vm.paperwork.received || false;
        LeadService.setPaperworkReceived(vm.leadId, value).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Updated paperwork received!')
                    .position('top right')
                    .hideDelay(4000)
                );
                if (vm.paperwork.received == true) vm.paperwork.dateReceived = new Date();
                else vm.paperwork.dateReceived = null;
            },
            error => {
                ErrorHandler.showDialog('Failed to update paperwork received', error);
                console.error('Failed to update paperwork status:', error);
            }
        );
    }
}

export { gbbgTogglePaperwork };
