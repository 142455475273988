const gbbgUserDetails = {
    templateUrl: '/partials/templates/management/user/details.html',
    bindings: { user: '=' },
    transclude: true,
    controller,
    controllerAs: 'vm'
};
function controller() {
    "ngInject";
    /*validthis:true*/
    const vm = this;
}

export { gbbgUserDetails };
