const gbbgNewFlag = {
    template: `
    <md-button
        class="md-fab md-secondary md-hue-1 md-mini"
        ng-click="::vm.openDialog()"
        ng-disabled="::vm.readOnly"
    >
        <md-icon md-font-library="material-icons">flag</md-icon>
        <md-tooltip md-direction="bottom">New flag</md-tooltip>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, AuthService, ErrorHandler, FlagService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() { vm.readOnly = AuthService.isAgent(); }

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((flag) => {
            FlagService.addFlag(flag).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Flag added successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to add new eligibility flag', error);
                    console.error('Failed to add new eligibility flag:', error);
                }
            );
        }, () => {});
    }

    const template = `
    <md-dialog aria-label="Add user" style="max-width:600px;">
        <form name="newFlagForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>New flag</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content class="md-padding" layout-padding>

                <gbbg-flag-details flag="vm.flag"></gbbg-flag-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.addFlag()"
                    ng-disabled="newFlagForm.$invalid"
                > Add flag </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.flag = {
        name: '',
        keywordString: ''
    };

    vm.cancel = $mdDialog.cancel;
    vm.addFlag = addFlag;

    function addFlag() {
        $mdDialog.hide(vm.flag);
    }
}

export { gbbgNewFlag }
