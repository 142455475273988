function reportRoutes($locationProvider, $stateProvider) {
    'ngInject';
    $stateProvider
    .state('root.auth.management.reports', {
        url: '/reports',
        views: {
            "main@root.auth": {
                template: `
                <md-content>
                    <md-card>
                        <md-card-title>
                            <md-card-title-text>
                                <span class="md-headline">Reports</span>
                            </md-card-title-text>
                        </md-card-title>
                        <md-divider></md-divider>
                        <md-list>
                            <md-list-item ui-sref="root.auth.management.reports.claims">Claim reports</md-list-item>
                            <md-list-item ui-sref="root.auth.management.reports.client">Client reports</md-list-item>
                        </md-list>
                    </md-card>
                </md-content>
                `
            }
        },
        data: { title: 'Reports' }
    })
    .state('root.auth.management.reports.claims', {
        url: '/claims?report&from&to&claimCountry&clientCountry&team&source',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/report/claim_reports.html',
                controller: 'reportClaimViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'Claim reports' }
    })
    .state('root.auth.management.reports.client', {
        url: '/client?report&from&to&limit&clientCountry&team&source',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/report/client_reports.html',
                controller: 'reportClientViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'Client reports' }
    })
    // .state('root.auth.management.reports.source', {
    //     url: '/source?report&from&to&limit&clientCountry&team&source',
    //     views: {
    //         "main@root.auth": {
    //             templateUrl: '/partials/views/report/source_reports.html',
    //             controller: 'reportSourceViewController',
    //             controllerAs: 'vm'
    //         }
    //     },
    //     data: { title: 'Enquiry source reports' }
    // });
}

export { reportRoutes };
