const gbbgClientContactDetails = {
    template: `
    <div layout="column">
        <md-input-container class="no-bottom-margin">
            <input type="text" placeholder="Contact name" ng-model="vm.contact.name"/>
        </md-input-container>
        <md-input-container class="no-margin">
            <input type="email" placeholder="Contact email" ng-model="vm.contact.email"/>
        </md-input-container>
        <md-input-container class="no-margin">
            <input type="tel" placeholder="Contact phone" ng-model="vm.contact.phone"/>
        </md-input-container>
        <div layout="row" ng-repeat="info in vm.contact.additional track by $index">
            <md-input-container class="no-margin" flex>
                <input type="text" placeholder="Contact info" ng-model="vm.contact.additional[$index]"/>
            </md-input-container>
            <md-button class="md-icon-button"
                ng-click="vm.removeAdditionalContactInfo($index)"
                aria-label="Remove additional contact information"
            > <md-icon>remove</md-icon> </md-button>
        </div>
        <div layout="row">
            <md-input-container class="no-margin" flex>
                <input
                    type="text"
                    placeholder="Additional contact info, e.g. phone, email"
                    ng-model="vm.additional"
                    ng-disabled="vm.contact.additional.length >= 5"
                />
            </md-input-container>
            <md-button
                class="md-icon-button"
                ng-click="vm.addAdditionalContactInfo(vm.additional)"
                ng-disabled="vm.contact.additional.length >= 5"
                aria-label="Add additional contact information"
            > <md-icon>add</md-icon> </md-button>
        </div>
        <md-input-container class="no-margin">
            <input type="text" placeholder="Position / title in company" ng-model="vm.contact.position"/>
        </md-input-container>
    </div>
    `,
    bindings: { contact: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.additional = '';

    vm.addAdditionalContactInfo = addAdditionalContactInfo;
    vm.removeAdditionalContactInfo = removeAdditionalContactInfo;

    function addAdditionalContactInfo(text) {
        if (!vm.contact.additional) vm.contact.additional = [];
        if (text && text.length && vm.contact.additional.length <= 5) {
            vm.contact.additional.push(text);
            vm.additional = '';
        }
    }

    function removeAdditionalContactInfo(idx) {
        if (!vm.contact.additional) return;
        if (idx >= 0) vm.contact.additional.splice(idx, 1);
    }
}

export { gbbgClientContactDetails };
