function userRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.users', {
        url: '/users',
        abstract: true,
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-new-user button-class="md-fab md-hue-1 md-mini"></gbbg-new-user>
                </gbbg-top-nav>
                `
            }
        }
    })
    .state('root.auth.management.users.all', {
        url: "?sort&status&query",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/user/list.html',
                controller: 'userListViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'All users' }
    })
    .state('root.auth.management.users.new', {
        url: "/new",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/user/new.html',
                controller: 'userNewViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'New user' }
    })
    .state('root.auth.management.users.user', {
        url: "/:id",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/user/user.html',
                controller: 'userViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'User' }
    });
}

export { userRoutes };
