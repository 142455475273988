const gbbgAccessRecordList = {
    template: `
    <md-card>
        <md-card-title>Access record</md-card-title>
        <md-divider></md-divider>
        <md-list class="md-dense" ng-if="vm.records.length">
            <md-list-item class="md-2-line" ng-repeat="record in vm.records">
                <div class="md-list-item-text">
                    <h3>{{record.date | date: 'medium'}}&nbsp;</h3>
                    <p>{{record.user.firstName}} {{record.user.surname}}
                    accessed {{record.docType}} {{record.docRef._id}}</p>
                </div>

                <md-icon
                    class="md-secondary"
                    ng-click="vm.openRecord(record)"
                    aria-label="Open record"
                >open_in_new_window</md-icon>
                <md-divider></md-divider>
            </md-list-item>
        </md-list>
        <md-button
            ng-if="vm.showLoadMore"
            ng-click="vm.getRecords()"
            ng-disabled="vm.loading"
        >Load more</md-button>
    </md-card>
    `,
    bindings: {
        userId: '@',
        claimId: '@',
        clientId: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller($state, $stateParams, AccessService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let requestPage = 1;

    vm.loading = false;
    vm.showLoadMore = false;

    vm.records = AccessService.records;

    vm.getRecords = getRecords;
    vm.openRecord = openRecord;

    vm.$onInit = getRecords;

    function getRecords() {
        if (vm.loading) return;
        vm.loading = true;
        if (requestPage == 1) AccessService.clearRecords();

        const queryObject = {
            user: $stateParams.user || vm.userId,
            docRef: vm.claimId || vm.clientId,
            page: requestPage
        };

        if (vm.claimId) queryObject.docType = 'claim';
        if (vm.clientId) queryObject.docType = 'client';
        if ($stateParams.type) queryObject.docType = $stateParams.type;

        AccessService.getAccessRecords(queryObject).then(
            data => {
                vm.loading = false;
                vm.showLoadMore = (data.page < data.pages);
                vm.totalClients = data.total || 0;
                requestPage = Number(data.page) + 1;
            },
            error => {
                vm.loading = false;
                ErrorHandler.showDialog('Failed to get access records', error);
                console.error('Failed to get access records:', error);
            }
        );
    }

    function openRecord({ docType, docRef }) {
        if (docType === 'claim') {
            $state.go('root.auth.claims.claim', { id: docRef._id }, { reload: 'root.auth.claims.claim' });
        } else if (docType === 'client') {
            if (docRef.status.status === 'active') {
                $state.go('root.auth.active.client', { id: docRef._id }, { reload: 'root.auth.active.client' });
            } else {
                $state.go('root.auth.leads.lead', { leadId: docRef._id }, { reload: 'root.auth.leads.lead' });
            }
        }
    }
}

export { gbbgAccessRecordList };
