import { gbbgDeleteAuthority } from './authority_delete';
import { gbbgAuthorityDetails } from './authority_details';
import { gbbgNewIssuingAuthority } from './authority_new_button';
import { authorityRoutes } from './authority_routes';
import { gbbgSelectAuthority } from './authority_select_by_country';
import { AuthorityService } from './authority_service';
import { gbbgUpdateAuthority } from './authority_update';

angular
.module('authority', [])
.config(authorityRoutes)
.component('gbbgDeleteAuthority', gbbgDeleteAuthority)
.component('gbbgAuthorityDetails', gbbgAuthorityDetails)
.component('gbbgNewIssuingAuthority', gbbgNewIssuingAuthority)
.component('gbbgSelectAuthority', gbbgSelectAuthority)
.component('gbbgUpdateAuthority', gbbgUpdateAuthority)
.factory('AuthorityService', AuthorityService);

export default 'authority';
