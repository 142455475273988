// import jsPDF from 'jspdf'
// import { applyPlugin } from 'jspdf-autotable'

import { default as moment } from 'moment'

import * as CLAIMS_CHARGES_REPORT from './constants/claims_charges_report'
import * as CLAIMS_COMMISSION_REPORT from './constants/claims_commission_report'
import * as CLAIMS_COMMISSION_INCL_CLAIMS_REPORT from './constants/claims_commission_incl_claims_report'
import * as CLAIMS_REPORT from './constants/claims_report'
import * as SCHEDULE from './constants/invoice_schedule'

// applyPlugin(jsPDF)

const alignRight = { halign: 'right' }

function PdfService($http) {
    'ngInject';
    const service = {
        pdf: null,
        create(docType, data) {
            service.pdf = new jsPDF({ orientation: 'landscape' })
            let config = null
            let filename = ''
            let multiple = false
            let dataKey = null
            switch (docType) {
                case 'pdf':
                    config = CLAIMS_REPORT
                    filename = `${moment().format('YYYY-MM-DD')}_claims_report.pdf`
                    break;
                case 'chargesPdf':
                    config = CLAIMS_CHARGES_REPORT
                    filename = `${moment().format('YYYY-MM-DD')}_claims_charges_report.pdf`
                    multiple = true
                    dataKey = 'claims'
                    break;
                case 'commissionPdf':
                    config = CLAIMS_COMMISSION_REPORT
                    filename = `${moment().format('YYYY-MM-DD')}_claims_commission_incl_claims_report.pdf`
                    break;
                case 'commClaimsPdf':
                    config = CLAIMS_COMMISSION_INCL_CLAIMS_REPORT
                    filename = `${moment().format('YYYY-MM-DD')}_claims_commission_report.pdf`
                    multiple = true
                    dataKey = 'claims'
                    break;
                case 'schedule':
                    config = SCHEDULE.init()
                    filename = `${moment().format('YYYY-MM-DD')}_schedule.pdf`
                    dataKey = 'invoices'
                    break;
                default:
                    config = CLAIMS_REPORT
                    filename = `${moment().format('YYYY-MM-DD')}_claims_report.pdf`
            }
            if (multiple === true) {
                buildMultiple(config, data, dataKey).then((pdf) => {
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof pdf.putTotalPages === 'function') {
                        pdf.putTotalPages('{total_pages_count_string}')
                    }
                    pdf.save(filename || 'report.pdf')
                    if (config.resetObjTotals) config.resetObjTotals()
                })
            } else {
                buildOne(config, data, dataKey).then((pdf) => {
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof pdf.putTotalPages === 'function') {
                        pdf.putTotalPages('{total_pages_count_string}')
                    }
                    pdf.save(filename || 'report.pdf')
                    if (config.resetObjTotals) config.resetObjTotals()
                })
            }
        }
    }
    return service
    function buildOne(config, data, dataKey) {
        // const pdf = new jsPDF({ orientation: 'landscape' })
        service.pdf.autoTable({
            theme: 'grid',
            margin: config.MARGIN || { top: 5, left: 5, right: 5, bottom: 10 },
            head: config.PDF_HEADER(data),
            headStyles: config.HEAD_STYLES || null,
            columns: config.PDF_COLUMNS,
            columnStyles: config.COLUMN_STYLES,
            // body: data.claimsArray,
            body: (dataKey) ? data[dataKey] : data.reportData,
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
            showHead: 'everyPage',
            showFoot: 'everyPage',
            styles: config.STYLES || { fontSize: 8 },
            didDrawPage: config.didDrawPage,
            willDrawCell: config.willDrawCell
        })
        return Promise.resolve(service.pdf)
    }
    function buildMultiple(config, data, dataKey) {
        return Promise.all(data.reportData.map((item) => {
            return buildOne(config, item, dataKey)
        }))
    }
}

export { PdfService }
