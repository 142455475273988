const gbbgUpdateFlag = {
    template: `
    <md-button class="md-icon-button" ng-click="::vm.getFlag()">
        <md-icon>open_in_browser</md-icon>
        <md-tooltip md-direction="down">Edit flag</md-tooltip>
    </md-button>
    `,
    bindings: { flagId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, AuthService, FlagService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.getFlag = getFlag;

    vm.$onInit = activate;

    function activate() { vm.readOnly = AuthService.isAgent(); }

    function getFlag() {
        FlagService.getFlag(vm.flagId).then(
            data => openDialog(data),
            error => {
                ErrorHandler.showDialog('Failed to get flag', error);
                console.error(`Failed to get eligibility flag '${$stateParams.id}':`, error);
            }
        );
    }

    function openDialog(flag) {
        $mdDialog.show({
            template,
            locals: {
                flag,
                readOnly: vm.readOnly
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((flag) => {
            if (vm.readOnly) return;
            FlagService.updateFlag(flag).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Flag updated successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to update eligibility flag', error);
                    console.error('Failed to update eligibility flag:', error);
                }
            );
        }, () => {});
    }

    const template = `
    <md-dialog aria-label="Add user" style="max-width:600px;">
        <form name="updateFlagForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>New flag</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content class="md-padding" layout-padding>

                <gbbg-flag-details flag="::vm.flag"></gbbg-flag-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="::vm.save()"
                    ng-disabled="::vm.readOnly || updateFlagForm.$invalid"
                > Update flag </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController(flag, readOnly, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.readOnly = readOnly;
    vm.flag = flag;

    vm.cancel = $mdDialog.cancel;
    vm.save = save;

    function save() {
        $mdDialog.hide(vm.flag);
    }
}

export { gbbgUpdateFlag }
