import { default as ngFileUpload } from 'ng-file-upload';
import { default as ngQuill } from 'ng-quill';

import { gbbgAddAttachment } from './email_add_attachment';
import { gbbgEmailAttachmentsList } from './email_attachments_list';
import { gbbgEmailCcSelect } from './email_cc_select';
import { gbbgOpenEmailIcon } from './email_open_icon';
import { gbbgEmailRecipientSelect } from './email_recipient_select';
import { emailRoutes } from './email_routes';
import { gbbgEmailSenderSelect } from './email_sender_select';
import { EmailService } from './email_service';
import { gbbgAddEmailTemplate } from './email_template_add';
import { gbbgEmailTemplateContent } from './email_template_content';
import { gbbgEmailTemplateCopy } from './email_template_copy';
import { gbbgDeleteEmailTemplate } from './email_template_delete';
import { gbbgEmailTemplateKey } from './email_template_key';
import { gbbgEmailTemplateSelect } from './email_template_select';
import { gbbgUpdateEmailTemplate } from './email_template_update';

angular.module('email', [
    ngFileUpload,
    ngQuill
])
.config(emailRoutes)
.config((ngQuillConfigProvider) => {
    'ngInject';
    ngQuillConfigProvider.set({
        modules: {
            toolbar: [
                [ 'bold', 'italic', 'underline', 'strike' ],
                [ { 'color': [] }, { 'background': [] } ],
                [ { 'align': [] } ],
                [ { 'list': 'ordered' }, { 'list': 'bullet' } ],
                [ { 'header': [ 1, 2, 3, 4, 5, 6, false ] } ]
            ]
        }
    });
})
.component('gbbgAddAttachment', gbbgAddAttachment)
.component('gbbgEmailAttachmentsList', gbbgEmailAttachmentsList)
.component('gbbgEmailCcSelect', gbbgEmailCcSelect)
.component('gbbgOpenEmailIcon', gbbgOpenEmailIcon)
.component('gbbgEmailRecipientSelect', gbbgEmailRecipientSelect)
.component('gbbgAddEmailTemplate', gbbgAddEmailTemplate)
.component('gbbgEmailSenderSelect', gbbgEmailSenderSelect)
.component('gbbgEmailTemplateContent', gbbgEmailTemplateContent)
.component('gbbgEmailTemplateCopy', gbbgEmailTemplateCopy)
.component('gbbgDeleteEmailTemplate', gbbgDeleteEmailTemplate)
.component('gbbgEmailTemplateKey', gbbgEmailTemplateKey)
.component('gbbgEmailTemplateSelect', gbbgEmailTemplateSelect)
.component('gbbgUpdateEmailTemplate', gbbgUpdateEmailTemplate)
.factory('EmailService', EmailService);

export default 'email';
