const gbbgLeadTabsDetails = {
    templateUrl: '/partials/views/lead/tabs/details.html',
    bindings: { lead: '=' },
    controller,
    controllerAs: 'vm'
};
const gbbgLeadTabsContacts = {
    templateUrl: '/partials/views/lead/tabs/contacts.html',
    bindings: { lead: '=' },
    controller,
    controllerAs: 'vm'
};
const gbbgLeadTabsNotes = {
    templateUrl: '/partials/views/lead/tabs/notes.html',
    bindings: { lead: '=' },
    controller,
    controllerAs: 'vm'
};
function controller(LeadHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.updatePhoneNumber = updatePhoneNumber;
    /*
        Passed into gbbg-client-basic-details
        Then passed down into gbbg-timezone-select
        Executed when country/timezone is updated – country value passed back up
    */
    function updatePhoneNumber(country) {
        if (country) LeadHandler.updatePhoneNumber(country);
    }
}

export { gbbgLeadTabsDetails, gbbgLeadTabsContacts, gbbgLeadTabsNotes };
