const gbbgClientDescription = {
    template: `
    <div layout="column">
        <md-input-container class="no-bottom-margin">
            <label>Website</label>
            <input type="text" ng-model="vm.client.website" maxlength="50" md-maxlength="50"/>
        </md-input-container>
        <md-input-container class="no-margin">
            <label>Nature of business</label>
            <input type="text" ng-model="vm.client.nature"/>
        </md-input-container>
        <md-input-container class="no-margin">
            <textarea placeholder="Description of business" ng-model="vm.client.description"></textarea>
        </md-input-container>
    </div>
    `,
    bindings: { client: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    "ngInject";
    /*validthis:true*/
    const vm = this;
}

export { gbbgClientDescription };
