const gbbgClientTabsNotes = {
    templateUrl: '/partials/views/client/tabs/notes.html',
    bindings: { client: '<' },
    controller,
    controllerAs: 'vm'
}
function controller() {
    'ngInject'
    /*validthis:true*/
    const vm = this
}

export { gbbgClientTabsNotes }
