function claimInvoiceDialogController(invoice, country, traveller, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    const d = new Date();

    vm.dates = {};
    vm.dates.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    vm.dates.lastYear = new Date(d.getFullYear() - 1, d.getMonth(), d.getDate());
    vm.dates.twoYearsAgo = new Date(d.getFullYear() - 2, d.getMonth(), d.getDate());

    vm.invoice = {
        supplier: {
            supplier: {
                name: '',
                address: {
                    branch: '',
                    country: country || ''
                }
            },
            typeOfSupply: [],
            /* beverages, business samples, car rental, chauffeur, conference/exhibition,
            fuel, hotel, meals, misc., parking, stationery, taxi, telecoms, training/course/seminar*/
            vatRate: 0 // percentage
        },
        penalty: {
            set: false,
            reason: '',
            value: 10 // 10% added to vatClaimed, separate to the fee.
        },
        taxDate: null,
        invoiceNo: '',
        traveller: { name: '', position: '' },
        claimType: '', // full, partial, rejected, null
        notes: '',
        fee: 0, // 30% up to £10,000; 25% – 
        gross: 0,
        vatTotal: 0,
        vatClaimed: 0,
        vatRefunded: 0
    };

    vm.cancel = $mdDialog.cancel;
    vm.hide = hide;
    vm.isInvalid = isInvalid;
    vm.updateVatRate = updateVatRate;
    vm.updateTotalVat = updateTotalVat;

    activate();

    function activate() {
        if (traveller) vm.invoice.traveller = traveller || { name: '', position: '' };
        if (invoice) vm.invoice = angular.merge(vm.invoice, invoice);
        if (invoice && invoice.supplier && invoice.supplier.supplier && invoice.supplier.supplier.address) {
            vm.country = invoice.supplier.supplier.address.country || '';
        } else {
            vm.country = country || '';
        }
    }

    function hide() { $mdDialog.hide(vm.invoice); }

    function updateVatRate() {
        if (!vm.invoice.gross || !vm.invoice.vatTotal) return;
        const exVat = vm.invoice.gross - vm.invoice.vatTotal;
        const rate = ((100 / exVat) * vm.invoice.gross);
        vm.invoice.supplier.vatRate = Number(rate).toFixed(2) - 100;
    }

    function updateTotalVat() {
        if (!vm.invoice.gross || !vm.invoice.supplier.vatRate) return;
        const totalPercentage = 100 + (vm.invoice.supplier.vatRate);
        const exVat = (vm.invoice.gross / totalPercentage) * 100;
        vm.invoice.vatTotal = Number((vm.invoice.gross - exVat).toFixed(2));
    }

    function isInvalid(form) {
        const { gross, vatTotal, vatClaimed } = vm.invoice;
        const excessVat = (vatTotal > 0 && vatTotal > gross);
        const excessVatClaimed = (vatClaimed > gross || (vatTotal && vatClaimed > vatTotal));
        return (
            form.$invalid || excessVat || excessVatClaimed ||
            gross <= 0 || vatClaimed <= 0
        );
    }
}

export { claimInvoiceDialogController };
