const gbbgUrnSelect = {
    template: `
    <div layout="row">
        <md-input-container flex>
            <label>URN</label>
            <md-select
                disable-if-agent
                ng-model="vm.selectedUrn"
                ng-change="vm.openDialog()"
            >
                <md-option ng-value="vm.emptyObject"><em>None</em></md-option>
                <md-option
                    ng-repeat="urn in vm.urns"
                    ng-value="urn"
                >
                    <strong>{{urn.number}}</strong>
                    <span>{{urn.country}}</span>
                </md-option>
            </md-select>
        </md-input-container>
        <ng-transclude></ng-transclude>
    <div>
    `,
    transclude: true,
    bindings: { urns: '<', selectedUrn: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($scope, $mdDialog, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let originalUrn = {};

    vm.emptyObject = {};

    vm.openDialog = openDialog;

    $scope.$on('urn.update', (e, data) => {
        if (!data) return;
        updateUrn(data);
        vm.selectedUrn = data;
    });

    vm.$onInit = activate;

    function activate() {
        vm.urns = vm.urns || [];
        if (!vm.selectedUrn) return;
        /* Find a URN in vm.urns that matches vm.selectedUrn. Necessary because
        the URNs in vm.urns have an '_id' property that isn't in vm.selectedUrn
        and therefore the equality check fails. */
        const urnAsArray = vm.urns.filter(urn => (
            urn.country === vm.selectedUrn.country &&
            urn.number === vm.selectedUrn.number
        ));
        if (urnAsArray.length === 1) vm.selectedUrn = urnAsArray[0];
        originalUrn = angular.copy(vm.selectedUrn);
    }

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to change the URN?')
            .textContent('Changes will take effect immediately.')
            .ariaLabel('Change URN')
            .ok('Change URN')
            .cancel('Cancel')
        ).then(
            () => updateUrn(vm.selectedUrn),
            () => vm.selectedUrn = angular.copy(originalUrn)
        );
    }

    function updateUrn(urn) {
        ClaimService.setUrn(null, urn).then(
            data => ClaimService.addTimelineActivity(data),
            error => {
                ErrorHandler.showDialog('Failed to update URN', error);
                console.error('Failed to update URN:', error);
            }
        );
    }
}

export { gbbgUrnSelect };
