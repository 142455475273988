const gbbgPreClaimListFilters = {
    template: `
    <md-card md-whiteframe="6">
        <md-card-content layout="column" layout-gt-xs="row" layout-align="space-between center">
            <md-input-container>
                <label>Pre-claim status</label>
                <md-select
                    ng-model="vm.$stateParams.status"
                    aria-label="Pre claim status select"
                >
                    <md-option ng-repeat="status in vm.statuses" ng-value="status.value">{{status.label}}</md-option>
                </md-select>
            </md-input-container>
            <gbbg-enquiry-source-select source="vm.$stateParams.source"></gbbg-enquiry-source-select>
            <div layout="row" layout-align="center center">
                <md-input-container style="margin-bottom: 0px;">
                    <label>Date created</label>
                    <md-datepicker
                        ng-model="vm.dates.from"
                        md-current-view="year"
                        md-placeholder="From date"
                        md-max-date="vm.dates.to"
                    ></md-datepicker>
                </md-input-container>
                <md-input-container style="margin-bottom: 0px;">
                    <label>Max date</label>
                    <md-datepicker
                        ng-model="vm.dates.to"
                        md-current-view="year"
                        md-placeholder="To date"
                        md-min-date="vm.dates.from"
                        md-max-date="vm.dates.today"
                    ></md-datepicker>
                </md-input-container>
            </div>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions layout="row" layout-align="end">
            <md-button class="md-primary" ng-click="vm.go(vm.$stateParams.status, vm.dates.from, vm.dates.to, vm.$stateParams.source)">
                <md-icon>search</md-icon>
                Filter
            </md-button>
        </md-card-actions>
    </md-card>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($state, $stateParams) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$stateParams = $stateParams;

    vm.dates = {
        today: new Date(),
        from: null,
        to: null
    };

    vm.statuses = [
        { label: 'pending approval', value: 'unassigned' },
        { label: 'in processing', value: 'assigned' },
        { label: 'closed / invalid', value: 'closed' }
    ];

    vm.go = go;

    vm.$onInit = activate;

    function activate() {
        const d = new Date();
        const { to, from, source } = $stateParams;
        vm.dates.from = from ? new Date(parseInt(from)) : new Date();
        vm.dates.to = to ? new Date(parseInt(to)) : new Date(Date.now());
    }

    function go(status, from, to, source) {
        status = status || vm.$stateParams.status || 'unassigned';
        from = new Date(from || parseInt($stateParams.from) || null).getTime();
        to = new Date(to || parseInt($stateParams.to) || null).getTime();
        source = source || vm.$stateParams.source || null;
        setFiltersToLocalStorage({
            status: status,
            from: vm.dates.from,
            to: vm.dates.to,
            source: source
        });
        $state.go($state.current.name, { status, from, to, source }, { reload: $state.current.name });
    }

    function setFiltersToLocalStorage({ status = '', from, to, source }) {
        localStorage.setItem('gbbgPreClaimFilterStatus', status);
        localStorage.setItem('gbbgPreClaimFilterSource', source);
        if (from && from.getTime) localStorage.setItem('gbbgPreClaimFilterFrom', from.getTime());
        if (to && to.getTime) localStorage.setItem('gbbgPreClaimFilterTo', to.getTime());
    }
}

export { gbbgPreClaimListFilters };
