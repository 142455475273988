function activeListViewController($state, $stateParams, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let requestPage = 1;

    vm.showLoadMore = false;
    vm.loading = false;
    vm.assignedToMe = true;

    vm.clients = [];

    vm.getActiveClients = getActiveClients;
    vm.concatAddress = concatAddress;

    vm.$onInit = getActiveClients;

    function getActiveClients() {
        vm.loading = true;
        const { assigned, status, query, sort } = $stateParams;

        if (!assigned) return $state.go('root.auth.active.all', {
            assigned: assigned || localStorage.getItem('gbbgLeadFilterAssigned') || 'me',
            sort: sort || localStorage.getItem('gbbgLeadFilterSort') || 'referenceNo'
        }, { reload: $state.current.name });

        ActiveService.getActiveClients({ assigned, status, query, sort, page: requestPage }).then(
            data => {
                vm.loading = false;
                data.clients.map(client => vm.clients.push(client));
                vm.showLoadMore = (data.page < data.pages);
                vm.totalClients = data.total || 0;
                requestPage = Number(data.page) + 1;
            },
            error => {
                vm.loading = false;
                ErrorHandler.showDialog('Failed to load active clients', error);
                console.error('Failed to get active client records:', error);
            }
        );
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { activeListViewController };
