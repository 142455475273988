function leadRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.leads', {
        url: "/leads",
        abstract: true
    })
    .state('root.auth.leads.all', {
        url: "?assigned&status&flag&query&sort",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/list.html',
                controller: 'leadListViewController',
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.leads.new', {
        url: "/new",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/new.html',
                controller: 'newleadViewController',
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.leads.lead', {
        url: "/lead/:leadId",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/lead.html',
                controller: 'leadViewController',
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.leads.flagged', {
        url: "/flagged?lockedFilter&agentFilter",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/flagged.html',
                controller: 'leadFlaggedViewController',
                controllerAs: 'vm'
            }
        },
        params: { filter: 'true' }
    })
    .state('root.auth.leads.contact', {
        url: "/uncontacted?date&agentFilter",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/uncontacted.html',
                controller: 'leadUncontactedViewController',
                controllerAs: 'vm'
            }
        },
        params: { date: '3m', agentFilter: 'false' }
    });
}

export { leadRoutes };
