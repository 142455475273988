function UserService($http) {
    'ngInject';
    const service = {
        user: {},
        getAllUsers(filters = {}) {
            const { sort = 'referenceNo', status = 'all', query } = filters;
            return $http.get(`/api/users?sort=${sort}&status=${status}&query=${query}`).then(success, failure);
        },
        getUser(userId) {
            return $http.get(`/api/user/${userId}`).then((response) => {
                if (response.status != 200) throw response;
                Object.assign(service.user, response.data);
                return response.data;
            }, failure);
        },
        getUsersByEmail(email) {
            return $http.get(`/api/users/email?email=${email}`).then(success, failure);
        },
        addUser(user, teams) {
            return $http.post('/api/users', { user, teams }).then(success, failure);
        },
        updateUser(userId, userObj) {
            return $http.put(`/api/user/${userId}`, userObj).then(success, failure);
        },
        changeAccess(userId, access) {
            return $http.put(`/api/user/access/${userId}`, { access }).then(success, failure);
        },
        changeStatus(userId, status) {
            return $http.put(`/api/user/activity/${userId}?active=${status}`).then(success, failure);
        },
        changePassword(passwords) {
            const userId = service.user._id || null;
            return $http.put(`/api/user/password/${userId}`, passwords).then(success, failure);
        },
        getPasswordResetCode(email) {
            return $http.get(`/user/password/code?email=${email}`).then(success, failure);
        },
        resetPassword(email, code, password) {
            return $http.post('/user/password/reset', { email, code, password }).then(success, failure);
        },
        hasWriteAccess() {
            const { _id } = service.user;
            const { gbbgUserId, gbbgUserAccess } = localStorage
            return (_id && (_id === gbbgUserId) || gbbgUserAccess >= '701');
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { UserService };
