import { activeListViewController } from './active_list_view_controller';
import { activeClientViewController } from './active_view_controller';
import { activeUncontactedViewController } from './active_uncontacted_view_controller';

function activeRoutes($locationProvider, $stateProvider) {
    'ngInject';
    $stateProvider
    .state('root.auth.active', {
        url: '/active',
        abstract: true
    })
    .state('root.auth.active.all', {
        url: '?assigned&query&sort',
        views: {
            'main@root.auth': {
                templateUrl: '/partials/views/client/active/list.html',
                controller: activeListViewController,
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.active.uncontacted', {
        url: '/uncontacted?date&agentFilter&status',
        views: {
            'main@root.auth': {
                templateUrl: '/partials/views/client/active/uncontacted.html',
                controller: activeUncontactedViewController,
                controllerAs: 'vm'
            }
        },
        params: { date: '3m', agentFilter: 'false', status: 'active' }
    })
    .state('root.auth.active.client', {
        url: '/:id',
        views: {
            'main@root.auth': {
                templateUrl: '/partials/views/client/active/client.html',
                controller: activeClientViewController,
                controllerAs: 'vm'
            }
        }
    })
}

export { activeRoutes }
