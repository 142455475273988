function ClaimService($http) {
    'ngInject';
    const service = {
        claim: {},
        validations: [],
        getAllClaims(params) {
            return $http.get('/api/claims', { params }).then(success, failure);
        },
        getClaim(claimId) {
            return $http.get(`/api/claims/${claimId}`).then(success, failure);
        },
        getClaimsForClient(clientId) {
            return $http.get(`/api/claims/client/${clientId}`).then(success, failure);
        },
        checkValidity(claimId) {
            const id = claimId ? claimId : service.claim._id;
            return $http.get(`/api/claims/validation/${id}`).then((response) => {
                if (response.status != 200) throw response;
                service.claim.valid = response.data.valid || false;
                service.validations.splice(0);
                response.data.validations.map(v => service.validations.push(v));
                return response.data;
            }, failure);
        },
        get65a(claimId) {
            return $http.get(`/api/claim/65a/${claimId}`).then(success, failure);
        },
        getSchedule(claimId) {
            return $http.get(`/api/claim/schedule/${claimId}`).then(success, failure);
        },
        getRefundFigures(claimId) {
            claimId = claimId || service.claim._id;
            return $http.get(`/api/claims/figures/${claimId}`).then(success, failure);
        },
        getClientBusinessTravellers() {
            const claimId = service.claim._id;
            return $http.get(`/api/claims/travellers/${claimId}`).then(success, failure);
        },
        newClaim(clientId, { cert = null, urn = null }) {
            return $http.post('/api/claims', { client: clientId, cert, urn }).then(success, failure);
        },
        newClaimFromPreClaim(preClaimId) {
            return $http.post(`/api/claims/pre-claim`, { preClaimId }).then(success, failure);
        },
        saveClaim(claim) {
            return $http.put(`/api/claims/${claim._id}`, claim).then(success, failure);
        },
        setUrn(claimId, urn) {
            claimId = (claimId && claimId != null) ? claimId : service.claim._id || null;
            return $http.put(`/api/claims/urn/${claimId}`, { urn }).then(success, failure);
        },
        setCert(claimId, cert) {
            claimId = (claimId && claimId != null) ? claimId : service.claim._id || null;
            return $http.put(`/api/claims/cert/${claimId}`, { cert }).then(success, failure);
        },
        addInvoice(claimId, invoice) {
            claimId = (claimId && claimId != null) ? claimId : service.claim._id || null;
            return $http.put(`/api/claims/invoice/${claimId}`, invoice).then(success, failure);
        },
        updateInvoice(claimId, invoice) {
            claimId = (claimId && claimId != null) ? claimId : service.claim._id || null;
            if (!invoice._id) return Promise.reject('No invoice ID found in invoice object.');
            return $http.put(`/api/claims/${claimId}/invoice/${invoice._id}`, invoice).then(success, failure);
        },
        deleteInvoice(invoiceId) {
            const claimId = service.claim._id;
            return $http.delete(`/api/claims/${claimId}/invoice/${invoiceId}`).then(success, failure);
        },
        addToTimeline(claimId, activity, details, email) {
            return $http.put(`/api/claims/timeline/${claimId}`, { activity, details, email }).then(success, failure);
        },
        updateFees(claimId, fees) {
            return $http.put(`/api/claims/fees/${claimId}`, fees).then(success, failure);
        },
        toggleDocumentsSent() {
            const claimId = service.claim._id;
            return $http.put(`/api/claims/documents/sent/${claimId}`).then((response) => {
                if (response.status != 200) throw response;
                service.addTimelineActivity(response.data);
                return response.data;
            }, failure);
        },
        toggleDocumentsReceived() {
            const claimId = service.claim._id;
            return $http.put(`/api/claims/documents/received/${claimId}`).then((response) => {
                if (response.status != 200) throw response;
                service.addTimelineActivity(response.data);
                return response.data;
            }, failure);
        },
        generateClientInvoice(invoiceDetails) {
            const claimId = service.claim._id;
            return $http.put(`/api/claim/invoice/client/${claimId}`, invoiceDetails).then((response) => {
                if (response.status != 200) throw response;
                if (!service.claim.refund) service.claim.refund = { invoices: [] };
                if (response.data.invoice) service.claim.refund.invoices.push(response.data.invoice);
                if (!service.claim.timeline) service.claim.timeline = [];
                service.claim.timeline.push(response.data.timelineAction);
                return response.data;
            }, failure);
        },
        generateAgentInvoice(invoiceDetails) {
            const claimId = service.claim._id;
            return $http.put(`/api/claim/invoice/agent/${claimId}`, invoiceDetails).then(success, failure);
        },
        deleteClientAgentInvoice(idx, invoiceId) {
            const claimId = service.claim._id;
            return $http.delete(`/api/claim/${claimId}/invoice/client/${invoiceId}`).then((response) => {
                if (response.status != 200) throw response;
                service.claim.refund.invoices.splice(idx, 1);
                service.addTimelineActivity(response.data);
                return response.data;
            }, failure);
        },
        sendInvoice(invoiceId, email, recipients, sender) {
            const claimId = service.claim._id;
            return $http.put(`/api/claim/${claimId}/invoice/${invoiceId}/send`, {
                email,
                recipients,
                sender
            }).then((response) => {
                if (response.status != 200) throw response;
                const { status, timelineAction } = response.data;
                service.claim.status.status = status;
                service.addTimelineActivity(timelineAction);
                return response.data;
            }, failure);
        },
        submitClaim(claimId, data) {
            return $http.put(`/api/claim/submit/${claimId}`, data).then((response) => {
                if (response.status != 200) throw response;
                const { submission, status, timelineAction } = response.data;
                service.claim.status.status = status;
                service.claim.submissions.push(submission);
                service.addTimelineActivity(timelineAction);
                return response.data;
            }, failure);
        },
        closeClaim(reason) {
            const claimId = service.claim._id;
            return $http.put(`/api/claim/close/${claimId}`, { reason }).then((response) => {
                if (response.status != 200) throw response;
                const { timelineAction } = response.data;
                service.claim.status.status = 'closed';
                service.claim.status.details = reason;
                service.addTimelineActivity(timelineAction);
                return response.data;
            }, failure);
        },
        refundClaim(refund, invoices) {
            if (!service.claim._id) Promise.reject('No claim ID stored in service.');
            return $http.put(`/api/claim/refund/${service.claim._id}`, {
                refund, invoices
            }).then((response) => {
                if (response.status != 200) throw response;
                const { invoices, refund, status  } = response.data;
                service.claim.invoices.splice(0, 1);
                service.claim.invoices = [...invoices];
                angular.copy(refund, service.refund);
                // service.refund = refund;
                if (service.claim.status) service.claim.status.status = status.status;
                else service.claim = { status: status.status };
                return response.data;
            }, failure);
        },
        revertClaim(password) {
            if (!service.claim._id) Promise.reject('No claim ID stored in service.')
            return $http.put(`/api/claim/revert/${service.claim._id}`, {
                password
            }).then((response) => {
                if (response.status != 200) throw response;
                const { refund, status  } = response.data
                angular.copy(refund, service.refund)
                if (service.claim.status) service.claim.status.status = status.status
                else service.claim = { status: status.status }
                return response.data
            }, failure)
        },
        getInvoicesAsPdf(claimId) {
            const id = claimId || service.claim._id;
            return $http.get(`/api/claim/invoices/pdf/${id}`).then(success, failure);
        },
        getClientTimeline(clientId) {
            return $http.get(`/api/clients/active/timeline/${clientId}`).then(success, failure)
        },
        setClaim(claim) {
            if (!claim || !claim._id) return;
            Object.keys(claim).map((key) => service.claim[key] = claim[key])
        },
        setFlag(details, colour) {
            if (!service.claim.status) service.claim.status = {};
            if (!service.claim.status.flag) service.claim.status.flag = {};
            service.claim.status.flag.details = details;
            service.claim.status.flag.colour = colour;
        },
        addTimelineActivity(timelineAction) {
            const { claim } = service;
            if (claim.timeline && Array.isArray(claim.timeline)) claim.timeline.unshift(timelineAction);
            else claim.timeline = [ timelineAction ];
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { ClaimService };
