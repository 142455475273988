function gbbgAddUrn(AuthService) {
    'ngInject';
    return {
        restrict: 'A',
        scope: true,
        bindToController: {
            clientId: '@',
            urns: '=',
            selectedUrn: '=?',
            country: '@'
        },
        controller,
        controllerAs: 'vm',
        link(scope, elem, attrs) {

            if (AuthService.isAgent()) {
                elem[0].disabled = true;
            } else {
                elem.on('click', scope.vm.openDialog);
                scope.$on('$destroy', () => elem.off('click', scope.vm.openDialog));
            }
        }
    };
}
function controller($scope, $mdDialog, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm',
            locals: { country: vm.country || '' }
        }).then(urn => {
            if (!urn || !urn.number || !urn.country) return;
            ActiveService.addUrn(vm.clientId, urn).then(
                data => {
                    const { urn, timelineAction } = data;
                    Array.isArray(vm.urns) ? vm.urns.push(urn) : vm.urns = [ urn ];
                    if (vm.selectedUrn) vm.selectedUrn = urn;
                    ActiveService.addTimelineAction(timelineAction);
                    $scope.$emit('urn.update', vm.selectedUrn);
                },
                error => {
                    ErrorHandler.showDialog('Failed to add URN', error);
                    console.error('Failed to add URN to client:', error);
                }
            );
        }, () => { });
    }
}
function dialogController(country, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.urn = {
        number: '',
        country: country || ''
    };

    vm.done = $mdDialog.hide;
    vm.cancel = $mdDialog.cancel;
}

const template = `
<md-dialog aria-label="Add URN">
    <form name="urnForm" ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Add URN</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label>Unique reference number</label>
                    <input type="text" ng-model="vm.urn.number" required/>
                </md-input-container>
                <gbbg-country-select country="vm.urn.country"></gbbg-country-select>
            </div>
        </md-dialog-content>
        <md-dialog-actions layout="row">
            <span flex></span>
            <md-button
                class="md-primary md-raised"
                ng-click="vm.done(vm.urn)"
                ng-disabled="urnForm.$invalid || !vm.urn.country"
            > Add URN </md-button>
            <md-button ng-click="vm.cancel()"> Cancel </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>`
;

export { gbbgAddUrn }
