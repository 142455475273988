const gbbgClientNameAutocomplete = {
    template: `
    <md-autocomplete
        flex
        required
        md-selected-item="vm.selectedItem"
        md-search-text="vm.client.name"
        md-search-text-change="vm.getNames(vm.client.name)"
        md-items="item in vm.items"
        md-item-text="item.name"
        md-selected-item-change="vm.openDialog()"
        md-floating-label="Company name"
    >
        <md-item-template>
            <span md-highlight-text="vm.searchText">{{item.name}}</span>
        </md-item-template>
    </md-autocomplete>
    `,
    bindings: { client: "=", onChange: '&', noPrompt: '@?', status: '@?' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, $timeout, ClientService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.selectedItem;
    vm.items = [];

    vm.$state = $state;

    vm.openDialog = openDialog;
    vm.getNames = getNames;
    vm.updateOnChange = updateOnChange

    function getNames(searchText) {
        if (!searchText) return;
        ClientService.getClientNames(searchText, vm.status).then(
            data => vm.items = data,
            error => console.error('Failed to get client names:', error)
        );
    }

    function openDialog() {
        if (vm.noPrompt) {
            vm.client.company = Object.assign({}, vm.selectedItem)
        }
        if (vm.noPrompt || !vm.selectedItem || !vm.selectedItem.name || !vm.selectedItem._id) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Load existing record?')
            .textContent(`A client record already exists for '${vm.selectedItem.name}'. Would you like to load this client record or continue creating a new client?`)
            .ariaLabel('Load existing record')
            .ok('Load existing record')
            .cancel('Make new lead')
        ).then(
            () => {
                if (vm.selectedItem.status && vm.selectedItem.status.status === 'active') {
                    $state.go('root.auth.active.client', { id: vm.selectedItem._id });
                } else {
                    $state.go('root.auth.leads.lead', { leadId: vm.selectedItem._id });
                }
            },
            () => { }
        );
    }

    function updateOnChange({ country }) {
        if (vm.onChange) $timeout(() => vm.onChange({ country }));
    }
}

export { gbbgClientNameAutocomplete }
