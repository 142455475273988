import { claimInvoiceDialogController } from './claim_invoice_dialog_controller';

const gbbgClaimAddInvoice = {
    template: `
    <md-button class="md-accent md-raised md-icon-button" ng-click="vm.openDialog()">
        <md-icon md-font-library="material-icons">add_circle_outline</md-icon>
        <md-tooltip md-direction="down">Add invoice</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@', country: '@', traveller: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog(invoice = {}) {
        $mdDialog.show({
            templateUrl: '/partials/templates/claim/add_invoice_dialog.html',
            locals: {
                country: vm.country || '',
                traveller: vm.traveller || { name: '', position: '' },
                invoice // if reopening after error
            },
            controller: claimInvoiceDialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then((invoice) => {
            ClaimService.addInvoice(vm.claimId, invoice).then(
                data => {
                    ClaimService.setClaim(data)
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Invoice added successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to add invoice', error, 'Okay', false, () => openDialog(invoice));
                    console.error('Failed to add invoice:', error);
                }
            );
        }, () => {});
    }
}

export { gbbgClaimAddInvoice };
