function newleadViewController($mdDialog, $state, LeadService, ClientService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.isLoaded = false;

    vm.lead = {
        primaryContact: { },
        estimates: { }
    };

    vm.createNewLead = createNewLead;
    vm.updateSource = updateSource;

    vm.$onInit = activate;

    function activate() { vm.isLoaded = true; }

    function createNewLead() {
        LeadService.createNewLead(vm.lead).then(
            data => {
                vm.lead._id = data._id;
                openDialog(vm.lead._id);
            },
            error => {
                ErrorHandler.showDialog('Failed to create new lead', error);
                console.error('Failed to create new lead:', error);
            }
        );
    }

    function updateSource(source, sourceId) {
        if (!source && !sourceId) return;
        const { primaryContact = {} } = vm.lead;
        if (!primaryContact.name) primaryContact.name = source.contact;
        if (!primaryContact.email) primaryContact.email = source.email;
        if (!primaryContact.phone) primaryContact.phone = source.phone;
        if (source.defaults && source.defaults.commission) vm.lead.estimates.agentFee = source.defaults.commission;
    }

    function openDialog(leadId) {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Lead created! What next?')
            .textContent('Do you want to open this record or create another new lead?')
            .ariaLabel('New lead created dialog')
            .ok('Open lead')
            .cancel('Add another lead')
        ).then(() => {
            $state.go('root.auth.leads.lead', { leadId: vm.lead._id }, { reload: 'root.auth.leads.lead' });
        }, () => {
            $state.go('root.auth.leads.new', {  }, { reload: 'root.auth.leads.new' });
        });
    }
}

export { newleadViewController };
