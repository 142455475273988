const alignRight = { halign: 'right' }

const PDF_HEADER = function(data) {
    return [
        [
            { content: 'Claims commission report', colSpan: 1 },
            { content: data.total, styles: alignRight },
            { content: data.totalRefunded, styles: alignRight },
            { content: data.totalGbbgAllocation, styles: alignRight },
            { content: data.totalAgentAllocation, styles: alignRight },
            { content: data.totalClientAllocation, styles: alignRight },
            { content: data.totalCosts, styles: alignRight },
            { content: data.totalCharges, styles: alignRight },
        ],
        [ 'Source', 'Total claimed', 'Total refunded', 'GBBG fee', 'Source/agent fee', 'Client fee', 'Restyling charges', 'Other charges' ]
    ]
}

const PDF_COLUMNS = [
    { header: 'Source', dataKey: 'source' },
    { header: 'Total claimed', dataKey: 'total', styles: { halign: 'right' } },
    { header: 'Total refunded', dataKey: 'totalRefunded', styles: { halign: 'right' } },
    { header: 'GBBG fee', dataKey: 'totalGbbgAllocation', styles: { halign: 'right' } },
    { header: 'Agent fee', dataKey: 'totalAgentAllocation', styles: { halign: 'right' } },
    { header: 'Client fee', dataKey: 'totalClientAllocation', styles: { halign: 'right' } },
    { header: 'Restyling', dataKey: 'totalCharges', styles: { halign: 'right' } },
    { header: 'Other charges', dataKey: 'totalCosts', styles: { halign: 'right' } }
]

const COLUMN_STYLES = {
    total: alignRight,
    totalRefunded: alignRight,
    totalGbbgAllocation: alignRight,
    totalAgentAllocation: alignRight,
    totalClientAllocation: alignRight,
    totalCharges: alignRight,
    totalCosts: alignRight
}

const STYLES = { fontSize: 8 }

const didDrawPage = function({ doc }) {
    // Example: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
    let str = `Page ${doc.internal.getNumberOfPages()} of {total_pages_count_string}` // https://github.com/simonbengtsson/jsPDF-AutoTable/issues/144#issuecomment-232106480
    doc.setFontSize(8)
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 5, pageHeight - 5)
}

export { PDF_HEADER, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage }
