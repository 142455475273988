const gbbgSourceDetails = {
    templateUrl: '/partials/templates/source/details.html',
    bindings: { source: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.payTimes = [
        'monthly',
        'quarterly',
        'half-yearly',
        'yearly'
    ];
}

export { gbbgSourceDetails };
