import { gbbgPreClaimActions } from './pre-claim_actions';
import { gbbgAddPreClaim } from './pre-claim_add';
import { gbbgPreClaimClose } from './pre-claim_close';
import { gbbgPreClaimListFilters } from './pre-claim_list_filters';
import { gbbgPreClaimList } from './pre-claim_list';
import { gbbgPreClaimToClaim } from './pre-claim_new_claim';
import { preClaimRoutes } from './pre-claim_routes';
import { PreClaimService } from './pre-claim_service';
import { gbbgPreClaimTimeline } from './pre-claim_timeline';

angular
.module('preClaim', [])
.config(preClaimRoutes)
.component('gbbgPreClaimActions', gbbgPreClaimActions)
.component('gbbgAddPreClaim', gbbgAddPreClaim)
.component('gbbgPreClaimClose', gbbgPreClaimClose)
.component('gbbgPreClaimListFilters', gbbgPreClaimListFilters)
.component('gbbgPreClaimList', gbbgPreClaimList)
.component('gbbgPreClaimToClaim', gbbgPreClaimToClaim)
.component('gbbgPreClaimTimeline', gbbgPreClaimTimeline)
.factory('PreClaimService', PreClaimService);

export default 'preClaim';
