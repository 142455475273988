function SignInController($mdDialog, $scope, $state, $stateParams, $window, AuthService, ErrorHandler) {
    "ngInject"
    /*validthis:true*/
    const vm = this

    vm.pending = false // diable button while request pending
    vm.message = ''
    vm.message = $stateParams.redirectMessage || ''

    vm.credentials = {
        email: '',
        password: ''
    }

    vm.signIn = signIn
    vm.msAuth = msAuth

    vm.$onInit = activate

    function activate() {
        if (!$stateParams.redirectMessage || !$stateParams.redirectMessage.length) return
        vm.message  = $stateParams.redirectMessage
    }

    function msAuth() {
        AuthService
        .getMsUri()
        .then((data) => {
            /*
                Store the redirect uri for later.
                Redirect user to allow MS auth
            */
            $window.location.href = data
        }, error => {
            vm.pending = false
            $mdDialog.cancel(error)
            vm.message = error.data ? error.data.message : 'Sign in failed. Unknown error.'
            console.error('Sign in failed:', error)
        })
    }

    function signIn() {
        return; // prevent users from signing in with password
        vm.pending = true
        AuthService.signIn(vm.credentials).then(
            data => {
                vm.pending = false
                $mdDialog.hide(vm.credentials) // AuthService.openSignInDialog opens a dialog when 400/401 response from server
                if (window.location.pathname === '/') {
                    if (data && data.requirePasswordReset) {
                        $state.go('root.auth.management.users.user', { id: data.id })
                    } else {
                        $state.go('root.auth.leads.all')
                    }
                } else {
                    $state.go($state.current.name, {}, { reload: $state.current.name })
                }
            },
            error => {
                vm.pending = false
                $mdDialog.cancel(error)
                vm.message = error.data ? error.data.message : 'Sign in failed.'
                ErrorHandler.showDialog('Signin failed', error)
                console.error('Sign in failed:', error)
            }
        )
    }
}

export { SignInController }
