function utilsApiImage($http, $timeout) {
    'ngInject';
    return {
        restrict: 'A',
        link
    };
    function link(scope, elem, attrs) {

        $timeout(activate);

        function activate() {
            const url = attrs.utilsApiImage;
            const origin = getOriginRedirectUrl();
            const token = localStorage.gbbgToken;
            if (url && token) {
                if (elem[0].tagName === 'IMG') {
                    attrs.src = `${origin}/api/image/${url}?token=${token}`;
                    attrs.$set('src', `${origin}/api/image/${url}?token=${token}`);
                } else {
                    elem.css({
                        'background-image': `url('${origin}/api/image/${url}?token=${token}')`,
                    });
                }
            }
        }

        function getOriginRedirectUrl() {
            const origin = window.location.origin;
            if (origin.indexOf('http://localhost:3000') >= 0) return 'http://localhost:8082';
            if (origin.indexOf('https://testcrm.') >= 0) return 'https://testapi.gbbusinessvat.com';
            if (origin.indexOf('https://crm.') >= 0) return 'https://api.gbbusinessvat.com';
            if (origin.indexOf('http://localhost:3001') >= 0) return 'http://localhost:8083';
            if (origin.indexOf('https://testarchive.') >= 0) return 'https://testarchiveapi.gbbusinessvat.com';
            if (origin.indexOf('https://archive.') >= 0) return 'https://archiveapi.gbbusinessvat.com';
        }
    }
}

export { utilsApiImage }
