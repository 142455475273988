function claimListViewController($state, $stateParams, ClaimService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    let requestPage = 1

    vm.showLoadMore = false
    vm.isLoading = false
    vm.claims = []

    vm.getClaims = getClaims

    vm.$onInit = getClaims

    function getClaims() {
        vm.isLoading = true
        const { status, sort, query } = $stateParams

        if (!status) return $state.go('root.auth.claims.all', {
            status: status || localStorage.getItem('gbbgClaimFilterStatus') || 'pending',
            sort: sort || localStorage.getItem('gbbgClaimFilterSort') || '-dateCreated',
            query: query || localStorage.getItem('gbbgClaimFilterQuery') || '',
        }, { reload: $state.current.name })

        ClaimService.getAllClaims({ status, sort, query, page: requestPage }).then(
            (data) => {
                vm.isLoading = false
                data.claims.map(claim => vm.claims.push(claim))
                vm.showLoadMore = (data.page < data.pages)
                vm.totalClaims = data.total || 0
                requestPage = Number(data.page) + 1
            },
            (error) => {
                vm.isLoading = false
                ErrorHandler.showDialog('Failed to get claims', error)
                console.error('Failed to get claims:', error)
            }
        )
    }
}

export { claimListViewController }
