const gbbgClientTimelineSpeeddial = {
    template: `
    <md-fab-speed-dial
        md-open="vm.isOpen"
        md-direction="left"
        class="md-scale md-fab md-mini"
        ng-mouseenter="vm.isOpen = true"
        ng-mouseleave="vm.isOpen = false"
    >
        <md-fab-trigger>
            <md-button aria-label="menu" class="md-fab md-mini md-secondary">
                <md-icon md-font-library="material-icons">menu</md-icon>
            </md-button>
        </md-fab-trigger>

        <md-fab-actions style="position: absolute; left: -400%;">
            <div ng-repeat="action in vm.actions">
                <md-button
                    class="md-fab md-raised md-mini"
                    aria-label="{{action.label}}"
                    ng-click="vm.openDialog(action.action)"
                >
                    <md-icon md-font-library="material-icons">{{action.icon}}</md-icon>
                    <md-tooltip md-direction="bottom">{{action.label}}</md-tooltip>
                </md-button>
            </div>
        </md-fab-actions>
    </md-fab-speed-dial>
    `,
    bindings: {
        clientId: '@',
        timeline: '=',
        lockedOpen: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClientService, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.isOpen = false;

    vm.actions = [
        { label: 'received call', icon: 'call_end', action: 'call-in' },
        { label: 'made call', icon: 'call', action: 'call-out' },
        { label: 'received email', icon: 'call_received', action: 'email-in' },
        { label: 'send email', icon: 'call_made', action: 'email-out' }
    ];

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() { vm.isOpen = vm.lockedOpen ? true : false; }

    function openDialog(activity) {
        $mdDialog.show({
            templateUrl: '/partials/templates/client/timeline_dialog.html',
            locals: {
                clientId: vm.clientId,
                activity
            },
            fullscreen: true,
            controller: dialogController,
            controllerAs: 'vm'
        }).then(({ details, email, recipients, sender, send }) => {
            if (send && send == true) {
                sendEmail(email, recipients, details, sender);
            } else {
                addToTimeline(activity, details, email);
            }
        }, () => {});
    }

    function addToTimeline(activity, details, email, emailId) {
        if (!activity || !vm.clientId) return;
        ClientService.addToTimeline(vm.clientId, activity, details, email, emailId).then(
            (data) => {
                if (vm.timeline) vm.timeline.unshift(data);
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Timeline updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            (error) => {
                ErrorHandler.showDialog('Timeline update failed', error);
                console.error('Timeline update failed:', error);
            }
        );
    }

    function sendEmail(email, recipients, details, sender) {
        EmailService.sendEmail(email, recipients, vm.clientId, null, details, sender).then(
            (emailId) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Email sent successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                // addToTimeline('email-out', details, null, emailId);
            },
            (error) => {
                ErrorHandler.showDialog('Failed to send email', error);
                console.error('Failed to send email:', error);
            }
        );
    }
}
function dialogController(activity, clientId, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.clientId = clientId;
    vm.details = '';

    vm.activity = (activity === 'call-out' || activity === 'call-in') ? 'call' : 'email';
    vm.allowSend = (activity === 'email-out') || false;
    vm.attachEmail = false;

    vm.email = {
        cc: [],
        bcc: [],
    };
    vm.selectedRecipients = [];

    vm.submit = submit;

    function submit(send = false) {
        if (!vm.attachEmail || vm.activity != 'email') vm.email = null;
        $mdDialog.hide({
            details: vm.details,
            email: vm.email,
            recipients: vm.selectedRecipients,
            sender: vm.selectedSender,
            send
        });
    }
}

export { gbbgClientTimelineSpeeddial };
