const alignRight = { halign: 'right' }

const PDF_HEADER = function(data) {
    return [
        [
            { content: data.source, colSpan: 2 },
            { content: data.total, styles: alignRight },
            { content: data.totalRefunded, styles: alignRight },
            { content: data.totalGbbgAllocation, styles: alignRight },
            { content: data.totalAgentAllocation, styles: alignRight },
            { content: data.totalClientAllocation, styles: alignRight },
            { content: data.totalCosts, styles: alignRight },
            { content: data.totalCharges, styles: alignRight },
        ],
        [
            'Claim',
            'Client',
            { content: 'Total claimed', styles: alignRight },
            { content: 'Total refunded', styles: alignRight },
            { content: 'GBBG fee', styles: alignRight },
            { content: 'Source/agent fee', styles: alignRight },
            { content: 'Client fee', styles: alignRight },
            { content: 'Restyling charges', styles: alignRight },
            { content: 'Other charges', styles: alignRight }
        ]
    ]
}

const PDF_COLUMNS = [
    { header: 'Claim', dataKey: 'referenceNo' },
    { header: 'Client', dataKey: 'client' },
    { header: 'Total claimed', dataKey: 'total' },
    { header: 'Total refunded', dataKey: 'totalRefunded' },
    { header: 'GBBG fee', dataKey: 'totalGbbgAllocation' },
    { header: 'Agent fee', dataKey: 'totalAgentAllocation' },
    { header: 'Client fee', dataKey: 'totalClientAllocation' },
    { header: 'Restyling', dataKey: 'totalCharges' },
    { header: 'Other charges', dataKey: 'totalCosts' }
]

const COLUMN_STYLES = {
    client: { minCellWidth: 30 },
    total: alignRight,
    totalRefunded: alignRight,
    totalGbbgAllocation: alignRight,
    totalAgentAllocation: alignRight,
    totalClientAllocation: alignRight,
    totalCharges: alignRight,
    totalCosts: alignRight
}

const STYLES = { fontSize: 8 }

const didDrawPage = function({ doc }) {
    // Example: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
    // let str = `Page ${pageNumber} of {total_pages_count_string}`
    let str = `Page ${doc.internal.getNumberOfPages()} of {total_pages_count_string}` // https://github.com/simonbengtsson/jsPDF-AutoTable/issues/144#issuecomment-232106480
    doc.setFontSize(8)
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 5, pageHeight - 5)
}

export { PDF_HEADER, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage }
