import { emailTemplatesListViewController } from './email_templates_list_view_controller';
import { emailErrorsViewController } from './email_errors_view_controller';

function emailRoutes($stateProvider) {
    'ngInject';
    $stateProvider
    .state('root.auth.management.emails', {
        url: '/emails?team',
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-add-email-template></gbbg-add-email-template>
                </gbbg-top-nav>
                `
            },
            "main@root.auth": {
                templateUrl: '/partials/views/email/list.html',
                controller: emailTemplatesListViewController,
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.management.email_errors', {
        url: '/email-errors',
        views: {
            "top@root.auth": {
                template: `<gbbg-top-nav></gbbg-top-nav>`
            },
            "main@root.auth": {
                templateUrl: '/partials/views/email/errors.html',
                controller: emailErrorsViewController,
                controllerAs: 'vm'
            }
        }
    });
}

export { emailRoutes }
