const gbbgAddEmailTemplate = {
    template: `
    <md-button
        class="md-fab md-secondary md-hue-1 md-mini"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">add</md-icon>
        <md-tooltip md-direction="bottom">New email template</md-tooltip>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $state, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/email/template_dialog.html',
            controller: dialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then((email) => {
            if (!email.title || !email.subject || !email.body) return;
            EmailService.newTemplate(email).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('New email template added successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                },
                error => {
                    ErrorHandler.showDialog('Failed to create new email template', error);
                    console.error('Failed to create new email template:', error);
                }
            );
        }, () => {});
    }
}
function dialogController(AuthService, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.notAdmin = !AuthService.isAdmin();
    vm.dialogTitle = 'New email template';

    vm.email = {
        title: '',
        subject: '',
        body: '',
        teams: []
    };

    vm.cancel = $mdDialog.cancel;
    vm.addTemplate = addTemplate;

    function addTemplate() { $mdDialog.hide(vm.email); }
}

export { gbbgAddEmailTemplate };
