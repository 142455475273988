const gbbgUserSelect = {
    template($element, $attrs) {
        if ($attrs.multiple) {
            return `
            <div layout="row">
                <md-input-container>
                    <label>Select user(s)</label>
                    <md-select
                        ng-model="vm.selectedUsers"
                        ng-change="vm.upateOnChange()"
                        multiple
                    >
                        <md-option
                            ng-repeat="user in vm.users"
                            ng-value="user._id"
                        >{{user.firstName}} {{user.surname}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container>
                    <md-checkbox
                        ng-click="vm.toggleAll()"
                        ng-checked="vm.selectedUsers.length >= vm.users.length"
                    >Select all users</md-checkbox>
                </md-input-container>
            </div>
            `
        } else {
            return `
            <div>
                <md-input-container>
                    <label>Select user</label>
                    <md-select ng-model="vm.selectedUser" ng-change="vm.upateOnChange()">
                        <md-option ng-repeat="user in vm.users" ng-value="user._id">
                            <strong>{{user.firstName}} {{user.surname}}</strong>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
            `
        }
    },
    bindings: {
        selectedUser: '=?',
        selectedUsers: '=?',
        onChange: '&'
    },
    controller,
    controllerAs: 'vm'
};
function controller(ErrorHandler, UserService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.users = [];

    vm.$onInit = activate;

    vm.upateOnChange = upateOnChange;
    vm.toggleAll = toggleAll;

    function activate() {
        UserService.getAllUsers().then(
            data => {
                vm.users = data;
                vm.users.unshift({ _id: null, firstName: 'Undefined user' });
                vm.selectedUsers = vm.users[0]._id;
            },
            error => {
                ErrorHandler.showDialog('Failed to get user list', error);
                console.error('Failed to get user list:', error);
            }
        );
    }

    function toggleAll() {
        if (vm.selectedUsers.length === vm.users.length) vm.selectedUsers.splice(0);
        else vm.selectedUsers = [...vm.users];
    }

    function upateOnChange() { if (vm.onChange) vm.onChange({ userId: vm.selectedUser }); }
}

export { gbbgUserSelect }
