import { gbbgAccessListFilters } from './access_list_filters';
import { gbbgAccessRecordList } from './access_list';
import { accessRoutes } from './access_routes';
import { AccessService } from './access_service';

angular
.module('access', [])
.config(accessRoutes)
.component('gbbgAccessListFilters', gbbgAccessListFilters)
.component('gbbgAccessRecordList', gbbgAccessRecordList)
.factory('AccessService', AccessService);

export default 'access';
