const gbbgClaimClientTimeline = {
    template: `
    <md-list>
        <div ng-repeat="t in vm.timeline | orderBy: '-timestamp' track by $index">
            <md-list-item class="md-3-line md-long-text">
                <div class="md-list-item-text" layout="column">
                    <h3>{{t.timestamp | date:'yyyy/MM/dd @ h:mma'}}</h3>
                    <h4 ng-bind="t.addedBy.firstName + ' ' + t.addedBy.surname"></h4>
                    <p>
                        <strong ng-bind="t.activity + ':'"></strong>
                        <span ng-bind="t.details"></span>
                    </p>
                </div>
            </md-list-item>
            <md-divider></md-divider>
        </div>
    </md-list>
    `,
    bindings: { clientId: '@' },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, ClaimService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.timeline = []

    vm.$onInit = activate

    function activate() {
        ClaimService
        .getClientTimeline(vm.clientId)
        .then(
            (data) => vm.timeline = data.timeline,
            (error) => {
                ErrorHandler.showDialog('Failed to get client timeline', error)
                console.log('Failed to get client timeline:', error)
            }
        )
    }
}

export { gbbgClaimClientTimeline }
