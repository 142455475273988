function LeadService($http, LeadHandler) {
    return {
        createNewLead(lead) {
            return $http.post(`/api/lead`, lead).then(success, failure);
        },
        getLeads(params) {
            return $http.get(`/api/leads`, { params }).then(success, failure);
        },
        getLead(id) {
            return $http.get(`/api/lead/${id}`).then(success, failure);
        },
        saveLead(lead) {
            const { _id } = lead;
            return $http.put(`/api/lead/${_id}`, lead).then((response) => {
                if (response.status != 200) throw response;
                if (LeadHandler.currentLead && LeadHandler.currentLead._id) {
                    LeadHandler.currentLead.status = response.data.status;
                }
                return response.data;
            }, failure);
        },
        deleteLead(leadId) {
            return $http.delete(`/api/lead/${leadId}`).then(success, failure);
        },
        setStatus(leadId, status, reason, details) {
            return $http.put(`/api/lead/status/${leadId}`, { status, reason, details }).then(success, failure);
        },
        setActive(leadId) {
            return $http.put(`/api/lead/activate/${leadId}`).then(success, failure);
        },
        setPaperworkSent(leadId, status) {
            return $http.put(`/api/lead/paperwork/sent/${leadId}`, { sent: status }).then(success, failure);
        },
        setPaperworkReceived(leadId, status) {
            return $http.put(`/api/lead/paperwork/received/${leadId}`, { received: status }).then(success, failure);
        },
        setMultipleStatuses(leadArray) {
            return $http.post(`/api/lead/status`, leadArray).then(success, failure);
        },
        getLeadCountries() {
            return $http.get('/api/lead/unassigned/countries').then(success, failure);
        },
        getLeadsByCountry(countries, enquirySource = null) {
            if (countries && Array.isArray(countries)) {
                countries = countries.map(country => country || 'null'); // removes any empty strings from array
                countries.join(',');
            }
            return $http.get(`/api/leads/unassigned/country/${countries || null}?source=${enquirySource}`).then(success, failure);
        },
        assignLeads(leads, users, setAll) {
            const all = setAll ? '?set=all' : '';
            return $http.post(`/api/leads/assign${all}`, { leads, users }).then(success, failure);
        },
        setAccountManager(leads, users, setAll) {
            const all = setAll ? '?set=all' : '';
            return $http.post(`/api/leads/account-manager${all}`, { leads, users }).then(success, failure);
        },
        getFlaggedLeads(lockedFilter = false, agentFilter = false) {
            return $http.get(`/api/leads/flagged?lockedFilter=${lockedFilter}&agentFilter=${agentFilter}`).then(success, failure);
        },
        toggleForceValid(leadId) {
            return $http.put(`/api/lead/force-validity/${leadId}`).then(success, failure);
        }
    };
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { LeadService };
