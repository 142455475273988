const gbbgClaimList = {
    template: `
    <md-list ng-if="vm.claims && vm.claims.length">
        <md-list-item class="md-3-line" ng-repeat="claim in vm.claims track by $index">
            <div class="md-list-item-text">
                <h3>
                    <strong ng-bind="::claim.referenceNo"></strong>
                    <span>
                        ({{claim.status.status | claimStatus}} {{ (claim.refund.refundTotal || claim.refund.claimTotal || claim.invoiceTotal) | currency: '£' }})
                    </span>
                </h3>
                <h4>
                    <strong ng-bind="::claim.client.name"></strong>
                    <span ng-if="::claim.client.address.city" ng-bind="::claim.client.address.city + ','"></span>
                    <span ng-bind="::claim.client.address.country"></span>
                </h4>
                <p>
                    <span>
                        Created: {{::claim.dateCreated | date:'dd MMMM yyyy'}}
                        by {{::claim.createdBy.firstName}} {{::claim.createdBy.surname}}
                    </span>
                    <span ng-if="::claim.lastInteraction"> | Last update: {{::claim.lastInteraction}}</span>
                </p>
            </div>
            <md-button
                class="md-secondary md-icon-button"
                ui-sref="root.auth.claims.claim({ id: claim._id })"
                ui-sref-opts="{ inherit: false }"
            >
                <md-icon>open_in_new</md-icon>
                <md-tooltip>Open claim</md-tooltip>
            </md-button>
            <md-checkbox
                class="md-secondary"
                ng-if="vm.showSelect"
                aria-label="{{claim.referenceNo}}"
                ng-click="vm.toggleSelected(claim._id)"
                ng-checked="vm.isChecked(claim._id)"
            ></md-checkbox>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { claims: '<', selectedClaims: '=?' },
    controller,
    controllerAs: 'vm'
};
function controller($attrs) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$onInit = activate;

    function activate() {
        vm.showSelect = ($attrs.showSelect && $attrs.showSelect === 'true');
        if (!vm.selectedClaims) vm.selectedClaims = [];
    }

    vm.toggleSelected = toggleSelected;
    vm.isChecked = isChecked;

    function toggleSelected(claimId) {
        const idx = vm.selectedClaims.indexOf(claimId);
        if (idx >= 0) vm.selectedClaims.splice(idx, 1);
        else vm.selectedClaims.push(claimId);
    }

    function isChecked(claimId) { return vm.selectedClaims.includes(claimId); }
}

export { gbbgClaimList };
