const gbbgCloseDialog = {
    template: `
    <md-button class="md-icon-button" ng-click="vm.cancel()">
        <md-icon md-font-library="material-icons">close</md-icon>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.cancel = $mdDialog.cancel;
}

export { gbbgCloseDialog };
