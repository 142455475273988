const alignRight = { halign: 'right' }

const PDF_HEADER = function(data) {
    return [
        [
            { content: `${data.name} (${data.referenceNo})`, colSpan: 4 },
            { content: data.totalRefunded, styles: alignRight },
            { content: data.totalPenalties, styles: alignRight },
            { content: data.totalBankCharges, styles: alignRight },
            { content: data.totalCertCosts, styles: alignRight },
            { content: data.totalAddCosts, styles: alignRight },
            { content: data.totalCharges, styles: alignRight },
        ],
        [ 'Ref.', 'Claim country', 'URN', 'Refund date', 'Refund total', 'Invoice penalties', 'Bank charges', 'Cert costs', 'Add. costs', 'Total charges (ex. invoice penalties)' ]
    ]
}

const PDF_COLUMNS = [
    { header: 'Ref.', dataKey: 'referenceNo' },
    // { header: 'Client', dataKey: 'clientName' },
    // { header: 'Client country', dataKey: 'clientCountry' },
    { header: 'Claim country', dataKey: 'country' },
    { header: 'URN', dataKey: 'urn' },
    { header: 'Date refunded', dataKey: 'dateRefunded' },
    { header: 'Refund total', dataKey: 'refundTotal', styles: { halign: 'right' } },
    { header: 'Invoice penalties', dataKey: 'penaltyTotal', styles: { halign: 'right' } },
    { header: 'Bank charges', dataKey: 'bankCharge', styles: { halign: 'right' } },
    { header: 'Cert costs', dataKey: 'certCost', styles: { halign: 'right' } },
    { header: 'Add. costs', dataKey: 'additionalCosts', styles: { halign: 'right' } },
    { header: 'Total charges (ex. invoice penalties)', dataKey: 'totalCharges', styles: { halign: 'right' } }
]

const COLUMN_STYLES = { refundTotal: alignRight, penaltyTotal: alignRight, bankCharge: alignRight, certCost: alignRight, additionalCosts: alignRight, totalCharges: alignRight }

const STYLES = { fontSize: 8 }

const didDrawPage = function({ doc }) {
    // Example: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
    let str = `Page ${doc.internal.getNumberOfPages()} of {total_pages_count_string}` // https://github.com/simonbengtsson/jsPDF-AutoTable/issues/144#issuecomment-232106480
    doc.setFontSize(8)
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 5, pageHeight - 5)
}

export { PDF_HEADER, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage }
