import { assignLeadsByCountryViewController } from './assign_country_view_controller';
import { assignLeadsFromUserViewController } from './assign_users_view_controller';

function assignRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.assign', {
        url: '/assign',
        abstract: true
    })
    .state('root.auth.management.assign.country', {
        url: '',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/assign/country.html',
                controller: assignLeadsByCountryViewController,
                controllerAs: 'vm'
            }
        },
        title: 'Assign leads by country'
    })
    .state('root.auth.management.assign.users', {
        url: '/users',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/lead/assign/users.html',
                controller: assignLeadsFromUserViewController,
                controllerAs: 'vm'
            }
        },
        title: '(re)assign leads from user'
    });
}

export { assignRoutes };
