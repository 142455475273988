const gbbgEmailSenderSelect = {
    template: `
    <md-input-container>
        <label>Sender</label>
        <md-select ng-model="vm.sender">
            <md-option
                ng-repeat="email in vm.emailAddresses"
                ng-value="email"
            >{{email}}</md-option>
        </md-selct>
    </md-input-container>
    `,
    bindings: { sender: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.emailAddresses = [ localStorage.gbbgEmail ];
    vm.sender = vm.emailAddresses[0];

    vm.$onInit = activate;

    function activate() {
        if (localStorage.gbbgUserAccess <= 301) return;
        vm.emailAddresses.push('payments@gbbusinessglobal.com');
    }
}

export { gbbgEmailSenderSelect };
