const gbbgClientTimelineUpdate = {
    template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.openDialog()"
        ng-disabled="!vm.clientId || !vm.timelineRecord || !vm.timelineRecord._id"
    >
        <md-icon>open_in_browser</md-icon>
        <md-tooltip>Edit timeline</md-tooltip>
    </md-button>
    `,
    bindings: {
        clientId: '@',
        timelineRecord: '='
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClientService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.clientId || !vm.timelineRecord || !vm.timelineRecord._id) return;
        $mdDialog.show({
            template,
            locals: { timelineRecord: vm.timelineRecord },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((timelineRecord) => {
            ClientService.updateTimelineRecord(vm.clientId, timelineRecord).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Updated timeline record successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to update timeline record', error);
                    console.error('Failed to update timeline record:', error);
                }
            );
        }, () => { });
    }

    const template = `
    <md-dialog class="large-dialog" aria-label="Update timeline record">
        <form name="timelineRecordForm" ng-cloak>
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Update timeline record</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content>
                <div class="md-dialog-content" layout="column">

                    <md-input-container>
                        <label>Timeline activity</label>
                        <md-select ng-model="vm.timelineRecord.activity" required>
                            <md-option ng-repeat="activity in vm.activities" ng-value="activity">{{activity}}</md-option>
                        </md-select>
                    </md-input-container>

                    <md-input-container>
                        <label>Timeline details</label>
                        <textarea
                            md-maxlength="500"
                            maxlength="500"
                            ng-model="vm.timelineRecord.details"
                            required
                        ></textarea>
                    </md-input-container>

                </div>
            </md-dialog-content>
            <md-dialog-actions layout="row">
                <span flex></span>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.updateTimeline()"
                    ng-disabled="timelineRecordForm.$invalid"
                > Update timeline </md-button>
                <md-button ng-click="vm.cancel()"> Cancel </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController(timelineRecord, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.activities = [ 'call-out', 'call-in', 'email-out', 'email-in', 'update', 'audit' ];

    vm.timelineRecord = timelineRecord;

    vm.cancel = $mdDialog.cancel;
    vm.updateTimeline = updateTimeline;

    function updateTimeline() {
        const { _id, activity, details } = vm.timelineRecord;
        if (!_id || !activity || !details) return;
        $mdDialog.hide(vm.timelineRecord);
    }
}

export { gbbgClientTimelineUpdate };
