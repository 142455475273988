import io from 'socket.io-client';

const notificationList = {
    template: `
    <md-button
        id="notification-toggle"
        class="md-icon-button notifications__toggle"
        ng-class="{ 'notifications__toggle--unread': vm.hasUnreadMessages }"
        ng-click="vm.showMenu()"
        ng-disabled="vm.disableSocket"
        aria-label="Show notifications"
    >
        <md-tooltip>{{vm.numUnread}} unread</md-tooltip>
        <md-icon>{{vm.icon}}</md-icon>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdPanel, $timeout, NotificationService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let socket = null;
    const room = localStorage.gbbgUserId;

    vm.disableSocket = false;
    vm.hasUnreadMessages = false;
    vm.showCounter = false;
    vm.counter = 0;
    vm.numUnread = 0;
    vm.icon = 'filter_none';

    vm.showMenu = showMenu;
    vm.calcUnread = calcUnread;

    vm.$onInit = activate;

    function activate() {
        let url = window.location.origin;
        const validUrls = ['http://localhost:3000', 'https://testcrm.gbbusinessvat.com', 'https://crm.gbbusinessvat.com'];
        if (!validUrls.includes(url)) return vm.disableSocket = true;
        if (url === 'http://localhost:3000') url = 'http://localhost:8080';
        socket = io.connect(url, { reconnectionAttempts: 10, reconnectionDelay: 10000 });

        socket.on('connect', () => {
            socket.emit('room', room);
            NotificationService.clearAll();
        });

        socket.on('message', (message) => {
           NotificationService.addMessage(message);
           if (!message.read || message.read != true) {
               $timeout(() => {
                   vm.hasUnreadMessages = true;
                   calcUnread();
               });
           }
        });

        socket.on('messages', (messages = []) => {
           if (!messages || !messages.length) return;
           NotificationService.addMessages(messages);
           if (messages.some(message => !message.read || message.read != true)) {
               $timeout(() => {
                   vm.hasUnreadMessages = true;
                   calcUnread();
               });
           }
        });
    }

    function showMenu(ev) {
        const position = $mdPanel
        .newPanelPosition()
        .relativeTo('#notification-toggle')
        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

        $mdPanel.open({
            attachTo: angular.element(document.body),
            template,
            // panelClass: 'notifications',
            controller: panelController,
            controllerAs: 'vm',
            position: position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 999
        });
        vm.hasUnreadMessages = false;
    }

    function calcUnread() {
        vm.numUnread = NotificationService.notifications.filter(note => note.read != true).length;
        if (!vm.numUnread || vm.numUnread < 1) vm.icon = 'filter_none';
        else if (vm.numUnread > 0 && vm.numUnread < 10) vm.icon = 'filter_' + vm.numUnread;
        else vm.icon = 'filter_9_plus';
    }
}
function panelController($state, $timeout, NotificationService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.notifications = NotificationService.notifications;

    vm.openDoc = openDoc;

    $timeout(() => {
        NotificationService.setRead().then(() => {

        }, (error) => {
            ErrorHandler.showDialog('Failed to mark notifications as read', error);
            console.error('Failed to mark notifications as read:', error);
        });
    }, 1000);

    function openDoc(ref, type) {
        if (!ref || !type) return;
        if (type === 'lead') {
            $state.go('root.auth.leads.lead', { leadId: ref }, { reload: 'root.auth.leads.lead' });
        } else if (type === 'client') {
            $state.go('root.auth.active.client', { id: ref }, { reload: 'root.auth.active.client' });
        } else if (type === 'claim') {
            $state.go('root.auth.claims.claim', { id: ref }, { reload: 'root.auth.claims.claim' });
        } else if (type === 'preClaim') {
            $state.go('root.auth.preclaims.all', {}, { reload: 'root.auth.preclaims.all' });
        }
    }
}
const template = `
<div class="notifications" aria-label="Select your favorite dessert.">
    <notification-scroll>
        <md-list class="md-dense">
            <md-list-item
                class="notifications__notification md-3-line"
                ng-repeat="note in vm.notifications"
                ng-class="{ 'notifications__notification--unread': !note.read }"
            >
                <div class="md-list-item-text">
                    <h3>{{note.title}}</h3>
                    <h4 title="{{note.message}}">{{note.message}}</h4>
                    <p>{{note.date | date: 'dd MMM, yyyy HH:mm'}}</p>
                </div>
                <md-icon
                    class="md-secondary"
                    ng-if="note.docRef && note.docRef.length"
                    ng-click="vm.openDoc(note.docRef, note.docType)"
                    aria-label="Open record"
                >open_in_new_window</md-icon>
                <md-divider></md-divider>
            </md-listem-item>
        </md-list>
    </notification-scroll>
</div>
`;

export { notificationList };
