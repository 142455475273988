function NotificationService($http) {
    'ngInject';
    const service = {
        notifications: [],
        loadNotifications() {
            let date;
            if (service.notifications && service.notifications.length) {
                date = service.notifications[service.notifications.length-1].date;
            }
            return $http.get('/api/notifications', { params: { date } }).then((response) => {
                if (response.status != 200) throw response;
                if (response.data && response.data.length) {
                    response.data.map(message => service.notifications.push(message));
                }
                return response.data;
            }, failure);
        },
        setRead() {
            let date = service.notifications.length ? service.notifications[0].date : new Date();
            return $http.put('/api/notifications/read', { date }).then((response) => {
                if (response.status != 200) throw response;
                service.notifications.forEach((message) => {
                    if (message.date <= date) message.read = true;
                });
                return response.data;
            }, failure);
        },
        addMessage(message) {
            if (!message || typeof message != 'object') return;
            service.notifications.unshift(message);
        },
        addMessages(messages) {
            if (!messages || !messages.length) return;
            messages.map(message => service.notifications.push(message));
        },
        clearAll() {
            service.notifications.splice(0);
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { NotificationService };
