const gbbgUpdateSource = {
    template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">open_in_browser</md-icon>
        <md-tooltip md-direction="bottom">Edit source</md-tooltip>
    </md-button>
    `,
    bindings: { sourceId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ErrorHandler, SourceService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        SourceService.getSource(vm.sourceId).then((data) => {
            $mdDialog.show({
                template,
                locals: { source: data },
                controller: dialogController,
                controllerAs: 'vm'
            }).then((source) => updateSource(source), () => {});
        }, (error) => {
            ErrorHandler.showDialog('Failed to get enquiry source', error);
            console.error('Failed to get enquiry source:', error);
        });
    }

    function updateSource(source) {
        SourceService.updateSource(source).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Enquiry source updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                ErrorHandler.showDialog('Failed to update enquiry source', error);
                console.error('Failed to update enquiry source:', error);
            }
        );
    }

    const template = `
    <md-dialog aria-label="Update source" style="max-width:600px;">
        <form name="updateSourceForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Update enquiry source</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content>

                <gbbg-source-details source="vm.source"></gbbg-source-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.save()"
                    ng-disabled="updateSourceForm.$invalid"
                > Update source </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController(source, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.source = source;

    vm.cancel = $mdDialog.cancel;
    vm.save = save;

    function save() {
        $mdDialog.hide(vm.source);
    }
}

export { gbbgUpdateSource }
