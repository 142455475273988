const gbbgLeadActivateButton = {
    template: `
    <div layout="column">
        <md-button
            class="md-raised"
            ng-disabled="vm.isInvalid()"
            ng-click="vm.openDialog()"
        >Set active record</md-button>
    </div>
    `,
    bindings: { lead: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, ErrorHandler, LeadService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.isInvalid = isInvalid;
    vm.openDialog = openDialog;

    function openDialog() {
        if (isInvalid()) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Activate record')
            .textContent('Are you sure you want to make this an active client record?')
            .ariaLabel('Activate record')
            .ok('Activate')
            .cancel('Cancel')
        ).then(() => setActive(), () => {});
    }

    function setActive() {
        if (isInvalid()) return;
        LeadService.setActive(vm.lead._id).then(
            data => $state.go('root.auth.active.client', { id: vm.lead._id }, { reload: 'root.auth.active.client' }),
            error => {
                ErrorHandler.showDialog('Activation failed', error);
                console.error('Failed to make lead an active record:', error);
            }
        );
    }

    function isInvalid() {
        return (vm.lead.status.status !== 'prospect' ||
            (
                !vm.lead.primaryContact.name &&
                !vm.lead.primaryContact.email &&
                !vm.lead.primaryContact.phone
            ) ||
            !vm.lead.source ||
            (!vm.lead.assignedTo || vm.lead.assignedTo == null || !vm.lead.assignedTo._id)
        );
    }
}

export { gbbgLeadActivateButton };
