function teamRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.teams', {
        url: '/teams',
        abstract: true,
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-new-team></gbbg-new-team>
                </gbbg-top-nav>
                `
            }
        }
    })
    .state('root.auth.management.teams.all', {
        url: "?sort&query",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/team/list.html',
                controller: 'teamListViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'All teams' }
    })
    .state('root.auth.management.teams.new', {
        url: "/new",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/team/new.html',
                controller: 'teamNewViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'New team' }
    })
    .state('root.auth.management.teams.team', {
        url: "/:id",
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/team/team.html',
                controller: 'teamViewController',
                controllerAs: 'vm'
            }
        },
        data: { title: 'Team' }
    });
}

export { teamRoutes };
