function gbbgAlwaysDisabled($interpolate, $state) {
    return {
        restrict: 'A',
        link
    };
    function link(scope, elem, attrs) {

        let killWatcher;

        activate();

        function activate() {
            if (!attrs.state || (attrs.state === $state.current.name)) disable();
        }

        function disable() {
            elem.attr('disabled', true);
            killWatcher = scope.$watch(
                () => $interpolate(elem.attr('disabled') || '')(scope),
                () => {
                    elem.attr('disabled', true); // Just to make sure
                    killWatcher();
                }
            );
        }
    }
}

export { gbbgAlwaysDisabled };
