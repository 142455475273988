function ReportService($http, $timeout) {
    'ngInject';
    const service = {
        summary: {
            value: 0,
            commission: 0,
            // claim report
            gbbgFee: 0,
            agentFee: 0,
            clientFee: 0,
            penaltyTotal: 0,
            // client report
            totalClaims: 0
        },
        data: [],
        getClaimReport(params = {}) {
            return $http.get('/api/report/claims', { params })
            .then((response) => {
                if (response.status != 200) throw response;
                service.data.splice(0);
                const { reportData = [], value = 0, gbbgFee = 0, agentFee = 0, clientFee = 0, penaltyTotal = 0 } = response.data;
                service.summary.value = value;
                service.summary.gbbgFee = gbbgFee;
                service.summary.agentFee = agentFee;
                service.summary.clientFee = clientFee;
                service.summary.penaltyTotal = penaltyTotal;
                $timeout(() => reportData.map(claim => service.data.push(claim)), 0);
                return response.data;
            }, failure);
        },
        getClientReport(params = {}) {
            return $http.get('/api/report/clients', { params })
            .then((response) => {
                if (response.status != 200) throw response;
                const { reportData = [], value = 0, totalClaims = 0 } = response.data;
                service.data.splice(0);
                service.summary.value = value;
                service.summary.totalClaims = totalClaims;
                $timeout(() => reportData.map(claim => service.data.push(claim)), 0);
                return response.data;
            }, failure);
        },
        // getSourceReport(params = {}) {
        //     return $http.get('/api/report/sources', { params })
        //     .then((response) => {
        //         if (response.status != 200) throw response;
        //         const { reportData = [], value = 0, totalClaims = 0, totalCommission = 0 } = response.data;
        //         service.data.splice(0);
        //         service.summary.value = value;
        //         service.summary.commission = totalCommission;
        //         service.summary.totalClaims = totalClaims;
        //         // $timeout(() => reportData.map(claim => service.data.push(claim)), 0);
        //         return response.data;
        //     }, failure);
        // },
        exportClaimReport(claimIds, docType = 'pdf') {
            if (!claimIds) return Promise.reject('No claim IDs provided in HTTP request.');
            return $http.get(`/api/report/claims/${docType}`, { params: { claimIds } }).then(success, failure);
        },
        exportClientReport(params = {}, clientIds, docType = 'pdf') {
            if (!clientIds) return Promise.reject('No client IDs provided in HTTP request.');
            return $http.get(`/api/report/clients/${docType}`, { params: { clientIds, params } }).then(success, failure);
        },
        exportSourceReport(params = {}, sourceIds, docType = 'pdf') {
            if (!sourceIds) return Promise.reject('No source IDs provided in HTTP request.');
            return $http.get(`/api/report/source/${docType}`, { params: { sourceIds, params } }).then(success, failure);
        },
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { ReportService };
