const gbbgAccessListFilters = {
    template: `
    <md-card>
        <md-card-content layout="column" layout-gt-xs="row" layout-align="space-between center">

            <div layout="row">
                <md-input-container>
                    <label>Record type</label>
                    <md-select ng-model="vm.selectedType">
                        <md-option
                            ng-repeat="type in vm.recordTypes"
                            ng-value="type.value"
                        >{{type.label}}</md-option>
                    </md-select>
                </md-input-container>

                <gbbg-user-select selected-user="vm.selectedUser"></gbbg-user-select>
            </div>

            <md-button class="md-icon-button" ng-click="vm.search()">
                <md-icon>search</md-icon>
            </md-button>

        </md-card-content>
    </md-card>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($state, $stateParams) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.recordTypes = [
        { label: 'all records', value: '' },
        { label: 'claim records', value: 'claim' },
        { label: 'client records', value: 'client' }
    ];

    vm.search = search;

    vm.$onInit = activate;

    function activate() {
        vm.selectedType = $stateParams.type || vm.recordTypes[0].value;
        vm.selectedUser = $stateParams.user || null;
    }

    function search() {
        $state.go($state.current.name, {
            type: vm.selectedType,
            user: vm.selectedUser
        }, { reload: $state.current.name });
    }
}

export { gbbgAccessListFilters };
