const gbbgLeadsFilters = {
    templateUrl: '/partials/templates/lead/filters.html',
    bindings: { leads: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($state, $stateParams) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.isLowLevelAgent = false; // Disable 'assigned to' filter if lowest level agent

    vm.$stateParams = $stateParams;

    vm.query = $stateParams.query || '';

    vm.sortables = [
        { name: 'reference no.', value: 'referenceNo'},
        { name: 'reference no. (rev)', value: '-referenceNo'},
        { name: 'name', value: 'name' },
        { name: 'name (rev)', value: '-name' },
        { name: 'country', value: 'address.country'},
        { name: 'country (rev)', value: '-address.country'},
        { name: 'timezone', value: 'address.timeDiff' },
        { name: 'timezone (rev)', value: '-address.timeDiff' }
    ];
    vm.statuses = [ 'any', 'pending', 'prospect', 'closed', 'ineligible' ];
    vm.assignees = [ 'me', 'all', 'unassigned' ];
    if (localStorage.gbbgUserAccess > 301) vm.assignees.push('agents');

    vm.go = go;
    vm.updateFlag = updateFlag;

    vm.$onInit = activate;

    function activate() {
        vm.isLowLevelAgent = (localStorage.gbbgUserAccess < 201);
    }

    function go(s, a, q, f) {
        const status = s || $stateParams.status || 'pending';
        const sort = vm.$stateParams.sort || vm.sortables[0].value;
        const flag = f || vm.$stateParams.flag;
        const assigned = a || $stateParams.assigned || 'me';
        const query = (q == null) ? null : (q || $stateParams.query || null);
        setFiltersToLocalStorage(vm.$stateParams);
        $state.go($state.current.name, { status, flag, assigned, query, sort }, { reload: $state.current.name });
    }

    function updateFlag({ flag }) {
        vm.go(null, null, null, flag);
    }

    function setFiltersToLocalStorage({ assigned = '', status = '', flag = '', query = '', sort = '' }) {
        localStorage.setItem('gbbgLeadFilterAssigned', assigned);
        localStorage.setItem('gbbgLeadFilterStatus', status);
        localStorage.setItem('gbbgLeadFilterFlag', flag);
        localStorage.setItem('gbbgLeadFilterQuery', query);
        localStorage.setItem('gbbgLeadFilterSort', sort);
    }
}

export { gbbgLeadsFilters };
