const gbbgLeadCountrySelect = {
    template: `
    <div layout="column">
        <div layout="row" layout-align="end center" layout-padding>
            <gbbg-enquiry-source-select flex
                source="vm.enquirySource"
                on-change="vm.getLeads(sourceId)"
            ></gbbg-enquiry-source-select>
            <md-input-container flex>
                <label>Select country/coutries</label>
                <md-select
                    ::ng-model="vm.selectedCountries"
                    ::ng-change="vm.getLeads()"
                    multiple
                >
                    <md-option
                        ::ng-value="country"
                        ng-repeat="country in vm.countries track by $index"
                    >{{country || 'No specified country'}}</md-option>
                </md-select>
            </md-input-container>
            <md-input-container>
                <md-checkbox
                    ::ng-click="vm.toggleAll()"
                    ng-checked="vm.selectedCountries.length >= vm.countries.length"
                >Select all countries</md-checkbox>
            </md-input-container>
        </div>
    </div>
    `,
    bindings: {
        leads: '=',
        onChange: '&?'
    },
    controller,
    controllerAs: 'vm'
};
function controller(LeadService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.enquirySource = null;

    vm.selectedCountries = [];

    vm.countries = [];

    vm.getLeads = getLeads;
    vm.toggleAll = toggleAll;

    vm.$onInit = activate;

    function activate() {
        LeadService.getLeadCountries().then(
            data => vm.countries = data,
            error => {
                ErrorHandler.showDialog('Failed to load countries for unassigned leads', error);
                console.error('Failed to get lead countries:', error);
            }
        );
    }

    function toggleAll() {
        if (vm.selectedCountries.length === vm.countries.length) vm.selectedCountries.splice(0);
        else vm.selectedCountries = [...vm.countries];
        getLeads();
    }

    function getLeads(sourceId = null) {
        if (vm.onChange) vm.onChange();
        if (vm.leads) vm.leads.splice(0);
        if (!vm.selectedCountries || !vm.selectedCountries.length) return;

        LeadService.getLeadsByCountry(vm.selectedCountries, sourceId || vm.enquirySource).then(
            data => { if (data && Array.isArray(data)) vm.leads = data; },
            error => {
                ErrorHandler.showDialog('Failed to load leads by country', error);
                console.error('Failed to get leads by country:', error);
            }
        );
    }
}

export { gbbgLeadCountrySelect };
