function userListViewController($state, $stateParams, ErrorHandler, UserService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.loading = false;

    vm.users = [];

    vm.$onInit = activate;

    function activate() {
        vm.loading = true;
        const { sort, status, query } = $stateParams;

        if (!sort) return $state.go('root.auth.management.users.all', {
            sort: sort || 'referenceNo',
            status: status || 'all',
            query
        }, { reload: $state.current.name });

        vm.users.splice(0);
        UserService.getAllUsers($stateParams).then(
            data => {
                vm.loading = false;
                vm.users = data;
            },
            error => {
                vm.loading = false;
                ErrorHandler.showDialog('Failed to get users', error);
                console.error('Failed to get users:', error);
            }
        );
    }
}

export { userListViewController };
