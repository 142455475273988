import { emailSendDialogController } from '../../management/email/email_send_dialog_controller';

const gbbgClaimRefundInvoices = {
    template: `
    <md-list>
        <md-list-item class="md-2-line" ng-repeat="invoice in vm.invoices">
            <div class="md-list-item-text">
                <h3>{{invoice.invoiceNo}} ({{invoice.type}} invoice)</h3>
                <p>
                    {{invoice.date | date: 'medium'}}
                    <span ng-if="invoice.paymentRef"> | Payment ref.: {{invoice.paymentRef}}</span>
                </p>
            </div class="md-list-item-text">
            <md-button class="md-secondary md-icon-button" ng-click="vm.viewInvoice(invoice.file)">
                <md-icon>pageview</md-icon>
                <md-tooltip md-direction="down">View invoice</md-tooltip>
            </md-button>
            <md-button class="md-secondary md-icon-button" ng-click="vm.openEmailDialog(invoice.file)">
                <md-icon>send</md-icon>
                <md-tooltip md-direction="down">Send invoice</md-tooltip>
            </md-button>
            <md-button class="md-secondary md-icon-button" ng-click="vm.deleteClientAgentInvoice($index, invoice.file)">
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="down">Delete invoice</md-tooltip>
            </md-button>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { invoices: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $stateParams, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.deleteClientAgentInvoice = deleteClientAgentInvoice;
    vm.viewInvoice = viewInvoice;
    vm.openEmailDialog = openEmailDialog;

    function viewInvoice(fileId) {
        if (!fileId) return;
        const url = window.location.getOriginRedirectUrl();
        window.open(`${url}/pdf/claim/${$stateParams.id}/invoice/${fileId}?auth_token=${localStorage.gbbgToken}`);
    }

    function openEmailDialog(invoiceFile) {
        $mdDialog.show({
            templateUrl: '/partials/templates/claim/send_refund_invoice_dialog.html',
            locals: {
                clientId: '',
                claimId: $stateParams.id,
                attachments: []
            },
            controller: emailSendDialogController,
            controllerAs: 'vm'
        }).then(({ email, recipients, sender }) => {
            email.attachmentIds.push(invoiceFile);
            ClaimService.sendInvoice(invoiceFile, email, recipients, sender).then(
                (data) => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Invoice sent successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                }, (error) => {
                    ErrorHandler.showDialog('Failed to send refund invoice', error);
                    console.error('Failed to send refund invoice', error);
                }
            );
        }, () => { });
    }

    function deleteClientAgentInvoice(idx, invoiceId) {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Delete invoice?')
            .textContent('The invoice will be permanently deleted.')
            .ariaLabel('Delete client/agent invoice')
            .ok('Delete invoice')
            .cancel('Cancel')
        ).then(() => {
            ClaimService.deleteClientAgentInvoice(idx, invoiceId).then(
                (data) => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Invoice deleted successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                (error) => {
                    ErrorHandler.showDialog('Failed to delete invoice', error);
                    console.error('Failed to delete invoice:', error);
                }
            )
        }, () => {});
    }
}


export { gbbgClaimRefundInvoices };
