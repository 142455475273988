const gbbgClientTimeline = {
    template: `
    <md-list class="md-dense" flex>
        <md-subheader class="md-no-sticky">
            <div layout="row" layout-align="center center">
                <span class="md-headline" hide-xs>Timeline</span>
                <span flex></span>
                <gbbg-client-timeline-speeddial
                    client-id="{{vm.clientId}}"
                    timeline="vm.timeline"
                    locked-open="true"
                ></gbbg-client-timeline-speeddial>
            </div>
        </md-subheader>
        <md-list-item
            class="md-3-line md-long-text"
            ng-repeat="t in vm.timeline | orderBy: '-timestamp' track by $index"
        >
            <div class="md-list-item-text" layout="column">
                <h3>{{t.timestamp | date:'yyyy/MM/dd @ h:mma'}}</h3>
                <h4 ng-bind="t.addedBy.firstName + ' ' + t.addedBy.surname"></h4>
                <p>
                    <strong ng-bind="t.activity + ':'"></strong>
                    <span ng-bind="t.details"></span>
                </p>
            </div>
            <gbbg-open-email-icon
                class="md-secondary"
                ng-if="t.email"
                email-id="{{t.email}}"
            ></gbbg-open-email-icon>
            <gbbg-client-timeline-update
                class="md-secondary"
                gbbg-hide-unless-acc-manager
                client-id="{{vm.clientId}}"
                timeline-record="t"
            ></gbbg-client-timeline-update>
            <md-icon
                gbbg-hide-unless-admin
                class="md-secondary md-hue-3"
                aria-label="Delete activity"
                ng-click="vm.openDialog(t._id)"
            >delete_forever</md-icon>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { clientId: '@', timeline: '=' },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, ClientService, ErrorHandler) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    let selectedActivtyId = null;

    vm.openDialog = openDialog;

    function openDialog(activityId) {
        selectedActivtyId = activityId;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Delete timeline activity?')
            .textContent('Are you sure you want to delete this record? This action is permanent and cannot be undone.')
            .ariaLabel('Delete timeline activity')
            .ok('Delete')
            .cancel('Cancel')
        ).then(
            () => { confirmDelete() },
            () => {}
        );
    }

    function confirmDelete() {
        if (!vm.clientId || !selectedActivtyId) return;
        ClientService.deleteFromTimeline(vm.clientId, selectedActivtyId).then(
            data => {
                for (let i = 0; i < vm.timeline.length; i++) {
                    if (vm.timeline[i]._id == selectedActivtyId) {
                        vm.timeline.splice(i, 1);
                        break;
                    }
                }
                selectedActivtyId = null;
            },
            error => {
                ErrorHandler.showDialog('Timeline deletion failed', error);
                console.error('Failed to delete from timeline:', error);
            }
        );
    }
}

export { gbbgClientTimeline };
