const gbbgClaimValidations = {
    template: `
    <md-card md-theme="{{ vm.getTheme() }}" md-theme-watch>
        <md-card-title>
            <md-card-title-text ng-if="vm.validations.length">
                <span class="md-headline" ng-bind="vm.headline">Validation errors</span>
                <span class="md-subhead">You must fix the following issues with your claim before you can submit it to customs.</span>
            </md-card-title-text>
        </md-card-title>
        <md-card-content>
            <md-list ng-if="vm.validations.length">
                <md-list-item ng-repeat="validation in vm.validations">
                    <p ng-bind="validation"></p>
                </md-list-item>
            </md-list>
            <p ng-if="!vm.validations.length">No issues found. This claim is ready for submission.</p>
        </md-card-content>
        <md-divider></md-divider>
        <ng-transclude></ng-transclude>
    </md-card>
    `,
    bindings: { claimId: '@' },
    transclude: true,
    controller,
    controllerAs: 'vm'
};
function controller(ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.getTheme = getTheme;

    vm.$onInit = activate;

    function activate() { vm.validations = ClaimService.validations; }

    function getTheme() { return (vm.validations.length > 0) ? 'docs-dark' : 'default';  }
}

export { gbbgClaimValidations };
