import { default as moment } from 'moment-timezone';

function config($httpProvider, $locationProvider, $mdDateLocaleProvider, $mdThemingProvider) {
    'ngInject';
    // $locationProvider.html5Mode(false).hashPrefix('')
    window.location.getOriginRedirectUrl = getOriginRedirectUrl;
    $httpProvider.interceptors.push('httpRequestInterceptor');
    $mdDateLocaleProvider.formatDate = (date) => moment(date).format('DD MMM YYYY'); // format datepicker
    $mdThemingProvider
        .theme('default')
        .primaryPalette('green')
        .accentPalette('amber');
    $mdThemingProvider
        .theme('docs-dark')
        .primaryPalette('green')
        .accentPalette('pink')
        .dark();
    function getOriginRedirectUrl() {
        const origin = window.location.origin;
        if (origin.indexOf('http://localhost:3000') >= 0) return 'http://localhost:8082';
        if (origin.indexOf('https://testcrm.') >= 0) return 'https://testapi.gbbusinessvat.com';
        if (origin.indexOf('https://crm.') >= 0) return 'https://api.gbbusinessvat.com';
        if (origin.indexOf('http://localhost:3001') >= 0) return 'http://localhost:8083';
        if (origin.indexOf('https://testarchive.') >= 0) return 'https://testarchiveapi.gbbusinessvat.com';
        if (origin.indexOf('https://archive.') >= 0) return 'https://archiveapi.gbbusinessvat.com';
    }
}
function httpRequestInterceptor($injector, $q) {
    'ngInject';
    return {
        request: setHeaders,
        response: handleResponse,
        responseError: handleResponse
    };
    function setHeaders(config) {
        const whitelist = [ 'http://localhost', 'http://localhost:3000', 'https://testcrm.gbbusinessvat.com', 'https://crm.gbbusinessvat.com' ];
        const url = config.url;
        const origin = window.location.origin;
        const isHttp = (url.indexOf('https://') >= 0 || url.indexOf('http://') >= 0);
        if (isHttp && whitelist.every(white => url.indexOf(white) == -1)) return config;
        if (url.indexOf('/partials/') < 0) {
            config.url = window.location.getOriginRedirectUrl() + url;
        }
        const headers = $injector.get('$http').defaults.headers;
        headers.common.Authorization = ('Bearer ' + localStorage.getItem('gbbgToken'));
        return config;
    }
    function handleResponse(response) {
        handle400(response.status);
        handle401(response.status);
        return response || $q.when(response);
    }
    function handle401(status) {
        const onRestrictedView = $injector.get('$state').current.name.indexOf("root.auth") >= 0;
        if ((status == 401) && onRestrictedView) {
            const message = 'Your session expired or was corrupted. Please sign back in.';
            $injector.get('AuthService').openSignInDialog(message, null, signOut);
        }
    }
    function handle400(status) {
        if (status == 400) {
            const message = 'You made a fatal request and have been signed out. Please sign back in.';
            $injector.get('AuthService').openSignInDialog(message, null, signOut);
        }
    }
    function signOut(message = '') {
        $injector.get('AuthService').signOut().then(() => {
            $injector.get('$state').go('root.signin', {
                redirectMessage: message
            }, { reload: true });
        });
    }
}

export { config, httpRequestInterceptor };
