const gbbgEditContact = {
    template: `
    <md-button
        class="md-icon-button"
        ng-disabled="!vm.contact || !vm.contact._id"
        ng-click="vm.openDialog()"
    >
        <md-icon>open_in_browser</md-icon>
        <md-tooltip>Edit contact</md-tooltip>
    </md-button>
    `,
    bindings: { clientId: '@', contact: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClientService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.contact || !vm.contact._id) return;
        $mdDialog.show({
            template: `
            <md-dialog aria-label="Update contact dialog" style="max-width:600px;">
                <form name="updateContactDialogForm">
                    <md-toolbar>
                        <div class="md-toolbar-tools">
                            <h2>New contact</h2>
                            <span flex></span>
                            <gbbg-close-dialog></gbbg-close-dialog>
                        </div>
                    </md-toolbar>
                    <md-dialog-content layout="column" layout-padding>

                        <p>Contacts must have a name and either an email address or phone number.</p>

                        <gbbg-client-contact-details contact="vm.contact"></gbbg-client-contact-details>

                    </md-dialog-content>
                    <md-dialog-actions layout="row">
                        <span flex></span>
                        <md-button class="md-warn" ng-click="vm.cancel()">Cancel</md-button>
                        <md-button
                            class="md-primary md-raised"
                            ng-click="vm.updateContact()"
                            ng-disabled="updateContactDialogForm.$invalid || vm.invalidContact(vm.contact)"
                        > Update contact </md-button>
                    </md-dialog-actions>
                </form>
            </md-dialog>
            `,
            locals: { contact: vm.contact || {} },
            controller: dialogController,
            controllerAs: 'vm'
        }).then(contact => {
            if (!vm.clientId || !contact || !contact._id || !contact.name || (!contact.phone && !contact.email)) return;
            ClientService.updateContact(vm.clientId, contact).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Contact updated successfully.')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to update client', error);
                    console.error('Failed to update client:', error);
                }
            );
        }, () => {});
    }
}
function dialogController(contact, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.contact = contact;

    vm.cancel = $mdDialog.cancel;
    vm.updateContact = updateContact;
    vm.invalidContact = invalidContact;

    function updateContact() {
        if (!vm.contact || !vm.contact._id || !vm.contact.name) return;
        $mdDialog.hide(vm.contact);
    }

    function invalidContact(contact) {
        return (!contact || !contact.name || (!contact.phone && !contact.email));
    }
}

export { gbbgEditContact }
