const gbbgClientIssues = {
    template: `
    <md-card md-theme="{{vm.issues.from ? 'docs-dark' : ''}}" md-theme-watch>
        <md-card-content>
            <div ng-if="!vm.issues.from">
                <p>No issues have been listed for this client.</p>
            </div>
            <div ng-if="vm.issues.from">
                <p><b>{{vm.issues.from}}:</b> {{vm.issues.details}}</p>
            </div>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions layout="row" layout-align="end">
            <md-button
                class="md-warn"
                ng-if="!vm.issues.from"
                ng-click="vm.openDialog()"
            >Specify issue</md-button>
            <md-button
                ng-if="vm.issues.from"
                ng-click="vm.revokeIssue()"
            >Revoke issues</md-button>
        </mc-ard-actions>
    </md-card>
    `,
    bindings: { issues: '<?' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;
    vm.revokeIssue = revokeIssue;

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((issue) => {
            if (!issue || !issue.from || !issue.details) return;
            ActiveService.setIssue(issue).then((data) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Client issues set.')
                    .position('top right')
                    .hideDelay(3000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to set client issues', error);
                console.error('Failed to set client issues:', error);
            })
        }, () => {});
    }

    function revokeIssue() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to revoke these issues?')
            .textContent('Make sure all issues have been resolved before clearing issues.')
            .ariaLabel('Revoke issues')
            .ok('Revoke issues')
            .cancel('Cancel')
        ).then(() => {
            ActiveService.revokeIssue().then((data) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Issues revoked successfully.')
                    .position('top right')
                    .hideDelay(3000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to revoke issues', error);
                console.error('Failed to revoke issues:', error);
            });
        }, () => {});
    }

    const template = `
    <md-dialog aria-label="Client issues" style="width: 400px;">
        <md-toolbar class="md-warn">
            <div class="md-toolbar-tools">
                <h2>Specify client issue</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content>
            <md-content layout="column" layout-padding>

                <md-input-container flex>
                    <label>Issue raised by</label>
                    <input type="text" ng-model="vm.issues.from" maxlength="150" md-maxlength="150" required/>
                    <div class="hint">e.g. HMRC, customs, etc.</div>
                </md-input-container>

                <md-input-container flex>
                    <label>Issue details</label>
                    <textarea ng-model="vm.issues.details" maxlength="500" md-maxlength="500" required></textarea>
                    <div class="hint">Please provide as much detail as possible.</div>
                </md-input-container>

            </md-content>
        </md-dialog-content>
        <md-divider></md-divider>
        <md-dialog-actions layout="row">
            <span flex></span>
            <md-button
                type="submit"
                class="md-warn md-raised"
                ng-click="vm.setIssue()"
                ng-disabled="!vm.issues.from.length || !vm.issues.details.length"
            >Set issue</md-button>
        </md-dialog-actions>
    </md-dialog>
    `;
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.issues = {
        from: '',
        details: ''
    };

    vm.setIssue = setIssue;
    vm.cancel = $mdDialog.cancel;

    function setIssue() {
        if (!vm.issues.from.length || !vm.issues.details.length) return;
        $mdDialog.hide(vm.issues);
    }
}

export { gbbgClientIssues }
