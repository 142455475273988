const gbbgOpenEmailIcon = {
    template: `
    <md-icon
        class="md-secondary md-hue-3"
        aria-label="Open email"
        ng-click="vm.getEmail()"
    >mail_outline</md-icon>
    `,
    bindings: { emailId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $sce, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.getEmail = getEmail;

    function getEmail() {
        if (!vm.emailId) return;
        EmailService.getEmail(vm.emailId).then(
            data => openDialog(data),
            error => {
                ErrorHandler.showDialog('Failed to get sent email', error);
                console.error('Failed to get sent email:', error);
            }
        );
    }

    function openDialog(email) {
        $mdDialog.show({
            template: `
            <md-dialog aria-label="Refund invoice">
                <md-toolbar>
                    <div class="md-toolbar-tools">
                        <h2>Sent email</h2>
                        <span flex></span>
                        <gbbg-close-dialog></gbbg-close-dialog>
                    </div>
                </md-toolbar>
                <md-dialog-content class="md-padding">
                    <!--<div style="margin-bottom: 12px;">
                        <ng-quill-editor
                            ng-model="vm.thread"
                            placeholder="Add to email thread (contents will be appended to exiting contents)"
                            required="true"
                        ></ng-quill-editor>
                    </div>-->
                    <md-content class="md-padding">
                        <div class="email">
                            <h3 ng-bind="vm.email.subject"></h3>
                            <div ng-bind-html="vm.email.body"></div>
                        </div>
                    </md-content>
                </md-dialog-content>
            </md-dialog>
            `,
            locals: { email },
            controller: dialogController,
            controllerAs: 'vm',
            clickOutsieToClose: true
        });
    }
}
function dialogController(email, $sce) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    activate();

    function activate() {
        vm.email = email;
        vm.email.body = $sce.trustAsHtml(vm.email.body);
    }
}

export { gbbgOpenEmailIcon }
