import { dialogController, dialogTemplate } from './active_certs_add';

const gbbgActiveCerts = {
    template: `
    <md-card flex>
        <md-list class="md-dense">
            <md-list-item class="md-3-line" ng-repeat="cert in vm.certs">
            <div class="md-list-item-text" layout="column">
                <h3>
                    <b>Created</b> {{cert.dateCreated | date:'MMM d, y'}} |
                    <b>Exp</b> {{cert.dateExp | date:'MMM d, y'}}
                </h3>
                <h4 ng-if="cert.certNo" ng-bind="cert.certNo"></h4>
                <p>{{ cert.issuingAuthority.name }}</p>
            </div>
            <md-icon class="md-secondary" ng-click="vm.openDialog(cert)" aria-label="Edit certs">edit</md-icon>
            <md-divider></md-divider>
            </md-list-item>
        </md-list>
        <md-card-content ng-if="!vm.certs.length">
            <p>No tax certificates have been added to this client.</p>
        </md-card-content>
        <md-card-actions layout="row" layout-align="end center">
            <ng-transclude></ng-transclude>
        </md-card-actions>
    </md-card>
    `,
    transclude: true,
    bindings: { certs: '=', country: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog(cert) {
        $mdDialog.show({
            template: dialogTemplate,
            locals: {
                country: vm.country,
                cert
            },
            controller: dialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then((cert) => {
            ActiveService.updateCert(cert).then((data) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Cert updated successfully!')
                    .position('top right')
                    .hideDelay(3000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to update certificate', error);
                console.error('Failed to update certificate:', error);
            });
        }, () => { });
    }
}

export { gbbgActiveCerts };
