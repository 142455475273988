const gbbgClaimInvoiceValue = {
    template: `
    <md-card>
        <md-card-content>
            <div layout="row" layout-align="space-around">
                <h3>
                    <strong>VAT claimed:</strong>
                    <span>{{vm.claim.totalClaimed | currency:'£'}}</span>
                </h3>
                <h3>
                    <strong>Gross:</strong>
                    <span>{{vm.claim.totalGross | currency:'£'}}</span>
                </h3>
                <h3>
                    <strong>Total VAT:</strong>
                    <span>{{vm.claim.totalVat | currency:'£'}}</span>
                </h3>
            </div>
        </md-card-content>
    </md-card>
    `,
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgClaimInvoiceValue };
