function SupplierService($http) {
    'ngInject';
    return {
        getSuppliers() {
            return $http.get('/api/suppliers').then(success, failure)
        },
        searchSuppliers(searchText, country) {
            const name = searchText || ''
            const countryQuery = country ? `&country=${country}` : ''
            return $http.get(`/api/suppliers/search?query=${searchText}${countryQuery}`).then(success, failure)
        },
        mergeSuppliers(suppliers) {
            return $http.post(`/api/suppliers/merge`, suppliers).then(success, failure)
        }
    };
    function success(response) {
        if (response.status != 200) throw response
        else return response.data
    }
    function failure(error) { return error }
}

export { SupplierService }
