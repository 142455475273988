const gbbgLeadStatusReason = {
    template: `
    <div layout="column">
        <md-input-container>
            <label>Select reason</label>
            <md-select name="reason" ng-model="vm.reason" required>
                <md-option ng-repeat="reason in vm.reasons" ng-value="reason">{{reason.name}}</md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: { reason: '=' },
    controllerAs: 'vm',
    controller
};
function controller(FlagService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.reasons = [];

    vm.$onInit = activate;

    function activate() {
        FlagService.getAllFlags().then(
            data => vm.reasons = data || [],
            error => {
                console.error('Failed to load ineligiblity flags:', error);
            }
        );
    }
}

export { gbbgLeadStatusReason };
