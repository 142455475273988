const gbbgClientClaimsTimelines = {
    template: `
    <md-list>
        <div ng-repeat="claim in vm.claims">
            <md-list-item class="md-2-line">
                <div class="md-list-item-text">
                    <h3>{{claim.referenceNo}} ({{claim.timeline.length}} notes)</h3>
                    <p>Created on: {{claim.dateCreated | date: 'MMM d, y'}}</p>
                </div>
                <md-icon
                    class="md-secondary"
                    ng-click="vm.openDialog(claim.timeline)"
                    aria-label="See timeline notes"
                >pageview</md-icon>
            </md-list-item>
            <md-divider></md-divider>
        </div>
    </md-list>
    `,
    bindings: { clientId: '@' },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, ActiveService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.claims = []

    vm.openDialog = openDialog

    vm.$onInit = activate

    function activate() {
        ActiveService
        .getActiveClientClaimsTimelines(vm.clientId)
        .then(
            (data) => vm.claims = data,
            (error) => {
                ErrorHandler.showDialog('Failed to get active client claims timelines', error)
                console.log('Failed to get active client claims timelines:', error)
            }
        )
    }

    function openDialog(timeline) {
        $mdDialog.show({
            template: `
            <md-dialog aria-label="Claim timeline" style="max-width:600px;">
                <md-toolbar>
                    <div class="md-toolbar-tools">
                        <h2>Claim timeline</h2>
                        <span flex></span>
                        <md-button class="md-icon-button" ng-click="vm.cancel()">
                            <md-icon>close</md-icon>
                        </md-button>
                    </div>
                </md-toolbar>
                <md-dialog-content>
                    <md-list>
                    <md-list-item
                        class="md-3-line md-long-text"
                        ng-repeat="t in vm.timeline | orderBy: '-timestamp' track by $index"
                    >
                        <div class="md-list-item-text" layout="column">
                            <h3>{{t.timestamp | date:'yyyy/MM/dd @ h:mma'}}</h3>
                            <h4 ng-bind="t.addedBy.firstName + ' ' + t.addedBy.surname"></h4>
                            <p>
                                <strong ng-bind="t.activity + ':'"></strong>
                                <span ng-bind="t.details"></span>
                            </p>
                        </div>
                        <gbbg-open-email-icon
                            class="md-secondary"
                            ng-if="t.email"
                            email-id="{{t.email}}"
                        ></gbbg-open-email-icon>
                        <md-divider></md-divider>
                    </md-list-item>
                    </md-list>
                </md-dialog-content>
            </md-dialog>
            `,
            locals: { timeline },
            controller: dialogController,
            controllerAs: 'vm'
        })
    }
}
function dialogController(timeline, $mdDialog) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.timeline = timeline || []

    vm.cancel = $mdDialog.cancel
}

export { gbbgClientClaimsTimelines }
