import { accessListViewController } from './access_list_view_controller';

function accessRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.access', {
        url: "/access?type&user",
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-new-flag></gbbg-new-flag>
                </gbbg-top-nav>
                `
            },
            "main@root.auth": {
                template: `
                <div>
                    <gbbg-access-list-filters></gbbg-access-list-filters>
                    <gbbg-access-record-list></gbbg-access-record-list>
                </div>
                `,
                controller: accessListViewController,
                controllerAs: 'vm'
            }
        }
    });
}

export { accessRoutes };
