const gbbgUserSelectList = {
    template: `
    <md-list class="md-dense">
        <md-list-item class="md-2-line" ng-repeat="user in vm.users">
            <div class="md-list-item-text">
                <h3>{{user.firstName}} {{user.surname}}</h3>
                <p>{{user.teamNames.join(', ')}}</p>
            </div>

            <md-checkbox
                class="md-secondary"
                aria-label="{{user.email}}"
                ng-selected="vm.selectedUser(user._id)"
                ng-click="vm.toggleUser(user._id)"
            ></md-checkbox>

            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { users: '<', selectedUsers: '=' },
    controller,
    controllerAs: 'vm'
};
function controller(UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedUser = selectedUser;
    vm.toggleUser = toggleUser;

    vm.$onInit = activate;

    function activate() {
        UserService.getAllUsers().then(
            data => vm.users = data,
            error => {
                ErrorHandler.showDialog('Failed to get users', error);
                console.error('Failed to get users:', error);
            }
        );
    }

    function selectedUser(userId) {
        return vm.selectedUsers.indexOf(userId) >= 0;
    }

    function toggleUser(userId) {
        const idx = vm.selectedUsers.indexOf(userId);
        (idx >= 0) ? vm.selectedUsers.splice(idx, 1) : vm.selectedUsers.push(userId);
    }
}

export { gbbgUserSelectList }
