const gbbgClientSummary = {
    templateUrl: '/partials/templates/client/summary.html',
    bindings: { client: "<", businessCard: '=' },
    controller: newLeadSummaryController,
    controllerAs: 'vm'
};
function newLeadSummaryController() {
    "ngInject";
    /*validthis:true*/
    const vm = this;
}

export { gbbgClientSummary };
