const gbbgTeamAdmins = {
    template: `
    <md-list class="md-dense">
        <md-list-item class="md-2-line" ng-repeat="admin in vm.admins" ng-class="{ 'list-item-dark': !admin.active }">
            <div class="md-list-item-text">
                <h3>
                    <strong ng-bind="admin.firstName + ' ' + admin.surname"></strong>
                </h3>
                <p>{{::admin.email}} ({{::vm.accessRole(admin.access)}})</p>
            </div>
            <md-button
                class="md-icon-button md-secondary"
                ng-disabled="!vm.isAdmin"
                ng-if="((!!vm.isAdmin || false) || (vm.userAccess < user.access)) && !(vm.admins.length <= 1)"
                ng-click="::vm.removeAdmin(admin)"
            >
                <md-icon>remove_circle_outline</md-icon>
                <md-tooltip md-direction="down">Remove admin</md-tooltip>
            </md-button>
            <md-button
                class="md-icon-button md-secondary"
                ui-sref="root.auth.management.users.user({ id: admin._id })"
            > <md-icon>open_in_new</md-icon> </md-button>
        </md-list-item>
    </md-list>
    `,
    bindings: {
        admins: '<',
        isAdmin: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller(ACCESS_RIGHTS, $mdDialog, $state, $stateParams, ErrorHandler, TeamService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.accessRole = accessRole;
    vm.removeAdmin = removeAdmin;

    vm.$onInit = activate;

    function activate() { vm.userAccess = localStorage.gbbgUserAccess || '101'; }

    function removeAdmin(admin) {
        if (!admin) return;
        const name = admin.firstName + ' ' + admin.surname;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Revoke administrator access?')
            .textContent(`Are you sure you want to remove ${name} as an administrator of this team?`)
            .ariaLabel('Revoke admin role')
            .ok('Revoke')
            .cancel('Cancel')
        ).then(() => {
            TeamService.removeAdmins($stateParams.id, admin._id).then(
                data => $state.go($state.current.name, {}, { reload: $state.current.name }),
                error => {
                    ErrorHandler.showDialog('Failed to revoke administrator access', error);
                    console.error('Failed to revoke administrator access:', error);
                }
            );
        }, () => {});
    }

    function accessRole(access) { return ACCESS_RIGHTS[access]; }
}

export { gbbgTeamAdmins };
