const gbbgPreClaimTimeline = {
    templateUrl: '/partials/templates/claim/timeline.html',
    bindings: { claimId: '@', timeline: '=' },
    controller,
    controllerAs: 'vm'
};
function controller(PreClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.activityDetails = '';

    vm.addToTimeline = addToTimeline;

    function addToTimeline() {
        if (!vm.activityDetails.length || !vm.claimId) return;
        PreClaimService.addToTimeline(vm.claimId, 'update', vm.activityDetails).then(
            data => vm.timeline.push(data),
            error => {
                ErrorHandler.showDialog('Timeline update failed', error);
                console.error('An error occurred while adding an action to the timeline:', error);
            }
        );
    }
}

export { gbbgPreClaimTimeline };
