function assignLeadsFromUserViewController($mdDialog, $mdToast, $timeout, ClientService, ErrorHandler, LeadService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedUser = {};

    vm.clients = [];
    vm.users = [];
    vm.selectedClients = [];
    vm.selectedUsers = [];

    vm.getClients = getClients;
    vm.assignClients = assignClients;
    vm.openDialog = openDialog;

    function getClients(userId) {
        vm.selectedUser = userId;
        ClientService.getClientsByUser(userId).then(
            data => vm.clients = data,
            error => {
                ErrorHandler.showDialog('Failed to get clients for user', error);
                console.error('Failed to get clients for user:', error);
            }
        );
    }

    function openDialog() {
        let numClients = '';
        if (vm.allCountries == true) {
            numClients = 'all client';
        } else {
            numClients = vm.selectedClients.length === 1 ? 'one client' : `${vm.selectedClients.length} clients`;
        }
        const numUsers = vm.selectedUsers.length === 1 ? 'one user' : `${vm.selectedUsers.length} users`;
        $mdDialog.show(
            $mdDialog.confirm()
            .title(`Reassign ${numClients}?`)
            .textContent(`Are you sure you want to assign ${numClients} to ${numUsers}?`)
            .ariaLabel('Assign clients')
            .ok('Re-assign clients')
            .cancel('Cancel')
        ).then(() => assignClients(), () => {});
    }

    function assignClients() {
        if (!vm.selectedClients.length || !vm.selectedUsers.length) return;
        LeadService.assignLeads(vm.selectedClients, vm.selectedUsers).then(
            data => {
                $timeout(() => {
                    vm.selectedUser = {};
                    vm.clients.splice(0);
                    vm.selectedClients.splice(0);
                });
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Clients re-assigned successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                ErrorHandler.showDialog('Failed to re-assign clients', error);
                console.error('Failed to re-assign clients:', error);
            }
        );
    }
}

export { assignLeadsFromUserViewController };
