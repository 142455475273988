const gbbgClientBusinessTravellerAutocomplete = {
    template: `
    <div layout="row">
        <md-autocomplete flex
            md-input-name="Business traveller name"
            md-no-cache="false"
            md-selected-item="vm.traveller.name"
            md-search-text="vm.searchText"
            md-search-text-change="vm.selectedItemChange(vm.searchText)"
            md-selected-item-change="vm.selectedItemChange(item)"
            md-items="item in vm.querySearch(vm.searchText)"
            md-item-text="item.name"
            md-floating-label="Traveller name"
        >
            <md-item-template>
                <b md-highlight-text="vm.searchText">{{item.name}}</b>
                <span ng-if="item.position"> | {{item.position}}</span>
            </md-item-template>
        </md-autocomplete>
        <md-input-container flex>
            <label>Traveller position / title</label>
            <input type="text" ng-model="vm.traveller.position"/>
        </md-input-container>
    </div>
    `,
    bindings: {
        traveller: '=',
        claimId: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller($timeout, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.searchText = '';

    vm.$onInit = activate;

    function activate() {
        if (vm.traveller && vm.traveller.name) vm.searchText = vm.traveller.name;
        ClaimService.getClientBusinessTravellers().then(data => {
            vm.travellers = data || [];
        }, error => {
            ErrorHandler.showDialog('Failed to get business travellers', error);
            console.error('Failed to get client business travellers:', error);
        })
    }

    vm.querySearch = querySearch;
    vm.selectedItemChange = selectedItemChange;

    function querySearch(text = '') {
        return Promise.resolve(vm.travellers.filter((traveller) => {
            traveller.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
        }));
    }

    function selectedItemChange(newItem) {
        vm.traveller.name = (newItem && newItem.name) ? newItem.name : newItem;
        // if (!newItem || newItem === null) vm.traveller.name = vm.searchText;
        // if (newItem && newItem.name) vm.traveller.name = newItem.name;
        if (newItem && (newItem.position || typeof newItem.position === 'string')) vm.traveller.position = newItem.position;
    }
}

export { gbbgClientBusinessTravellerAutocomplete };
