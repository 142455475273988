const gbbgClientUsersAdd = {
    template($attrs) {
        const icon = ($attrs.user) ? 'edit' : 'person_add';
        const text = ($attrs.user) ? 'Edit' : 'Add';
        return `
        <md-button class="md-icon-button" ng-click="vm.openDialog()">
            <md-icon>${icon}</md-icon>
            <md-tooltip>${text} authorised user</md-toolip>
        </md-button>
        `;
    },
    bindings: { user: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $stateParams, ClientUsersService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            locals: { user: vm.user || { email: ' ' } },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((user) => {
            (vm.user && vm.user._id) ? updateUser(user) : addUser(user);
        }, () => {});
    }

    function addUser(user) {
        ClientUsersService.addUser($stateParams.id, user).then(
            (data) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('New user added successfully!')
                    .position('top right')
                    .hideDelay(3000)
                );
            },
            (error) => {
                ErrorHandler.showDialog('Failed to add new authorised user', error);
                console.error('Failed to add new authorised user:', error);
            }
        );
    }

    function updateUser(user) {
        ClientUsersService.updateAuthorisedUser(user._id, user).then(
            (data) => {
                vm.user = user;
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('User updated successfully!')
                    .position('top right')
                    .hideDelay(3000)
                );
            },
            (error) => {
                ErrorHandler.showDialog('Failed to update authorised user', error);
                console.error('Failed to update authorised user:', error);
            }
        );
    }

    const template = `
    <md-dialog style="max-width: 600px;" aria-label="Authorised user">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Authorised user</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content
                layout-padding
                layout="column"
                layout-gt-xs="row"
                layout-wrap
                layout-align="space-between end"
            >
                <gbbg-users-by-email selected-user="vm.user" flex></gbbg-users-by-email>
                <md-input-container flex="50">
                    <label>Phone number</label>
                    <input type="text" ng-model="vm.user.phone"/>
                </md-input-container>
                <md-input-container flex="50">
                    <label>First name</label>
                    <input type="text" ng-model="vm.user.firstName" required/>
                </md-input-container>
                <md-input-container flex="50">
                    <label>Surname</label>
                    <input type="text" ng-model="vm.user.surname" required/>
                </md-input-container>
                <md-input-container flex="50">
                    <label>Position / job title</label>
                    <input type="text" ng-model="vm.user.position"/>
                </md-input-container>
            </md-dialog-content>
            <md-dialog-content>
                <md-divider></md-divider>
            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button class="md-primary" ng-click="vm.save()">Save user</md-button>
            </md-dialog-actions>
    </md-dialog>
    `;
}
function dialogController(user, $mdDialog, UserService) {
    'ngInject';
    /*validthis: true*/
    const vm = this;

    vm.user = angular.copy(user);

    vm.save = save;

    function save() { $mdDialog.hide(vm.user); }
}

export { gbbgClientUsersAdd };
