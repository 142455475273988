function assignLeadsByCountryViewController($mdDialog, $state, LeadService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.leads = [];
    vm.teams = [];
    vm.users = [];

    vm.selectedLeads = [];
    vm.selectedUsers = [];

    vm.assignLeads = assignLeads;
    vm.clearLeads = clearLeads;
    vm.clearUsers = clearUsers;
    vm.openDialog = openDialog;

    function clearLeads() { vm.selectedLeads.splice(0); }

    function clearUsers() { vm.selectedUsers.splice(0); }

    function openDialog() {
        let numLeads = vm.selectedLeads.length === 1 ? 'one lead' : `${vm.selectedLeads.length} leads`;
        const numUsers = vm.selectedUsers.length === 1 ? 'one user' : `${vm.selectedUsers.length} users`;
        $mdDialog.show(
            $mdDialog.confirm()
            .title(`Assign ${numLeads}?`)
            .textContent(`Are you sure you want to assign ${numLeads} to ${numUsers}?`)
            .ariaLabel('Assign leads')
            .ok('Assign leads')
            .cancel('Cancel')
        ).then(() => assignLeads(), () => {});
    }

    function assignLeads() {
        if (!vm.selectedLeads.length || !vm.selectedUsers.length) return;
        LeadService.assignLeads(vm.selectedLeads, vm.selectedUsers).then(
            data => {
                vm.selectedLeads.splice(0);
                vm.selectedUsers.splice(0);
                $state.go($state.current.name, {}, { reload: $state.current.name });
            },
            error => {
                ErrorHandler.showDialog('Failed to assign leads', error);
                console.error('Failed to assign leads:', error);
            }
        );
    }
}

export { assignLeadsByCountryViewController };
