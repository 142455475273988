function routeConfig($locationProvider, $stateProvider) {
    'ngInject';
    $locationProvider.html5Mode(true);
    $stateProvider
    .state('root', {
        url: '',
        abstract: true,
        views: {}
    })
    .state('root.signin', {
        url: '/',
        views: {
            'nav@': { },
            'root@': {
                templateUrl: '/partials/views/auth/signin.html',
                controller: 'SignInController',
                controllerAs: 'vm'
            }
        },
        params: { redirectMessage: '' }
    })
    .state('root.msredirect', {
        url: '/ms-auth-response',
        views: {
            'nav@': {},
            'root@': {
                controller: function($location, $state, AuthService, ErrorHandler) {
                    'ngInject'
                    /*validthis:true*/
                    const vm = this

                    vm.$onInit = activate

                    function activate() {
                        const query = $location.search()

                        AuthService.msVerifyUser(query).then((resp) => {
                            $state.go('root.auth.leads.all')
                        }, (err) => {
                            console.error('Failed to authenticate user:', err)
                            const message = err.data ? err.data.message : err.message
                            $state.go('root.signin', { redirectMessage: `Failed to authenticate user: \n ${message || 'Unknown error.'}` })
                        })
                    }
                },
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth', {
        abstract: true,
        views: {
            'root@': {
                templateUrl: '/partials/views/main.html'
            },
            'top@root.auth': {
                template: `
                <gbbg-top-nav>
                    <md-button
                        class='md-fab md-secondary md-hue-1 md-mini'
                        ui-sref='root.auth.leads.new'
                    >
                        <md-icon md-font-library='material-icons'>add</md-icon>
                        <md-tooltip md-direction='bottom'>New lead</md-tooltip>
                    </md-button>
                </gbbg-top-nav>
                `
            },
            'nav@root.auth': {
                templateUrl: '/partials/views/sidenav.html',
                controller: 'SidenavController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            // Authenticate the first time a view is called
            authorize: AuthService => AuthService.authenticateTransition()
        }
    });
}

function runOnStateChange($rootScope, AuthService) {
    // When a view is called, check that the user is authenticated before navigating
    $rootScope.$on('$stateChangeStart', () => AuthService.authenticateTransition());
}

export { routeConfig, runOnStateChange };
