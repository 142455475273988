import { gbbgUserAccessSelect } from './user_access_select';
import { gbbgUsersByEmail } from './user_by_email';
import { gbbgUserDetails } from './user_details';
import { gbbgUserEmailSignature } from './user_email_signature';
import { gbbgUserListFilters } from './user_list_filters';
import { userListViewController } from './user_list_view_controller';
import { gbbgNewUser } from './user_new';
import { gbbgUserChangePassword } from './user_password_change';
import { gbbgPasswordResetButton } from './user_password_reset';
import { userRoutes } from './user_routes';
import { gbbgUserSelect } from './user_select';
import { gbbgUserSelectList } from './user_select_list';
import { UserService } from './user_service';
import { gbbgSuspendUserButton } from './user_suspend_button';
import { gbbgUserTeamsSelect } from './user_teams_select';
import { gbbgUserTeams } from './user_teams';
import { userViewController } from './user_view_controller';

angular.module('management.user', [])
.config(userRoutes)
.controller('userListViewController', userListViewController)
.controller('userViewController', userViewController)
.component('gbbgUserAccessSelect', gbbgUserAccessSelect)
.component('gbbgUsersByEmail', gbbgUsersByEmail)
.component('gbbgUserListFilters', gbbgUserListFilters)
.component('gbbgNewUser', gbbgNewUser)
.component('gbbgUserChangePassword', gbbgUserChangePassword)
.component('gbbgPasswordResetButton', gbbgPasswordResetButton)
.component('gbbgUserDetails', gbbgUserDetails)
.component('gbbgUserEmailSignature', gbbgUserEmailSignature)
.component('gbbgUserSelect', gbbgUserSelect)
.component('gbbgUserSelectList', gbbgUserSelectList)
.component('gbbgSuspendUserButton', gbbgSuspendUserButton)
.component('gbbgUserTeamsSelect', gbbgUserTeamsSelect)
.component('gbbgUserTeams', gbbgUserTeams)
.factory('UserService', UserService);

export default 'management.user';
