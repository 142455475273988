function authorityListViewController($state, $stateParams, AuthorityService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.loading = false;
    vm.authorities = [];
    vm.country = '';
    vm.$stateParams = $stateParams;

    vm.changeCountry = changeCountry;

    vm.$onInit = activate;

    function activate() {
        vm.country = vm.$stateParams.country;
        if (!vm.country) $state.go($state.current.name, { country: 'United Kingdom' }, { reload: $state.current.name });
        else getAuthorities();
    }

    function changeCountry() {
        if (!vm.country || vm.country === $stateParams.country) return;
        $state.go($state.current.name, { country: vm.country }, { reload: $state.current.name });
    }

    function getAuthorities() {
        if (!vm.country) return;
        vm.loading = true;
        AuthorityService.getAuthoritiesByCountry(vm.country).then(
            data => {
                vm.authorities = data;
                vm.loading = false;
            },
            error => {
                vm.loading = false;
                ErrorHandler.showDialog(`Failed to get issuing authorities for ${vm.country}:`, error);
                console.error(`Failed to get issuing authorities for ${vm.country}:`, error);
            }
        );
    }
}

export { authorityListViewController };
