function gbbgAddCert(AuthService) {
    'ngInject';
    return {
        restrict: 'A',
        scope: true,
        bindToController: {
            clientId: '@',
            certs: '=',
            selectedCert: '=?',
            country: '@'
        },
        controller,
        controllerAs: 'vm',
        link(scope, elem, attrs) {

            if (AuthService.isAgent()) {
                elem[0].disabled = true;
            } else {
                elem.on('click', scope.vm.openDialog);
                scope.$on('$destroy', () => elem.off('click', scope.vm.openDialog));
            }
        }
    };
}
function controller($scope, $mdDialog, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template: dialogTemplate,
            locals: {
                cert: {
                    issuingAuthority: {},
                    dateExp: '',
                    certNo: ''
                },
                country: vm.country
            },
            controller: dialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then((cert) => {
            if (!cert || !cert.dateExp || !cert.issuingAuthority) return;
            ActiveService.addCert(vm.clientId, cert).then(
                (data) => {
                    const { cert, timelineAction } = data;
                    Array.isArray(vm.certs) ? vm.certs.push(cert) : vm.certs = [ cert ];
                    if (vm.selectedCert) vm.selectedCert = cert;
                    ActiveService.addTimelineAction(timelineAction);
                    $scope.$emit('cert.update', cert);
                },
                error => {
                    ErrorHandler.showDialog('Failed to add certificate', error);
                    console.error('Failed to add certificate to client:', error);
                }
            );
        }, () => { });
    }
}
function dialogController(cert, country, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    const today = new Date();

    vm.country = country || '';

    vm.minDate = new Date(
        today.getFullYear()-1,
        today.getMonth(),
        today.getDate()
    );
    vm.maxDate = new Date(
        today.getFullYear() + 2,
        today.getMonth(),
        today.getDate()
    );
    vm.cert = cert;

    vm.done = $mdDialog.hide;
    vm.cancel = $mdDialog.cancel;
}

const dialogTemplate = `
<md-dialog class="large-dialog" aria-label="Add Tax Cert">
    <form name="certForm" ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Tax Certificate</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content>
            <div class="md-dialog-content" layout="column">

                <gbbg-select-authority
                    authority="vm.cert.issuingAuthority"
                    country="{{vm.country}}"
                ></gbbg-select-authority>

                <md-datepicker
                    ng-model="vm.cert.dateExp"
                    md-current-view="year"
                    md-placeholder="Expiry date"
                    md-min-date="vm.minDate"
                    md-max-date="vm.maxDate"
                    required
                ></md-datepicker>

                <md-input-container flex>
                    <label>Cert no</label>
                    <input type="text" ng-model="vm.cert.certNo"/>
                </md-input-container>

            </div>
        </md-dialog-content>
        <md-dialog-actions layout="row">
            <span flex></span>
            <md-button
                class="md-primary md-raised"
                ng-click="vm.done(vm.cert)"
                ng-disabled="certForm.$invalid || !vm.cert.issuingAuthority"
            > Save cert </md-button>
            <md-button ng-click="vm.cancel()"> Cancel </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

export { gbbgAddCert, dialogController, dialogTemplate }
