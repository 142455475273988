function ErrorHandler($mdDialog, $state) {
    'ngInject'
    return {
        showDialog(title, error, okayText, reload, callback) {
            let message = ''
            if (error && typeof error === 'string') message = error
            else if (error && typeof error === 'object') {
                if (error.status && (error.status == 400 || error.status == 401)) return
                if (error.data && error.data.message) message = error.data.message
                if (error.data && error.data.err) message += ` ${error.data.err.message}`
            }
            $mdDialog.show(
                $mdDialog.alert()
                .clickOutsideToClose(true)
                .title(title || '')
                .textContent(message || '')
                .ariaLabel(title || '')
                .ok(okayText || 'Okay')
            ).then(() => {
                if (reload && reload === true) return $state.go($state.current.name, {}, { reload: $state.current.name })
                if (callback) callback()
            })
        }
    }
}

export { ErrorHandler }
