import { notificationList } from './notification_list';
import { notificationScroll } from './notification_scroll';
import { NotificationService } from './notification_service';

angular
.module('notification', [])
.component('notificationList', notificationList)
.directive('notificationScroll', notificationScroll)
.factory('NotificationService', NotificationService);

export default 'notification';
