const gbbgSidenavToggle = {
    template: `
    <md-button class="md-fab md-mini" aria-label="Toggle sidenav" ng-click="vm.toggle()" hide-gt-sm>
        <md-icon md-font-library="material-icons">menu</md-icon>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdSidenav) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.toggle = $mdSidenav('left').toggle;
}

export { gbbgSidenavToggle };
