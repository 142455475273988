const gbbgLeadEstimates = {
    template: `
    <md-card>
        <md-card-content layout="row">
            <md-input-container flex>
                <label>Estimated VAT value (£)</label>
                <input
                    type="number"
                    placeholder="Estimated VAT value"
                    ng-model="vm.estimates.vatValue"
                >
            </md-input-container>
            <md-input-container flex>
                <label>Estimated agent fee (%)</label>
                <input
                    type="number"
                    placeholder="Agent fee as percentage"
                    max="100"
                    ng-model="vm.estimates.agentFee"
                >
            </md-input-container>
        </md-card-content>
    </md-card>
    `,
    bindings: { estimates: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgLeadEstimates };
