import { gbbgCertSelect } from './claim_cert_select'
import { gbbgClaimClientTimeline } from './claim_client_timeline'
import { gbbgClaimClose } from './claim_close'
import { gbbgClaimEmailDialog } from './claim_email_dialog'
import { gbbgClaimFeesUpdate } from './claim_fees_update_button'
import { gbbgClaimImgGrid } from './claim_image_grid'
import { gbbgClaimListFilters } from './claim_list_filters'
import { gbbgClaimList } from './claim_list'
import { gbbgNewClaimButton } from './claim_new_claim_button'
import { gbbgToggleClaimPaperwork } from './claim_paperwork_toggle'
import { gbbgClaimPeriod } from './claim_period'
import { gbbgPreview65a } from './claim_preview_65a'
import { claimRoutes } from './claim_routes'
import { gbbgClaimSaveButton } from './claim_save_button'
import { gbbgClaimSchedule } from './claim_schedule'
import { ClaimService } from './claim_service'
import { gbbgClaimSubmissions } from './claim_submissions'
import { gbbgClaimSubmit } from './claim_submit_button'
import { gbbgClaimSummary } from './claim_summary'
import { gbbgClaimTimeline } from './claim_timeline'
import { gbbgTypeOfSupply } from './claim_type_of_supply_select'
import { gbbgUrnSelect } from './claim_urn_select'
import { gbbgClaimValidations } from './claim_validations'
import { gbbgClaimValidationsProgress } from './claim_validations_progress'

import { gbbgClaimAddInvoice } from './invoice/claim_add_invoice'
import { gbbgClaimInvoiceActions } from './invoice/claim_invoice_actions'
import { gbbgDeleteClaimInvoice } from './invoice/claim_invoice_delete'
import { gbbgEditClaimInvoice } from './invoice/claim_invoice_edit'
import { gbbgClaimInvoiceValue } from './invoice/claim_invoice_value'
import { gbbgClaimInvoicesPdf } from './invoice/claim_invoices_pdf'
import { gbbgClaimInvoices } from './invoice/claim_invoices'

import { gbbgClientAgentInvoice } from './refund/claim_client_agent_invoice'
import { gbbgClaimInvoicesRefund } from './refund/claim_invoices_refund'
import { gbbgClaimRefundCharges } from './refund/claim_refund_charges'
import { gbbgClaimRefundInvoices } from './refund/claim_refund_invoices'
import { gbbgClaimRefundOverview } from './refund/claim_refund_overview'
import { gbbgClaimRefund } from './refund/claim_refund'

import { gbbgClaimRevert } from './revert/revert-status'

import { default as PreClaim } from './pre-claim/pre-claim'

angular
.module('claim', [
    PreClaim
])
.config(claimRoutes)
.component('gbbgCertSelect', gbbgCertSelect)
.component('gbbgClaimClientTimeline', gbbgClaimClientTimeline)
.component('gbbgClaimClose', gbbgClaimClose)
.component('gbbgClaimEmailDialog', gbbgClaimEmailDialog)
.component('gbbgClaimImgGrid', gbbgClaimImgGrid)
.component('gbbgClaimFeesUpdate', gbbgClaimFeesUpdate)
.component('gbbgClaimListFilters', gbbgClaimListFilters)
.component('gbbgClaimList', gbbgClaimList)
.component('gbbgNewClaimButton', gbbgNewClaimButton)
.component('gbbgToggleClaimPaperwork', gbbgToggleClaimPaperwork)
.component('gbbgClaimPeriod', gbbgClaimPeriod)
.component('gbbgPreview65a', gbbgPreview65a)
.component('gbbgClaimSaveButton', gbbgClaimSaveButton)
.component('gbbgClaimSchedule', gbbgClaimSchedule)
.component('gbbgClaimSubmissions', gbbgClaimSubmissions)
.component('gbbgClaimSubmit', gbbgClaimSubmit)
.component('gbbgClaimSummary', gbbgClaimSummary)
.component('gbbgClaimTimeline', gbbgClaimTimeline)
.component('gbbgTypeOfSupply', gbbgTypeOfSupply)
.component('gbbgUrnSelect', gbbgUrnSelect)
.component('gbbgClaimValidations', gbbgClaimValidations)
.component('gbbgClaimValidationsProgress', gbbgClaimValidationsProgress)
.factory('ClaimService', ClaimService)
// invoice
.component('gbbgClaimAddInvoice', gbbgClaimAddInvoice)
.component('gbbgClaimInvoiceActions', gbbgClaimInvoiceActions)
.component('gbbgDeleteClaimInvoice', gbbgDeleteClaimInvoice)
.component('gbbgEditClaimInvoice', gbbgEditClaimInvoice)
.component('gbbgClaimInvoiceValue', gbbgClaimInvoiceValue)
.component('gbbgClaimInvoicesPdf', gbbgClaimInvoicesPdf)
.component('gbbgClaimInvoices', gbbgClaimInvoices)
// refund
.component('gbbgClientAgentInvoice', gbbgClientAgentInvoice)
.component('gbbgClaimInvoicesRefund', gbbgClaimInvoicesRefund)
.component('gbbgClaimRefundCharges', gbbgClaimRefundCharges)
.component('gbbgClaimRefundInvoices', gbbgClaimRefundInvoices)
.component('gbbgClaimRefundOverview', gbbgClaimRefundOverview)
.component('gbbgClaimRefund', gbbgClaimRefund)
// revert
.component('gbbgClaimRevert', gbbgClaimRevert)

export default 'claim'
