const gbbgNewIssuingAuthority = {
    template: `
    <md-button
        class="md-fab md-secondary md-hue-1 md-mini"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">add</md-icon>
        <md-tooltip md-direction="bottom">New source</md-tooltip>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, $mdToast, AuthorityService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((authority) => {
            AuthorityService.newAuthority(authority).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Issuing authority added successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                },
                error => {
                    ErrorHandler.showDialog('Failed to add new issuing authority', error);
                    console.error('Failed to add new issuing authority:', error);
                }
            );
        }, () => {});
    }

    const template = `
    <md-dialog aria-label="Add source" style="max-width:600px;">
        <form name="newAuthorityForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>New issuing authority</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content class="md-padding" layout-padding>

                <gbbg-authority-details authority="vm.authority"></gbbg-authority-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.addAuthority()"
                    ng-disabled="newAuthorityForm.$invalid"
                > Add authority </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController($mdDialog, $stateParams) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.authority = {
        name: '',
        address: {
            location: '',
            country: $stateParams.country || ''
        },
        phone: null,
        email: ''
    };

    vm.cancel = $mdDialog.cancel;
    vm.addAuthority = addAuthority;

    function addAuthority() {
        $mdDialog.hide(vm.authority);
    }
}

export { gbbgNewIssuingAuthority };
