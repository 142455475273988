function leadListViewController($mdDialog, $state, $stateParams, ErrorHandler, LeadService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    let requestPage = 1; // for query pagination

    vm.showLoadMore = false;
    vm.loading = false;

    vm.leads = [];

    vm.concatAddress = concatAddress;
    vm.getLeads = getLeads;

    vm.getLeads();

    function getLeads() {
        vm.loading = true;
        let { assigned, status, flag, query, sort } = $stateParams;

        if (!assigned || !status) return $state.go('root.auth.leads.all', {
            assigned: assigned || localStorage.getItem('gbbgLeadFilterAssigned') || 'me',
            status: status || localStorage.getItem('gbbgLeadFilterStatus') || 'pending',
            flag: flag || localStorage.getItem('gbbgLeadFilterFlag') || null,
            sort: sort || localStorage.getItem('gbbgLeadFilterSort') || 'referenceNo',
            query: query || localStorage.getItem('gbbgLeadFilterQuery') || ''
        }, { reload: $state.current.name });

        LeadService.getLeads({ assigned, status, flag, query, sort, page: requestPage }).then(
            data => {
                vm.loading = false;
                data.leads.map(lead => vm.leads.push(lead));
                vm.totalLeads = data.total || 0;
                vm.showLoadMore = (data.page < data.pages);
                requestPage = Number(data.page) + 1;
            },
            error => {
                vm.loading = false;
                ErrorHandler.showDialog('Failed to load lead records', error);
                console.error('Failed to load lead records:', error);
            }
        );
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { leadListViewController };
