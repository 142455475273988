function ActiveService($http) {
    'ngInject';
    const service = {
        client: { },
        setClient(client) {
            if (typeof client !== 'object' || !client._id) return;
            if (service.client.businessTravellers) service.client.businessTravellers.splice(0);
            Object.keys(client).map(key => service.client[key] = client[key]);
        },
        addTimelineAction(timelineAction) {
            let { timeline } = service.client;
            (timeline && Array.isArray(timeline)) ? timeline.unshift(timelineAction) : timeline = [ timelineAction ];
        },
        getActiveClients(params = {}) {
            return $http.get('/api/clients/active', { params }).then(success, failure);
        },
        getActiveClient(id) {
            return $http.get(`/api/clients/active/${id}`).then(success, failure);
        },
        getClaimsSummary(id) {
            return $http.get(`/api/clients/active/claims/summary/${id}`).then(success, failure);
        },
        addUrn(clientId, urn) {
            return $http.put(`/api/clients/active/urn/${clientId}`, urn).then(success, failure);
        },
        addCert(clientId, cert) {
            return $http.put(`/api/clients/active/cert/${clientId}`, cert).then(success, failure);
        },
        updateCert(updatedCert) {
            if (!updatedCert || !updatedCert._id) Promise.reject('No cert ID provided.')
            const clientId = service.client._id;
            return $http.put(`/api/clients/active/${clientId}/cert/${updatedCert._id}`, updatedCert).then((response) => {
                if (response.status != 200) throw response;
                service.client.certs.map((cert) => {
                    if (cert._id == updatedCert._id) cert = updatedCert;
                    return cert;
                })
                return response.data;
            }, failure);
        },
        updateIssuingAuthority(clientId, issuingAuthority) {
            return $http.put(`/api/clients/active/authority/${clientId}`, issuingAuthority).then(success, failure);
        },
        setIssue(issues) {
            const { _id } = service.client;
            return $http.put(`/api/clients/active/issue/${_id}`, issues).then((response) => {
                if (response.status != 200) throw response;
                if (!service.client.status) service.client.status = {};
                service.client.status.issues = issues;
                service.addTimelineAction(response.data);
                return response.data;
            }, failure);
        },
        revokeIssue(issue) {
            const { _id } = service.client;
            return $http.delete(`/api/clients/active/issue/${_id}`).then((response) => {
                if (response.status != 200) throw response;
                if (!service.client.status) service.client.status = {};
                service.client.status.issues = { from: '', details: '' };
                service.addTimelineAction(response.data);
                return response.data;
            }, failure);
        },
        saveClient(client) {
            return $http.put(`/api/clients/${client._id}`, client).then((response) => {
                if (response.status != 200) throw response;
                if (service.client && service.client._id) {
                    service.client.status = response.data.status;
                }
                return response.data;
            }, failure);
        },
        addNewRelationship(newRelationship) {
            const { _id } = service.client;
            return $http.put(`/api/clients/active/family/${_id}`, newRelationship).then((response) => {
                if (response.status != 200) throw response;
                const { family, timeline } = response.data
                if (family) service.client.family = family
                if (timeline) service.client.timeline = family
                return response.data
            }, failure);
        },
        deleteRelationship(relationship) {
            const { _id } = service.client;
            const companyId = relationship.company._id
            return $http
            .delete(`/api/clients/active/family/${_id}/${companyId}?relationship=${relationship.relationship}`)
            .then((response) => {
                if (response.status != 200) throw response;
                const { family, timeline } = response.data
                if (family) service.client.family = family
                if (timeline) service.client.timeline = family
                return response.data
            }, failure)
        },
        getActiveClientClaimsTimelines(clientId) {
            if (!clientId || !clientId.length) return Promise.reject('No client ID passed to ActiveService.')
            return $http.get(`/api/clients/active/claims/timelines/${clientId}`).then(success, failure)
        },
        getClaimSheet(clientId) {
            return $http.get(`/api/clients/active/claimsheet/${clientId}`).then(success, failure)
        }
    }
    return service
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data
    }
    function failure(error) { throw error }
}

export { ActiveService }
