function supplierListViewController($mdDialog, $state, SupplierService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this

    vm.country = 'United States'
    vm.query = ''
    vm.suppliers = []
    vm.selectedSuppliers = []

    vm.concatName = concatName
    vm.search = search
    vm.toggle = toggle
    vm.exists = exists
    vm.openDialog = openDialog

    vm.$onInit = search

    function search() {
        if (!vm.country || !vm.country.length) { return }
        SupplierService.searchSuppliers(vm.query, vm.country).then(
            (data) => vm.suppliers = data,
            (error) => {
                ErrorHandler.showDialog('Failed to get suppliers', error)
                console.error('Failed to get suppliers:', error)
            }
        );
    }

    function concatName(supplier) {
        const { branch, country } = supplier.address
        let string = ''
        if (branch && country) string = `${branch}, ${country}`
        else string = branch || country
        return string
    }

    function toggle(item) {
        item = item.toString()
        if (!vm.selectedSuppliers.length) vm.selectedSuppliers.push(item)
        else {
            (vm.selectedSuppliers.includes(item)) ? vm.selectedSuppliers.splice(vm.selectedSuppliers.indexOf(item), 1) : vm.selectedSuppliers.push(item)
        }
    }

    function exists(item) {
        return vm.selectedSuppliers.includes(item)
    }

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
                .title('Are you sure?')
                .textContent(`
                    Merging suppliers is a permanent action and cannot be undone.
                    \n This will affect all claims that rely on the information in these records.
                    \n The earliest record will become the primary source of supplier information for the affected claims.
                `)
                .ariaLabel('Merge records')
                .ok('Merge suppliers')
                .cancel('Cancel')
        ).then(() => {
            console.log('Selected:', vm.selectedSuppliers)
            SupplierService.mergeSuppliers(vm.selectedSuppliers).then(
                () => $state.go($state.current.name, {}, { reload: $state.current.name }),
                (error) => {
                    ErrorHandler.showDialog('Failed to merge suppliers', error)
                    console.error('Failed to merge suppliers:', error)
                }
            );
        }, () => {})
    }
}

export { supplierListViewController };
