const gbbgClaimInvoicesPdf = {
    template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.getPdf()"
        ng-disabled="vm.processing || !vm.claimId"
    >
        <md-icon>picture_as_pdf</md-icon>
        <md-tooltip>Get PDF</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller(ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.getPdf = getPdf;

    function getPdf() {
        if (!vm.claimId) return;
        vm.processing = true;
        ClaimService.getInvoicesAsPdf().then(
            data => {
                vm.processing = false;
                const url = window.location.getOriginRedirectUrl();
                window.open(`${url}/pdf/claim/${vm.claimId}/invoices/${data.file._id}?auth_token=${localStorage.gbbgToken}`);
            },
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Failed to generate PDF for claim invoices', error);
                console.error('Failed to generate PDF for claim invoices:', error);
            }
        );
    }
}

export { gbbgClaimInvoicesPdf };
