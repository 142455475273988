const gbbgClaimInvoices = {
    template: `
    <md-list>
        <md-list-item class="md-3-line md-long-text" ng-repeat="invoice in vm.invoices">
            <div class="md-list-item-text">
                <h3>
                    <strong>{{$index+1}}.</strong>
                    <strong>VAT claimed:</strong>
                    <span>{{invoice.vatClaimed | currency:'£'}}</span>

                    <strong>Gross:</strong>
                    <span>{{invoice.gross | currency:'£'}}</span>

                    <strong>Total VAT:</strong>
                    <span>{{invoice.vatTotal | currency:'£'}}</span>

                    <strong
                        ng-class="{
                            'text--primary': (invoice.vatRefunded && invoice.vatRefunded == invoice.vatClaimed),
                            'text--warn': (invoice.vatRefunded && invoice.vatRefunded != invoice.vatClaimed)
                        }"
                    >VAT refunded:</strong>
                    <span>{{invoice.vatRefunded | currency:'£'}}</span>
                </h3>
                <h4>
                    <strong>{{invoice.taxDate | date: 'dd MMM, yyyy'}}</strong>
                    <span ng-bind="invoice.supplier.supplier.name"></span>
                    <span ng-if="invoice.supplier.supplier.taxNo">
                        ({{invoice.supplier.supplier.taxNo || 'no tax no.'}}),
                    </span>
                    <span ng-bind="invoice.supplier.supplier.address.branch + ','"></span>
                    <span ng-bind="invoice.supplier.supplier.address.country"></span>
                </h4>
                <p>
                    <strong>Invoice no.:</strong>
                    <span ng-bind="invoice.invoiceNo || 'n/a'"></span>

                    <strong>Type of supply:</strong>
                    <span ng-bind="invoice.supplier.typeOfSupply"></span>

                    <strong>VAT rate:</strong>
                    <span ng-bind="invoice.supplier.vatRate + '%'"></span>
                </p>
                <gbbg-delete-claim-invoice
                    class="md-secondary"
                    ng-show="vm.canDelete()"
                    invoice-id="invoice._id"
                ></gbbg-delete-claim-invoice>
                <md-icon class="md-secondary md-warn" ng-if="invoice.penalty.set">
                    priority_high
                    <md-tooltip>{{invoice.penalty.value}}% restyling charge</md-tooltip>
                </md-icon>
                <md-icon
                    class="md-secondary"
                    md-font-library="material-icons"
                    aria-label="Show invoice travller and notes"
                    ng-if="invoice.traveller || invoice.notes"
                    ng-click="vm.showNotes(invoice.traveller, invoice.notes)"
                >note <md-tooltip md-direction="down">Traveller & notes</md-tooltip>
                </md-icon>
                <gbbg-edit-claim-invoice class="md-secondary" invoice="invoice" gbbg-hide-unless-acc-manager></gbbg-edit-claim-invoice>
            </div>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { claimStatus: '<', invoices: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($attrs, $mdDialog, ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    const refundStatuses = ['refunded', 'invoiced', 'closed', 'rejected'];

    vm.canDelete = canDelete;
    vm.showNotes = showNotes;

    vm.$onInit = activate;

    function activate() {
        if (vm.invoices && ClaimService.claim && refundStatuses.includes(ClaimService.claim.status.status)) {
            if ($attrs.rejected && $attrs.rejected == 'true') {
                vm.invoices = vm.invoices.filter(invoice => invoice.rejected);
            } else {
                vm.invoices = vm.invoices.filter(invoice => !invoice.rejected);
            }
        }
    }

    function showNotes(traveller, notes) {
        $mdDialog.show(
            $mdDialog
            .alert()
            .title(traveller || 'Invoice notes')
            .textContent(notes || 'No notes were attached to this invoice.')
            .ok('Okay')
        );
    }

    function canDelete() {
        return (
            vm.claimStatus &&
            !['refunded', 'invoiced', 'rejected', 'closed'].includes(vm.claimStatus)
        );
    }
}

export { gbbgClaimInvoices };
