const gbbgPreClaimActions = {
    template: `
    <md-button class="md-icon-button md-secondary" ng-click="vm.openDialog()">
        <md-icon md-font-library="material-icons">info_outline</md-icon>
    </md-button>
    `,
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.claim._id) return;
        $mdDialog.show({
            templateUrl: '/partials/templates/pre-claim/actions_dialog.html',
            locals: { claim: vm.claim },
            fullscreen: true,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((claim) => { vm.claim = claim; }, () => {});
    }
}
function dialogController($http, claim, $mdDialog, $timeout, AuthService, PreClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.message = '';
    vm.claim = claim;

    vm.toggleDocumentsSent = toggleDocumentsSent;
    vm.toggleDocumentsReceived = toggleDocumentsReceived;
    vm.done = done;

    activate();

    function activate() {
        vm.disableSent = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 101;
        vm.disableReceived = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 301;
    }

    function done() { $mdDialog.hide(vm.claim); }

    function toggleDocumentsSent() {
        vm.message = '';
        PreClaimService.toggleDocumentsSent(vm.claim._id).then(
            data => {
                vm.message = 'Updated documents sent.';
                vm.claim.timeline.push(data);
            },
            error => {
                const err = (error && error.message) ? error.message : '';
                vm.message = `Failed to update documents sent value. ${err}`;
                console.error('Failed to update documents sent:', error);
            }
        );
    }

    function toggleDocumentsReceived() {
        vm.message = '';
        PreClaimService.toggleDocumentsReceived(vm.claim._id).then(
            data => {
                vm.message = 'Updated documents received.';
                vm.claim.timeline.push(data);
            },
            error => {
                const err = (error && error.message) ? error.message : '';
                vm.message = `Failed to update documents sent value. ${err}`;
                console.error('Failed to update documents sent:', error);
            }
        );
    }
}

export { gbbgPreClaimActions };
