function claimStatus() {
    return function(status = '') {
        status = status.toString().trim();
        if (status === 'refunded') return 'refunded from tax authority';
        if (status === 'invoiced') return 'refunded to client';
        return status;
    }
}

export { claimStatus };
