const gbbgTeamMembers = {
    template: `
    <md-list class="md-dense">
        <md-list-item class="md-2-line" ng-repeat="member in vm.members" ng-class="{ 'list-item-dark': !member.active }">
            <div class="md-list-item-text">
                <h3>
                    <strong ng-bind="member.firstName + ' ' + member.surname"></strong>
                </h3>
                <p>{{::member.email}} ({{::vm.accessRole(member.access)}})</p>
            </div>
            <md-button
                class="md-icon-button md-secondary"
                ng-disabled="!vm.isAdmin"
                ng-if="!!vm.isAdmin || false"
                ng-click="vm.setMemberAsAdmin(member)"
            >
                <md-icon>star_border</md-icon>
                <md-tooltip md-direction="down">Set as admin</md-tooltip>
            </md-button>
            <md-button
                class="md-icon-button md-secondary"
                ui-sref="root.auth.management.users.user({ id: member._id })"
            > <md-icon>open_in_new</md-icon> </md-button>
        </md-list-item>
    </md-list>
    `,
    bindings: { members: '<', isAdmin: '<?' },
    controller,
    controllerAs: 'vm'
};
function controller(ACCESS_RIGHTS, $mdDialog, $state, $stateParams, ErrorHandler, TeamService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.accessRole = accessRole;
    vm.setMemberAsAdmin = setMemberAsAdmin;

    function setMemberAsAdmin(member) {
        if (!member) return;
        const name = member.firstName + ' ' + member.surname;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Set member as administrator?')
            .textContent(`Are you sure you want to set ${name} as an administrator of this team?`)
            .ariaLabel('Set administrator')
            .ok('Set admin')
            .cancel('Cancel')
        ).then(() => {
            TeamService.addAdmins($stateParams.id, member._id).then(
                data => $state.go($state.current.name, {}, { reload: $state.current.name }),
                error => {
                    ErrorHandler.showDialog('Failed to set user as admin', error);
                    console.error("Failed to set user as admin:", error);
                }
            );
        }, () => {});
    }

    function accessRole(access) { return ACCESS_RIGHTS[access]; }
}

export { gbbgTeamMembers };
