const gbbgClientClaimsSummary = {
    template: `
    <div>
        <p class="small-margin"><b>Total claims:</b> {{vm.summary.total}}</p>
        <p class="small-margin"><b>Total pending:</b> {{vm.summary.pending}}</p>
        <p class="small-margin"><b>Total refunded:</b> {{vm.summary.refunded}}</p>
        <p class="small-margin"><b>Amount refunded:</b> {{vm.summary.amountRefunded | currency: '£'}}</p>
        <p class="small-margin"><b>Est. amount to refund:</b> {{vm.summary.estRefunds | currency: '£'}}</p>
    </div>
    `,
    bindings: { clientId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller(ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.summary = {
        total: 0,
        pending: 0,
        refunded: 0,
        amountRefunded: 0,
        estRefunds: 0
    };

    vm.$onInit = activate;

    function activate() {
        if (!vm.clientId) return;
        ActiveService.getClaimsSummary(vm.clientId).then(
            data => vm.summary = data,
            error => {
                ErrorHandler.showDialog('Failed to get client claims summary', error);
                console.error('Failed to get client claims summary');
            }
        );
    }
}

export { gbbgClientClaimsSummary };
