const gbbgPreClaimList = {
    template: `
    <md-list>
        <md-list-item class="md-3-line md-long-text" ng-repeat="claim in vm.preClaims">
            <div class="md-list-item-text">
                <h3>
                    <strong>
                        {{claim.referenceNo}}.
                    </strong>
                        {{claim.dateCreated | date:'dd MMM, yyyy'}}
                        ({{claim.estimatedValue | currency:'£'}}
                        <span ng-if="claim.clientFee > 0"> – {{claim.clientFee}}% to client</span>)

                    {{claim.createdBy.firstName + ' ' + claim.createdBy.surname}}
                    <span class="text--warn" ng-if="claim.isClosed && !claim.claim">
                        Closed on {{claim.dateClosed | date:'dd MMM, yyyy'}}
                    </span>
                    <span class="text--primary" ng-if="claim.claim" ui-sref="root.auth.claims.claim({ id: claim.claim })">
                        Claim created
                        <md-icon class="md-primary"> open_in_new
                            <md-tooltip md-direction="down">Open claim</md-tooltip>
                        </md-icon>
                    </span>
                </h3>
                <h4>
                    <span ng-click="vm.goToClient(claim.client)"> {{claim.client.name}} <md-icon style="font-size: 20px;"> open_in_new </md-icon> </span> |
                    {{claim.country}} |
                    {{vm.documentStatus(claim)}}
                </h4>
                <p ng-bind="claim.notes"></p>
            </div>
            <gbbg-pre-claim-actions class="md-secondary" claim="claim"></gbbg-pre-claim-actions>
            <gbbg-pre-claim-to-claim
                class="md-secondary"
                gbbg-hide-unless-admin
                claim-id="{{claim._id}}"
            ></gbbg-pre-claim-to-claim>
            <gbbg-pre-claim-close
                class="md-secondary"
                gbbg-hide-unless-admin
                claim-id="{{claim._id}}"
            ></gbbg-pre-claim-close>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    `,
    bindings: { preClaims: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($state, PreClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedClaims = PreClaimService.selectedClaims;

    vm.documentStatus = documentStatus;
    vm.selected = selected
    vm.toggleClaim = toggleClaim;
    vm.goToClient = goToClient;

    function documentStatus(claim) {
        const { sent, received } = claim.paperwork;
        if (sent && received) return 'Documents sent & received.';
        if (!sent) return 'Documents not sent.';
        if (sent && !received) return 'Documents sent, not received.';
    }

    function selected(claim) { return vm.selectedClaims && vm.selectedClaims.includes(claim._id); }

    function toggleClaim(claim) {
        if (claim.isClosed || claim.claim) return false;
        if (!vm.selectedClaims) return vm.selectedClaims = [ claim ];
        const exists = vm.selectedClaims.some(selectedClaim => selectedClaim._id == claim._id);
        if (!exists) vm.selectedClaims.push(claim);
        else vm.selectedClaims = vm.selectedClaims.filter(selectedClaim => selectedClaim._id != claim._id);
    }

    function goToClient(client) {
        if (!client || !client._id) return;
        const isActive = (client.status && client.status.status === 'active');
        if (isActive) {
            $state.go('root.auth.active.client', { id: client._id }, { reload: 'root.auth.active.client' });
        } else {
            $state.go('root.auth.leads.lead', { leadId: client._id }, { reload: 'root.auth.leads.lead' });
        }
    }
}

export { gbbgPreClaimList };
