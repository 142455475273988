import { SidenavController } from './nav_sidenav_controller';
import { gbbgSidenavToggle } from './nav_sidenav_toggle';
import { gbbgTopNav } from './nav_topbar';

angular
.module('nav', [])
.component('gbbgSidenavToggle', gbbgSidenavToggle)
.component('gbbgTopNav', gbbgTopNav)
.controller('SidenavController', SidenavController);

export default 'nav';
