function ClientService($http) {
    return {
        addContact(clientId, contact) {
            return $http.post(`/api/client/${clientId}/contacts`, contact).then(success, failure);
        },
        updateContact(clientId, contact) {
            if (!contact || !contact._id) return Promise.reject('Invalid contact object.');
            return $http.put(`/api/client/${clientId}/contacts/${contact._id}`, contact).then(success, failure);
        },
        deleteContact(clientId, contactId) {
            return $http.delete(`/api/client/${clientId}/contacts/delete/${contactId}`).then(success, failure);
        },
        setPrimaryContact(clientId, contact) {
            return $http.post(`/api/client/${clientId}/contacts/primary`, contact).then(success, failure);
        },
        getClientsByUser(userId) {
            return $http.get(`/api/clients/user/${userId}`).then(success, failure);
        },
        getClientNames(searchString, status) {
            const name = searchString || '';
            let queryString = `?name=${name}`
            if (status && status.length) queryString += `&status=${status}`
            return $http.get(`/api/clients/names${queryString}`).then(success, failure);
        },
        getContacts(clientId) {
            return $http.get(`/api/clients/contacts/${clientId}`).then(success, failure);
        },
        getUncontactedClients(date = '3m', agentFilter, status) {
            return $http
            .get(`/api/clients/uncontacted?date=${date}&agentFilter=${agentFilter}&status=${status}`)
            .then(success, failure);
        },
        addToTimeline(clientId, activity, details, email, emailId) {
            return $http.put(`/api/clients/timeline/${clientId}`, {
                activity, details, email, emailId
            }).then(success, failure);
        },
        deleteFromTimeline(clientId, timelineId) {
            return $http.delete(`/api/clients/${clientId}/timeline/${timelineId}`).then(success, failure);
        },
        updateTimelineRecord(clientId, timelineRecord) {
            const timelineId = timelineRecord._id;
            return $http.put(`/api/clients/${clientId}/timeline/${timelineId}`, timelineRecord).then(success, failure);
        },
        updateFees(clientId, fees) {
            return $http.put(`/api/clients/fees/${clientId}`, fees).then(success, failure);
        },
        addBusinessTraveller(clientId, traveller) {
            return $http.put(`/api/clients/traveller/${clientId}`, traveller).then(success, failure);
        }
    };
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { throw error; }
}

export { ClientService };
