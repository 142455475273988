const gbbgClaimRefundOverview = {
    template: `
    <md-card>
        <md-card-title>
            <md-card-title-text>
                <span class="md-headline">Estimated refund breakdown</span>
                <span class="md-subhead">These figures might not correlate exactly with final invoice totals.</span>
            </md-card-title-text>
        </md-card-title>
        <md-divider></md-divider>
        <md-card-content>
            <p style="margin-bottom: 6px;">Amount submitted to customs: <strong>{{vm.figures.submitted | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Amount refunded from customs: <strong>{{vm.figures.refunded | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Invoice restyling charge applied: <strong>{{vm.figures.penalties | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Additional charges: <strong>{{vm.figures.costs | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Est. agent allocation: <strong>{{vm.figures.toAgent | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Est. client allocation: <strong>{{vm.figures.clientFeeMinusCosts | currency: '£'}}</strong></p>

            <p style="margin-top: 6px; margin-bottom: 6px;">Est. GBBG allocation (excl. charges): <strong>{{vm.figures.gbbgFeeMinusAgentAllocation | currency: '£'}}</strong></p>

            <p style="margin-top: 6px;">Est. GBBG allocation (incl. charges): <strong>{{vm.figures.gbbgFeeIncludingCosts | currency: '£'}}</strong></p>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions layout="row">
            <md-button ng-click="vm.calculate()">Recalculate</md-button>
        </md-card-actions>
    </md-card>
    `,
    bindings: {
        status: '@',
        // submissions: '<', // latest submission
        refund: '=', // Claim.refund
        // agentFee: '@',
        // fees: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller($filter, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.figures = {
        submitted: 0,
        refunded: 0,
        penalties: 0,
        toAgent: 0,
        toClient: 0,
        toGbbg: 0
    };

    vm.calculate = calculate;

    vm.$onInit = calculate;

    function calculate() {
        if (vm.status !== 'refunded' && vm.status != 'invoiced') return;

        ClaimService.getRefundFigures().then(
            data => vm.figures = data,
            error => {
                ErrorHandler.showDialog('Failed to get refund figures', error);
                console.error('Failed to get refund figures:', error);
            }
        );
    }
}

export { gbbgClaimRefundOverview };
