function userViewController($mdToast, $stateParams, UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.validations = {
        isLoaded: false,
        disableSave: true,
        disableAll: true,
        changePassword: false,
        suspendUser: false,

    };

    vm.user = UserService.user;

    vm.updateUser = updateUser;

    vm.$onInit = activate;

    function activate() {
        if (!$stateParams.id) return;
        UserService.getUser($stateParams.id).then(
            data => {
                vm.validations.isLoaded = true;
                if (vm.user.hasWriteAccess && vm.user.hasWriteAccess !== false) {
                    vm.validations.disableSave = false;
                    vm.validations.disableAll = false;
                    vm.validations.changePassword = (vm.user.hasWriteAccess === 'full');
                    vm.validations.suspendUser = (vm.user.hasWriteAccess === 'full' && vm.user._id != localStorage.getItem('gbbgUserId'));
                }
            },
            error => {
                ErrorHandler.showDialog('Failed to load user', error);
                console.error('Failed to load user:', error);
            }
        );
    }

    function updateUser() {
        vm.validations.disableSave = true;
        UserService.updateUser($stateParams.id, vm.user).then(
            data => {
                vm.validations.disableSave = false;
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('User updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                vm.validations.disableSave = false;
                ErrorHandler.showDialog('Failed to update user', error);
                console.error('Failed to update user:', error);
            }
        );
    }
}

export { userViewController };
