const gbbgPreview65a = {
    template: `
    <md-button
        class="md-primary"
        ng-click="vm.getPreview()"
        ng-disabled="vm.parent.processing"
    >View 65A</md-button>
    `,
    require: { parent: '^gbbgClaimValidations' },
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($stateParams, $timeout, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.getPreview = getPreview;

    function getPreview() {
        if (!vm.claim || !vm.claim._id) return;
        vm.parent.processing = true;
        Promise.all([
            ClaimService.saveClaim(vm.claim),
            ClaimService.checkValidity($stateParams.id),
            ClaimService.get65a($stateParams.id)
        ]).then(
            data => {
                $timeout(() => vm.parent.processing = false);
                const url = window.location.getOriginRedirectUrl();
                window.open(`${url}/pdf/65a/${$stateParams.id}?auth_token=${localStorage.gbbgToken}`);
            }, error => {
                vm.parent.processing = false;
                ErrorHandler.showDialog('Failed to get 65A preview', error);
                console.error('Failed to get 65A preview:', error);
            }
        );
    }
}

export { gbbgPreview65a };
