const gbbgUserListFilters = {
    template: `
    <md-card md-whiteframe="6">
        <md-card-content layout="row" layout-wrap>
            <!-- Sort by -->
            <md-input-container flex>
                <label>Sort by</label>
                <md-select
                    ng-model="vm.$stateParams.sort"
                    ng-change="vm.go(vm.$stateParams.sort)"
                    aria-label="Sort by"
                >
                    <md-option ng-repeat="sort in vm.sortables" ng-value="sort.value">
                    {{sort.name}}
                    </md-option>
                </md-select>
            </md-input-container>
            <!-- Statuses -->
            <md-input-container flex>
                <label>Status</label>
                <md-select
                    ng-model="vm.$stateParams.status"
                    ng-change="vm.go(null, vm.$stateParams.status)"
                    aria-label="Select status"
                >
                    <md-option ng-repeat="status in vm.statuses" ng-value="status">
                    {{status}}
                    </md-option>
                </md-select>
            </md-input-container>
            <form layout="row" layout-align="center center" flex flex-xs="100">
                <md-input-container flex>
                    <label>Search query</label>
                    <input type="text" ng-model="vm.query"/>
                </md-input-container>
                <md-button
                    class="md-icon-button"
                    ng-click="vm.go(null, null, vm.query)"
                > <md-icon>search</md-icon> </md-button>
                <md-button
                    class="md-icon-button"
                    ng-click="vm.go(null, null, null)"
                > <md-icon>close</md-icon> </md-button>
            </form>
        </md-card-content>
    </md-card>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($state, $stateParams) {

    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.$stateParams = $stateParams;

    vm.query = $stateParams.query || '';

    vm.sortables = [
        { name: 'reference no.', value: 'referenceNo'},
        { name: 'reference no. (rev)', value: '-referenceNo'},
        { name: 'email', value: 'email' },
        { name: 'email (rev)', value: '-email' },
        { name: 'access', value: 'access' },
        { name: 'access (rev)', value: '-access' }
    ];
    vm.statuses = [ 'all', 'suspended', 'active' ];

    vm.go = go;

    function go(sort, status, query) {
        $state.go($state.current.name, {
            sort: sort || $stateParams.status || 'referenceNo',
            status: status || $stateParams.status || 'all',
            query: (query == null) ? null : (query || $stateParams.query || null)
        }, { reload: $state.current.name });
    }
}

export { gbbgUserListFilters };
