const gbbgDeleteEmailTemplate = {
    template: `
    <md-button
        class="md-warn md-icon-button"
        ng-disabled="!vm.templateId || !vm.hasAccess"
        ng-click="vm.openDialog()"
    >
        <md-icon>delete</md-icon>
        <md-tooltip md-direction="down">Delete email template</md-tooltip>
    </md-button>
    `,
    bindings: { templateId: '<', createdBy: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $state, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.hasAccess = false;

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() {
        vm.hasAccess = ((vm.createdBy && localStorage.gbbgUserId === vm.createdBy) || localStorage.gbbgUserAccess >= 701);
    }

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Delete email template?')
            .textContent('Deleting this email template is a permanent action and cannot be undone.')
            .ariaLabel('Delete email template')
            .ok('Delete template')
            .cancel('Cancel')
        ).then(() => deleteTemplate(), () => {});
    }

    function deleteTemplate() {
        if (!vm.templateId || !vm.hasAccess) return;
        EmailService.deleteTemplate(vm.templateId).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Email template deleted successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                $state.go($state.current.name, {}, { reload: $state.current.name });
            },
            error => {
                ErrorHandler.showDialog('Failed to delete email template', error);
                console.error(`Failed to delete email template with ID '${vm.templateId}':`, error);
            }
        );
    }
}

export { gbbgDeleteEmailTemplate }
