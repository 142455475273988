function leadUncontactedViewController($mdDialog, $state, $stateParams, AuthService, ClientService, ErrorHandler, LeadService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.dates = [
        { label: '3 months', value: '3m' },
        { label: '6 months', value: '6m' },
        { label: '9 months', value: '9m' }
    ];
    vm.selectedDate = $stateParams.date;

    vm.clients = [];
    vm.selectedLeads = [];

    vm.concatAddress = concatAddress;
    vm.update = update;
    vm.toggleFilter = toggleFilter;
    vm.toggleLead = toggleLead;
    vm.openDialog = openDialog;

    vm.$onInit = getUncontactedClients;

    function update() {
        $state.go($state.current.name, { date: vm.selectedDate }, { reload: $state.current.name });
    }

    function getUncontactedClients() {
        vm.isAdmin = !!AuthService.isAdmin();
        vm.agentFilter = ($stateParams.agentFilter && $stateParams.agentFilter == 'true');
        ClientService.getUncontactedClients($stateParams.date, vm.agentFilter).then((data) => {
            vm.clients = data;
            vm.selectedLeads = vm.clients.map((lead) => {
                lead.status.flag = 'no contact';
                return lead;
            });
        }, (error) => {
            ErrorHandler.showDialog('Failed to get uncontacted leads', error);
            console.error('Failed to get uncontacted leads:', error);
        });
    }

    function openDialog() {
        if (!vm.selectedLeads || vm.selectedLeads.length < 1) return;

        const len = vm.selectedLeads.length;
        const text = len > 1 ? `${len} leads` : 'one lead';

        $mdDialog.show(
            $mdDialog.confirm()
            .title('Close leads?')
            .textContent(`Are you sure you want to close ${text}? You can undo this action at a later date.`)
            .ariaLabel('Close uncontacted leads')
            .ok('Close uncontacted leads')
            .cancel('Cancel')
        ).then(() => { setStatuses() }, () => {});
    }

    function setStatuses() {
        vm.processing = true;

        vm.selectedLeads = vm.selectedLeads.map(lead => {
            return {
                _id: lead._id,
                status: 'closed',
                reason: lead.status.flag
            };
        }).filter(lead => (lead._id && lead.status && lead.reason));

        LeadService.setMultipleStatuses(vm.selectedLeads).then(
            data => {
                if (data.errors && data.errors.length) {
                    console.error('Errors returned from server while updating uncontacted leads:', errors);
                }
                $mdDialog.show(
                    $mdDialog.alert()
                    .title(`${data.successes} uncontacted leads closed`)
                    .textContent(`${data.errors.length} errors returned from server.`)
                    .ariaLabel('Leads closed dialog')
                    .ok('Okay')
                ).then(() => {
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                });
            },
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Failed to set statuses for uncontacted leads', error);
                console.error('Failed to update uncontacted lead statuses:', error);
            }
        );
    }

    function toggleLead(lead) {
        const idx = vm.selectedLeads.indexOf(lead);
        (idx >= 0) ? vm.selectedLeads.splice(idx, 1) : vm.selectedLeads.push(lead);
    }

    function toggleFilter(agentFilter) {
        $state.go($state.current.name, {
            agentFilter: (agentFilter || agentFilter == false) ? agentFilter : $stateParams.agentFilter
        }, { reload: $state.current.name });
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { leadUncontactedViewController }
