function teamListViewController($state, $stateParams, ErrorHandler, TeamService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.teams = [];

    vm.$onInit = activate;

    function activate() {
        const { sort } = $stateParams;

        if (!sort) return $state.go('root.auth.management.teams.all', {
            sort: sort || 'referenceNo'
        }, { reload: $state.current.name });

        vm.teams.splice(0);
        TeamService.getAllTeams($stateParams).then(
            data => { vm.teams = data; },
            error => {
                ErrorHandler.showDialog('Failed to get teams', error);
                console.error('Failed to get teams:', error);
            }
        );
    }
}

export { teamListViewController };
