const gbbgUserEmailSignature = {
    template: `
    <div layout="column">
        <ng-quill-editor
            ng-model="vm.signature.text"
            placeholder="Email signature"
            max-length="1000"
            required="true"
        ></ng-quill-editor>
        <span class="hint" ng-non-bindable>Reference the signature image as \{\{SIGNATURE_IMAGE\}\}</span>
        <p class="text--center">{{vm.signature.image.filename || 'Upload a signature image.'}}</p>
        <gbbg-add-attachment
            style="margin: auto;"
            class="md-secondary"
            attachment="vm.signature.image"
        ></gbbg-add-attachment>
    </div>
    `,
    bindings: { signature: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgUserEmailSignature };
