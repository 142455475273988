const gbbgClaimSubmit = {
    template: `
    <md-button
        class="md-accent"
        ng-class="vm.buttonClass"
        ng-click="vm.openDialog()"
        ng-disabled="vm.isInvalid()"
        ng-bind="vm.buttonText"
        aria-label="Submission button"
    ></md-button>
    `,
    require: { parent: '^gbbgClaimValidations' },
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($scope, $mdDialog, $mdToast, $stateParams, $timeout, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.validations = ClaimService.validations;
    vm.submissions = ClaimService.submissions;

    vm.isInvalid = isInvalid;
    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() {
        if (vm.claim.submissions.length > 0) {
            vm.buttonClass = '';
            vm.buttonText = 'Re-submit claim';
        } else {
            vm.buttonClass = 'md-raised';
            vm.buttonText = 'Submit claim';
        }
    }

    function openDialog() {
        if (vm.validations.length > 0) return;
        let recipients = [];
        const { client } = vm.claim;
        if (client.contacts && client.contacts.length > 0) recipients = [...client.contacts];
        if (client.primaryContact) recipients.push(client.primaryContact);
        $mdDialog.show({
            template,
            fullscreen: true,
            locals: {
                claimId: vm.claim._id,
                recipients
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((data) => saveAndValidate(data), () => {});
    }

    function saveAndValidate(data) {
        vm.parent.processing = true;
        Promise.all([
            ClaimService.saveClaim(vm.claim),
            ClaimService.checkValidity($stateParams.id)
        ]).then(
            (response) => {
                if (response[1].validations.length > 0) {
                    $timeout(() => vm.parent.processing = false);
                    ErrorHandler.showDialog('Failed to submit claim', 'Claim failed to pass all validations.');
                } else {
                    submitClaim(data);
                }
            }, (error) => {
                $timeout(() => vm.parent.processing = false);
                ErrorHandler.showDialog('Failed to save claim before submitting', error);
                console.error('Failed to save claim before submitting:', error);
            }
        );
    }

    function submitClaim(data) {
        ClaimService.submitClaim($stateParams.id, data).then(
            (data) => {
                $timeout(() => vm.parent.processing = false);
                vm.buttonClass = '';
                vm.buttonText = 'Re-submit claim';
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Claim submitted successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                $scope.$emit('claimSubmitted', { message: 'Submitted' }); // sent up to claim view controller
            },
            (error) => {
                $timeout(() => vm.parent.processing = false);
                ErrorHandler.showDialog('Failed to submit claim', error);
                console.error('Failed to submit claim:', error);
            }
        );
    }

    function isInvalid() {
        const statuses = ['refunded', 'invoiced', 'closed', 'rejected'];
        return (vm.validations && vm.validations.length > 0) ||
        (vm.submissions && vm.submissions.length) ||
        statuses.includes(vm.claim.status.status);
    }

    const template = `
    <md-dialog style="max-width:800px;" aria-label="Submit claim">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Submit claim</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content>

        <md-tabs md-dynamic-height md-border-bottom>
            <md-tab label="Review">
                <md-content class="md-padding">
                    <div layout="row">
                        <div>
                            <h6 class="no-margin">Submission date</h6>
                            <md-datepicker
                                md-max-date="vm.today"
                                ng-model="vm.submissionDate"
                                required
                            ></md-datepicker>
                        </div>
                        <div>
                            <h6 class="no-margin">Estimated refund due date</h6>
                            <md-datepicker
                                md-min-date="vm.today"
                                md-max-date="vm.nextYear"
                                ng-model="vm.dueDate"
                                required
                            ></md-datepicker>
                        </div>
                    </div>
                    <p><strong>Please take time to review this section before submitting this claim. If you haven't reviewed the 65A, do that now please.</strong></p>
                    <p>An estimated <strong>{{vm.estimatedValue}}</strong> is being claimed. Submitting this claim will set the date of submission to <strong>{{vm.submissionDate | date:'MMM dd, yyyy'}}</strong> and the status of the claim will be set to 'submitted'.</p>
                    <p>Before submitting this claim, you acknowledge that you have permission and approval to do so.</p>
                    <p>Make sure you have reviewed all the details of this claim, and that it passes all automated validations. Double-check all sections to ensure nothing got passed these validations.</p>
                </md-content>
            </md-tab>
            <md-tab label="Client email">
                <md-content class="md-padding" layout="column">
                    <p><strong>Please make sure the following email is relevant to the client and that all the information is suitable and correct.</strong></p>

                    <div layout="column" layout-gt-xs="row">
                        <gbbg-email-template-select flex
                            render="vm.email"
                            claim-id="{{vm.claimId}}"
                            overwrite="{ dueDate: vm.dueDate }"
                        ></gbbg-email-template-select>
                        <gbbg-email-recipient-select
                            recipients="vm.recipients"
                            selected-recipients="vm.selectedRecipients"
                        ><gbbg-email-recipient-select>
                    </div>

                    <gbbg-email-template-content email="vm.email"></gbbg-email-template-content>

                </md-content>
            </md-tab>
        </md-tabs>

        </md-dialog-content>
        <md-dialog-actions layout="row" layout-align="end">
            <md-button class="md-warn" ng-click="vm.cancel()">Cancel</md-button>
            <md-button
                class="md-primary"
                ng-click="vm.submitWithoutEmail()"
                ng-disabled="!vm.dueDate || vm.selectedRecipients.length"
            >Submit</md-button>
            <md-button
                class="md-primary md-raised"
                ng-click="vm.submitWithEmail()"
                ng-disabled="
                    !vm.selectedRecipients ||
                    vm.selectedRecipients.length < 1 ||
                    !vm.email.subject ||
                    !vm.email.body ||
                    !vm.dueDate
                "
            >Email & submit</md-button>
        </md-dialog-actions>
    </md-dialog>
    `;
}

function dialogController(recipients, claimId, $filter, $mdDialog, ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.claimId = claimId;

    vm.submissionDate = new Date();
    vm.estimatedValue = ClaimService.claim.invoices.map((invoice) => {
        const claimed = Number(invoice.vatClaimed); // make sure it's cast to Number
        return isNaN(claimed) ? 0 : claimed;
    }).reduce((acc, val) => acc + val, 0);
    vm.estimatedValue = $filter('currency')(vm.estimatedValue, '£', 2);

    const d = new Date();
    vm.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    vm.nextYear = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());
    const plusSixMonths = ((d.getMonth() + 6) > 11) ? (-12 + (d.getMonth() + 6)) : (d.getMonth() + 6);
    const year = ((d.getMonth() + 6) > 11) ? (d.getFullYear() + 1) : d.getFullYear();
    vm.dueDate = new Date(year, plusSixMonths, d.getDate());

    vm.email = {};

    vm.recipients = [];
    vm.selectedRecipients = [];

    vm.cancel = $mdDialog.cancel;
    vm.submitWithoutEmail = submitWithoutEmail;
    vm.submitWithEmail = submitWithEmail;
    vm.isChecked = isChecked;
    vm.toggle = toggle;

    activate();

    function activate() {
        vm.recipients = recipients.filter(recipient => (recipient.email && recipient.email.length > 0));
    }

    function isChecked(email) { return (vm.selectedRecipients.includes(email)); }

    function toggle(email) {
        const idx = vm.selectedRecipients.indexOf(email);
        (idx >= 0) ? vm.selectedRecipients.splice(idx, 1) : vm.selectedRecipients.push(email);
    }

    function submitWithoutEmail() {
        $mdDialog.hide({
            recipients: vm.selectedRecipients,
            email: vm.email,
            dueDate: vm.dueDate,
            submissionDate: vm.submissionDate
        });
    }

    function submitWithEmail() {
        $mdDialog.hide({
            recipients: vm.selectedRecipients,
            email: vm.email,
            dueDate: vm.dueDate,
            submissionDate: vm.submissionDate
        });
    }
}

export { gbbgClaimSubmit };
