const gbbgUserTeamsSelect = {
    template: `
    <md-list>
        <md-list-item ng-repeat="team in vm.teams">
            <p>
                <strong>{{team.name}}</strong>
                <small>{{team.location.area}}, {{team.location.country}}</small>
            </p>
            <md-checkbox
                class="md-secondary"
                aria-label="Toggle {{team.name}}"
                ng-click="vm.toggleTeam(team._id)"
                ng-checked="vm.isSelected(team._id)"
            ></md-checkbox>
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
    <md-checkbox ng-model="vm.admin" aria-label="Set new user as admin">
        Set as administrator
    </md-checkbox>
    `,
    bindings: { selectedTeams: '=', admin: '=' },
    controller,
    controllerAs: 'vm'
}
function controller(ErrorHandler, TeamService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.teams = [];

    vm.toggleTeam = toggleTeam;
    vm.isSelected = isSelected;

    vm.$onInit = activate;

    function activate() {
        TeamService.getAllTeams().then(
            data => vm.teams = data,
            error => {
                ErrorHandler.showDialog('Failed to get teams', error);
                console.error('Failed to get teams:', error);
            }
        );
    }

    function toggleTeam(teamId) {
        const idx = vm.selectedTeams.indexOf(teamId);
        (idx >= 0) ? vm.selectedTeams.splice(idx, 1) : vm.selectedTeams.push(teamId);
        // Filter any teams that aren't in vm.teams
        vm.selectedTeams.map((team, idx) => {
            if (!vm.teams.some(({ _id }) => _id == team._id)) vm.selectedTeams.splice(idx, 1);
            return team;
        });
    }

    function isSelected(teamId) { return vm.selectedTeams.includes(teamId); }
}

export { gbbgUserTeamsSelect };
