const gbbgNewClaimButton = {
    template: `
    <div layout="column">
        <md-button class="md-accent md-hue-1 md-raised" ng-click="vm.openDialog()">
            Add claim
        </md-button>
    </div>
    `,
    bindings: { clientId: '@', certs: '<', urns: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, ErrorHandler, ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.clientId) return;
        $mdDialog.show({
            template,
            locals: {
                certs: vm.certs,
                urns: vm.urns
            },
            controller: function(certs, urns, $mdDialog) {
                'ngInject';
                /*validthis:true*/
                const vm = this;

                vm.selectedCert = {};
                vm.selectedURN = {};

                vm.certs = certs || [];
                vm.urns = urns || [];

                vm.cancel = $mdDialog.cancel;

                vm.newClaim = function() {
                    $mdDialog.hide({
                        cert: vm.selectedCert,
                        urn: vm.selectedURN
                    });
                }
            },
            controllerAs: 'vm'
        }).then(
            data => {
                ClaimService.newClaim(vm.clientId, data).then(
                    data => {
                        $state.go('root.auth.claims.claim', { id: data });
                    },
                    error => {
                        ErrorHandler.showDialog('Failed to add new claim', error);
                        console.error('Failed to add new claim:', error);
                    }
                );
            },
            () => { console.log('Cancelled.'); }
        );
    }

    const template = `
    <md-dialog aria-label="Add new claim">
        <form ng-cloak>
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Add new claim for this client?</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content>
                <div class="md-dialog-content" layout="column">

                    <p>Please add a URN and certificate to your new claim.</p>
                    <p class="md-caption">URNs and certs can be added/changed at a later date.</p>

                    <md-input-container flex>
                        <label>Select a URN</label>
                        <md-select aria-label="Select URN" ng-model="vm.selectedURN">
                            <md-option ng-repeat="urn in vm.urns" ng-value="urn">
                                <strong ng-bind="urn.number"></strong>
                                <span ng-bind="urn.country"></span>
                            </md-option>
                        </md-select>
                    </md-input-container>

                    <md-input-container flex>
                        <label>Select a certificate</label>
                        <md-select aria-label="Select certificate" ng-model="vm.selectedCert">
                            <md-option ng-repeat="cert in vm.certs" ng-value="cert">
                                <strong>{{cert.dateExp | date: 'dd MMM, yyyy'}}</strong>
                                <span>{{cert.certNo}}</span>
                            </md-option>
                        </md-select>
                    </md-input-container>

                </div>
            </md-dialog-content>
            <md-dialog-actions layout="row">
                <span flex></span>
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button class="md-raised" ng-click="vm.newClaim()">Add claim</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}

export { gbbgNewClaimButton };
