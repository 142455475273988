import { default as Active } from './active/active';
import { default as ClientUsers } from './users/client_users';

import { gbbgClientBasicDetails } from './client_basic_details';
import { gbbgClientBusinessTravellerAutocomplete } from './client_business_traveller_autocomplete';
import { gbbgClientBusinessTravellers } from './client_business_travellers';
import { gbbgClientContactDetails } from './client_contact_details';
import { gbbgEditContact } from './client_contact_edit';
import { gbbgClientContacts } from './client_contacts';
import { gbbgClientDescription } from './client_description';
import { gbbgClientFeesUpdate } from './client_fees_update_button';
import { gbbgClientFees } from './client_fees';
import { gbbgClientNameAutocomplete } from './client_name_autocomplete'
import { gbbgClientPreview } from './client_preview_dialog';
import { ClientService } from './client_service';
import { gbbgClientSummary } from './client_summary';
import { gbbgClientTimelineActions } from './client_timeline_actions';
import { gbbgClientTimelineSpeeddial } from './client_timeline_speeddial';
import { gbbgClientTimelineUpdate } from './client_timeline_update';
import { gbbgClientTimeline } from './client_timeline';

angular.module('client', [
    Active, ClientUsers
])
.component('gbbgClientBasicDetails', gbbgClientBasicDetails)
.component('gbbgClientBusinessTravellerAutocomplete', gbbgClientBusinessTravellerAutocomplete)
.component('gbbgClientBusinessTravellers', gbbgClientBusinessTravellers)
.component('gbbgClientContactDetails', gbbgClientContactDetails)
.component('gbbgClientContacts', gbbgClientContacts)
.component('gbbgEditContact', gbbgEditContact)
.component('gbbgClientDescription', gbbgClientDescription)
.component('gbbgClientFeesUpdate', gbbgClientFeesUpdate)
.component('gbbgClientFees', gbbgClientFees)
.component('gbbgClientNameAutocomplete', gbbgClientNameAutocomplete)
.component('gbbgClientPreview', gbbgClientPreview)
.component('gbbgClientSummary', gbbgClientSummary)
.component('gbbgClientTimeline', gbbgClientTimeline)
.component('gbbgClientTimelineActions', gbbgClientTimelineActions)
.component('gbbgClientTimelineSpeeddial', gbbgClientTimelineSpeeddial)
.component('gbbgClientTimelineUpdate', gbbgClientTimelineUpdate)
.factory('ClientService', ClientService);

export default 'client';
