import { default as Access } from './access/access';
import { default as Email } from './email/email';
import { default as Flag } from './flag/flag';
import { default as Report } from './report/report';
import { default as Team } from './team/team';
import { default as User } from './user/user';

import { managementRoutes } from './management_routes';

angular.module('management', [
    Access,
    Email,
    Flag,
    Report,
    Team,
    User
]).config(managementRoutes);

export default 'management';
