import { gbbgReportFileExport } from './report_file_export';
import { reportRoutes } from './report_routes';
import { ReportService } from './report_service';
import { reportClaimViewController } from './report_claim_view_controller';
import { reportClientViewController } from './report_client_view_controller';
import { reportSourceViewController } from './report_source_view_controller';

angular
.module('report', [])
.config(reportRoutes)
.factory('ReportService', ReportService)
.controller('reportClaimViewController', reportClaimViewController)
.controller('reportClientViewController', reportClientViewController)
.controller('reportSourceViewController', reportSourceViewController)
.component('gbbgReportFileExport', gbbgReportFileExport)
.run(function($rootScope, $state) {
    $rootScope.$on('$stateChangeStart', (evt, to, params) => {
        if (
            (to.name === 'root.auth.management.reports' || to.name === 'root.auth.management.reports.client') &&
            (!localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 301)
        ) {
            evt.preventDefault();
            $state.go('root.auth.management.reports.claims', {}, { reload: 'root.auth.management.reports.claims' });
        }
    });
});;

export default 'report';
