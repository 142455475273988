const gbbgTopNav = {
    template: `
    <md-toolbar ng-cloak>
        <div class="md-toolbar-tools">
            <gbbg-sidenav-toggle></gbbg-sidenav-toggle>
            <span flex></span>
            <ng-transclude></ng-transclude>
            <notification-list></notification-list>
        </div>
    </md-toolbar>
    `,
    transclude: true,
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgTopNav }
