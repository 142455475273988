const gbbgClaimSaveButton = {
    template: `
    <md-button
        aria-label="Save"
        class="md-fab md-fab-bottom-right md-primary"
        ng-click="vm.save()"
        ng-disabled="vm.processing"
    > <md-icon md-font-library="material-icons">save</md-icon> </md-button>
    `,
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
}
function controller($mdToast, ClaimService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.processing = false

    vm.save = save

    function save() {
        vm.processing = true
        if (!vm.claim || !vm.claim._id) { return }
        ClaimService.saveClaim(vm.claim).then(
            (data) => {
                showToast('Saved claim successfully!')
                vm.processing = false
                validateClaim(vm.claim._id)
            },
            (error) => {
                vm.processing = false
                ErrorHandler.showDialog('Failed to save claim', error)
                console.error("Failed to save claim:", error)
            }
        )
    }

    function validateClaim(claimId) {
        ClaimService.checkValidity(claimId).then(
            (data) => {},
            (error) => {
                // ErrorHandler.showDialog('Failed validate claim after saving', error, 'Retry', false, vm.save)
                ErrorHandler.showDialog('Failed validate claim after saving', error)
                console.error('Failed to validate claim after saving:', error)
            }
        )
    }

    function showToast(message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message || '')
            .position('top right')
            .hideDelay(4000)
        )
    }
}

export { gbbgClaimSaveButton }
