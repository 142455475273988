function claimViewController($scope, $state, $stateParams, AuthService, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.readOnly = false;
    vm.isLoaded = false;

    vm.claim = {};

    vm.hasAccess = hasAccess;

    vm.$onInit = activate;

    // received from gbbgClaimSubmit
    $scope.$on('claimSubmitted', function(e) {
        e.preventDefault(); // stop propagation (https://stackoverflow.com/a/15193650/3025130)
        $scope.$broadcast('claimSubmitted');
    });

    function activate() {
        vm.readOnly = AuthService.isAgent();
        if (!$stateParams.id) $state.go('root.auth.claims.all');
        ClaimService.getClaim($stateParams.id).then(
            data => {
                ClaimService.setClaim(data)
                vm.claim = ClaimService.claim;
                vm.isLoaded = true;
                if (localStorage && localStorage.gbbgUserAccess >= 401) validateClaim(vm.claim._id);
            },
            error => {
                ErrorHandler.showDialog(`Failed to get claim '${$stateParams.id}'`, error);
                console.error(`Failed to load claim '${$stateParams.id}'`, error);
            }
        );
    }

    function validateClaim(claimId) {
        ClaimService.checkValidity(claimId).then(
            (data) => {},
            error => {
                ErrorHandler.showDialog('Failed validate claim after loading', error);
                console.error('Failed to validate claim after loading:', error);
            }
        );
    }

    function hasAccess() {
        if (localStorage) {
            const access = localStorage.gbbgUserAccess;
            return (access && access >= 501);
        } else {
            return false;
        }
    }
}

export { claimViewController };
