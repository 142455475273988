const gbbgNewUser = {
    template: `
    <md-button
        ng-class="vm.buttonClass"
        ng-click="vm.openDialog()"
    >
        <md-icon>person_add</md-icon>
        <md-tooltip md-direction="down">Create new user</md-tooltip>
    </md-button>
    `,
    bindings: {
        teamId: '<',
        buttonClass: '@'
    },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, $state, $stateParams, ErrorHandler, UserService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;
    vm.createMember = createMember;

    vm.$onInit = activate;

    function activate() {
        if (!vm.buttonClass) vm.buttonClass = 'md-icon-button';
    }

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/management/user/new_dialog.html',
            fullscreen: true,
            locals: { teamId: vm.teamId || null },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((member) => createMember(member), () => {});
    }

    function createMember({ user, selectedTeams }) {
        user.password = 'password123';

        UserService.addUser(user, selectedTeams).then(
            data => {
                if (!$state.includes('root.auth.management.users')) {
                    return $state.go($state.current.name, { id: $stateParams.id }, { reload: $state.current.name });
                }
                $state.go('root.auth.management.users.user', { id: data });
            },
            error => {
                ErrorHandler.showDialog('Failed to create new user', error);
                console.error('Failed to create new user:', error);
            }
        );
    }
}
function dialogController(ACCESS_RIGHTS, teamId, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.accessRights = ACCESS_RIGHTS;
    vm.maxAccess = localStorage.gbbgUserAccess || '101';

    vm.user = {
        admin: (localStorage.gbbgUserAccess < 501).toString(),
        hasWriteAccess: 'full'
    };

    vm.selectedTeams = [];
    if (teamId && teamId != null && teamId.length) vm.selectedTeams.push(teamId);

    vm.cancel = $mdDialog.cancel;
    vm.createMember = createMember;

    function createMember() {
        $mdDialog.hide({
            user: vm.user,
            selectedTeams: vm.selectedTeams
        });
    }
}

export { gbbgNewUser };
