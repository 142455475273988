const gbbgClaimValidationsProgress = {
    template: `
    <md-progress-linear md-mode="indeterminate" ng-if="vm.parent.processing"></md-progress-linear>
    `,
    require: { parent: '^gbbgClaimValidations' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgClaimValidationsProgress };
