function FlagService($http, $timeout) {
    const service = {
        flags: [],
        getAllFlags() {
            return $http.get('/api/flags').then((response) => {
                if (response.status != 200) throw response;
                service.flags.splice(0);
                response.data.map(flag => service.flags.push(flag));
                return response.data;
            }, failure);
        },
        getFlag(id) {
            return $http.get(`/api/flag/${id}`).then(success, failure);
        },
        addFlag(flag) {
            return $http.post('/api/flag', flag).then((response) => {
                if (response.status != 200) throw response;
                service.flags.push(response.data);
                return response.data;
            }, failure);
        },
        updateFlag(flag) {
            const id = flag._id;
            return $http.put(`/api/flag/${id}`, flag).then((response) => {
                if (response.status != 200) throw response;
                for (let i = 0; i < service.flags.length; i++) {
                    const thisFlag = service.flags[i];
                    if (thisFlag._id === response.data._id) {
                        thisFlag.name = response.data.name;
                        thisFlag.keywords.splice(0);
                        response.data.keywords.map(key => thisFlag.keywords.push(key));
                        thisFlag.keywordString = response.data.keywordString;
                        break;
                    }
                }
                return response.data;
            }, failure);
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { FlagService };
