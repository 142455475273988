function emailSendDialogController(clientId, claimId, attachments, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.email = {
        cc: [],
        bcc: []
    };

    vm.clientId = clientId;
    vm.claimId = claimId;

    vm.sendEmail = sendEmail;

    activate();

    function activate() {
        if (attachments && attachments.length) {

        }
    }

    function sendEmail() {
        if ((!vm.clientId && !vm.claimId) || !vm.selectedRecipients || !vm.selectedRecipients.length) return;
        $mdDialog.hide({
            email: vm.email,
            recipients: vm.selectedRecipients,
            sender: vm.selectedSender
        });
    }
}

export { emailSendDialogController };
