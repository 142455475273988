import { preClaimListViewController } from './pre-claim_list_view_controller';

function preClaimRoutes($stateProvider) {
    'ngInject';
    $stateProvider
    .state('root.auth.preclaims', {
        url: "/pre-claims",
        abstract: true
    })
    .state('root.auth.preclaims.all', {
        url: '?status&from&to&source',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/pre-claim/list.html',
                controller: preClaimListViewController,
                controllerAs: 'vm'
            }
        }
    });
}

export { preClaimRoutes };
