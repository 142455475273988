import { gbbgDeleteSource } from './source_delete';
import { gbbgSourceDetails } from './source_details';
import { gbbgNewSource } from './source_new_button';
import { sourceRoutes } from './source_routes';
import { gbbgEnquirySourceSelect } from './source_select';
import { SourceService } from './source_service';
import { gbbgUpdateSource } from './source_update';

// Module for enquiry sources
angular
.module('source', [])
.config(sourceRoutes)
.component('gbbgDeleteSource', gbbgDeleteSource)
.component('gbbgSourceDetails', gbbgSourceDetails)
.component('gbbgNewSource', gbbgNewSource)
.component('gbbgEnquirySourceSelect', gbbgEnquirySourceSelect)
.component('gbbgUpdateSource', gbbgUpdateSource)
.factory('SourceService', SourceService);

export default 'source';
