function gbbgDisableInputs($timeout) {
    return {
        restrict: 'A',
        link: {
            post(scope, elem, attrs) {

                let inputs = [];

                $timeout(activate, attrs.delay || 0);

                function activate() {
                    inputs = angular
                    .element(
                        elem[0].querySelectorAll('input:not([disabled]), textarea, select, button, md-select')
                    );

                    if (attrs.gbbgDisableInputs == true) disableAll();
                    else if (attrs.gbbgDisableInputs == false || attrs.gbbgDisableInputs == 'false') enableAll();
                    else if (!attrs.gbbgDisableInputs.length < 1) disableAll();

                    attrs.$observe('gbbgDisableInputs', val => {
                        (val && val.length > 0) ? enableAll() : disableAll();
                    });
                }

                function enableAll() {
                    if (inputs && inputs.length) inputs.attr('disabled', false);
                    else elem[0].disabled = false;
                }

                function disableAll() {
                    if (inputs && inputs.length) inputs.attr('disabled', true);
                    else elem.attr('disabled', true);
                }
            }
        }
    }
}
function disableIfAgent($timeout, AuthService) {
    return {
        restrict: 'A',
        link: {
            post(scope, elem, attrs) {
                let inputs = [];

                if (AuthService.isAgent()) {
                    $timeout(activate, attrs.delay || 0);
                }

                function activate() {
                    elem.attr('disabled', true);
                    elem[0].disabled = true;
                    elem.attr('ngDisabled', true);
                    elem[0].ngDisabed = true;
                    elem.on('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    });
                    const inputs = angular
                    .element(
                        elem[0].querySelectorAll('input:not([disabled]), textarea, select, button, md-select')
                    );
                    if (inputs && inputs.length) inputs.attr('disabled', true);
                }
            }
        }
    };
}

export { gbbgDisableInputs, disableIfAgent };
