const gbbgCertSelect = {
    template: `
    <div layout="row">
        <md-input-container flex>
            <label>Certificate</label>
            <md-select
                disable-if-agent
                ng-model="vm.selectedCert"
                ng-change="vm.openDialog()"
                ng-disabled="!vm.certs.length"
            >
                <md-option ng-value="vm.emptyObject"><em>None</em></md-option>
                <md-option
                    ng-repeat="cert in vm.certs"
                    ng-value="cert"
                >
                    <strong>{{cert.certNo}}</strong>
                    <span>{{cert.dateExp | date:'MMM d, y'}}</span>
                </md-option>
            </md-select>
        </md-input-container>
        <ng-transclude></ng-transclude>
    <div>
    `,
    transclude: true,
    bindings: { certs: '<', selectedCert: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($scope, $mdDialog, ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let originalCert = {};

    vm.emptyObject = {};

    vm.openDialog = openDialog;

    $scope.$on('cert.update', (e, data) => {
        if (!data) return;
        updateCert(data);
        vm.selectedCert = data;
    });

    vm.$onInit = activate;

    function activate() {
        vm.certs = vm.certs || [];
        if (!vm.selectedCert) return;
        /* Find a cert in vm.certs that matches vm.selectedCert. Necessary because
        the certs in vm.certs have an '_id' property that isn't in vm.selectedCert
        and therefore the equality check fails. */
        const certAsArray = vm.certs.filter(cert => (
            cert.issuingAuthority === vm.selectedCert.issuingAuthority &&
            cert.dateExp === vm.selectedCert.dateExp &&
            cert.dateCreated === vm.selectedCert.dateCreated
        ));
        if (certAsArray.length === 1) vm.selectedCert = certAsArray[0];
        originalCert = angular.copy(vm.selectedCert);
    }

    function openDialog() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to change the certificate?')
            .textContent('Changes will take effect immediately.')
            .ariaLabel('Change certificate')
            .ok('Change cert')
            .cancel('Cancel')
        ).then(
            () => updateCert(vm.selectedCert),
            () => vm.selectedCert = angular.copy(originalCert)
        );
    }

    function updateCert(cert) {
        ClaimService.setCert(null, cert).then(
            data => ClaimService.addTimelineActivity(data),
            error => {
                ErrorHandler.showDialog('Failed to update certificate', error);
                console.error('Failed to update certificate:', error);
            }
        );
    }
}

export { gbbgCertSelect };
