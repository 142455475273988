const gbbgDeleteClaimInvoice = {
    template: `
    <md-button class="md-icon-button" ng-click="vm.deleteInvoice()">
        <md-icon>delete</md-icon>
        <md-tooltip>Delete invoice</md-tooltip>
    </md-button>
    `,
    bindings: { invoiceId: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.deleteInvoice = deleteInvoice;

    function deleteInvoice() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to delete this invoice?')
            .textContent('Changes will take effect immediately and cannot be undone.')
            .ariaLabel('Delete invoice')
            .ok('Delete invoice')
            .cancel('Cancel')
        ).then(() => {
            ClaimService.deleteInvoice(vm.invoiceId).then((data) => {
                ClaimService.setClaim(data)
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Invoice deleted successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to delete invoice', error);
                console.error('Failed to delete invoice:', error);
            })
        }, () => { });
    }
}

export { gbbgDeleteClaimInvoice };
