const gbbgSuspendUserButton = {
    template: `
    <md-button
        ng-class="{
            'md-warn': vm.active,
            'md-primary': !vm.active
        }"
        ng-click="vm.changeStatus()"
    >{{ vm.active ? 'Suspend' : 'Activate' }}</button>
    `,
    bindings: { active: '=', userId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $stateParams, ErrorHandler, UserService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.changeStatus = changeStatus;

    function changeStatus() {
        (vm.active) ? suspendUser() : activateUser();
    }

    function suspendUser() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to suspend this user?')
            .textContent('Suspending a user will prevent them from logging into the system and restrict their access indefinitely. This account can be reactivated at a later date.')
            .ariaLabel('Suspend user')
            .ok('Suspend user')
            .cancel('Cancel')
        ).then(() => {
            UserService.changeStatus(vm.userId || $stateParams.id, false).then(
                data => vm.active = !vm.active,
                error => {
                    ErrorHandler.showDialog('Failed to suspend user', error);
                    console.error('Failed to suspend user:', error);
                }
            );
        }, () => {});
    }

    function activateUser() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to activate this account?')
            .textContent('Activating this account will restore access for the user. Depending on their access rights, their use will not be restricted.')
            .ariaLabel('Activate account')
            .ok('Activate')
            .cancel('Cancel')
        ).then(() => {
            UserService.changeStatus(vm.userId || $stateParams.id, true).then(
                data => vm.active = !vm.active,
                error => {
                    ErrorHandler.showDialog('Failed to activate user', error);
                    console.error('Failed to activate user:', error);
                }
            );
        }, () => {});
    }
}

export { gbbgSuspendUserButton };
