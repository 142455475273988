function TeamService($http) {
    'ngInject';
    const service = {
        team: {},
        getAllTeams(filters = {}) {
            const { sort = 'referenceNo', query, admin } = filters;
            return $http.get(`/api/teams?sort=${sort}&query=${query}&admin=${admin}`).then((response) => {
                if (response.status != 200) throw response;
                service.team = response.data;
                return response.data;
            }, failure);
        },
        getTeam(teamId) {
            return $http.get(`/api/team/${teamId}`).then(success, failure);
        },
        getTeamsByUserId(userId) {
            return $http.get(`/api/teams/user/${userId}`).then(success, failure);
        },
        createTeam(team) {
            return $http.post('/api/team', team).then(success, failure);
        },
        updateTeam(teamId, teamObj) {
            return $http.put(`/api/team/${teamId}`, teamObj).then(success, failure);
        },
        addAdmins(teamId, userIds) {
            return $http.put(`/api/team/${teamId}/admins`, { userIds }).then(success, failure);
        },
        removeAdmins(teamId, userIds) {
            return $http.put(`/api/team/${teamId}/admins/remove`, { userIds }).then(success, failure);
        },
        addMembers(teamId, userIds) {
            return $http.put(`/api/team/${teamId}/members`, { userIds }).then(success, failure);
        },
        createMember() {
            // return $http.put()
        },
        getNonMembers(teamId) {
            return $http.get(`/api/team/${teamId}/nonmembers`).then(success, failure);
        },
        hasWriteAccess() {
            return new Promise(resolve => {
                const { team } = service;
                const { gbbgUserId, gbbgUserAccess } = localStorage;

                if (!team || !team._id) return resolve(false);
                else if (gbbgUserAccess >= '701') return resolve(true)
                else {
                    let isAdmin = false;
                    team.administrators.map(a => { if (a._id === gbbgUserId) isAdmin = true });
                    resolve(isAdmin);
                }
            });
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { TeamService };
