import { default as Assign } from './assign/assign';

import { gbbgLeadActions } from './lead_actions';
import { gbbgLeadEstimates } from './lead_estimates';
import { LeadHandler } from './lead_handler';
import { leadFlaggedViewController } from './lead_flagged_view_controller';
import { gbbgLeadForceValid } from './lead_force_valid';
import { gbbgLeadsFilters } from './lead_leads_filters';
import { leadListViewController } from './lead_list_view_controller';
import { newleadViewController } from './lead_new_view_controller';
import { leadRoutes } from './lead_routes';
import { LeadService } from './lead_service';
import { gbbgLeadActivateButton } from './lead_set_active_button';
import { gbbgLeadStatusReason } from './lead_status_reason';
import { gbbgLeadStatus } from './lead_status';
import { gbbgLeadTabsDetails, gbbgLeadTabsContacts, gbbgLeadTabsNotes } from './lead_tabs';
import { gbbgTogglePaperwork } from './lead_toggle_paperwork';
import { leadUncontactedViewController } from './lead_uncontacted_view_controller';
import { leadViewController } from './lead_view_controller';

angular
.module('lead', [
    Assign
])
.config(leadRoutes)
.factory('LeadService', LeadService)
.factory('LeadHandler', LeadHandler)
.component('gbbgLeadActions', gbbgLeadActions)
.component('gbbgLeadEstimates', gbbgLeadEstimates)
.component('gbbgLeadForceValid', gbbgLeadForceValid)
.component('gbbgLeadsFilters', gbbgLeadsFilters)
.component('gbbgLeadActivateButton', gbbgLeadActivateButton)
.component('gbbgLeadStatusReason', gbbgLeadStatusReason)
.component('gbbgLeadStatus', gbbgLeadStatus)
.component('gbbgLeadTabsDetails', gbbgLeadTabsDetails)
.component('gbbgLeadTabsContacts', gbbgLeadTabsContacts)
.component('gbbgLeadTabsNotes', gbbgLeadTabsNotes)
.component('gbbgTogglePaperwork', gbbgTogglePaperwork)
.controller('leadFlaggedViewController', leadFlaggedViewController)
.controller('leadListViewController', leadListViewController)
.controller('newleadViewController', newleadViewController)
.controller('leadUncontactedViewController', leadUncontactedViewController)
.controller('leadViewController', leadViewController);

export default 'lead';
