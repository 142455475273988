function teamViewController($mdToast, $stateParams, $timeout, ErrorHandler, TeamService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.isLoaded = false;
    vm.disableAll = false;
    vm.isAdmin = false;

    vm.team = TeamService.team;

    vm.update = update;

    activate();

    function activate() {
        if (!$stateParams.id) return;
        TeamService.getTeam($stateParams.id).then(
            data => {
                vm.isLoaded = true;
                TeamService.team = data;
                vm.team = TeamService.team;
                TeamService.hasWriteAccess().then(data => vm.isAdmin = data);
            },
            error => {
                vm.disableAll = true;
                ErrorHandler.showDialog('Failed to load team', error);
                console.error('Failed to load team:', error);
            }
        );
    }

    function update() {
        if (!$stateParams.id || !vm.team._id) return;
        TeamService.updateTeam($stateParams.id, vm.team).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Team updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                ErrorHandler.showDialog('Failed to update team', error);
                console.log('Failed to update team:', error);
            }
        );
    }
}

export { teamViewController };
