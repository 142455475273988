const gbbgEnquirySourceSelect = {
    template($attrs) {
        const multiple = $attrs.multiple ? 'multiple' : '';
        return `
        <div layout="column" flex>
            <md-input-container class="no-margin" flex="100" style="max-width: 300px;">
                <label>Enquiry source</label>
                <md-select
                    ng-model="vm.source"
                    ng-change="vm.updateSource(vm.source)"
                    ng-disabled="!vm.enquirySources.length"
                    aria-label="Enquiry source selection"
                    ${multiple}
                >
                    <md-option ng-value="'null'">No enquiry source</md-option>
                    <md-option
                        ng-if="vm.enquirySources.length"
                        ng-repeat="source in vm.enquirySources"
                        ng-value="source._id"
                    > {{::vm.concatName(source)}} </md-option>
                </md-select>
            </md-input-container>
        </div>
        `;
    },
    bindings: { source: "=", onChange: '&' },
    controller,
    controllerAs: 'vm'
};
function controller(ErrorHandler, SourceService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.enquirySources = [];

    vm.concatName = concatName;
    vm.updateSource = updateSource;

    vm.$onInit = activate;

    function activate() {
        SourceService.getEnquirySources().then(
            data => vm.enquirySources = data,
            error => {
                ErrorHandler.showDialog('Failed to load enquiry sources', error);
                console.error('Failed to load enquiry sources:', error);
            }
        );
    }

    function updateSource(sourceId) {
        if (Array.isArray(sourceId)) {
            const sources = vm.enquirySources.filter(source => source._id == sourceId);
            if (vm.onChange) vm.onChange({ sources, sourceId });
        } else {
            const source = vm.enquirySources.filter(source => source._id == sourceId)[0];
            if (vm.onChange) vm.onChange({ source, sourceId });
        }
    }

    function concatName(source) {
        const { name, address = {} } = source;
        let string = name;
        if (address.city) string += ` | ${address.city}`;
        if (address.country) string += ` | ${address.country}`;
        return string || '';
    }
}

export { gbbgEnquirySourceSelect };
