const gbbgEmailRecipientSelect = {
    template: `
    <md-input-container flex>
        <label>Recipient(s)</label>
        <md-select ng-model="vm.selectedRecipients" multiple>
            <md-option
                ng-repeat="recipient in vm.recipients"
                ng-if="recipient && recipient.email && recipient.email.length > 0"
                ng-value="recipient.email"
            >{{recipient.email}}</md-option>
        </md-select>
    </md-input-container>
    `,
    bindings: {
        recipients: '<',
        selectedRecipients: '=',
        clientId: '@',
        // claimId: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller(ClientService, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$onInit = activate;

    function activate() {
        vm.selectedRecipients = [];
        if (!vm.recipients) vm.recipients = [];
        if (!vm.clientId) vm.clientId = (ClaimService.claim && ClaimService.claim.client) ? ClaimService.claim && ClaimService.claim.client._id : '';
        if (vm.recipients.length === 0 && (vm.clientId && vm.clientId.length)) {
            ClientService.getContacts(vm.clientId).then(
                data => {
                    vm.recipients = data.filter(contact => contact.email && contact.email.length);
                    if (vm.recipients.length > 0) vm.selectedRecipients.push(vm.recipients[0].email);
                },
                error => {
                    ErrorHandler.showDialog('Failed to get contacts for client', error);
                    console.error(`Failed to get contacts for client '${vm.clientId}':`, error);
                }
            );
        }
    }
}

export { gbbgEmailRecipientSelect };
