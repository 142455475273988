function accessListViewController(AccessService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$onInit = activate;

    function activate() { AccessService.clearRecords(); }
}

export { accessListViewController };
