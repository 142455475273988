function SidenavController($state) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.$state = $state;

    vm.sections = [
        {
            title: 'Leads',
            states: [
                { name: 'New lead', link: 'root.auth.leads.new' },
                { name: 'All leads', link: 'root.auth.leads.all', activeStates: ['root.auth.leads.lead'] },
                { name: 'Flagged leads', link: 'root.auth.leads.flagged({ lockedFilter: true, agentFilter: false })' },
                { name: 'Uncontacted leads', link: 'root.auth.leads.contact' },
            ]
        },
        {
            title: 'Active companies',
            states: [
                { name: 'All active records', link: 'root.auth.active.all', activeStates: ['root.auth.active.client'] },
                { name: 'Potential claims', link: 'root.auth.preclaims.all', activeStates: ['root.auth.preClaims'] },
                { name: 'All claims', link: 'root.auth.claims.all', activeStates: ['root.auth.claims.claim'] },
                { name: 'Uncontacted active clients', link: 'root.auth.active.uncontacted' }
            ]
        },
        {
            title: 'Management tools',
            states: [
                { name: 'My dashboard', link: `root.auth.management.users.user({ id: '${localStorage.gbbgUserId}' })` },
                { name: 'Users', link: 'root.auth.management.users.all', hide, access: 201 },
                { name: 'Teams', link: 'root.auth.management.teams.all', hide, access: 201 },
                { name: 'Assign leads by country', link: 'root.auth.management.assign.country', hide, access: 201 },
                { name: 'Re-assign leads from user', link: 'root.auth.management.assign.users', hide, access: 201 },
                { name: 'Enquiry sources', link: 'root.auth.management.sources', hide, access: 501 },
                { name: 'Issuing authorities', link: 'root.auth.management.authorities', hide, access: 501 },
                { name: 'Suppliers', link: 'root.auth.management.suppliers', hide, access: 501 },
                { name: 'Email templates', link: 'root.auth.management.emails', hide, access: 201 },
                { name: 'Email errors', link: 'root.auth.management.email_errors', hide, access: 501 },
                { name: 'Eligibility flags', link: 'root.auth.management.flags', hide, access: 201 },
                { name: 'Reports', link: `root.auth.management.reports`, hide, access: 201, activeStates: ['root.auth.management.reports.claims', 'root.auth.management.reports.client'] },
                { name: 'Access records', link: `root.auth.management.access`, hide, access: 701 }
            ],
        },
    ];

    vm.$onInit = activate;

    function activate() {
        vm.sections.map(section => section.states.map(state => state.isActive = isActive));
    }

    function hide(access = 701) {
        return !(localStorage.gbbgUserAccess && localStorage.gbbgUserAccess < access);
    }

    function isActive(state) {
        const current = $state.current.name;
        let states = [ this.link ];
        if (this.activeStates) states = [...states, ...this.activeStates];
        return states.includes(current);
    }
}

export { SidenavController };
