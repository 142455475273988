const gbbgActiveClientSaveButton = {
    template: `
    <md-button
        aria-label="Save"
        class="md-fab md-fab-bottom-right md-primary"
        ng-click="vm.save()"
        ng-disabled="vm.processing"
    > <md-icon md-font-library="material-icons">save</md-icon> </md-button>
    `,
    bindings: { client: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdToast, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.save = save;

    function save() {
        vm.processing = true;
        if (!vm.client || !vm.client._id) return;
        ActiveService.saveClient(vm.client).then(
            data => {
                showToast('Saved client successfully!')
                vm.processing = false;
            },
            error => {
                vm.processing = false;
                ErrorHandler.showDialog('Failed to save client', error);
                console.error("Failed to save client:", error);
            }
        );
    }

    function showToast(message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message || '')
            .position('top right')
            .hideDelay(4000)
        );
    }
}

export { gbbgActiveClientSaveButton };
