const gbbgSupplierSearch = {
    template: `
    <md-autocomplete
        flex
        required
        md-selected-item="vm.selectedItem"
        md-search-text="vm.searchText"
        md-search-text-change="vm.searchSuppliers(vm.searchText)"
        md-items="item in vm.items"
        md-item-text="item.name"
        md-selected-item-change="vm.setSupplier(item)"
        md-floating-label="Supplier name"
        md-menu-class="autocomplete-custom-template"
        md-menu-container-class="custom-container"
    >
        <md-item-template>
            <div class="item-title" md-highlight-text="vm.searchText">{{item.name}}</div>
            <div class="item-metadata" md-highlight-text="vm.searchText">{{item.address.branch}}, {{item.address.country}}</div>
        </md-item-template>
    </md-autocomplete>
    `,
    bindings: { country: '@', supplier: '=' },
    controller,
    controllerAs: 'vm'
};
function controller(SupplierService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.items = [];

    vm.searchSuppliers = searchSuppliers;
    vm.setSupplier = setSupplier;

    vm.$onInit = activate;

    function activate() {
        if (vm.supplier && vm.supplier.name) vm.searchText = vm.supplier.name;
    }

    function searchSuppliers(searchText) {
        if (vm.supplier) vm.supplier._id = undefined;
        vm.supplier.name = searchText || '';
        if (!searchText) return;
        SupplierService.searchSuppliers(searchText, vm.country).then(
            data => vm.items = data,
            error => console.error('Failed to get client names:', error)
        );
    }

    function setSupplier(supplier) {
        if (!supplier) return;
        const { _id, name, address, referenceNo, taxNo } = supplier;
        if (!vm.supplier) vm.supplier = {};
        vm.supplier._id = _id;
        vm.supplier.name = name;
        if (!vm.supplier.address) vm.supplier.address = {};
        vm.supplier.address.branch = address.branch;
        vm.supplier.address.country = address.country;
        vm.supplier.taxNo = taxNo;
    }
}

export { gbbgSupplierSearch };
