function SourceService($http) {
    'ngInject';
    const service = {
        sources: [],
        getEnquirySources() {
            return $http.get('/api/sources').then((response) => {
                if (response.status != 200) throw response;
                service.sources.splice(0);
                response.data.map(source => service.sources.push(source));
                return response.data;
            }, failure);
        },
        getSource(id) {
            return $http.get(`/api/sources/${id}`).then(success, failure);
        },
        newSource(source) {
            return $http.post('/api/sources', source).then(success, failure);
        },
        updateSource(source) {
            const { _id } = source;
            return $http.put(`/api/sources/${_id}`, source).then(success, failure);
        },
        deleteSource(sourceId) {
            return $http.delete(`/api/sources/${sourceId}`).then(success, failure);
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { SourceService };
