const gbbgClientTimelineActions = {
    template: `
    <div layout="column">
        <form name="timelineActivityForm" layout="row" layout-align="center center">
            <md-input-container flex class="no-margin">
                <label>Custom timeline message</label>
                <textarea
                    rows="2"
                    name="activity"
                    maxlength="150"
                    md-maxlength="150"
                    ng-model="vm.activityDetails"
                    required="required"
                ></textarea>
            </md-input-container>
            <md-button
                type="submit"
                class="md-primary md-raised"
                ng-disabled="!vm.activityDetails || timelineActivityForm.$invalid"
                ng-click="vm.addToTimeline('update', vm.activityDetails)"
            >Submit</md-button>
        </form>
        <div class="row">
            <div class="small-12 medium-6 columns">
                <div class="button-group small">
                    <div class="input-group-button">
                        <a class="button secondary" ng-click="vm.addToTimeline('call-out')">
                            <i class="fa fa-phone"></i>
                            <i class="fa fa-arrow-right"></i>
                        </a>
                        <a class="button secondary" ng-click="vm.addToTimeline('call-in')">
                            <i class="fa fa-phone"></i>
                            <i class="fa fa-arrow-left"></i>
                        </a>
                        <a class="button secondary" ng-click="vm.addToTimeline('email-out')">
                            <i class="fa fa-envelope"></i>
                            <i class="fa fa-arrow-right"></i>
                        </a>
                        <a class="button secondary" ng-click="vm.addToTimeline('email-in')">
                            <i class="fa fa-envelope"></i>
                            <i class="fa fa-arrow-left"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    bindings: { clientId: '@', timeline: '=' },
    controller,
    controllerAs: 'vm'
}
function controller(ClientService, ErrorHandler) {
    "ngInject"
    /*validthis:true*/
    const vm = this

    vm.activityDetails = ''

    vm.addToTimeline = addToTimeline

    function addToTimeline(activity, details) {
        if (!activity || !vm.clientId) return;
        ClientService.addToTimeline(vm.clientId, activity, details).then(
            data => {
                if (vm.timeline) vm.timeline.unshift(data)
                vm.activityDetails = ''
            },
            error => {
                ErrorHandler.showDialog('Timeline update failed', error)
                console.error("An error occurred while adding an action to the timeline:", error)
            }
        )
    }
}

export { gbbgClientTimelineActions }
