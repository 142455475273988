const gbbgUpdateAuthority = {
    template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">open_in_browser</md-icon>
        <md-tooltip md-direction="bottom">Edit authority</md-tooltip>
    </md-button>
    `,
    bindings: { authorityId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ErrorHandler, AuthorityService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        AuthorityService.getAuthority(vm.authorityId).then((data) => {
            $mdDialog.show({
                template,
                locals: { authority: data },
                controller: dialogController,
                controllerAs: 'vm'
            }).then((authority) => updateAuthority(authority), () => {});
        }, (error) => {
            ErrorHandler.showDialog('Failed to get issuing authority', error);
            console.error('Failed to get issuing authority:', error);
        });
    }

    function updateAuthority(authority) {
        AuthorityService.updateAuthority(authority).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Issuing authority updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            error => {
                ErrorHandler.showDialog('Failed to update issuing authority', error);
                console.error('Failed to update issuing authority:', error);
            }
        );
    }

    const template = `
    <md-dialog aria-label="Update issuing authority" style="max-width:600px;">
        <form name="updateAuthorityForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Update issuing authority</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content class="md-padding" layout-padding>

                <gbbg-authority-details authority="vm.authority"></gbbg-authority-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.save()"
                    ng-disabled="updateAuthorityForm.$invalid"
                > Update authority </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController(authority, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.authority = authority;

    vm.cancel = $mdDialog.cancel;
    vm.save = save;

    function save() {
        $mdDialog.hide(vm.authority);
    }
}

export { gbbgUpdateAuthority }
