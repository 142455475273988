const gbbgAuthorityDetails = {
    template: `
    <div layout="column" ng-if="vm.authority">
        <md-input-container>
            <label>Name</label>
            <input type="text" ng-model="vm.authority.name" required/>
        </md-input-container>
        <md-input-container>
            <label>Location / street address</label>
            <input type="text" ng-model="vm.authority.address.location" required/>
        </md-input-container>
        <gbbg-country-select country="vm.authority.address.country" required="true"></gbbg-country-select>
        <div class="row">
            <md-input-container flex>
                <label>Email address</label>
                <input type="email" ng-model="vm.authority.email"/>
            </md-input-container>
            <md-input-container>
                <label>Phone number</label>
                <input type="number" ng-model="vm.authority.phone"/>
            </md-input-container>
        </div>
    </div>
    `,
    bindings: { authority: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgAuthorityDetails };
