const gbbgClientPreview = {
    template: `
    <md-button class="md-secondary md-icon-button" ng-click="vm.preview($event)" aria-label="call">
        <md-icon md-font-library="material-icons">info_outline</md-icon>
        <md-tooltip md-direction="bottom">Preview</md-tooltip>
    </md-button>
    `,
    bindings: { client: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    const template = `
        <md-dialog aria-label="{{vm.client.name}}">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2 ng-bind="::vm.client.name"></h2>
                    <span flex></span>
                    <gbbg-close-dialog/>
                </div>
            </md-toolbar>
            <md-dialog-content>
                <div class="md-dialog-content">
                    <p><b>Client ID:</b> {{vm.client._id}}</p>
                    <p><b>Client ref:</b> {{vm.client.referenceNo}}</p>
                    <p>Client ref: {{vm.client.referenceNo}}</p>
                </div>
            </md-dialog-content>
        </md-dialog>
    `;

    vm.preview = preview;

    function preview(ev) {
        if (!vm.client) return;
        $mdDialog.show({
            controller: dialogController,
            controllerAs: 'vm',
            locals: { client: vm.client },
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true,
            template
        });
    }

    function dialogController(client) {
        'ngInject';
        /*validthis:true*/
        const vm = this;

        vm.client = client;
    }
}

export { gbbgClientPreview };
