function AuthorityService($http) {
    'ngInject';
    return {
        getAuthoritiesByCountry(country) {
            return $http.get(`/api/authority/country/${country}`).then(success, failure);
        },
        getAuthority(authorityId) {
            return $http.get(`/api/authority/${authorityId}`).then(success, failure);
        },
        newAuthority(authority) {
            return $http.post('/api/authority', authority).then(success, failure);
        },
        updateAuthority(authority) {
            if (!authority._id) return Promise.reject('Issuing authority ID missing.');
            return $http.put(`/api/authority/${authority._id}`, authority).then(success, failure);
        },
        deleteAuthority(authorityId) {
            return $http.delete(`/api/authority/${authorityId}`).then(success, failure);
        }
    };
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { AuthorityService };
