import { gbbgAddCert } from './active_certs_add'
import { gbbgActiveCerts } from './active_certs'
import { gbbgClientClaimSheet } from './active_claims_sheet'
import { gbbgClientClaimsSummary } from './active_claims_summary'
import { gbbgClientClaimsTimelines } from './active_claims_timelines'
import { gbbgClientFamily } from './active_family'
import { gbbgClientIssues } from './active_issue'
import { activeRoutes } from './active_routes'
import { gbbgActiveClientSaveButton } from './active_save_button'
import { ActiveService } from './active_service'
import { gbbgClientTabsNotes } from './active_tabs'
import { gbbgAddUrn } from './active_urn_add'
import { gbbgActiveUrns } from './active_urns'

angular
.module('active', [])
.config(activeRoutes)
.directive('gbbgAddCert', gbbgAddCert)
.directive('gbbgAddUrn', gbbgAddUrn)
.component('gbbgActiveCerts', gbbgActiveCerts)
.component('gbbgClientClaimSheet', gbbgClientClaimSheet)
.component('gbbgClientClaimsSummary', gbbgClientClaimsSummary)
.component('gbbgClientClaimsTimelines', gbbgClientClaimsTimelines)
.component('gbbgClientFamily', gbbgClientFamily)
.component('gbbgClientIssues', gbbgClientIssues)
.component('gbbgActiveClientSaveButton', gbbgActiveClientSaveButton)
.component('gbbgClientTabsNotes', gbbgClientTabsNotes)
.component('gbbgActiveUrns', gbbgActiveUrns)
.factory('ActiveService', ActiveService)

export default 'active'
