function gbbgHideUnlessAdmin($timeout) {
    return {
        restrict: 'A',
        link
    };
    function link(scope, elem, attrs) {

        $timeout(activate);

        function activate() {
            const { gbbgUserAccess } = localStorage;

            if (gbbgUserAccess < 701) elem.remove();
        }
    }
}
function gbbgHideUnlessAccManager($timeout) {
    return {
        restrict: 'A',
        link
    };
    function link(scope, elem, attrs) {

        $timeout(activate);

        function activate() {
            const { gbbgUserAccess } = localStorage;

            if (gbbgUserAccess < 501) elem.remove();
        }
    }
}
function gbbgHideUnless($timeout) {
    return {
        restrict: 'A',
        link
    };
    function link(scope, elem, attrs) {

        $timeout(activate);

        function activate() {
            const { gbbgUserAccess = 101 } = localStorage;
            const { gbbgHideUnless = 901 } = attrs;

            if (gbbgUserAccess < gbbgHideUnless) elem.remove();
        }
    }
}

export { gbbgHideUnless, gbbgHideUnlessAdmin, gbbgHideUnlessAccManager };
