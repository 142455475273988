const gbbgClaimSchedule = {
    template: `
    <md-button
        class="md-primary"
        ng-click="vm.getSchedule()"
        ng-disabled="vm.parent.processing"
    >View schedule</md-button>
    `,
    require: { parent: '^gbbgClaimValidations' },
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($stateParams, $timeout, ClaimService, ErrorHandler, PdfService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.getSchedule = getSchedule;

    function getSchedule() {
        if (!vm.claim || !vm.claim._id) return;
        vm.parent.processing = true;

        // Agents cannot save/update a claim, so we need to bypass this step when they request the schedule pdf
        const isAgent = (!localStorage || !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 301);
        const processes = (isAgent) ? [ ClaimService.getSchedule($stateParams.id) ] : [
            ClaimService.saveClaim(vm.claim),
            ClaimService.checkValidity($stateParams.id),
            ClaimService.getSchedule($stateParams.id)
        ];

        Promise.all(processes).then(
            (data) => {
                $timeout(() => vm.parent.processing = false);
                // const url = window.location.getOriginRedirectUrl();
                // window.open(`${url}/pdf/schedule/${$stateParams.id}?auth_token=${localStorage.gbbgToken}`);
                // PdfService.create('schedule', data[data.length-1]) // last response object from promise.all
            }, error => {
                vm.parent.processing = false;
                ErrorHandler.showDialog('Failed to get invoice schedule', error);
                console.error('Failed to get invoice schedule:', error);
            }
        );
    }
}

export { gbbgClaimSchedule };
