const gbbgClientFeesUpdate = {
    template: `
    <md-button
        class="md-primary md-raised"
        ng-disabled="vm.parent.feeForm.$invalid || vm.processing"
        ng-click="vm.update()"
    >Update fees</md-button>
    `,
    require: { parent: '^gbbgClientFees' },
    bindings: { clientId: '@', fees: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ActiveService, ClientService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.update = update;

    function update() {
        vm.processing = true;
        if (checkForDuplicates()) {
            $mdDialog.show(
                $mdDialog
                .alert()
                .clickOutsideToClose(true)
                .title('Duplicates found')
                .textContent('\'From\' values must be unique.')
                .ariaLabel('Found duplicate figures')
                .ok('Okay')
            ).then(() => vm.processing = false);
        } else {
            $mdDialog.show(
                $mdDialog
                .confirm()
                .title('Update fees for this client?')
                .textContent('These changes will affect any future claims you make for this client, but do not affect existing/past claims.')
                .ariaLabel('Update client fees')
                .ok('Update fees')
                .cancel('Cancel')
            ).then(() => {
                ClientService.updateFees(vm.clientId, vm.fees).then(
                    data => {
                        vm.processing = false;
                        ActiveService.addTimelineAction(data);
                        $mdToast.show(
                            $mdToast.simple()
                            .textContent('Updated client fees successfully!')
                            .position('top right')
                            .hideDelay(4000)
                        );
                    },
                    error => {
                        vm.processing = false;
                        ErrorHandler.showDialog('Failed to update fees', error);
                        console.error(`Failed to update fees for client '${vm.clientId}':`, error);
                    }
                );
            }, () => {});
        }
    }

    function checkForDuplicates() {
        return vm.fees.map(fee => fee.from).some((fee, idx, arr) => arr.indexOf(fee) != idx);
    }
}

export { gbbgClientFeesUpdate };
