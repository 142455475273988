import { gbbgAddTeamMemberButton } from './team_add_member_button';
import { gbbgTeamAdmins } from './team_admins';
import { gbbgTeamDetails } from './team_details';
import { gbbgTeamListFilters } from './team_list_filters';
import { teamListViewController } from './team_list_view_controller';
import { gbbgTeamMembers } from './team_members';
import { gbbgNewTeam } from './team_new_button';
import { teamRoutes } from './team_routes';
import { gbbgTeamsSelect } from './team_select';
import { TeamService } from './team_service';
import { teamViewController } from './team_view_controller';

angular.module('management.team', [])
.config(teamRoutes)
.controller('teamListViewController', teamListViewController)
.controller('teamViewController', teamViewController)
.component('gbbgAddTeamMemberButton', gbbgAddTeamMemberButton)
.component('gbbgNewTeam', gbbgNewTeam)
.component('gbbgTeamAdmins', gbbgTeamAdmins)
.component('gbbgTeamListFilters', gbbgTeamListFilters)
.component('gbbgTeamDetails', gbbgTeamDetails)
.component('gbbgTeamMembers', gbbgTeamMembers)
.component('gbbgTeamsSelect', gbbgTeamsSelect)
.factory('TeamService', TeamService);

export default 'management.team';
