const gbbgClientAgentInvoice = {
    template: `
    <md-button class="md-accent" ng-click="vm.openDialog('client')"> New client invoice </md-button>
    <md-button class="md-accent"ng-click="vm.openDialog('agent')"> New agent invoice </md-button>
    `,
    require: { parent: '^gbbgClaimRefundCharges' },
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $stateParams, $timeout, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog(invoiceType) {
        $mdDialog.show({
            templateUrl: '/partials/templates/claim/refund_invoice_dialog.html',
            locals: {
                claim: vm.claim,
                invoiceType: invoiceType
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((invoice) => {
            invoice.invoiceType = invoiceType;
            getInvoice(invoice);
        }, () => {});
    }

    function getInvoice(invoice) {
        vm.parent.processing = true;
        ClaimService.generateClientInvoice(invoice).then(
            (data) => {
                $timeout(() => vm.parent.processing = false);
                const url = window.location.getOriginRedirectUrl();
                window.open(`${url}/pdf/claim/${$stateParams.id}/invoice/${data.invoice.file}?auth_token=${localStorage.gbbgToken}`);
                if (invoice.changeStatus) {
                    ClaimService.claim.status.status = 'invoiced'
                }
            },
            (error) => {
                $timeout(() => vm.parent.processing = false);
                ErrorHandler.showDialog('Failed to get invoice for client', error);
                console.error('Failed to get invoice for client:', error);
            }
        );
    }
}
function dialogController(claim, invoiceType, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.invoice = {
        type: invoiceType,
        recipient: '',
        invoiceNo: '',
        date: new Date(),
        address: '',
        additionalCostsInfo: '', // e.g. postage, admin costs, HMRC appeal, etc.
        changeStatus: false
    };

    vm.cancel = $mdDialog.cancel;
    vm.getInvoice = getInvoice;

    activate();

    function activate() {
        if (invoiceType === 'agent') return;
        const { client, refund } = claim;
        if (client.primaryContact) vm.invoice.recipient = claim.client.primaryContact.name;
        if (client.address) {
            client.address.street2 = undefined;
            const { street1, street2, street3, city, country, zip } = client.address;
            if (street1) vm.invoice.address = street1;
            if (street2) vm.invoice.address += `\n${street2}`;
            if (street3) vm.invoice.address += `\n${street3}`;
            if (city) vm.invoice.address += `\n${city}`;
            if (country) vm.invoice.address += `\n${country}`;
            if (zip) vm.invoice.address += `\n${zip}`;
        }
    }

    function getInvoice() { $mdDialog.hide(vm.invoice); }
}

export { gbbgClientAgentInvoice };
