import { AuthService } from './auth_service';
import { SignInController } from './auth_signin_controller';
import { gbbgSignOut } from './auth_signout';

angular
.module('auth', [])
.controller('SignInController', SignInController)
.factory('AuthService', AuthService)
.component('gbbgSignOut', gbbgSignOut);

export default 'auth';
