const gbbgEmailTemplateKey = {
    template: `
    <md-list class="md-dense">
        <md-list-item ng-repeat="(key, value) in vm.keys">
            <p><b>{{key}}</b> {{ value }} </p>
        </md-list-item>
    </md-list>
    `,
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.keys = {
        '{{CLIENT_NAME}}': 'The name of the client/company you\'re emailing.',
        '{{CLIENT_REF}}': 'The client reference number in the database.',
        '{{CLIENT_ADDRESS}}': 'The address of the client/company you\'re emailing.',
        '{{CONTACT_NAME}}': 'The name of the primary contact for the client/company.',
        '{{CONTACT_EMAIL}}': 'The email of the primary contact for the client/company.',
        '{{CONTACT_PHONE}}': 'The phone number of the primary contact for the client/company.',
        '{{INELIGIBLE_REASON}}': 'The reason the client was flagged to ineligible/flagged, e.g. finance, education.',
        '{{STATUS_DETAILS}}': 'Any additional details which were provided when setting the status.',
        // CLAIM
        '{{CLAIM_REF}}': 'The reference number of the claim you\'re emailing about (requires claim).',
        '{{CLAIM_COUNTRY}}': 'The country in which the claim is being made (requires claim).',
        '{{CLAIM_TRAVEL_REASON}}': 'The reason for travel as specified in the claim record (requires claim).',
        '{{CLAIM_TRAVELLER}}': 'The primary traveller as specified in the claim record (requires claim).',
        '{{CLAIM_STATUS}}': 'The current status of the claim, e.g. pending, submitted, refunded, etc. (requires claim)',
        '{{CLAIM_STATUS_DETAILS}}': 'Any additional details which were provided when setting the claim status (requires claim).',
        '{{CLAIM_SUBMISSION_DATE}}': 'The most recent submission date for a claim (requires claim).',
        '{{CLAIM_TOTAL}}': 'The total amount of money claimed (calculated from invoices – requires claim).',
        '{{CLAIM_GBBG_FEE}}': 'The amount of the claimed value allocated to GBBG (requires claim).',
        '{{CLAIM_PENALTY_TOTAL}}': 'The total amount restyling charges applied to claim invoices (calculated from invoices – requires claim).',
        // CLAIM REFUND
        '{{REFUND_DUE_DATE}}': 'The estimed date of refund from the tax authority (requires submitted claim).',
        '{{REFUND_DATE_CUSTOMS}}': 'The date the claim was refund from the tax authority/customs (requires refunded claim).',
        '{{REFUND_DATE_CUSTOMER}}': 'The date the refund was given to the client (requires refunded claim).',
        '{{REFUND_DATE_INVOICE}}': 'The date the client was invoiced (requires refunded claim).',
        '{{REFUND_TOTAL}}': 'The total amount of money refunded from the tax authority/customs (requires refunded claim).',
        '{{REFUND_CLIENT_TOTAL}}': 'The total amount of money refunded to the client, minus penalties and charges.',
        '{{REFUND_LOCAL_TOTAL}}': 'The total amount of money refunded in client\'s local currency (requires refunded claim, defaults to REFUND_TOTAL value is no alternative).',
        '{{REFUND_CURRENCY}}': 'The client\'s local currency (requires refunded claim, defaults to GBP if no local currency set).',
        '{{REFUND_PENALTY_TOTAL}}': 'The monetary total of restyling charges and another charges applied (requires refunded claim).',
        // REQUEST USER
        '{{MY_NAME}}': 'Your name, as shown in your profile.',
        '{{MY_EMAIL}}': 'Your email address.',
    };
}

export { gbbgEmailTemplateKey }
