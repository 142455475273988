function activeUncontactedViewController($mdDialog, $state, $stateParams, AuthService, ErrorHandler, ClientService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.dates = [
        { label: '3 months', value: '3m' },
        { label: '6 months', value: '6m' },
        { label: '9 months', value: '9m' }
    ];
    vm.selectedDate = $stateParams.date;

    vm.clients = [];

    vm.concatAddress = concatAddress;
    vm.update = update;
    vm.toggleFilter = toggleFilter;

    vm.$onInit = getUncontactedClients;

    function update() {
        $state.go($state.current.name, { date: vm.selectedDate }, { reload: $state.current.name });
    }

    function getUncontactedClients() {
        vm.isAdmin = !!AuthService.isAdmin();
        vm.agentFilter = ($stateParams.agentFilter && $stateParams.agentFilter == 'true');
        ClientService
        .getUncontactedClients($stateParams.date, vm.agentFilter, $stateParams.status)
        .then((data) => vm.clients = data, (error) => {
            ErrorHandler.showDialog('Failed to get uncontacted clients', error);
            console.error('Failed to get uncontacted clients:', error);
        });
    }

    function toggleFilter(agentFilter) {
        $state.go($state.current.name, {
            agentFilter: (agentFilter || agentFilter == false) ? agentFilter : $stateParams.agentFilter
        }, { reload: $state.current.name });
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { activeUncontactedViewController }
