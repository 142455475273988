import { gbbgAssignAccountManagerSelect } from './assign_account_manager_select';
import { gbbgLeadCountrySelect } from './assign_lead_country_select';
import { assignLeadsByCountryViewController } from './assign_country_view_controller';
import { gbbgAssignLeadSelect } from './assign_lead_select';
import { assignRoutes } from './assign_routes';
import { gbbgLeadSelect } from './assign_select_leads';
import { gbbgSelectTeam } from './assign_select_team';
import { assignLeadsFromUserViewController } from './assign_users_view_controller';

angular.module('assign', [])
.config(assignRoutes)
.component('gbbgAssignAccountManagerSelect', gbbgAssignAccountManagerSelect)
.component('gbbgLeadCountrySelect', gbbgLeadCountrySelect)
.component('gbbgAssignLeadSelect', gbbgAssignLeadSelect)
.component('gbbgLeadSelect', gbbgLeadSelect)
.component('gbbgSelectTeam', gbbgSelectTeam)
.controller('assignLeadsByCountryViewController', assignLeadsByCountryViewController)
.controller('assignLeadsFromUserViewController', assignLeadsFromUserViewController);

export default 'assign';
