function flagListViewController(FlagService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.flags = FlagService.flags;

    activate();

    function activate() {
        FlagService.getAllFlags().then(
            data => {
                if (!data || !data.length) {
                    ErrorHandler.showDialog('No flags returned', 'No eligibility flags were returned from the database.');
                }
            },
            error => {
                ErrorHandler.showDialog('Failed to get eligibility flags', error);
                console.error('Failed to get eligibility flags:', error);
            }
        );
    }
}

export { flagListViewController }
