const gbbgUpdateEmailTemplate = {
    template: `
    <md-button
        class="md-secondary md-icon-button"
        ng-click="vm.getTemplate()"
    >
        <md-icon md-font-library="material-icons">open_in_browser</md-icon>
        <md-tooltip md-direction="bottom">Edit email template</md-tooltip>
    </md-button>
    `,
    bindings: { templateId: '<', createdBy: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $state, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.email = {};

    vm.getTemplate = getTemplate;

    function getTemplate() {
        if (!vm.templateId || vm.templateId == undefined) return;
        EmailService.getTemplate(vm.templateId).then(
            data => openDialog(data),
            error => {
                ErrorHandler.showDialog('Failed to get email template', error);
                console.error(`Failed to get email template with ID '${vm.templateId}'.`, error);
            }
        );
    }

    function openDialog(email) {
        $mdDialog.show({
            templateUrl: '/partials/templates/email/template_dialog.html',
            locals: {
                email,
                createdBy: vm.createdBy
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((email) => {
            if (!email.title || !email.subject || !email.body) return;
            EmailService.updateTemplate(email).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Email template updated successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                },
                error => {
                    ErrorHandler.showDialog('Failed to update email template', error);
                    console.error('Failed to update email template:', error);
                }
            );
        }, () => {});
    }
}
function dialogController(email, createdBy, AuthService, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.notAdmin = !AuthService.isAdmin();

    vm.dialogTitle = 'Edit email template';

    vm.email = email;

    vm.cancel = $mdDialog.cancel;
    vm.updateTemplate = updateTemplate;

    function updateTemplate() { $mdDialog.hide(vm.email); }
}

export { gbbgUpdateEmailTemplate };
