const gbbgClaimRefund = {
    template: `
    <md-card>
        <md-card-content>
            <h4 ng-bind="vm.text.title"></h4>
            <p class="md-body-1" ng-bind="vm.text.body"></p>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions layout="row" layout-align="end">
            <md-button
                class="md-primary"
                ng-click="vm.openDialog()"
                ng-disabled="(!vm.submissions || !vm.submissions.length || vm.refund.dateRefundedFromCustoms)"
                gbbg-hide-unless-acc-manager
            >Refund from tax auth</md-button>
            <gbbg-claim-revert ng-if="vm.showRevert"></gbbg-claim-revert>
        </md-card-actions>
    </md-card>
    `,
    bindings: {
        invoices: '<',
        refund: '<',
        submissions: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller($scope, $filter, $mdDialog, $mdToast, ClaimService, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.showRevert = false // only show revert button if claim has been refunded

    vm.text = {
        title: '',
        body: ''
    };

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    $scope.$on('claimSubmitted', activate); // sent down from claimViewController

    function activate() {
        if (!vm.submissions || !vm.submissions.length) {
            vm.text.title = 'This claim has not been submitted to a tax authority.';
            vm.text.body = 'A due date will be set after the claim has been submitted.';
        } else if ((vm.submissions && vm.submissions.length > 0) && (!vm.refund || !vm.refund.dateRefundedFromCustoms)) {
            // vm.submissions.sort((a, b) => (new Date(a.dateSubmitted) > new Date(b.dateSubmitted)));
            // const latest = vm.submissions[0];
            const latest = vm.submissions[vm.submissions.length-1];
            const dueDate = $filter('date')(latest.dateDue, 'dd MMM, yyyy');
            vm.text.title = 'This claim has not been refunded from a tax authority.';
            vm.text.body = `This claim's due date was set as ${dueDate}.`;
        } else {
            const claimType = vm.refund.full ? 'Full' : 'Partial'; // 'partial' or 'full'
            const refundDate = $filter('date')(vm.refund.dateRefundedFromCustoms, 'dd MMM, yyyy');
            const batch = vm.refund.batchId ? `Batch ID: ${vm.refund.batchId}.` : '';
            vm.text.title = `${claimType} claim refunded on ${refundDate}. ${batch}`;
            vm.text.body = 'Claims can only be refunded once.';

            vm.showRevert = true
        }
    }

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/claim/refund_dialog.html',
            fullscreen: true,
            locals: {
                invoices: vm.invoices || [],
                refund: vm.refund
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then(data => {
            if (!data || !data.refund || (!data.invoices && !data.invoices.length)) return;
            ClaimService.refundClaim(vm.refund, vm.invoices).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Claim refunded successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    if (!ClaimService.claim || !ClaimService.claim._id) return;
                    EmailService.openSendEmailDialog(null, ClaimService.claim._id).then(
                        data => {
                            $mdToast.show(
                                $mdToast.simple()
                                .textContent('Email sent successfully!')
                                .position('top right')
                                .hideDelay(4000)
                            );
                        },
                        error => {
                            if (!error) return;
                            ErrorHandler.showDialog('Failed to send email', error);
                            console.log('Failed to send email:', error);
                        }
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to refund claim', error);
                    console.error('Failed to refund claim:', error);
                }
            );
        }, () => {});
    }
}
function dialogController(invoices, refund, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.invoices = invoices;
    vm.refund = refund;

    vm.cancel = $mdDialog.cancel;
    vm.refundClaim = refundClaim;

    vm.isInvalid = isInvalid;
    vm.updateInvoiceValues = updateInvoiceValues;

    function updateInvoiceValues(full) {
        if (!full) return;
        vm.invoices.forEach(invoice => invoice.vatRefunded = Number(invoice.vatClaimed.toFixed(2)));
    }

    function isInvalid(form) {
        /*
            1. At least one invoice must be refunded
            2. Refund values cannot exceed claimed values
            3. Partial refunds: all refund values CANNOT equal claim values
            4. Full refunds: all refund values MUST EQUAL claim values
        */
        const invalidInvoices = vm.invoices.some(invoice => (invoice.vatClaimed < invoice.vatRefunded || invoice.vatRefunded < 0));
        const allRejected = vm.invoices.every(invoice => (invoice.vatRefunded <= 0));
        let inconsistent = false;
        if (vm.refund.full) {
            let someInequal = vm.invoices.some(invoice => invoice.vatClaimed != invoice.vatRefunded);
            inconsistent = someInequal;
        } else {
            let allEqual = vm.invoices.every(invoice => invoice.vatClaimed == invoice.vatRefunded);
            inconsistent = allEqual;
        }
        return (form.$invalid || invalidInvoices || allRejected || inconsistent);
    }

    function refundClaim() {
        $mdDialog.hide({
            refund: vm.refund,
            invoices: vm.invoices
        });
    }
}

export { gbbgClaimRefund };
