const gbbgEmailTemplateSelect = {
    template: `
    <div layout="column">
        <md-input-container>
            <label>Select email template</label>
            <md-select
                ng-model="vm.selectedTemplate"
                ng-change="vm.renderTemplate(vm.selectedTemplate)"
            >
                <md-option value="blank">Blank email</md-option>
                <md-option
                    ng-repeat="template in vm.templates"
                    ng-value="template._id"
                >{{template.title}}</md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: {
        render: '=',
        selectedTemplate: '=?', // ID
        object: '<',
        clientId: '@',
        claimId: '@',
        overwrite: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller(EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.emptyEmail = {
        _id: null,
        tile: '',
        subject: '',
        body: ''
    };
    vm.render = {};
    vm.templates = [];

    vm.renderTemplate = renderTemplate;

    vm.$onInit = activate;

    function activate() {
        EmailService.getAllTemplates().then(
            data => {
                vm.templates = data;
                if (vm.templates.length) {
                    if (!vm.selectedTemplate) vm.selectedTemplate = vm.templates[0]._id;
                    renderTemplate(vm.selectedTemplate);
                }
            },
            error => {
                ErrorHandler.showDialog('Failed to get email templates', error, 'Retry', activate);
                console.error('Failed to get email templates:', error);
            }
        );
    }

    function renderTemplate(templateId) {
        if (!templateId || !vm.selectedTemplate) {
            if (vm.render) vm.render = angular.copy(vm.emptyEmail);
        }
        // if blank template is selected, user's signature is returned from server
        if (vm.clientId || vm.claimId) {
            EmailService.renderTemplate(templateId, vm.clientId, vm.claimId, vm.overwrite).then(
                data => { vm.render = data; },
                error => {
                    ErrorHandler.showDialog('Failed to get rendered template', error);
                    console.error('Failed to get rendered template:', error);
                }
            );
        } else if (vm.object) {
            EmailService.renderTemplateForObject(templateId, vm.object).then(
                data => { vm.render = data; },
                error => {
                    ErrorHandler.showDialog('Failed to get rendered template for object', error);
                    console.error('Failed to get rendered template for object:', error);
                }
            );
        }
    }
}

export { gbbgEmailTemplateSelect };
