import * as Clipboard from 'clipboard-js';

const gbbgEmailTemplateCopy = {
    template: `
    <div layout="row">
        <md-button
            class="md-icon-button"
            ng-click="vm.copyEmailBody()"
            ng-disabled="!vm.email.body"
        >
            <md-icon>content_copy</md-icon>
            <md-tooltip md-direction="top">Copy email body (recommended)</md-tooltip>
        </md-button>
        <md-button
            class="md-icon-button"
            ng-click="vm.openMailClient()"
            ng-disabled="(!vm.recipient || !vm.email.subject || !vm.email.body)"
        >
            <md-icon>open_in_new</md-icon>
            <md-tooltip md-direction="top">Open mail client</md-tooltip>
        </md-button>
    </div>
    `,
    bindings: {
        email: '<',
        recipient: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.copyEmailBody = copyEmailBody;
    vm.openMailClient = openMailClient;

    function copyEmailBody() {
        if (!vm.email.body) return;
        // https://github.com/lgarron/clipboard.js
        Clipboard.copy({ "text/html": vm.email.body }).then(
            () => console.log('Copied email body to clipboard'),
            () => console.log('Failed to copy email body to clipboard.')
        );
    }

    function openMailClient() {
        if (!vm.recipient || !vm.email.subject || !vm.email.body) return;
        // Cannot open mailto link with HTML in body
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = vm.email.body;
        const text = tempDiv.textContent || tempDiv.innerText || "";
        window.location.href = `mailto:${vm.recipient}?subject=${vm.email.subject}&body=${text}`;
    }
}

export { gbbgEmailTemplateCopy };
