const alignCenter = { halign: 'center' }
const alignRight = { halign: 'right' }

const MARGIN = { top: 30, left: 20, right: 10, bottom: 20 }
let CLAIM = {}

const PDF_HEADER = function(claim) {
    CLAIM = claim
    return [
        // [ 'Number', 'Type of supply', 'Supplier', 'Address of supplier', 'VAT registration number', 'Date of invoice', 'Invoice number', 'Amount of tax £' ]
    ]
}

const HEAD_STYLES = {
    fillColor: false,
    fontStyle: 'normal', // not 'bold'
    textColor: '#000000',
    halign: 'center',
    valign: 'bottom',
    lineColor: '#000000',
    lineWidth: 0.25
}

const PDF_COLUMNS = [
    { header: 'Number', dataKey: 'index' },
    { header: 'Type of supply', dataKey: 'typeOfSupply' },
    { header: 'Supplier', dataKey: 'supplier' },
    { header: 'Address of supplier', dataKey: 'supplierAddress' },
    { header: 'VAT registration number', dataKey: 'taxNo' },
    { header: 'Date of invoice', dataKey: 'dateOfInvoice' },
    { header: 'Invoice number', dataKey: 'invoiceNo' },
    { header: 'Amount of tax £', dataKey: 'vatClaimed' }
]

const COLUMN_STYLES = {
    index: { halign: 'center', minCellWidth: 16 },
    typeOfSupply: alignCenter,
    supplier: { halign: 'center', minCellWidth: 30 },
    supplierAddress: alignCenter,
    taxNo: { halign: 'center', minCellWidth: 30 },
    dateOfInvoice: { halign: 'center', minCellWidth: 30 },
    invoiceNo: { halign: 'center', minCellWidth: 28 },
    vatClaimed: { halign: 'right', minCellWidth: 25 }
}

const STYLES = {
    fontSize: 10,
    textColor: '#000000',
    lineColor: '#000000',
    lineWidth: 0.25
}

let totalsObj = {}
const willDrawCell = function(hookData) {
    if (hookData.section === 'body') {
        if (hookData.column.index === 7) {
            if (hookData.cell.text && hookData.cell.text[0]) {
                if (totalsObj[hookData.pageNumber]) {
                    totalsObj[hookData.pageNumber] += Number(hookData.cell.text[0])
                } else {
                    totalsObj[hookData.pageNumber] = Number(hookData.cell.text[0])
                }
                hookData.cell.text = [ `£${Number(hookData.cell.text[0] || 0).toFixedNoRounding(2)}` ]
            }
        }
    }
}

const didDrawPage = function({ table, pageNumber, settings, doc }) {
    // Example: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
    doc.setFontSize(10)
    doc.setDrawColor(0, 0, 0) // make sure lines/rectangles are black
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    // HEADER
    let headerStr1 = `GB Business VAT Ireland Ltd.`
    doc.text(headerStr1, 20, 20)
    let headerStr2 = `Schedule number ${pageNumber}`
    doc.text(headerStr2, pageWidth - 41, 20)
    if (pageNumber > 1) {
        // let total = isNaN(totalsObj[pageNumber-1]) ? 0 : totalsObj[pageNumber-1]
        let total = 0
        for (let i = 1; i < pageNumber; i++) { total += totalsObj[i] }
        let headerStr3 = `Brought forward £           ${Number(total).toFixedNoRounding(2)}`
        doc.text(headerStr3, pageWidth - 63.5, 25)
        doc.setLineWidth(0.25)
        doc.rect(pageWidth - 35, 21.5, 25, 5)
    }

    // console.log('Internal: ', doc.internal);
    let footerStr1 = `${CLAIM.urn}`
    doc.text(footerStr1, 22, pageHeight - 10)
    doc.setLineWidth(0.25)
    doc.rect(20, pageHeight - 14, 25, 5);
    let total = isNaN(totalsObj[pageNumber]) ? 0 : totalsObj[pageNumber]
    // if it's not the first page, add the total from the previous pages.
    if (pageNumber > 1) {
        for (let i = 1; i < pageNumber; i++) { total += totalsObj[i] }
        // https://stackoverflow.com/a/11022526/3025130
        var pow = Math.pow(10, 2);
        total = (Math.round(total * pow) / pow)
    }

    let footerStr2 = `Page total £            ${Number(total).toFixedNoRounding(2)}`
    doc.text(footerStr2, pageWidth - 56, pageHeight - 15)
    doc.setLineWidth(0.25)
    doc.rect(pageWidth - 35, pageHeight - 19, 25, 5)
}

const currency = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

function resetObjTotals() {
    totalsObj = {} // reset so that figures do not carry over to next schedule.
}

export function init() {
    return {
        MARGIN, PDF_HEADER, HEAD_STYLES, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage, willDrawCell, resetObjTotals
    }
}

// export { MARGIN, PDF_HEADER, HEAD_STYLES, PDF_COLUMNS, COLUMN_STYLES, STYLES, didDrawPage, willDrawCell }
