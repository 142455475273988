const gbbgTimezoneSelect = {
    template: `
    <div layout="row">
        <md-autocomplete flex
            md-input-name="timezoneAutocomplete"
            md-input-minlength="2"
            md-input-maxlength="50"
            md-selected-item="vm.selectedCountry"
            md-selected-item-change="vm.getCountryTimezones(item)"
            md-search-text="vm.searchText"
            md-items="item in vm.filterCountries(vm.searchText)"
            md-item-text="item.countryName"
            md-require-match
            md-floating-label="Country search"
            ng-required="vm.required"
        >
            <md-item-template>
                <span md-highlight-text="vm.searchText">{{item}}</span>
            </md-item-template>
        </md-autocomplete>
        <md-input-container md-no-float flex="30">
            <label>Time diff.</label>
            <md-select
                ng-model="vm.selectedTimezone"
                ng-change="vm.updateAddress()"
                ng-disabled="!vm.selectedTimezone || !vm.countryZones.length"
            >
                <md-option ng-repeat="(key, item) in vm.countryZones track by $index" ng-value="item">
                    {{item.gmtOffset / 3600}} ({{item.zoneName}})
                </md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: { address: "=", onChange: '&' },
    controller,
    controllerAs: 'vm'
};
const gbbgCountrySelect = {
    template: `
    <div layout="column">
        <md-autocomplete flex
            md-input-name="timezoneAutocomplete"
            md-input-minlength="2"
            md-input-maxlength="50"
            md-selected-item="vm.country"
            md-selected-item-change="vm.updateOnChange()"
            md-search-text="vm.searchText"
            md-items="item in vm.filterCountries(vm.searchText)"
            md-item-text="item.countryName"
            md-require-match
            md-floating-label="{{vm.label}}"
            ng-disabled="vm.disabled"
            ng-required="vm.required"
        >
            <md-item-template>
                <span md-highlight-text="vm.searchText">{{item}}</span>
            </md-item-template>
        </md-autocomplete>
    </div>
    `,
    bindings: { country: "=", disabled: '@', onChange: '&' },
    controller,
    controllerAs: 'vm'
};
function controller($http, $attrs, $timeout, ErrorHandler, TimezoneService, $element) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.required = false;
    vm.searchText = '';
    vm.selectedTimezone = {};
    vm.countries = TimezoneService.countries;
    vm.timezones = TimezoneService.timezones;

    vm.activate = activate;
    vm.clearSearch = clearSearch;
    vm.filterCountries = filterCountries;
    vm.getCountryTimezones = getCountryTimezones;
    vm.updateAddress = updateAddress;
    vm.updateOnChange = updateOnChange;

    vm.$onInit = activate;

    function activate() {
        vm.required = ($attrs.required && $attrs.required != 'false') ? true : false;
        vm.label = $attrs.label || 'Country search';
        if (vm.timezones && Object.keys(vm.timezones).length > 0) {
            vm.searchText = vm.address ? vm.address.country : (vm.country || '');
            vm.selectedCountry = vm.address ? vm.address.country : (vm.country || '');
            vm.countryZones = vm.timezones[vm.selectedCountry];
            vm.selectedTimezone = TimezoneService.getSelectedTimezone(vm.address);
            return;
        }
        TimezoneService.getTimezones()
        .then(() => {
            if (!vm.address || !vm.address.country) return;
            vm.searchText = vm.address ? vm.address.country : (vm.country || '');
            vm.selectedCountry = vm.address ? vm.address.country : (vm.country || '');
            vm.countryZones = vm.timezones[vm.selectedCountry];
            vm.selectedTimezone = TimezoneService.getSelectedTimezone(vm.address);
        })
        .catch(() => {
            ErrorHandler.showDialog(
                'Timezones failed to load',
                'A list of countries and timezones was not returned from the API.',
                'Retry',
                false,
                activate
            );
        });
    }

    function filterCountries(text) {
        if (!text || !text.length || !vm.countries || !vm.countries.length) return [];
        return vm.countries.filter(country => country.toLowerCase().includes(text.toLowerCase()));
    }

    function getCountryTimezones(country) {
        if (!country || !vm.timezones.hasOwnProperty(country)) vm.countryZones = [];
        else vm.countryZones = vm.timezones[country];
        vm.selectedTimezone = vm.countryZones[0];
        vm.updateAddress();
    }

    function updateAddress() {
        if (!vm.selectedTimezone) return;
        if (!vm.address) vm.address = { timeDiff: 0 };
        vm.address.timeDiff = vm.selectedTimezone.gmtOffset / 3600; // calculate difference in hours
        vm.address.timezone = vm.selectedTimezone.zoneName;
        vm.address.country = vm.selectedTimezone.countryName;
        vm.address.countryCode = vm.selectedTimezone.countryCode;
        vm.updateOnChange();
    }

    function updateOnChange() {
        const country = (vm.selectedTimezone && vm.selectedTimezone.countryName) ? vm.selectedTimezone.countryName : vm.selectedCountry;
        if (vm.onChange) $timeout(() => vm.onChange({ country: country })); }

    function clearSearch() { vm.searchText = ''; }
}
function TimezoneService($http, $q) {
    'ngInject';
    const service = {
        timezones: (localStorage.gbbgTimezones) ? JSON.parse(localStorage.gbbgTimezones) : {},
        countries: (localStorage.gbbgCountries) ? JSON.parse(localStorage.gbbgCountries) : [],
        getTimezones() {
            return new Promise((resolve, reject) => {
                $http.get('https://api.timezonedb.com/v2.1/list-time-zone?key=AEZQOSG59D7R&format=json', {
                    headers: { Authorization: undefined }
                })
                .then((response, error) => {
                    if (error || !response || !response.data || !response.data.zones) {
                        console.error('Failed to load timezones from timezonedb.com.', response);
                        return reject();
                    }
                    service.parseZonesAsCountries(response.data.zones);
                    resolve();
                });
            });
        },
        parseZonesAsCountries(zones) {
            zones.map((zone) => {
                // If the timezone country exists...
                const hasCountry = service.timezones.hasOwnProperty(zone.countryName);
                // ...add the country to the object...
                if (hasCountry) service.timezones[zone.countryName].push(zone);
                // else create new array for that key
                else service.timezones[zone.countryName] = [ zone ];
                return zone;
            });
            Object.keys(service.timezones).sort().map(key => service.countries.push(key));
            // Save timezones & countries to local storage so they don't have to be loaded every time
            if (localStorage) {
                localStorage.gbbgCountries = JSON.stringify(service.countries);
                localStorage.gbbgTimezones = JSON.stringify(service.timezones);
            }
            return service.timezones;
        },
        getSelectedTimezone(address) {
            if (!address || !address.country) return;
            let selectedTimezone = {};
            const { timezones } = service;
            // Find the selected country (if one already exists)
            for (let key in timezones) {
                const country = timezones[key] || [];
                for (let y = 0; y < country.length; y++) {
                    let zone = country[y];
                    const { countryName, zoneName } = zone;
                    if (address.country === countryName && address.timezone === zoneName) {
                        selectedTimezone = zone;
                        break;
                    }
                }
                if (selectedTimezone.countryName) break;
            }
            return selectedTimezone;
        }
    };
    return service;
}

export { gbbgTimezoneSelect, gbbgCountrySelect, TimezoneService };
