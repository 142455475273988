const gbbgNewSource = {
    template: `
    <md-button
        class="md-fab md-secondary md-hue-1 md-mini"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">add</md-icon>
        <md-tooltip md-direction="bottom">New source</md-tooltip>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($state, $mdDialog, $mdToast, ErrorHandler, SourceService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((source) => {
            SourceService.newSource(source).then(
                data => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Enquiry source added successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                    $state.go($state.current.name, { }, { reload: $state.current.name });
                },
                error => {
                    ErrorHandler.showDialog('Failed to add new enquiry source', error);
                    console.error('Failed to add new enquiry source:', error);
                }
            );
        }, () => {});
    }

    const template = `
    <md-dialog aria-label="Add source" style="max-width:600px;">
        <form name="newSourceForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>New enquiry source</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>
            <md-dialog-content>

                <gbbg-source-details source="vm.source"></gbbg-source-details>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.addSource()"
                    ng-disabled="newSourceForm.$invalid"
                > Add source </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.source = {
        name: '',
        address: {
            street1: '',
            street2: '',
            street3: '',
            city: '',
            country: '',
            zip: ''
        },
        email: '',
        phone: ''
    };

    vm.cancel = $mdDialog.cancel;
    vm.addSource = addSource;

    function addSource() {
        $mdDialog.hide(vm.source);
    }
}

export { gbbgNewSource }
