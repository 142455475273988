function activeClientViewController($stateParams, $timeout, ActiveService, ClaimService, PreClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.showAuthorisedUsers = false;
    vm.isLoaded = false;
    vm.client = {};
    vm.selectedPreClaims = [];

    vm.preClaims = PreClaimService.preClaims;

    vm.concatAddress = concatAddress;

    vm.$onInit = activate;

    function activate() {
        if (!$stateParams.id) return;
        vm.showAuthorisedUsers = (localStorage.gbbgUserAccess >= 401);
        ActiveService.getActiveClient($stateParams.id).then(
            data => {
                ActiveService.setClient(data);
                vm.client = ActiveService.client;
                $timeout(() => {
                    Promise.all([
                        ClaimService.getClaimsForClient($stateParams.id),
                        PreClaimService.getPreClaimsByClient($stateParams.id)
                    ]).then(
                        data => {
                            if (data[0]) vm.client.claims = data[0];
                            // if (data[1]) vm.preClaims = PreClaimService.preClaims;
                        }, error => {
                            ErrorHandler.showDialog('Failed to get claims for client', error);
                            console.error('Failed to get claims for client:', error);
                        }
                    );
                    vm.isLoaded = true;
                }, 500);
            },
            error => {
                ErrorHandler.showDialog(`Failed to load client ${$stateParams.id}`, error, true);
                console.error(`Failed to get active record for client '${$stateParams.id}':`, error);
            }
        );
    }

    function concatAddress(address) {
        if (!address) return '';
        const { city = '', country = '' } = address;
        if (city.length && country.length) return `${city}, ${country}`;
        else return city || country;
     }
}

export { activeClientViewController };
