const gbbgTeamsSelect = {
    template($element, $attrs) {
        let select = ``;
        if ($attrs.disableMultiple && $attrs.disableMultiple == 'true') {
            select = `
            <label>Team selection</label>
            <md-select
                ng-model="vm.selectedTeam"
                ng-required="vm.required"
                ng-disabled="!vm.teams.length"
                ng-change="vm.updateOnChange()"
                aria-label="Multiple teams selection"
            > <md-option ng-repeat="team in vm.teams" ng-value="team._id">{{team.name}}</md-option> </md-select>
            `;
        } else {
            select = `
            <label>Multiple teams selection</label>
            <md-select
                ng-model="vm.selectedTeams"
                ng-required="vm.required"
                ng-disabled="!vm.teams.length"
                ng-change="vm.updateOnChange()"
                aria-label="Multiple teams selection"
                multiple
            > <md-option ng-repeat="team in vm.teams" ng-value="team._id">{{team.name}}</md-option> </md-select>
            `;
        }
        return `
        <div layout="column">
            <md-input-container flex>
                ${select}
                <p class="hint" ng-if="!vm.teams.length && vm.admin == true">
                    No teams were returned where you are the administrator.
                </p>
            </md-input-container>
        </div>
        `
    },
    bindings: {
        selectedTeam: '=?',
        selectedTeams: '=?',
        onChange: '&',
        required: '<',
        admin: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller($attrs, TeamService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.teams = [];

    vm.updateOnChange = updateOnChange;

    vm.$onInit = activate;

    function activate() {
        TeamService.getAllTeams({ admin: vm.admin }).then(
            data => {
                vm.teams = data;
                if ((!vm.required || vm.required === 'false') && $attrs.disableMultiple == 'true') {
                    vm.teams.unshift({ _id: 'null', name: 'No team selected' });
                }
            },
            error => {
                ErrorHandler.showDialog('Failed to get teams', error, 'Retry', false, activate);
                console.error('Failed to get teams:', error);
            }
        );
    }

    function updateOnChange() {
        if (vm.onChange) vm.onChange({ team: vm.selectedTeam, teams: vm.selectedTeams });
    }
}

export { gbbgTeamsSelect }
