const gbbgClaimSubmissions = {
    template: `
    <md-card>
        <md-card-content>
            <h3>Latest</h3>
            <p ng-bind="vm.latest()"></p>
            <h3>Previous</h3>
            <p ng-if="vm.submissions.length < 1">This claim has no previous submissions.</p>
            <md-list ng-if="vm.submissions.length">
                <md-list-item ng-repeat="sub in vm.submissions | limitTo: vm.submissions.length-1">
                    Submitted on {{sub.dateSubmitted | date: 'dd MMM, yyyy'}}.
                    Amount claimed {{sub.amount | currency: '£'}}.
                    Est. value of {{sub.value | currency: '£'}}.
                </md-list-item>
            </md-list>
        </md-card-content>
    </md-card>
    `,
    bindings: { submissions: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($filter) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.latest = latest;

    function latest() {
        if (!vm.submissions || !vm.submissions.length) return 'This claim has not been submitted to a tax authority yet.';

        // vm.submissions.sort((a, b) => (new Date(a.dateSubmitted) < new Date(b.dateSubmitted)));

        const latest = vm.submissions[vm.submissions.length-1];

        const dateSubmitted = $filter('date')(latest.dateSubmitted, 'dd MMM, yyyy');
        const claimAmount = $filter('currency')(latest.amount, '£');
        const claimValue = $filter('currency')(latest.value, '£');

        return `This claim was last submitted on ${dateSubmitted}. The amount claimed was ${claimAmount} which had an estimated fee value of ${claimValue}.`
    }
}

export { gbbgClaimSubmissions };
