const gbbgNewTeam = {
    template: `
    <md-button
        class="md-fab md-secondary md-hue-1 md-mini"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">group_add</md-icon>
        <md-tooltip md-direction="bottom">New team</md-tooltip>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, TeamService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then((team) => {
            TeamService.createTeam(team).then(
                data => $state.go('root.auth.management.teams.team', { id: data.id }),
                error => {
                    ErrorHandler.showDialog('Failed to create new team', error);
                    console.error('Failed to create new team:', error);
                }
            );
        }, () => {})
    }
}
const template = `
<md-dialog>
    <form name="newTeamForm">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Add new team</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content layout-padding>
            <p class="md-caption">You will automatically be assigned as the team administrator.</p>
            <div layout="column" layout-align="start">
                <md-input-container flex>
                    <label>Name</label>
                    <input type="text" ng-model="vm.team.name" maxlength="100" required/>
                </md-input-container>

                <md-input-container flex>
                    <label>Area / location</label>
                    <input type="text" ng-model="vm.team.location.area" maxlength="100"/>
                </md-input-container>

                <gbbg-timezone-select address="vm.team.location" required="true" flex></gbbg-timezone-select>
            </div>
        </md-dialog-content>
        <md-dialog-actions layout="row" layout-align="end end">
            <md-button class="md-warn" ng-click="vm.cancel()">Cancel</md-button>
            <md-button
                class="md-primary md-raised"
                ng-click="vm.addTeam()"
                ng-disabled="newTeamForm.$invalid"
            >Create team</md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.team = {
        name: '',
        location: {}
    };

    vm.cancel = $mdDialog.cancel;

    vm.addTeam = addTeam;

    function addTeam() { $mdDialog.hide(vm.team); }
}

export { gbbgNewTeam };
