const gbbgPreClaimClose = {
    template: `
    <md-button
        class="md-warn md-icon-button"
        ng-disabled="!vm.claimId"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">delete_sweep</md-icon>
        <md-tooltip md-direction="down">Close potential claims</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, PreClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!vm.claimId) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .clickOutsideToClose(true)
            .title('Are you sure?')
            .textContent('Closing this potential claim is permanent and cannot be undone.')
            .ariaLabel('Confirm close')
            .ok('Close claim')
            .cancel('Cancel')
        ).then(() => {
            PreClaimService.closeClaim(vm.claimId).then(
                data => {
                    $mdDialog.show(
                        $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('Claim closed')
                        .textContent('Claim closed successfully.')
                        .ariaLabel('Closed potential claim')
                        .ok('Okay!')
                    );
                    $state.go($state.current.name, {}, { reload: $state.current.name });
                },
                error => {
                    ErrorHandler.showDialog('Failed to close potential claims', error);
                    console.error('Failed to close potential claims:', error);
                }
            );
        }, () => {});
    }
}

export { gbbgPreClaimClose };
