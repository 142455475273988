const gbbgToggleClaimPaperwork = {
    template: `
    <div layout="column">
        <md-switch
            ng-model="vm.paperwork.sent"
            ng-disabled="vm.disableToggle"
            ng-change="vm.toggleDocumentsSent()"
        > Docs sent to GBBG <b ng-if="vm.paperwork.sent">{{vm.paperwork.dateSent | date: 'MMM d, y'}}</b> </md-switch>

        <md-switch
            ng-model="vm.paperwork.received"
            ng-change="vm.toggleDocumentsReceived()"
            ng-disabled="!vm.paperwork.sent || vm.disableToggle"
        > Docs received by GBBG <b ng-if="vm.paperwork.received">{{vm.paperwork.dateReceived | date: 'MMM d, y'}}</b> </md-switch>
    </div>
    `,
    bindings: { paperwork: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.toggleDocumentsSent = toggleDocumentsSent;
    vm.toggleDocumentsReceived = toggleDocumentsReceived;

    vm.$onInit = activate;

    function activate() {
        vm.disableToggle = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 301;
    }

    function toggleDocumentsSent() {
        ClaimService.toggleDocumentsSent().then(
            data => {
                if (vm.paperwork.sent === true) vm.paperwork.dateSent = new Date();
            },
            error => {
                ErrorHandler.showDialog('Failed to update documents sent:', error);
                console.error('Failed to update documents sent:', error);
            }
        );
    }

    function toggleDocumentsReceived() {
        ClaimService.toggleDocumentsReceived().then(
            data => {
                if (vm.paperwork.received === true) vm.paperwork.dateReceived = new Date();
            },
            error => {
                ErrorHandler.showDialog('Failed to update documents received:', error);
                console.error('Failed to update documents sent:', error);
            }
        );
    }
}

export { gbbgToggleClaimPaperwork };
