const gbbgEmailAttachmentsList = {
    template: `
    <div layout="row" layout-align="space-between center" layout-padding>
        <ng-transclude></ng-transclude>
        <md-button
            class="md-icon-button"
            ng-click="vm.restoreAttachments()"
            ng-disabled="!vm.backup.length"
        >
            <md-icon>restore</md-icon>
            <md-tooltip md-direction="down">Restore deleted attachments</md-tooltip>
        </md-button>
    </div>
    <md-list>
        <md-list-item ng-repeat="attachment in vm.attachments">
            <p ng-bind="attachment.filename"></p>
            <md-button class="md-icon-button md-secondary" ng-click="vm.removeAttachment($index)">
                <md-icon>delete</md-icon>
            </md-button>
        </md-list-item>
    </md-list>
    `,
    transclude: true,
    bindings: { attachments: '=', attachmentIds: '=?' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.backup = [];
    vm.backupIds = [];

    vm.removeAttachment = removeAttachment;
    vm.restoreAttachments = restoreAttachments;

    function removeAttachment($index) {
        if ((!vm.attachments || !vm.attachments.length) && (!vm.attachmentIds || !vm.attachmentIds.length)) return;
        const _id = vm.attachments[$index]._id;
        if (!vm.backup.some(attach => attach.id)) vm.backup.push(vm.attachments[$index]);
        vm.attachments.splice($index, 1);

        if (!vm.attachmentIds || !vm.attachmentIds.length) return;
        const idx = vm.attachmentIds.indexOf(_id);
        if (idx >= 0) {
            if (!vm.backupIds.some(id => id === _id)) vm.backupIds.push(_id);
            vm.attachmentIds.splice(idx, 1);
        }
    }

    function restoreAttachments() {
        if (vm.attachments) {
            vm.attachments = [...vm.attachments, ...vm.backup];
            vm.backup.splice(0);
        }
        if (vm.attachmentIds && vm.backupIds.length) {
            vm.attachmentIds = [...vm.attachmentIds, ...vm.backupIds];
            vm.backupIds.splice(0);
        }
    }
}

export { gbbgEmailAttachmentsList }
