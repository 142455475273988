const gbbgClaimRefundCharges = {
    template: `
    <md-card>
        <md-card-title>
            <md-card-title-text>
                <span class="md-headline">Client charges &amp; local currency</span>
                <span class="md-subhead">Charges are deducted from the client's share of the refund.</span>
            </md-card-title-text>
        </md-card-title>
        <md-divider></md-divider>
        <md-card-content>
            <div layout="column" layout-gt-xs="row" layout-wrap>
                <md-input-container flex flex-gt-xs="33">
                    <label>Bank charges</label>
                    <input type="number" ng-model="vm.refund.bankCharge" min="0" required/>
                </md-input-container>
                <md-input-container flex flex-gt-xs="33">
                    <label>Cost of certificate</label>
                    <input type="number" ng-model="vm.refund.certCost" min="0" required/>
                </md-input-container>
                <md-input-container flex flex-gt-xs="33">
                    <label>Additional costs/charges</label>
                    <input type="number" ng-model="vm.refund.additionalCosts" min="0" required/>
                </md-input-container>
                <md-input-container flex flex-gt-xs="33">
                    <label>Local currency (e.g. GBP, Canadian dollar)</label>
                    <input type="text" ng-model="vm.refund.localCurrency" required/>
                </md-input-container>
                <md-input-container flex flex-gt-xs="33">
                    <label>Value in local currency</label>
                    <input type="number" min="0" ng-model="vm.refund.localCurrencyTotal" ng-change="vm.updateRate()" required/>
                </md-input-container>
                <md-input-container flex flex-gt-xs="33">
                    <label>Exchange rate</label>
                    <input type="number" min="0" ng-model="vm.refund.exchangeRate" ng-change="vm.updateLocalTotal()" required/>
                </md-input-container>
                <p class="no-margin">
                    <span class="md-body-2">Date refunded to customer:</span>
                    <span class="md-body-1" ng-if="vm.refund.dateRefundedToCustomer">
                        {{vm.refund.dateRefundedToCustomer | date: 'dd MMM, yyyy'}}
                    </span>
                    <span class="md-body-1" ng-if="!vm.refund.dateRefundedToCustomer">
                        Not refunded to customer
                    </span>
                    <md-icon ng-click="vm.openDateDialog()">
                        date_range
                        <md-tooltip>Set refund date</md-tooltip>
                    </md-icon>
                </p>
            </div>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions layout="row" layout-align="end end">
            <ng-transclude></ng-transclude>
        </md-card-actions>
        <md-progress-linear md-mode="indeterminate" ng-if="vm.processing"></md-progress-linear>
    </md-card>
    `,
    transclude: true,
    bindings: { refund: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.openDateDialog = openDateDialog;
    vm.updateLocalTotal = updateLocalTotal;
    vm.updateRate = updateRate;

    function updateRate() {
        const { exchangeRate, localCurrencyTotal = 0, clientFee = 0, penaltyTotal = 0, bankCharge = 0, certCost = 0, additionalCosts = 0 } = vm.refund;
        if (!localCurrencyTotal || localCurrencyTotal == 0) return;
        const amountMinusPenalty = (clientFee - penaltyTotal - bankCharge - certCost - additionalCosts);
        vm.refund.exchangeRate = (localCurrencyTotal / amountMinusPenalty);
    }

    function updateLocalTotal() {
        const { exchangeRate, clientFee = 0, penaltyTotal = 0, bankCharge = 0, certCost = 0, additionalCosts = 0 } = vm.refund;
        if (!exchangeRate || exchangeRate == 0 || exchangeRate == 1) return;
        const amountMinusPenalty = (clientFee - penaltyTotal - bankCharge - certCost - additionalCosts);
        vm.refund.localCurrencyTotal = (amountMinusPenalty * exchangeRate);
    }

    function openDateDialog() {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then(({ date }) => vm.refund.dateRefundedToCustomer = date, () => {});
    }

    const template = `
    <md-dialog aria-label="Date refunded to client dialog" style="max-width:600px;">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Date refunded to client</h2>
                <span flex></span>
                <gbbg-close-dialog></gbbg-close-dialog>
            </div>
        </md-toolbar>
        <md-dialog-content>

            <div class="md-padding" style="min-width: 400px; padding-top: 20px;" layout="column">
                <md-input-container flex>
                    <label>Date refunded to customer</label>
                    <md-datepicker
                        ng-model="vm.date"
                        md-max-date="vm.today"
                        md-autofocus
                    ></md-datepicker>
                </md-input-container>
            </div>

        </md-dialog-content>
        <md-divider></md-divider>
        <md-dialog-actions layout="row">
            <span flex></span>
            <md-button type="submit" class="md-primary md-raised" ng-click="vm.save()">Set date</md-button>
        </md-dialog-actions>
    </md-dialog>
    `;
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.today = new Date();

    vm.date = new Date();

    vm.save = save;

    function save() {
        $mdDialog.hide({ date: vm.date  })
    }
}

export { gbbgClaimRefundCharges };
