function gbbgQuerySort($state, $stateParams) {
    return {
        restrict: 'AE',
        template: `
        <small ng-click="setSort()">
            <ng-transclude></ng-transclude>
            <i class="fa" ng-if="showCaret()" ng-class="caretClass()"></i>
        </small>
        `,
        scope: { field: '@' },
        transclude: true,
        replace: true,
        link
    };
    function link(scope, elem, attrs) {

        activate();

        function activate() {
            scope.showCaret = showCaret;
            scope.caretClass = caretClass;
            scope.setSort = setSort;
        }

        function setSort() {
            const field = scope.field;
            let { assigned, status, query, sort } = $stateParams;

            if (!sort || sort.indexOf(field) < 0) sort = field;
            else if (sort.slice(0, 1) === '-' && sort.indexOf(field) >= 0) sort = field;
            else if (sort.slice(0, 1) !== '-' && sort.indexOf(field) >= 0) sort = ('-' + field);

            const params = { assigned, status, query, sort };
            $state.go($state.current.name, params, { reload: $state.current.name });
            caretClass();
        }

        function showCaret() { return (!$stateParams.sort) ? false : $stateParams.sort.indexOf(scope.field) >= 0; }

        function caretClass() { return (!$stateParams.sort) ? '' : $stateParams.sort.slice(0, 1) === '-' ? 'fa-caret-down' : 'fa-caret-up'; }
    }
}

export { gbbgQuerySort };
