const gbbgPasswordResetButton = {
    template: `<p class="md-caption" ng-click="vm.openDialog()">Forgot your password?</p>`,
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        return; // disable while MS authentication is active
        $mdDialog.show(
            $mdDialog.prompt()
            .title('Reset your password?')
            .textContent('Enter the email address for the account you want a reset code for. \n Your reset code will be emailed to you.')
            .placeholder('Email address')
            .ariaLabel('Email address')
            .required(true)
            .ok('Get code')
            .cancel('Cancel')
        ).then(email => {
            UserService.getPasswordResetCode(email).then(
                () => openResetDialog(email),
                error => {
                    ErrorHandler.showDialog('Failed to request password reset code', error);
                    console.error('Failed to request password reset code:', error);
                }
            );
        }, () => { });
    }

    function openResetDialog(email) {
        $mdDialog.show({
            template,
            controller: dialogController,
            controllerAs: 'vm'
        }).then(({ code = '', password = '' }) => {
            UserService.resetPassword(email, code, password).then(
                () => {
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Password reset successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    );
                },
                error => {
                    ErrorHandler.showDialog('Failed to reset password', error);
                    console.error('Failed to reset password:', error);
                }
            );
        }, () => { });
    }

    const template = `
    <md-dialog aria-label="Reset password" style="max-width:600px;">
        <form name="resetPasswordForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Reset password</h2>
                </div>
            </md-toolbar>
            <md-dialog-content>

                <div layout="column" class="md-padding">
                    <md-input-container>
                        <label>Reset code</label>
                        <input type="text" ng-model="vm.code" required/>
                    </md-input-container>

                    <md-input-container>
                        <label>New password</label>
                        <input type="password" name="newPassword" ng-model="vm.password" minlength="8" ng-minlength="8" required/>
                        <div ng-messages="resetPasswordForm.newPassword.$error">
                            <div ng-message="minlength">Passwords must be at least 8 characters.</div>
                        </div>
                    </md-input-container>
                </div>

            </md-dialog-content>
            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    ng-disabled="resetPasswordForm.$invalid"
                    ng-click="vm.resetPassword()"
                >Reset password</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `;
}
function dialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.code = '';
    vm.password = '';

    vm.cancel = $mdDialog.cancel;
    vm.resetPassword = resetPassword;

    function resetPassword() {
        $mdDialog.hide({
            code: vm.code,
            password: vm.password
        });
    }
}

export { gbbgPasswordResetButton };
