function AccessService($http) {
    'ngInject';
    const service = {
        records: [],
        getAccessRecords(params = {}) {
            return $http.get('/api/access', { params }).then(response => {
                if (response.status != 200) throw response;
                if (response.data.records) response.data.records.map(record => service.records.push(record));
                return response.data;
            }, failure);
        },
        clearRecords() { service.records.splice(0); }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { throw error; }
}

export { AccessService };
