const gbbgSpinner = {
    template: `
    <div style="margin: auto; text-align: center;" ng-if="vm.spinning">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
    </div>
    `,
    bindings: { spinning: '<' },
    controller,
    controllerAs: 'vm'
}
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;
}

export { gbbgSpinner };
