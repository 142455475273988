const gbbgAssignLeadSelect = {
    template: `
    <div layout="column">
        <md-input-container ng-if="vm.users.length > 0">
            <label>Assign lead to...</label>
            <md-select ng-model="vm.selectedUser" ng-change="vm.openDialog()" ng-disabled="::vm.disabled">
                <md-option
                    ng-repeat="user in vm.users track by user._id"
                    ng-value="user._id"
                    ng-disabled="::vm.disabled"
                >{{user.firstName + ' ' + user.surname}}</md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: {
        leadId: '@',
        assignedTo: '=',
        status: '@'
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, $state, ErrorHandler, LeadService, LeadHandler, UserService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedUser = '';
    vm.users = [];

    vm.openDialog = openDialog;

    vm.$onInit = activate;

    function activate() {
        vm.disabled = !localStorage.gbbgUserAccess || localStorage.gbbgUserAccess <= 101;
        vm.selectedUser = (vm.assignedTo && vm.assignedTo._id) ? vm.assignedTo._id : '';
        UserService.getAllUsers().then(
            data => vm.users = data.filter((user) => user.active || user._id == vm.selectedUser),
            error => {
                ErrorHandler.showDialog('Failed to load users', error, 'Retry', true);
                console.error("Failed to get users:", error);
            }
        );
    }

    function openDialog() {
        if (vm.disabled || !vm.selectedUser || !vm.leadId) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Change assignee')
            .textContent('Are you sure you want to reassign this client?')
            .ariaLabel('Change assignee')
            .ok('Assign')
            .cancel('Cancel')
        ).then(() => assignLead(), () => {});
    }

    function assignLead() {
        if (isInvalid()) return;
        LeadService.assignLeads([ vm.leadId ], [ vm.selectedUser ]).then(
            data => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Reassigned successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
                LeadHandler.currentLead.assignedTo = vm.users.filter(({ _id }) => _id === vm.selectedUser).shift();
            },
            error => {
                ErrorHandler.showDialog('Failed to reassign client', error);
                console.error('Failed to assign client:', error);
            }
        );
    }

    function isInvalid() {
        return (
            !vm.selectedUser ||
            (vm.assignedTo && vm.selectedUser === vm.assignedTo._id)
        );
    }
}

export { gbbgAssignLeadSelect };
