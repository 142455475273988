const gbbgClaimClose = {
    template: `
    <md-button
        class="md-warn"
        ng-disabled="vm.cannotClose()"
        ng-click="vm.openDialog()"
    > Close claim </md-button>
    `,
    bindings: {
        claimId: '@',
        status: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.cannotClose = cannotClose;
    vm.openDialog = openDialog;

    function cannotClose() {
        return ['closed', 'refunded', 'invoiced'].includes(vm.status.status);
    }

    function openDialog() {
        $mdDialog.show(
            $mdDialog.prompt()
            .title('Are you sure you want to close this claim?')
            .textContent(`Closing a claim is a permanent action. Only do this if you're sure a claim will not be refunded or the client no longer wishes to proceed with the application.`)
            .placeholder('Reason for closing')
            .ariaLabel('Reason for closing')
            .required(true)
            .ok('Close claim')
            .cancel('Cancel')
        ).then((reason) => {
            ClaimService.closeClaim(reason).then((data) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Claim closed successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            }, (error) => {
                ErrorHandler.showDialog('Failed to close claim', error);
                console.error('Failed to close claim:', error);
            });
        }, () => {});
    }
}

export { gbbgClaimClose }
