import { gbbgFlagDetails } from './flag_details';
import { gbbgNewFlag } from './flag_new_button';
import { flagRoutes } from './flag_routes';
import { gbbgEligibilityFlagSelect } from './flag_select';
import { FlagService } from './flag_service';
import { gbbgUpdateFlag } from './flag_update';

angular
.module('flag', [])
.config(flagRoutes)
.component('gbbgFlagDetails', gbbgFlagDetails)
.component('gbbgNewFlag', gbbgNewFlag)
.component('gbbgEligibilityFlagSelect', gbbgEligibilityFlagSelect)
.component('gbbgUpdateFlag', gbbgUpdateFlag)
.factory('FlagService', FlagService);

export default 'flag';
