const gbbgUsersByEmail = {
    template: `
    <md-autocomplete
        flex
        required
        md-selected-item="vm.user"
        md-search-text="vm.searchText"
        md-search-text-change="vm.getUsers(vm.searchText)"
        md-items="item in vm.users"
        md-item-text="item.email"
        md-selected-item-change="vm.setUser()"
        md-floating-label="Email address" md-min-length="0"
    >
        <md-item-template>
            <span md-highlight-text="vm.searchText">{{item.email}}</span>
        </md-item-template>
    </md-autocomplete>
    `,
    bindings: { selectedUser: '=' },
    controller,
    controllerAs: 'vm'
};
function controller(UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.searchText = '';

    vm.select = {}

    vm.user;
    vm.users = [];

    vm.getUsers = getUsers;
    vm.setUser = setUser;
    vm.itemText = itemText;

    vm.$onInit = activate;

    function activate() {
        vm.user = { email: vm.selectedUser.email }
    }

    function getUsers(email = '') {
        vm.selectedUser.email = email;
        UserService.getUsersByEmail(email).then(
            data => vm.users = data,
            error => console.error('Failed to get users by email:', error)
        );
    }

    function setUser() {
        if (!vm.user || !vm.user._id) return;
        vm.selectedUser = vm.user || {};
    }

    function itemText() { return vm.selectedUser.email || ''; }
}

export { gbbgUsersByEmail };
