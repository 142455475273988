function leadViewController($state, $stateParams, $timeout, ErrorHandler, LeadService, LeadHandler) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.isLoaded = false;

    vm.id = $stateParams.leadId;

    vm.lead = LeadHandler.currentLead;

    vm.$onInit = activate;

    function activate() {
        LeadHandler.clearCurrentLead(); // wipe any existing leads
        LeadService.getLead(vm.id).then(
            data => {
                LeadHandler.setCurrentLead(data).then(lead => {});
                $timeout(() => vm.isLoaded = true, 500);
            },
            error => {
                ErrorHandler.showDialog('Failed to load lead', error, 'Retry', true);
                console.error("Failed to get lead:", error);
            }
        );
    }
}

export { leadViewController };
