const gbbgClaimRevert = {
    template: `
    <div layout="row">
        <md-button
            class="md-warn"
            ng-click="vm.openDialog()"
            gbbg-hide-unless-admin
            flat
        >REVERT</md-button>
    <div>
    `,
    transclude: true,
    bindings: { certs: '<', selectedCert: '=' },
    controller,
    controllerAs: 'vm'
}
function controller($scope, $mdDialog, AuthService, ClaimService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.openDialog = openDialog

    function openDialog() {
        if (!AuthService || !AuthService.isAdmin()) { return; }
        $mdDialog.show({
            template: `
                <md-dialog aria-label="Revert claim dialog">
                    <md-toolbar class="md-warn">
                        <div class="md-toolbar-tools">
                            <h2>Revert claim?</h2>
                            <span flex></span>
                            <gbbg-close-dialog></gbbg-close-dialog>
                        </div>
                    </md-toolbar>
                    <md-dialog-content>
                        <md-content class="md-padding" layout="column">
                            <p>Warning! This will revert a claim to a previous state. Values, calculations and claim information may be lost. This action cannot be undone.</p>
                            <p>Only administrators have access to this feature.</p>
                            <p>Please insert your password below to continue.</p>
                            <md-input-container>
                                <label>Admin password</label>
                                <input type="password" ng-model="vm.password"/>
                            </md-input-container>
                        </md-content>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <md-button ng-click="vm.closeDialog()">Cancel</md-button>
                        <md-button
                            ng-click="vm.revert()"
                            ng-disabled="!vm.password || !vm.password.length || vm.password.length < 5"
                            class="md-warn"
                        >Revert claim</md-button>
                    </md-dialog-actions>
                </md-dialog>
            `,
            controller: DialogController,
            controllerAs: 'vm'
        }).then(
            revertClaim,
            () => {}
        )
    }

    function revertClaim(password) {
        if (!password || !password.length || !password.length > 5) { return; }
        ClaimService
        .revertClaim(password)
        .then((data) => { window.location.reload() },
        (error) => {
            ErrorHandler.showDialog('Failed to revert claim', error)
            console.error('Failed to revert claim:', error)
        })
    }
}

function DialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.closeDialog = $mdDialog.hide

    vm.revert = revert

    function revert() { $mdDialog.hide(vm.password) }
}

export { gbbgClaimRevert }
