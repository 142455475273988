import { countryCodes } from './utils_country_code';

import { gbbgAlwaysDisabled } from './utils_always_disabled';
import { utilsApiImage } from './utils_api_image';
import { capitalize } from './utils_capitalize';
import { claimStatus } from './utils_claims_status_filter';
import { gbbgCloseDialog } from './utils_close_dialog';
import { gbbgCurrencySelect } from './utils_currency_select';
import { gbbgDisableInputs, disableIfAgent } from './utils_disable_inputs';
import { ErrorHandler } from './utils_error_dialog';
import { gbbgForceKeepFocus } from './utils_force_keep_focus';
import { gbbgHideUnless, gbbgHideUnlessAdmin, gbbgHideUnlessAccManager } from './utils_hide_unless_admin';
import { gbbgLocalTime } from './utils_local_time';
import { gbbgQuerySort } from './utils_query_sort';
import { startFrom } from './utils_repeat_offset_filter';
import { gbbgSpinner } from './utils_spinner';
import { gbbgTimezoneSelect, gbbgCountrySelect, TimezoneService } from './utils_timezone_select';

angular.module('utils', [])
.config(() => {
    if (localStorage && (!localStorage.gbbgCountryCodes || !localStorage.gbbgCountryCodes.length)) {
        const countryCodesAsString = JSON.stringify(countryCodes);
        localStorage.setItem('gbbgCountryCodes', countryCodesAsString);
    }
})
.factory('ErrorHandler', ErrorHandler)
.factory('TimezoneService', TimezoneService)
.filter('capitalize', capitalize)
.filter('claimStatus', claimStatus)
.filter('startFrom', startFrom)
.directive('gbbgAlwaysDisabled', gbbgAlwaysDisabled)
.directive('utilsApiImage', utilsApiImage)
.directive('gbbgDisableInputs', gbbgDisableInputs)
.directive('disableIfAgent', disableIfAgent)
.directive('gbbgForceKeepFocus', gbbgForceKeepFocus)
.directive('gbbgHideUnless', gbbgHideUnless)
.directive('gbbgHideUnlessAdmin', gbbgHideUnlessAdmin)
.directive('gbbgHideUnlessAccManager', gbbgHideUnlessAccManager)
.directive('gbbgLocalTime', gbbgLocalTime)
.directive('gbbgQuerySort', gbbgQuerySort)
.component('gbbgCloseDialog', gbbgCloseDialog)
.component('gbbgCurrencySelect', gbbgCurrencySelect)
.component('gbbgSpinner', gbbgSpinner)
.component('gbbgCountrySelect', gbbgCountrySelect)
.component('gbbgTimezoneSelect', gbbgTimezoneSelect);

export default 'utils';
