const gbbgAddTeamMemberButton = {
    template: `
    <md-button class="md-primary" ng-click="vm.openDialog()">
        Add {{vm.admin ? 'admins' : 'users'}}
    </md-button>
    `,
    bindings: { admin: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, $stateParams, ErrorHandler, TeamService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        if (!$stateParams.id) return;
        TeamService.getNonMembers($stateParams.id).then(
            data => {
                $mdDialog.show({
                    template,
                    locals: { users: data },
                    controller: dialogController,
                    controllerAs: 'vm'
                }).then((selectedUsers) => {
                    if (vm.admin && (vm.admin === 'true' || vm.admin === true)) {
                        addAdmins(selectedUsers)
                    } else {
                        addMembers(selectedUsers);
                    }
                }, () => {});
            },
            error => {
                ErrorHandler.showDialog('Failed to get users', error);
                console.error('Failed to get users:', error);
            }
        );
    }

    function addMembers(selectedUsers) {
        TeamService.addMembers($stateParams.id, selectedUsers).then(
            data => $state.go($state.current.name, {}, { reload: $state.current.name }),
            error => {
                ErrorHandler.showDialog('Failed to add new members', error);
                console.error('Failed to add members to team:', error);
            }
        );
    }

    function addAdmins(selectedUsers) {
        TeamService.addAdmins($stateParams.id, selectedUsers).then(
            data => $state.go($state.current.name, {}, { reload: $state.current.name }),
            error => {
                ErrorHandler.showDialog('Failed to add administrators to team', error);
                console.error('Failed to add administrators to team:', error);
            }
        );
    }

    const template = `
    <md-dialog aria-label="Add user" style="min-width: 300px; max-width:600px;">
        <form name="newMemberForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Add user</h2>
                    <span flex></span>
                    <gbbg-close-dialog></gbbg-close-dialog>
                </div>
            </md-toolbar>

            <md-dialog-content class="md-padding" layout-padding style="max-height: 300px;">

                <p ng-if="!vm.users.length">
                    No existing user accounts are available for this team.
                </p>

                <gbbg-user-select-list users="vm.users" selected-users="vm.selectedUsers"></gbbg-user-select-list>

            </md-dialog-content>

            <md-dialog-actions layout="row" layout-align="end">
                <md-button ng-click="vm.cancel()">Cancel</md-button>
                <md-button
                    class="md-primary md-raised"
                    ng-click="vm.addUsers()"
                    ng-disabled="vm.selectedUsers.length <= 0"
                >Add <ng-pluralize
                    count="vm.selectedUsers.length"
                    when="{'0': 'users',
                    'one': 'one user',
                    'other': '{} users'}">
                </ng-pluralize> to team</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
    `
}
function dialogController(users, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.user = {};
    vm.users = users || [];
    vm.selectedUsers = [];

    vm.cancel = $mdDialog.cancel;
    vm.addUsers = addUsers;

    function addUsers() {
        $mdDialog.hide(vm.selectedUsers);
    }
}

export { gbbgAddTeamMemberButton };
