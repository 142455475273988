const gbbgClaimListFilters = {
    template: `
    <md-card md-whiteframe="6">
        <md-card-content layout="column" layout-gt-xs="row">
            <md-input-container>
                <label>Claim status</label>
                <md-select ng-model="vm.$stateParams.status" ng-change="vm.go(vm.$stateParams.status, vm.$stateParams.sort, vm.$stateParams.query)" aria-label="Claim status select">
                    <md-option ng-repeat="status in vm.statuses" ng-value="status">{{status}}</md-option>
                </md-select>
            </md-input-container>
            <md-input-container>
                <label>Sort by</label>
                <md-select ng-model="vm.$stateParams.sort" ng-change="vm.go(vm.$stateParams.status, vm.$stateParams.sort, vm.$stateParams.query)" aria-label="Claim status select">
                    <md-option ng-repeat="sortable in vm.sortables" ng-value="sortable.value">{{sortable.label}}</md-option>
                </md-select>
            </md-input-container>
            <div layout="row" layout-align="center center" flex>
                <md-input-container flex>
                    <label>Query</label>
                    <input type="text" ng-model="vm.$stateParams.query"/>
                </md-input-container>
                <md-button class="md-icon-button" aria-label="call" ng-click="vm.go(null, null, vm.$stateParams.query)">
                    <md-icon md-font-library="material-icons">search</md-icon>
                    <md-tooltip md-direction="bottom">Search</md-tooltip>
                </md-button>
                <md-button class="md-icon-button" aria-label="call" ng-click="vm.go(null, null)">
                    <md-icon md-font-library="material-icons">clear</md-icon>
                    <md-tooltip md-direction="bottom">Clear search</md-tooltip>
                </md-button>
            </div>
        </md-card-content>
    </md-card>
    `,
    controller,
    controllerAs: 'vm'
}
function controller($state, $stateParams) {
    'ngInject';
    /*validthis:true*/
    const vm = this

    vm.$stateParams = $stateParams

    vm.query = ''
    vm.status = ''
    vm.sort = ''

    vm.statuses = [
        'any',
        'pending',
        'processing',
        'submitted',
        'resubmitted',
        'refunded',
        'invoiced',
        'closed',
        'rejected'
    ]
    vm.sortables = [
        { label: 'Date created (descending)', value: '-dateCreated' },
        { label: 'Date created (ascending)', value: 'dateCreated' },
        { label: 'Claim reference', value: 'referenceNo' },
        { label: 'Client name (ascending)', value: 'client.name' },
        { label: 'Client name (descending)', value: '-client.name' },
    ]

    vm.go = go

    vm.$onInit = activate

    function activate() {
        vm.query = $stateParams.query || ''
        vm.status = $stateParams.status || ''
        vm.sort = $stateParams.sort || ''
    }

    function go(status, sort, query) {
        setFiltersToLocalStorage(vm.$stateParams)
        $state.go($state.current.name, {
            status: status || vm.$stateParams.status || 'processing',
            sort: sort || vm.$stateParams.sort || '-dateCreated',
            query: (query == null) ? null : (query || $stateParams.query || null)
        }, { reload: $state.current.name })
    }

    function setFiltersToLocalStorage({ status = '', sort = '', query = '' }) {
        localStorage.setItem('gbbgClaimFilterStatus', status)
        localStorage.setItem('gbbgClaimFilterSort', sort)
        localStorage.setItem('gbbgClaimFilterQuery', query)
    }
}

export { gbbgClaimListFilters }
