const gbbgCurrencySelect = {
    template: `
    <md-input-container>
        <label>Currency</label>
        <md-select ng-model="vm.currency">
            <md-option
                ng-repeat="(key, currency) in vm.currencies"
                ng-value="currency"
            > {{currency}} </md-option>
        </md-select>
    </md-input-container>
    `,
    bindings: { currency: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($http, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.currencies = [];

    vm.$onInit = activate;

    function activate() {
        $http.get('https://openexchangerates.org/api/currencies.json').then(
            response => {
                if (response && response.data) return vm.currencies = response.data;
                ErrorHandler.showDialog(
                    'Failed to get currencies',
                    'An error occurred while loading a list of currencies. Please try again.',
                    'Retry',
                    false,
                    activate
                );
            }
        );
    }
}

export { gbbgCurrencySelect };
