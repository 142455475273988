const gbbgReportFileExport = {
    template: `
    <div layout='row' layout-align='center center'>
        <table id='pdf-table'></table>
        <md-menu>
            <md-button
                aria-label='Open phone interactions menu'
                ng-click='vm.openMenu($mdMenu, $event)'
                ng-disabled='vm.isInvalid()'
            > Export as...</md-button>
            <md-menu-content width='4'>
                <md-menu-item ng-repeat='export in vm.exportTypes'>
                    <md-button
                        ng-click='vm.checkExport(export.docType)'
                        ng-disabled='export.disabled()'
                    > {{ export.text }}</md-button>
                </md-menu-item>
          </md-menu-content>
        </md-menu>
        <md-progress-circular class='md-hue-2' ng-disabled='!vm.processing' md-diameter='16px'></md-progress-circular>
    </div>
    `,
    bindings: {
        claimIds: '<?',
        clientIds: '<?',
        sourceIds: '<?'
    },
    controller,
    controllerAs: 'vm'
};
function controller($http, $mdDialog, $stateParams, PdfService, ReportService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.exportTypes = [
        { text: 'Records as PDF', docType: 'pdf' },
        { text: 'Records as Excel', docType: 'excel' },
        {
            text: 'Charges & fee sheet (Excel)',
            docType: 'chargesExcel',
            disabled() {
                const { report } = $stateParams;
                return !report || report == 'null' || ['pending', 'submitted'].includes(report);
            }
        },
        {
            text: 'Charges & fee sheet (PDF)',
            docType: 'chargesPdf',
            disabled() {
                const { report } = $stateParams;
                return !report || report == 'null' || ['pending', 'submitted'].includes(report);
            }
        }
    ];

    vm.openMenu = openMenu;
    vm.checkExport = checkExport;
    vm.isInvalid = isInvalid;

    vm.$onInit = activate;

    function activate() {
        if (vm.claimIds && localStorage.gbbgUserAccess > 301) {
            vm.exportTypes.unshift({
                text: '13th Directive submission',
                docType: '13th',
                disabled() { return !['submitted', 'submittedNotResubmitted', 'resubmitted'].includes($stateParams.report) }
            });
            vm.exportTypes.push({ text: 'Commission as PDF', docType: 'commissionPdf' });
            vm.exportTypes.push({ text: 'Commission as Excel', docType: 'commissionExcel' });
            vm.exportTypes.push({ text: 'Commission PDF incl. claims', docType: 'commClaimsPdf' });
            vm.exportTypes.push({ text: 'Commission Excel incl. claims', docType: 'commClaimsExcel' });
        }
        if (vm.clientIds && localStorage.gbbgUserAccess > 301) {
            vm.exportTypes.push({ text: 'Claim sheet as PDF', docType: 'claimPdf' });
            vm.exportTypes.push({ text: 'Claim sheet as Excel', docType: 'claimExcel' });
            vm.exportTypes.push({ text: 'Cert expiries as PDF', docType: 'certsPdf', disabled() { return $stateParams.report !== 'certs' } });
            vm.exportTypes.push({ text: 'Cert expiries as Excel', docType: 'certsExcel', disabled() { return $stateParams.report !== 'certs' } });
        }
    }

    function openMenu($mdMenu, ev) { $mdMenu.open(ev); }

    function checkExport(docType) {
        if (docType !== 'chargesExcel' && docType !== 'chargesPdf') return exportReport(docType);
        $mdDialog.show(
            $mdDialog.alert()
            .title('Export claim charges')
            .textContent('Only refunded claims will be exported with this report.')
            .ariaLabel('Confirm export')
            .ok('Okay')
        ).then(() => exportReport(docType));
    }

    function exportReport(docType) {
        if (vm.isInvalid()) return;
        vm.processing = true;

        const url = window.location.getOriginRedirectUrl();

        if (vm.claimIds && vm.claimIds.length) {
            ReportService.exportClaimReport(vm.claimIds, docType).then(
                (data) => {
                    vm.processing = false;
                    if (docType.toLowerCase().includes('pdf')) {
                        // PdfService.create(docType, data)
                        vm.processing = false
                    // } else if (docType === 'chargesPdf') {
                    //     console.log('Result:', data)
                    } else {
                        const type = docType.toLowerCase().includes('excel') ? 'excel' : 'pdf';
                        window.open(`${url}/${type}/${data}?auth_token=${localStorage.gbbgToken}`);
                        // window.open(`${url}/report/claim/${docType}/${data}?auth_token=${localStorage.gbbgToken}`);
                    }
                }, (error) => {
                    vm.processing = false
                    ErrorHandler.showDialog('Failed to export claim report', error)
                    console.error('Failed to export claim report:', error)
                }
            );
        } else if (vm.clientIds && vm.clientIds.length) {
            ReportService.exportClientReport($stateParams, vm.clientIds, docType).then(
                data => {
                    vm.processing = false;
                    window.open(`${url}/report/client/${docType}/${data}?auth_token=${localStorage.gbbgToken}`);
                }, error => {
                    vm.processing = false;
                    ErrorHandler.showDialog('Failed to export client report', error);
                    console.error('Failed to export client report:', error);
                }
            );
        }
    }

    function isInvalid() {
        return (
            vm.processing ||
            (
                (!vm.claimIds || !vm.claimIds.length) &&
                (!vm.clientIds || !vm.clientIds.length) &&
                (!vm.sourceIds || !vm.sourceIds.length)
            )
        );
    }
}

export { gbbgReportFileExport };
