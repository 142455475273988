const gbbgLeadSelect = {
    template: `
    <div layout="column">
        <div layout="row" layout-align="space-between center">
            <div>
                <ng-transclude></ng-transclude>
            </div>
            <div class="md-padding">
                <label>Select all leads</label>
                <md-checkbox
                    class="no-margin"
                    ng-checked="vm.leads.length && (vm.selectedLeads.length === vm.leads.length)"
                    ng-click="vm.toggleAll()"
                    aria-label="Select all leads"
                ></md-checkbox>
            </div>
        </div>
        <md-divider></md-divider>
        <md-list class="md-dense">
            <md-list-item ng-repeat="lead in vm.leads">
                {{lead.name}}
                <md-checkbox
                    class="md-secondary"
                    ng-checked="vm.selectedLeads.indexOf(lead._id) >= 0"
                    ng-click="vm.toggleLead(lead._id)"
                    aria-label="{{lead.name}}"
                >{{lead.name}}</md-checkbox>
                <md-divider></md-divider>
            </md-list-item>
        </md-list>
    </div>
    `,
    bindings: {
        leads: '<',
        selectedLeads: '='
    },
    transclude: true,
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.toggleAll = toggleAll;
    vm.toggleLead = toggleLead;

    vm.$onInit = activate;

    function activate() { if (!vm.selectedLeads) vm.selectedLeads = []; }

    function toggleAll() {
        if (!vm.leads) vm.leads = [];
        const allSelected = (vm.selectedLeads.length > 0 && vm.selectedLeads.length === vm.leads.length)
        if (vm.selectedLeads) vm.selectedLeads.splice(0);
        if (!allSelected) vm.leads.map(l => {
            if (!vm.selectedLeads.includes(l._id)) vm.selectedLeads.push(l._id);
        });
    }

    function toggleLead(leadId) {
        const idx = vm.selectedLeads.indexOf(leadId);
        (idx >= 0) ? vm.selectedLeads.splice(idx, 1) : vm.selectedLeads.push(leadId);
    }
}

export { gbbgLeadSelect };
