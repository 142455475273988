const gbbgClientBusinessTravellers = {
    template: `
    <md-button ng-click="vm.openDialog()">
        <md-icon>flight</md-icon>({{vm.travellers.length || '0'}})
        <md-tooltip>Add business travellers</md-tooltip>
    </md-button>
    `,
    bindings: { clientId: '@', travellers: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            template,
            locals: {
                clientId: vm.clientId,
                travellers: vm.travellers || []
            },
            controller: dialogController,
            controllerAs: 'vm'
        }).then((data) => {
            if (data && data.length) {
                vm.travellers.splice(0);
                data.map((traveller) => vm.travellers.push(traveller));
            }
        }, () => { });
    }

    const template = `
    <md-dialog aria-label="Business travellers" style="max-width:600px;">
        <form name="businessTravellerForm">
            <md-toolbar>
                <div class="md-toolbar-tools">
                    <h2>Business travellers</h2>
                    <span flex></span>
                    <md-button class="md-icon-button" ng-click="vm.cancel()">
                        <md-icon>close</md-icon>
                    </md-button>
                </div>
            </md-toolbar>
            <md-dialog-content>

                <div class="md-padding">
                    <p>Add business travellers to the lead/client record so they can be added to claims/invoices for quickly later.</p>

                    <div layout="row" layout-align="start center">
                        <md-input-container flex>
                            <label>Traveller name</label>
                            <input type="text" ng-model="vm.traveller.name" required/>
                        </md-input-container>
                        <md-input-container flex>
                            <label>Traveller position / title</label>
                            <input type="text" ng-model="vm.traveller.position"/>
                        </md-input-container>
                        <md-button
                            class="md-primary md-raised"
                            ng-click="vm.addTraveller()"
                            ng-disabled="businessTravellerForm.$invalid"
                        >Add</md-button>
                    </div>

                    <p class="text--warn" ng-if="vm.showError">An error occurred while adding a new business traveller.</p>
                </div>

                <md-list>
                    <md-divider></md-divider>
                    <md-list-item ng-repeat="traveller in vm.travellers track by $index">
                        <span><b>{{traveller.name}}</b> {{traveller.position}}</span>
                        <md-divider></md-divider>
                    </md-list-item>
                </md-list>
            </md-dialog-content>
        </form>
    </md-dialog>
    `;
}
function dialogController($mdDialog, clientId, travellers, ClientService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.showError = false;

    vm.travellers = travellers;

    vm.traveller = { name: '', position: '' };

    vm.addTraveller = addTraveller;
    vm.cancel = cancel;

    function addTraveller() {
        vm.showError = false;
        ClientService.addBusinessTraveller(clientId, vm.traveller).then(
            data => {
                vm.travellers.unshift(angular.extend({}, vm.traveller, data));
            },
            error => {
                vm.showError = true;
                console.error('Failed to add new business traveller:', error);
            }
        );
    }

    function cancel() { $mdDialog.hide(angular.copy(vm.travellers)); }
}

export { gbbgClientBusinessTravellers };
