const ACCESS_RIGHTS = {
    '101': 'Agent',
    '201': 'Office manager',
    '301': 'Agent manager',
    '501': 'Account manager',
    '701': 'Management',
    '901': 'Super user'
};

export { ACCESS_RIGHTS };
