const gbbgClaimInvoiceActions = {
    template: `
    <md-checkbox class="md-secondary" ng-model="vm.selected" aria-label="Select invoice"></md-checkbox>
    `,
    // require: { parent: '^gbbgClaimInvoices' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.$onInit = activate;

    function activate() {
        // console.log('Invoice actions:', vm.parent);
    }
}

export { gbbgClaimInvoiceActions };
