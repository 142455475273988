const gbbgClaimSummary = {
    template: `
    <md-card ng-style="vm.cardClass()">
        <md-card-content>
            <h2>
                <span ng-bind="vm.claim.client.name"></span>
                <md-icon
                    md-font-library="material-icons"
                    ui-sref="root.auth.active.client({ id: vm.claim.client._id })"
                > open_in_new
                    <md-tooltip md-direction="down">Open client</md-tooltip>
                </md-icon>
            </h2>

            <h3>
                <small ng-bind="vm.claim.client.address.city + ','"></small>
                <small ng-bind="vm.claim.client.address.country"></small>
            </h3>

            <p>Current claim status: <b>{{vm.claim.status.status | claimStatus}}</b></p>
            <p ng-if="vm.claim.status.details">Status details: {{vm.claim.status.details}}</p>
            <p>VAT claimed: <b>{{vm.claim.totalClaimed | currency: '£'}}</b></p>
            <p>Est. value: <b>{{vm.claim.commissionValue | currency: '£'}}</b></p>
            <p>Commission: <b>{{vm.claim.commissionFee}}%</b></p>

            <div layout="row" gbbg-hide-unless-acc-manager>
                <md-input-container flex>
                    <label>Flag details</label>
                    <md-select ng-model="vm.selectedFlagDetails" ng-change="vm.updateFlag()">
                        <md-option ng-value="">No flag</md-option>
                        <md-option
                            ng-repeat="details in vm.flagDetails track by $index"
                            ng-value="details"
                        >{{details}}</md-option>
                    </md-select>
                    <span class="hint">Quickly identifiable claim status details</span>
                </md-input-container>

                <md-input-container>
                    <label>Flag colour</label>
                    <md-select ng-model="vm.selectedColour" ng-change="vm.updateFlag()">
                        <md-option ng-value="">No colour</md-option>
                        <md-option
                            ng-repeat="(key, color) in vm.colourHash"
                            ng-value="key"
                        >{{key}}</md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-card-content>
    </md-card>

    <md-card>
        <md-card-content>
            <p style="margin-top:4px;margin-bottom:4px;">
                <md-icon md-font-library="material-icons">
                    face
                    <md-tooltip md-direction="left">Created by</md-tooltip>
                </md-icon>
                {{vm.claim.createdBy.firstName + ' ' + vm.claim.createdBy.surname}}
            </p>
            <p style="margin-top:4px;margin-bottom:4px;">
                <md-icon md-font-library="material-icons">
                    info
                    <md-tooltip md-direction="left">Claim ID</md-tooltip>
                </md-icon>
                <small>{{::vm.claim._id}}</small>
            </p>
            <p style="margin-top:4px;margin-bottom:4px;">
                <md-icon md-font-library="material-icons">
                    info_outline
                    <md-tooltip md-direction="left">Reference no.</md-tooltip>
                </md-icon>
                {{vm.claim.referenceNo}}
            </p>
            <p style="margin-top:4px;margin-bottom:4px;">
                <md-icon md-font-library="material-icons">
                    event_note
                    <md-tooltip md-direction="left">Date created</md-tooltip>
                </md-icon>
                {{vm.claim.dateCreated | date:'dd MMM, yyyy'}}
            </p>
        </md-card-content>
    </md-card>

    <md-card>
        <md-card-content>

            <gbbg-toggle-claim-paperwork paperwork="vm.claim.paperwork"></gbbg-toggle-claim-paperwork>

        </md-card-content>
    </md-card>
    `,
    bindings: { claim: '<' },
    controller,
    controllerAs: 'vm'
};
function controller(ClaimService) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.colourHash = {
        blue: '#5C5CFF', // '#0000ff'
        green: '#5CFF5C', // '#00ff00'
        yellow: '#FFFF5C', // '#ffff00'
        orange: '#FFAE5C', // '#ff8000'
        red: '#FF5C5C' //'#FF0000'
    };

    vm.flagDetails = [
        'awaiting paperwork',
        'awaiting more or certified invoices',
        'claim refused (details pending)',
        'awaiting refund deposit',
        'query pending on bank details',
        'awaiting payment details'
    ];

    // vm.vatClaimed = vatClaimed;
    vm.cardClass = cardClass;
    vm.updateFlag = updateFlag;

    vm.$onInit = activate;

    function activate() {
        if (vm.claim && vm.claim.status && vm.claim.status.flag) {
            vm.selectedColour = vm.claim.status.flag.colour;
            vm.selectedFlagDetails = vm.claim.status.flag.details;
        }
    }

    // function vatClaimed() {
    //     if (vm.claim.invoices && vm.claim.invoices.length > 0) {
    //         return vm.claim.invoices.map((inv) => inv.vatClaimed).reduce((acc, val) => acc += val, 0);
    //     } else {
    //         return 0;
    //     }
    // }

    function updateFlag() {
        ClaimService.setFlag(vm.selectedFlagDetails, vm.selectedColour);
    }

    function cardClass() {
        if (localStorage.gbbgUserAccess <= 301 || !vm.claim.status.flag || !vm.claim.status.flag.colour) return '';
        return { 'background': vm.colourHash[vm.selectedColour] }
    }
}

export { gbbgClaimSummary };
