const gbbgClientClaimSheet = {
    template: `
    <md-button
        class="md-fab md-fab-top-right"
        aria-label="Get claims sheet"
        ng-click="vm.getClaimSheet()"
    >
        <md-tooltip md-direction="left">Get claims sheet</md-tooltip>
        <md-icon>receipt</md-icon>
    </md-button>
    `,
    controller,
    controllerAs: 'vm'
}
function controller($stateParams, ActiveService, ErrorHandler) {
    'ngInject'
    /*validthis:true*/
    const vm = this

    vm.getClaimSheet = getClaimSheet

    function getClaimSheet() {
        ActiveService
        .getClaimSheet($stateParams.id)
        .then((data) => {
            console.log('DATA:', data)
            const url = window.location.getOriginRedirectUrl()
            window.open(`${url}/pdf/${data}?auth_token=${localStorage.gbbgToken}`)
        }, (error) => {
            ErrorHandler.showDialog('Failed to get claim sheet', error)
            console.error('Failed to get claim sheet:', error)
        })
    }
}

export { gbbgClientClaimSheet }
