import { default as angular } from 'angular'
import { default as ngSanitize } from 'angular-sanitize'
import { default as AngularMaterial } from 'angular-material'
import { default as AngularMessages } from 'angular-messages'
import { default as AngularUiRouter } from 'angular-ui-router'

import { config, httpRequestInterceptor } from './app_config'
import { ACCESS_RIGHTS } from './app_constants'
import { routeConfig, runOnStateChange } from './app_routes'

import { default as Auth } from './js/auth/auth'
import { default as Claim } from './js/claim/claim'
import { default as Client } from './js/client/client'
import { default as IssuingAuthority } from './js/issuing-authority/authority'
import { default as Lead } from './js/lead/lead'
import { default as Management } from './js/management/management'
import { default as Nav } from './js/nav/nav'
import { default as Notification } from './js/notification/notification'
import { default as Pdf } from './js/pdf/pdf'
import { default as Source } from './js/source/source'
import { default as Supplier } from './js/supplier/supplier'
import { default as Utils } from './js/utils/utils'

angular.module('app', [
    ngSanitize,
    AngularMaterial,
    AngularMessages,
    AngularUiRouter,
    Auth,
    Claim,
    Client,
    IssuingAuthority,
    Lead,
    Management,
    Nav,
    Notification,
    Pdf,
    Source,
    Supplier,
    Utils
])
.constant('ACCESS_RIGHTS', ACCESS_RIGHTS)
.config(config)
.factory('httpRequestInterceptor', httpRequestInterceptor)
.config(routeConfig)
.run(runOnStateChange)

Number.prototype.toFixedNoRounding = function(n) {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g')
    const a = this.toString().match(reg)[0]
    const dot = a.indexOf('.')
    if (dot === -1) { // integer, insert decimal dot and pad up zeros
        return a + '.' + '0'.repeat(n)
    }
    const b = n - (a.length - dot) + 1
    return b > 0 ? (a + '0'.repeat(b)) : a
}
