const gbbgUserAccessSelect = {
    template: `
    <div layout="column">
        <md-input-container flex>
            <label>Access level</label>
            <md-select
                class="no-top-margin"
                ng-model="vm.access"
                ng-change="vm.updateAccess()"
                ng-disabled="!vm.hasWriteAccess || vm.hasWriteAccess === false"
                aria-label="User access level"
                required
            >
                <md-option
                    ng-repeat="(key, value) in vm.accessRights"
                    ng-disabled="key > vm.maxAccess"
                    ng-value="key"
                >{{value}}</md-option>
            </md-select>
        </md-input-container>
    </div>
    `,
    bindings: {
        userId: '<',
        userAccess: '=',
        hasWriteAccess: '<'
    },
    controller,
    controllerAs: 'vm'
};
function controller(ACCESS_RIGHTS, $mdDialog, AuthService, UserService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.access = '101';
    vm.maxAccess = '101';

    vm.accessRights = ACCESS_RIGHTS;

    vm.updateAccess = updateAccess;

    vm.$onInit = activate;

    function activate() {
        vm.access = vm.userAccess || '101';
        vm.maxAccess = localStorage.gbbgUserAccess || '101';
    }

    function updateAccess() {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Update user access rights?')
            .textContent('This change may affect which features this user has access to.')
            .ariaLabel('Update user access')
            .ok('Change access')
            .cancel('Cancel')
        ).then(
            () => {
                UserService.changeAccess(vm.userId, vm.access).then(
                    data => {
                        vm.userAccess = vm.access;
                        /* update the localStorage if the updated user is the logged in user
                        so that access rights will be updated throughout the app. */
                        if (UserService.user && UserService.user._id == localStorage.gbbgUserId) {
                            localStorage.gbbgUserAccess = vm.access;
                        }
                    },
                    error => {
                        ErrorHandler.showDialog('Failed to update user access level', error);
                        console.error('Failed to update user access level:', error);
                    }
                );
            },
            () => {}
        );
    }
}

export { gbbgUserAccessSelect };
