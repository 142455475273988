const gbbgClaimImgGrid = {
    template: `
    <div>
        <div style="margin-bottom: 24px;">
            <md-grid-list
                ng-if="vm.files && vm.files.length"
                md-cols-xs="3"
                md-cols="5"
                md-row-height="1:1"
                md-gutter="12px"
                md-gutter-gt-sm="8px"
            >
                <md-grid-tile
                    ng-repeat="file in vm.files"
                    style="
                        background-size: cover;
                        box-sizing: border-box;
                        border: 1px solid #eee;
                        border-radius: 3px;
                        padding: 2px;
                    "
                    utils-api-image="{{file}}"
                    ng-click="vm.openImage(file)"
                ></md-grid-tile>
            </md-grid-list>
        </div>

        <div style ng-if="vm.hasImage">
            <img style="max-width: 100%;" utils-api-image="{{vm.selectedImage}}"/>
        </div>
    </div>
    `,
    bindings: { files: '<' },
    controller,
    controllerAs: 'vm'
};
function controller($timeout) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.hasImage = false;
    vm.selectedImage = null;

    vm.openImage = openImage;

    function openImage(file) {
        vm.hasImage = false;
        vm.selectedImage = file;
        $timeout(() => vm.hasImage = true);
    }
}

export { gbbgClaimImgGrid }
