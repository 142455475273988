function ClientUsersService($http) {
    const service = {
        authorisedUsers: [],
        getAuthorisedUsers(clientId) {
            return $http.get(`/api/clients/active/authorised/${clientId}`).then((response) => {
                if (response.status != 200) throw response;
                service.authorisedUsers.splice(0);
                response.data.map(user => service.authorisedUsers.push(user));
                return response.data;
            }, failure);
        },
        addUser(clientId, user) {
            if (!clientId) return Promise.reject('No client ID pass to addUser function');
            return $http.put(`/api/clients/active/authorised/${clientId}`, user).then((response) => {
                if (response.status != 200) throw response;
                service.authorisedUsers.unshift(response.data);
                return response.data;
            }, failure);
        },
        updateAuthorisedUser(userId, user) {
            return $http.put(`/api/user/authorised/${userId}`, user).then(success, failure);
        },
        resetUserPassword(userId) {
            return $http.put(`/api/user/authorised/password/${userId}`).then(success, failure);
        }
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { throw error; }
}

export { ClientUsersService };
