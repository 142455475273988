const gbbgClaimFeesUpdate = {
    template: `
    <md-button
        class="md-primary"
        ng-disabled="vm.parent.feeForm.$invalid || vm.processing"
        ng-click="vm.update()"
    >Update fees</md-button>
    `,
    require: { parent: '^gbbgClientFees' },
    bindings: { claimId: '@', fees: '=' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.processing = false;

    vm.update = update;

    function update() {
        vm.processing = true;
        if (checkForDuplicates('from')) {
            $mdDialog.show(
                $mdDialog
                .alert()
                .clickOutsideToClose(true)
                .title('Duplicates found')
                .textContent('\'From\' values must be unique.')
                .ariaLabel('Found duplicate figures')
                .ok('Okay')
            ).then(() => vm.processing = false);
        } else {
            $mdDialog.show(
                $mdDialog
                .confirm()
                .title('Update fees for this claim?')
                .textContent('These changes will affect any future claims you make for this claim, but do not affect existing/past claims.')
                .ariaLabel('Update claim fees')
                .ok('Update fees')
                .cancel('Cancel')
            ).then(() => {
                ClaimService.updateFees(vm.claimId, vm.fees).then(
                    data => {
                        vm.processing = false;
                        ClaimService.addTimelineActivity(data);
                        $mdToast.show(
                            $mdToast.simple()
                            .textContent('Updated claim fees successfully!')
                            .position('top right')
                            .hideDelay(4000)
                        );
                    },
                    error => {
                        vm.processing = false;
                        ErrorHandler.showDialog('Failed to update fees', error);
                        console.error(`Failed to update fees for claim '${vm.claimId}':`, error);
                    }
                );
            }, () => {});
        }
    }

    function checkForDuplicates(key) {
        return vm.fees.map(fee => fee.from).some((fee, idx, arr) => arr.indexOf(fee) != idx);
    }
}

export { gbbgClaimFeesUpdate };
