function emailErrorsViewController(EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    let page = 1;

    vm.loading = false;
    vm.showLoadMore = false;
    vm.totalErrors = 0;
    vm.emailErrors = [];

    vm.getEmailErrors = getEmailErrors;

    vm.$onInit = getEmailErrors;

    function getEmailErrors() {
        vm.loading = true;
        EmailService.getEmailErrors(page).then((data) => {
            vm.loading = false;
            data.emailErrors.map(emailError => vm.emailErrors.push(emailError));
            vm.totalErrors = data.total || 0;
            vm.showLoadMore = (data.page < data.pages);
            page = (Number(data.page) + 1);
        }, (err) => {
            vm.loading = false;
            console.error('Failed to get email errors:', err);
            ErrorHandler.showDialog('Failed to get email errors', err)
        })
    }
}

export { emailErrorsViewController }
