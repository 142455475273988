import { supplierRoutes } from './supplier_routes'
import { gbbgSupplierSearch } from './supplier_search'
import { SupplierService } from './supplier_service'

angular
.module('supplier', [])
.config(supplierRoutes)
.component('gbbgSupplierSearch', gbbgSupplierSearch)
.factory('SupplierService', SupplierService)

export default 'supplier'
