const gbbgSelectAuthority = {
    template: `
    <md-autocomplete
        md-selected-item="vm.selectedAuthority"
        md-search-text="vm.searchText"
        md-selected-item-change="vm.selectedItemChange(item)"
        md-items="item in vm.filterAuthorities(vm.searchText)"
        md-item-text="item.name"
        md-floating-label="Issuing authorities"
        md-menu-class="autocomplete-custom-template"
        ng-disabled="!vm.authorities.length"
    >
        <md-item-template>
            <span class="item-title">
                <span md-highlight-text="vm.searchText" md-highlight-flags="^i"> {{item.name}} </span>
            </span>
            <span class="item-metadata">
                <small ng-bind="item.address.location"></small>
            <span>
        </md-item-template>
        <md-not-found>
            <span class="item-title">
                No authorities matching "{{vm.searchText}}" were found.
            </span>
            <!--<span class="item-metadata">
                <a ui-sref="root.auth">Create a new one!</a>
            <span>-->
        </md-not-found>
    </md-autocomplete>
    `,
    bindings: { authority: '=', country: '@' },
    controller,
    controllerAs: 'vm'
};
function controller(AuthorityService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.searchText = '';
    vm.selectedAuthority = { name: '' };
    vm.authorities = [ { name: '' } ];

    vm.filterAuthorities = filterAuthorities;
    vm.selectedItemChange = selectedItemChange;

    vm.$onInit = activate;

    function activate() {
        if (!vm.country) return;
        AuthorityService.getAuthoritiesByCountry(vm.country).then(
            data => {
                vm.authorities = data;
                if (vm.authority) vm.selectedAuthority = vm.authority
            },
            error => {
                ErrorHandler.showDialog('Failed to get issuing authorities', error);
                console.error(`Failed to get issuing authorities for ${vm.country}:`, error);
            }
        );
    }

    function filterAuthorities(searchText) {
        return vm.authorities.filter((auth) => {
            const hasAddress = (auth.address && auth.address.location);
            const nameMatch = hasAddress ? auth.address.location.toLowerCase().includes(searchText.toLowerCase()) : false;
            return nameMatch || auth.name.toLowerCase().includes(searchText.toLowerCase());
        })
    }

    function selectedItemChange(item) {
        vm.authority = item;
    }
}

export { gbbgSelectAuthority };
