import { claimInvoiceDialogController } from './claim_invoice_dialog_controller'

const gbbgEditClaimInvoice = {
    template: `
    <md-button class="md-icon-button" ng-click="vm.openDialog()">
        <md-icon>open_in_browser</md-icon>
        <md-tooltip>Edit invoice</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@', invoice: '=' },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, $mdToast, ClaimService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this

    vm.openDialog = openDialog

    function openDialog(invoice) {
        $mdDialog.show({
            templateUrl: '/partials/templates/claim/edit_invoice_dialog.html',
            locals: {
                country: '', // empty values to prevent DI error
                traveller: '', // empty values to prevent DI error
                invoice: invoice || vm.invoice
            },
            controller: claimInvoiceDialogController,
            controllerAs: 'vm',
            fullscreen: true
        }).then((invoice) => {
            ClaimService.updateInvoice(vm.claimId, invoice).then(
                (data) => {
                    vm.invoice = invoice
                    ClaimService.setClaim(data)
                    $mdToast.show(
                        $mdToast.simple()
                        .textContent('Invoice updated successfully!')
                        .position('top right')
                        .hideDelay(4000)
                    )
                },
                (error) => {
                    ErrorHandler.showDialog('Failed to update invoice', error, 'Okay', false, () => openDialog(invoice))
                    console.error('Failed to update invoice:', error)
                }
            )
        }, () => {})
    }
}

export { gbbgEditClaimInvoice }
