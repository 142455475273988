const gbbgClientBasicDetails = {
    templateUrl: '/partials/templates/client/basic_details.html',
    bindings: { client: "=", onChange: '&' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $state, $timeout, ClientService) {
    "ngInject";
    /*validthis:true*/
    const vm = this;

    vm.selectedItem;
    vm.items = [];

    vm.$state = $state;

    vm.openDialog = openDialog;
    vm.getNames = getNames;
    vm.updateOnChange = updateOnChange

    function getNames(searchText) {
        if (!searchText) return;
        ClientService.getClientNames(searchText).then(
            data => vm.items = data,
            error => console.error('Failed to get client names:', error)
        );
    }

    function openDialog() {
        if (!vm.selectedItem || !vm.selectedItem.name || !vm.selectedItem._id) return;
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Load existing record?')
            .textContent(`A client record already exists for '${vm.selectedItem.name}'. Would you like to load this client record or continue creating a new client?`)
            .ariaLabel('Load existing record')
            .ok('Load existing record')
            .cancel('Make new lead')
        ).then(
            () => {
                if (vm.selectedItem.status && vm.selectedItem.status.status === 'active') {
                    $state.go('root.auth.active.client', { id: vm.selectedItem._id });
                } else {
                    $state.go('root.auth.leads.lead', { leadId: vm.selectedItem._id });
                }
            },
            () => { }
        );
    }

    function updateOnChange({ country }) {
        if (vm.onChange) $timeout(() => vm.onChange({ country }));
    }
}

export { gbbgClientBasicDetails };
