const gbbgSelectTeam = {
    template: `
    <md-input-container>
        <label>Select team</label>
        <md-select ng-model="vm.selectedTeam" ng-change="vm.selectTeam()">
            <md-option
                ng-repeat="team in vm.teams"
                ng-value="team._id"
                aria-label="{{team.name}}"
            >{{team.name}}</md-option>
        </md-select>
    </md-input-container>
    `,
    bindings: {
        users: '=',
        onChange: '&?'
    },
    controller,
    controllerAs: 'vm'
};
function controller(TeamService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.selectedTeam = '';
    vm.users = [];

    vm.selectTeam = selectTeam;

    vm.$onInit = activate;

    function activate() {
        TeamService.getAllTeams().then(
            data => vm.teams = data,
            error => {
                ErrorHandler.showDialog('Failed to get teams', error);
                console.error('Failed to load teams:', error);
            }
        );
    }

    function selectTeam() {
        if (vm.onChange) vm.onChange(); // clear selected users
        if (vm.users) vm.users.splice(0);
        if (vm.selectedUsers) vm.selectedUsers.splice(0);
        TeamService.getTeam(vm.selectedTeam).then(
            data => {
                vm.users = [...data.members, ...data.administrators];
                vm.selectedUsers = vm.users.map(user => user._id);
            },
            error => {
                ErrorHandler.showDialog('Failed to load team users', error);
                console.error('Failed to load users:', error);
            }
        );
    }
}

export { gbbgSelectTeam };
