function PreClaimService($http, ClientService) {
    'ngInject'
    const service = {
        preClaims: [],
        newPreClaim(claim) {
            return $http.post('/api/pre-claims', claim).then(response => {
                if (response.status != 200) throw response;
                service.preClaims.push(response.data.preClaim);
                return response.data;
            }, failure);
        },
        getAllPreClaims({ status, from, to, source, page: requestPage }) {
            service.preClaims.splice(0);
            return $http.get(`/api/pre-claims?status=${status}&from=${from}&to=${to}&source=${source}`).then(response => {
                if (response.status != 200) throw response;
                if (Array.isArray(response.data)) {
                    response.data.map(claim => service.preClaims.push(claim));
                }
                return response.data;
            }, failure);
        },
        getPreClaimsByClient(clientId) {
            service.preClaims.splice(0);
            return $http.get(`/api/pre-claims/client/${clientId}`).then(response => {
                if (response.status != 200) throw response;
                if (Array.isArray(response.data)) {
                    response.data.map(claim => service.preClaims.push(claim));
                }
                return response.data;
            }, failure);
        },
        toggleDocumentsSent(claimId) {
            return $http.put(`/api/pre-claims/documents/sent/${claimId}`).then(success, failure);
        },
        toggleDocumentsReceived(claimId) {
            return $http.put(`/api/pre-claims/documents/received/${claimId}`).then(success, failure);
        },
        closeClaim(preClaimId) { // array of pre claim IDs
            if (!preClaimId) return Promise.reject('No potential claim ID passed to function.');
            return $http.put(`/api/pre-claims/close/${preClaimId}`).then(success, failure);
        },
        addToTimeline(claimId, activity, details) {
            return $http.put(`/api/pre-claims/timeline/${claimId}`, { activity, details }).then(success, failure);
        },
    };
    return service;
    function success(response) {
        if (response.status != 200) throw response;
        else return response.data;
    }
    function failure(error) { return error; }
}

export { PreClaimService };
