import { claimListViewController } from './claim_list_view_controller'
import { claimViewController } from './claim_view_controller'

function claimRoutes($stateProvider) {
    'ngInject'
    $stateProvider
    .state('root.auth.claims', {
        url: '/claims',
        abstract: true
    })
    .state('root.auth.claims.all', {
        url: '?status&query&sort',
        views: {
            'main@root.auth': {
                templateUrl: '/partials/views/claim/list.html',
                controller: claimListViewController,
                controllerAs: 'vm'
            }
        }
    })
    .state('root.auth.claims.claim', {
        url: '/:id',
        views: {
            'main@root.auth': {
                templateUrl: '/partials/views/claim/claim.html',
                controller: claimViewController,
                controllerAs: 'vm'
            }
        }
    })
}

export { claimRoutes }
