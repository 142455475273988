const gbbgFlagDetails = {
    template: `
    <div layout="column" disable-if-agent>
        <md-input-container>
            <label>Flag name</label>
            <input
                type="text"
                placeholder="e.g. organisation"
                maxlength="50"
                ng-pattern="vm.regex"
                ng-trim="false"
                ng-model="vm.flag.name"
                required
            />
            <div class="hint">Flag names can only contain letters, numbers, spaces, underscores and dashes.</div>
        </md-input-container>
        <md-input-container>
            <label>Keywords (comma separated)</label>
            <textarea
                rows="3"
                placeholder="e.g. organisation, .org, .int"
                ng-model="vm.flag.keywordString"
            ></textarea>
            <div class="hint">Keywords are used to automatically flag leads that might be ineligible.</div>
        </md-input-container>
        <gbbg-email-template-select selected-template="vm.flag.email"></gbbg-email-template-select>
        <md-input-container>
            <label>Email message</label>
            <textarea ng-model="vm.flag.emailMessage" md-maxlength="200" maxlength="200"></textarea>
            <span class="hint">This message is displayed in the ineligiblity email to describe why a lead was flagged.</span>
        </md-input-container>
    </div>
    `,
    bindings: { flag: '=' },
    controller,
    controllerAs: 'vm'
};
function controller() {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.regex = /^[a-zA-Z0-9-_ ]+(?: +[A-Za-z0-9_-]+)*$/;
}

export { gbbgFlagDetails };
