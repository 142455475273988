function LeadHandler($q) {
    "ngInject";
    const service = {
        currentLead: {},
        emptyLead: {
            _id: '',
            name: '',
            nature: '',
            description: '',
            notes: '',
            source: '',
            website: '',
            assignedTo: {},
            accountManager: {},
            address: {},
            createdBy: {},
            estimates: {},
            status: {},
            primaryContact: {},
            contacts: [],
            timeline: []
        },
        countryCodes: (localStorage.getItem('gbbgCountryCodes')) ? JSON.parse(localStorage.getItem('gbbgCountryCodes')) : [],
        setCurrentLead(lead) {
            return new Promise((resolve) => {
                lead.timeline = lead.timeline.sort((a, b) => {
                    a = new Date(a.timestamp);
                    b = new Date(b.timestamp);
                    return (a > b) ? -1 : (a < b) ? 1 : 0;
                }); // sort timeline by most recent timestamp
                if (service.currentLead.businessTravellers) service.currentLead.businessTravellers.splice(0);
                resolve(angular.merge(service.currentLead, lead));
            });
        },
        clearCurrentLead() {
            Object.keys(service.emptyLead).map(key => {
                service.currentLead[key] = angular.copy(service.emptyLead[key]);
            });
        },
        addToTimeline(activity) {
            if (!activity) return;
            if (!service.currentLead.timeline) service.currentLead.timeline = [];
            service.currentLead.timeline.unshift(activity);
        },
        isEmpty(lead) {
            return (lead && lead._id && (
                !lead.name && !lead.address && !lead.primaryContact && !lead.nature && !lead.description
            ));
        },
        // call this function inside the country dropdown selection
        updatePhoneNumber(country) {
            if (!country || !service.currentLead.primaryContact || (service.currentLead.primaryContact && service.currentLead.primaryContact.phone)) return;
            service.currentLead.primaryContact.phone = (service.countryCodes[country] && service.countryCodes[country].length) ? service.countryCodes[country] : '';
        }
    };
    return service;
}

export { LeadHandler };
