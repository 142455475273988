const gbbgClaimEmailDialog = {
    template: `
    <md-button
        aria-label="Send email"
        class="md-fab md-mini md-fab-bottom-right md-secondary"
        style="bottom: 26px; right: 85px;"
        ng-click="vm.openDialog()"
    >
        <md-icon md-font-library="material-icons">send</md-icon>
        <md-tooltip md-direction="top">Email client</md-tooltip>
    </md-button>
    `,
    bindings: { claimId: '@' },
    controller,
    controllerAs: 'vm'
};
function controller($mdDialog, $mdToast, ClaimService, EmailService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.openDialog = openDialog;

    function openDialog() {
        $mdDialog.show({
            templateUrl: '/partials/templates/client/timeline_dialog.html',
            locals: { claimId: vm.claimId },
            fullscreen: true,
            controller: dialogController,
            controllerAs: 'vm'
        }).then(({ details, email, recipients, sender, send }) => {
            if (send && send == true) {
                sendEmail(email, recipients, details, sender);
            } else {
                addToTimeline(details, email);
            }
        }, () => {});
    }

    function addToTimeline(details, email) {
        if (!vm.claimId) return;
        ClaimService.addToTimeline(vm.claimId, 'email-out', details, email).then(
            (data) => {
                if (vm.timeline) vm.timeline.unshift(data);
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Timeline updated successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            (error) => {
                ErrorHandler.showDialog('Timeline update failed', error);
                console.error('Timeline update failed:', error);
            }
        );
    }

    function sendEmail(email, recipients, details, sender) {
        EmailService.sendEmail(email, recipients, null, vm.claimId, details, sender).then(
            (emailId) => {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Email sent successfully!')
                    .position('top right')
                    .hideDelay(4000)
                );
            },
            (error) => {
                ErrorHandler.showDialog('Failed to send email', error);
                console.error('Failed to send email:', error);
            }
        );
    }
}
function dialogController(claimId, $mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this;

    vm.claimId = claimId;
    vm.details = '';

    vm.attachEmail = false;
    vm.allowSend = true;

    vm.email = {
        cc: [],
        bcc: []
    };
    vm.selectedRecipients = [];

    vm.submit = submit;

    function submit(send = false) {
        if (!vm.attachEmail) vm.email = null;
        $mdDialog.hide({
            details: vm.details,
            email: vm.email,
            recipients: vm.selectedRecipients,
            sender: vm.selectedSender,
            send
        });
    }
}

export { gbbgClaimEmailDialog };
