const gbbgClientFamily = {
    template: `
    <md-card style="max-width: 600px;">
        <md-card-title>
            <md-card-title-text>
                <span class="md-headline">Corporate family</span>
                <span class="md-subhead">Relationships help quickly identify inter-connected companies and corporate structures.</span>
            </md-card-title-text>
        </md-card-title>
        <md-divider></md-divider>
        <md-list>
            <md-list-item ng-repeat="member in vm.family">
                <p>{{member.company.name}} ({{member.relationship}})</p>
                <md-icon
                    class="md-secondary"
                    aria-label="Open client"
                    ng-click="vm.openClientRecord(member.company)"
                >open_in_new_window</md-icon>
                <md-icon
                    class="md-secondary"
                    aria-label="Open client"
                    ng-click="vm.openDeleteRelationshipDialog(member)"
                >delete_outline</md-icon>
            </md-list-item>
        </md-list>
        <md-divider></md-divider>
        <md-card-actions layout="row">
            <span flex></span>
            <md-button ng-click="vm.openNewRelationshipDialog()">Add relationship</md-button>
        </md-card-actions>
    </md-card>
    `,
    bindings: { family: '<?' },
    controller,
    controllerAs: 'vm'
}
function controller($mdDialog, $state, ActiveService, ErrorHandler) {
    'ngInject';
    /*validthis:true*/
    const vm = this

    vm.openNewRelationshipDialog = openNewRelationshipDialog
    vm.openDeleteRelationshipDialog = openDeleteRelationshipDialog
    vm.openClientRecord = openClientRecord


    function openNewRelationshipDialog() {
        $mdDialog.show({
            template: `
            <md-dialog aria-label="Add relationship" style="width: 500px;">
                <md-dialog-content layout="column" layout-padding>
                    <gbbg-client-name-autocomplete
                        client="vm.newRelationship"
                        no-prompt="true"
                        status="active"
                    ></gbbg-client-name-autocomplete>
                    <md-input-container>
                        <label>Relationship type</label>
                        <md-select ng-model="vm.newRelationship.relationship">
                            <md-option
                                ng-repeat="relationship in vm.relationships"
                                ng-value="relationship"
                            > {{relationship}} </md-option>
                        </md-select>
                    </md-input-container>
                </md-dialog-content>
                <md-divider></md-divider>
                <md-dialog-actions layout="row">
                    <span flex></span>
                    <md-button ng-click="vm.cancel()">
                        Cancel
                    </md-button>
                    <md-button
                        ng-click="vm.create()"
                        ng-disabled="!vm.newRelationship.company"
                    > Create </md-button>
                </md-dialog-actions>
            </md-dialog>
            `,
            controller: newRelationshipDialogController,
            controllerAs: 'vm'
        }).then((newRelationship) => {
            if (!newRelationship.company || !newRelationship.relationship) return;
            ActiveService
            .addNewRelationship(newRelationship).then(
                () => {},
                (error) => {
                    ErrorHandler.showDialog('Failed to create new relationship', error)
                    console.error('Failed to create new relationship:', error)
                }
            )
        }, () => {})
    }

    function openDeleteRelationshipDialog(relationship) {
        $mdDialog.show(
            $mdDialog.confirm()
            .title('Are you sure you want to delete this relationship?')
            .textContent('This will also remove the relationship for the connected client record.')
            .ariaLabel('Delete relationship')
            .ok('Delete')
            .cancel('Cancel')
        ).then(() => {
            ActiveService
            .deleteRelationship(relationship).then(
                () => {},
                (error) => {
                    ErrorHandler.showDialog('Failed to delete relationship', error)
                    console.error('Failed to delete relationship:', error)
                }
            )
        }, () => {})
    }

    function openClientRecord(company) {
        if (company.status && company.status.status === 'active') {
            $state.go('root.auth.active.client', { id: company._id })
        } else {
            $state.go('root.auth.leads.lead', { leadId: company._id })
        }
    }
}

function newRelationshipDialogController($mdDialog) {
    'ngInject';
    /*validthis:true*/
    const vm = this

    vm.newRelationship = {
        company: '',
        relationship: 'sister'
    }

    vm.relationships = [ 'sister', 'parent', 'child' ]

    vm.cancel = $mdDialog.cancel
    vm.create = create

    function create() {
        if (vm.newRelationship.company._id) vm.newRelationship.company = vm.newRelationship.company._id
        $mdDialog.hide(vm.newRelationship)
    }
}

export { gbbgClientFamily }
