import { supplierListViewController } from './supplier_list_view_controller'

function supplierRoutes($stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.suppliers', {
        url: '/suppliers',
        views: {
            "main@root.auth": {
                templateUrl: '/partials/views/supplier/list.html',
                controller: supplierListViewController,
                controllerAs: 'vm'
            }
        }
    })
}

export { supplierRoutes }
