import { flagListViewController } from './flag_list_view_controller';

function flagRoutes($locationProvider, $stateProvider) {
    "ngInject";
    $stateProvider
    .state('root.auth.management.flags', {
        url: "/flag",
        views: {
            "top@root.auth": {
                template: `
                <gbbg-top-nav>
                    <gbbg-new-flag></gbbg-new-flag>
                </gbbg-top-nav>
                `
            },
            "main@root.auth": {
                templateUrl: '/partials/views/flag/list.html',
                controller: flagListViewController,
                controllerAs: 'vm'
            }
        }
    });
}

export { flagRoutes };
